/* =========== IMAGE PATH VARIABLE ============= */
$assetPath : "/assets/images";
/* Usage of bacground image */
//background: url(#{$assetPath}/background.jpg) repeat-x fixed 0 0;

// Color system
$white:    	#ffffff;
$dark-black:#000000;	
$black:	    #222222;	
$primary: 	#0E378C;
$secondary:	#6c757d;
$success:  	#47B872;
$info:     	#17a2b8;
$warning: 	#fd7e14;
$danger:  	#E73945;
$light:   	#f8f9fa;
$dark:    	#474747;
$light-gray:#9D9D9D;
$switch-gray:#c2c2c2;
$gary: 		  #E3E3E3;
$extra-light-gray:#EFEFEF;
$facebook:#405993;
$linkedin:#2F72AC;
$disabled:#CCCCCC;
$yellow: 	#F3C32F;
$wizard-border-bg:#D6D6D6;
$light-red:#f3193e;
$light-blue:#164095; 
$light-blue-btn:#f2f5f9;
$box-border-color:#e8e8e8;
$iframe-bg:#e3e3e8;
$dark-gray:#afa6a6;
$white-light:#dddddd;

// Body
// Settings for the `<body>` element.
$body-bg: $extra-light-gray;
$body-color: $black;

// Links
// Style anchor elements.
$link-color:#222222;
$link-decoration:none;
$link-hover-color:darken($primary, 50%);
$link-hover-decoration:underline;

// Font
$font-family-base: 'Source Sans Pro', sans-serif;
$font-heading: 'Source Sans Pro', sans-serif;
$font-size-base: 1rem; // Assumes the browser default, typically `16px` 
$font-custom:16; 
$font-size-lg: $font-size-base * 1.25; //20px
$font-size-sm: $font-size-base * .875; //14px
$font-lighter: lighter;
$font-light: 300;
$font-regular:400;
$font-medium:500;
$font-semibold: 600;
$font-bold: 700;
$font-bolder: bolder;
$line-height-base: 1.25;

// Style p element.
$paragraph-margin-bottom:   1rem;

// Media BreakPoint
$breakpoint-xxxxl: 1680.97px;
$breakpoint-xxxl: 1540.91px;
$breakpoint-xxl: 1440.91px;
$breakpoint-xxl-b: 1400.91px;
$breakpoint-x-xl: 1280.91px;
$breakpoint-xl: 1200.91px;
$breakpoint-lg: 992px;
$breakpoint-slg: 840px;
$breakpoint-md: 768.91px;
$breakpoint-sm-sm: 767.91px;
$breakpoint-sm: 576.91px;
$breakpoint-xs-xs: 480.91px;
$breakpoint-xs: 414.91px;
$breakpoint-xsw: 375.91px;
$breakpoint-xsww: 320.21px;



// Components
// Define common padding and border radius sizes and more.
$border-width:1px;

$border-radius-24:1.5rem;
$border-radius-12:0.75rem;
$border-radius-8:0.5rem;
$border-radius-4:0.25rem;

$rounded-pill:50rem;

$box-shadow-sm:0 .125rem .25rem rgba($black, .075);
$box-shadow:0 .5rem 1rem rgba($black, .15);
$box-shadow-lg:0 1rem 3rem rgba($black, .175);
$box-shadow-0:none;

$caret-width:.3em;
$caret-vertical-align:$caret-width * .85;
$caret-spacing:$caret-width * .85;

$transition-base:all .2s ease-in-out;
$transition-fade:opacity .15s linear;
$transition-collapse:height .35s ease;

// Buttons
$btn-padding-y:               1rem;
$btn-padding-x:               1rem;
$btn-font-family:             $font-family-base;
$btn-font-size:               $font-size-base;
$btn-line-height:             $line-height-base;
$btn-white-space:             null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            0.5rem;
$btn-padding-x-sm:            0.5rem;
$btn-font-size-sm:            $font-size-sm;

$btn-padding-y-lg:            1.25rem;
$btn-padding-x-lg:            1.25rem;
$btn-font-size-lg:            $font-size-lg;

// Forms
$label-margin-bottom:         .5rem;
$input-padding-y:             1rem;
$input-padding-x:             1rem;
$input-font-family:           $font-family-base;
$input-font-size:             $font-size-base;
$input-font-weight:           $font-regular;

$input-padding-y-sm:          0.5rem;
$input-padding-x-sm:          0.5rem;
$input-font-size-sm:          $font-size-sm;

$input-padding-y-lg:          1.25rem;
$input-padding-x-lg:          1.25rem;
$input-font-size-lg:          $font-size-lg;

//Gradiant Style
$fallback:$primary;
$from:$primary;
$to:darken($primary,10);
$direction:225deg;

// Quickly modify global styling by enabling or disabling optional features.
$enable-caret:                                true !default;
$enable-rounded:                              true !default;
$enable-shadows:                              false !default;
$enable-gradients:                            false !default;
$enable-transitions:                          true !default;
$enable-prefers-reduced-motion-media-query:   true !default;
$enable-hover-media-query:                    false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:                         true !default;
$enable-pointer-cursor-for-buttons:           true !default;
$enable-print-styles:                         true !default;
$enable-responsive-font-sizes:                true !default;
$enable-validation-icons:                     true !default;
$enable-deprecation-messages:                 true !default;

// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
  ),
  $spacers
);

// fonts size
$font-12:0.75rem;
$font-13:0.8125rem;
$font-14:0.875rem;
$font-15:0.9375rem;
$font-16:1rem;
$font-18:1.125rem;
$font-20:1.25rem;
$font-22:1.375rem;
$font-24:1.5rem;
$font-28:1.75rem;
$font-32:2rem;
$font-34:2.125rem;

 
// Margin
$margin-bottom-50:3.125rem;
$margin-bottom-40:2.5rem;
$margin-bottom-35:2.1875rem;
$margin-bottom-30:1.875rem; 
$margin-bottom-25:1.5625rem;
$margin-bottom-20:1.25rem;
$margin-bottom-15:0.9375rem;
$margin-bottom-10:0.625rem;
$margin-bottom-5:0.3125rem;

$margin-top-50:3.125rem;
$margin-top-40:2.5rem;
$margin-top-35:2.1875rem;
$margin-top-30:1.875rem;
$margin-top-25:1.5625rem;
$margin-top-20:1.25rem;
$margin-top-15:0.9375rem;
$margin-top-10:0.625rem; 
$margin-top-5:0.3125rem;

// Padding
$padding-bottom-50:3.125rem;
$padding-bottom-40:2.5rem;
$padding-bottom-30:1.875rem;
$padding-bottom-25:1.5625rem;
$padding-bottom-20:1.25rem;
$padding-bottom-15:0.9375rem;
$padding-bottom-10:0.625rem;
$padding-bottom-5:0.3125rem;

$padding-top-50:3.125rem;
$padding-top-40:2.5rem;
$padding-top-30:1.875rem;
$padding-top-25:1.5625rem;
$padding-top-20:1.25rem;
$padding-top-15:0.9375rem;
$padding-top-10:0.625rem;




