.reset-pass-wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: size(110) size(0);
  @include media(down, $breakpoint-md){
    padding: size(50) size(0);
  }
  .card{
    border: none;
    border-radius: $border-radius-24;
    background-color: $white;
    padding: 100px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    box-shadow: 0 0 25px rgba(0,0,0,0.15);
    flex-direction: inherit;
    width: 100%;
    max-width: 1064px;
    margin: 0 auto;
    @include media(down, $breakpoint-lg){
      padding: size(60) size(35);
    }
    @include media(down, $breakpoint-md){
      flex-direction: column;
      padding: size(35);
    }
    .imgbox{
      width: 100%;
      max-width: 409px;
      @include media(down, $breakpoint-lg){
        max-width: 280px;
      }
      @include media(down, $breakpoint-md){
        margin: 0 auto size(35);
        max-width: 400px;
      }
    }
    form{
      text-align: center;
      width: 100%;
      max-width: 390px;
      @include media(down, $breakpoint-lg){
        max-width: 290px;
      }
      @include media(down, $breakpoint-md){
        max-width: 310px;
      }
      h3{
        text-align: center;
        margin-bottom: $margin-bottom-10;
      }
      p{
        text-align: center;
        margin-bottom: $margin-bottom-30;
        @include media(down, $breakpoint-lg){
          margin-bottom: $margin-bottom-20;
        }
      }
      .btngroup{
        display: flex;
        align-items: center;
        justify-content: center;
        .btn{
          max-width: 185px;
          width: 100%;
          &:first-child{
            margin-right: size(20);
          }
        }
      }
    }
  }
} 