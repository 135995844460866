// Begin: Button
.btn {
    @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $line-height-base, $box-shadow-0);
    min-height: 56px;
    padding: size(13) size(16);
    border-radius: $border-radius-12;
    font-size: $font-18;
    line-height: 1.66;
    text-transform: capitalize;
    position: relative;
    overflow: hidden;
    letter-spacing: -0.12px;
    font-weight: $font-semibold;
    min-width: 100px;
    @include media(down, $breakpoint-sm-sm) {
        padding: size(10) size(16);
        min-height: initial;
        font-size: $font-15;
    }
    &.btn-sm {
        font-size: $font-15;
        line-height: size(22);
        min-height: 40px;
        padding: size(8) size(20);
        border-radius: $border-radius-4;
    }
    &.btn-primary {
        background-color: $primary;
        color: $white;
        border: 1px solid $primary;
        &:hover {
            background-color: $white;
            color: $primary;
        }
        &:disabled {
            color: $white;
            background-color: $disabled;
            border-color: $disabled;
            cursor: no-drop;
        }
    }
    &.btn-tertiary{
        background-color: $light-blue-btn;
        color: $primary;
        border: 1px solid $light-blue-btn;
        &:hover { 
            background-color: $primary;
            color: $white;  
            span{
                &:before{
                    color: $white !important; 
                }
            }
        } 
        &:disabled {
            color: $white;
            background-color: $disabled;
            border-color: $disabled;
            cursor: no-drop;
        }
    }
    &.btn-primary:hover,
    &.btn-primary,
    &.btn-primary:not(:disabled):not(.disabled):active,
    .show>&.btn-primary.dropdown-toggle,
    &.btn-primary:not(:disabled):not(.disabled).active:focus,
    &.btn-primary:not(:disabled):not(.disabled):active:focus,
    .show>&.btn-primary.dropdown-toggle:focus {
        box-shadow: none;
        text-decoration: none;
    }
    &.btn-default {
        background-color: $white;
        color: $primary;
        border: 1px solid $white;
        &:hover {
            background-color: $primary;
            color: $white;
            border: 1px solid $white;
        }
    }
    &.btn-small{
        font-size: size(16);
        border-radius: $border-radius-4;
        padding: size(5) size(16);
        min-height: 36px;
        line-height: size(26);
        font-weight: $font-regular;
    }
    &.btn-dark {
        background-color: $black;
        color: $white;
        border: 1px solid $black;
        &:hover {
            background-color: $white;
            color: $primary;
            border: 1px solid $primary;
        }
    }
    &.border-btn{
        background-color: transparent;
        border: 2px solid $primary;
        color: $primary;
        &:hover {
            background-color: $primary;
            color: $white;
        }
    }
    &.facebook-btn {
        background-color: $facebook;
        color: $white;
        border: 1px solid $facebook;
        &:hover {
            background-color: $facebook;
            color: $white;
            border: 1px solid $facebook;
        }
    }
    &.linkedin-btn {
        background-color: $linkedin;
        color: $white;
        border: 1px solid $linkedin;
        &:hover {
            background-color: $linkedin;
            color: $white;
            border: 1px solid $linkedin;
        }
    }
    &.disabled {
        color: $white;
        background-color: $disabled;
        border-color: $disabled;
        cursor: no-drop;
    }
    &.btn-accept {
        background-color: $success;
        color: $white;
        border: 1px solid $success;
        &:hover {
            background-color: $white;
            color: $success;
        }
        &:disabled {
            color: $white;
            background-color: $disabled;
            border-color: $disabled;
            cursor: no-drop;
        }
    }
    &.btn-reject {
        background-color: $danger;
        color: $white;
        border: 1px solid $danger;
        &:hover {
            background-color: $white;
            color: $danger;
        }
        &:disabled {
            color: $white;
            background-color: $disabled;
            border-color: $disabled;
            cursor: no-drop;
        }
    }
    &.btn-pending {
        background-color: $yellow;
        color: $white;
        border: 1px solid $yellow;
        &:hover {
            background-color: $white;
            color: $yellow;
        }
        &:disabled {
            color: $white;
            background-color: $disabled;
            border-color: $disabled;
            cursor: no-drop;
        }
    }
    &.btn-lg {
        max-width: 370px;
        width: 100%;
    }
    &.btn-dashed {
        border: 2px dashed $extra-light-gray;
        background-color: transparent;
        color: $primary;
        &:hover {
            border-color: $primary;
        }
    }
    &.btn-danger{
        background-color: $danger;
        border-color: $danger;
        color: $white;
    }
    &.btn-yellow{
        background-color: $yellow;
        color: $white;
        border: 1px solid $yellow;
        &:hover {
            background-color: $white;
            color: $yellow;
        }
        &:disabled {
            color: $white;
            background-color: $disabled;
            border-color: $disabled;
            cursor: no-drop;
        }
    }
    &.btn-teritary{
        background-color: $light-blue-btn;
        color: $primary;
        border: 1px solid $light-blue-btn;
        &:hover { 
            background-color: $primary;
            color: $white;  
            span{
                &:before{
                    color: $white !important; 
                }
            }
        } 
        &:disabled {
            color: $white;
            background-color: $disabled;
            border-color: $disabled;
            cursor: no-drop;
        }
    }
    &.btn-alert{
        min-width: min-content;
        padding: size(4) size(10);
        min-height:30px;
        line-height: inherit;
    }
    &.mw-114{
        min-width: 114px !important;
    }
    &.mw-335{
        max-width: 335px !important;
    }
    &.mw-180{
        min-width: 180px !important;
        @include media(down, $breakpoint-sm-sm){
            min-width: 130px !important;
        }
    }
    &.mw-185{
        min-width: 185px !important;
        @include media(down, $breakpoint-sm-sm){
            min-width: 135px !important;
        }
    }
    &.mw-200{
        min-width: 200px !important;
        @include media(down, $breakpoint-sm-sm){
            min-width: 140px !important;
        }
    }
}

// Form Control
.form-group {
    margin-bottom: $margin-bottom-20;
    label {
        text-align: left;
        display: flex;
        align-items: center;
        font-size: size(14);
        sup {
            top: -3px;
            left: 4px;
        }
        .icon-icon-info {
            margin-left: size(10);
            cursor: pointer;
        }
    }
    &.field-error {
        label {
            sup {
                color: $danger;
            }
        }
    }
    .input-info-span {
        display: flex;
        justify-content: flex-end;
        color: $light-gray;
        margin-top: $margin-top-5;
    }
    .input-note-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            color: $light-gray;
            font-size: size(13);
        }
        .btn {
            &.text-danger {
                color: $danger;
                font-size: size(13);
                min-height: inherit;
                min-width: inherit;
            }
        }
    }
}


/* border input  */

.border-btm-input {
    position: relative;
    .form-control {
        &.normal-input {
            border: none;
            border-bottom: 2px solid $extra-light-gray;
            border-radius: 0;
            padding: 13px 105px 13px 0;
            background: transparent;
            &:placeholder-shown {                
                border-color: $extra-light-gray;
                background: transparent;
            }
        }
    }
    .btn {
        position: absolute;
        right: 0;
        top: 0;
        padding: size(13) 0;
        text-align: right;
        min-width: inherit;
        font-size: size(14);
    }
}

textarea {
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

input {
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

.form-control {
    @include placeholder {
        //color:$gary;
    }
    border: 1px solid $light-gray;
    background-color: $white;
    padding: size(13) size(24);
    width: 100%;
    height: 56px;
    border-radius: $border-radius-12;
    @include media(down, $breakpoint-sm) {
        height: 46px;
    }
    &.focus,
    &:focus {
        @include placeholder {
            color: $light-gray;
        }
    }
    &[readonly] {
        color: $light-gray;
        border: 0;
        background-color: $extra-light-gray;
    }
    &:placeholder-shown {
        background: $extra-light-gray;
        border: 1px solid $extra-light-gray; 
        text-overflow: ellipsis !important;
    }
}

.sm-form-control {
    font-size: size(15);
    line-height: size(22);
    color: $black;
    width: 100%;
    height: 41px;
    border: none;
    background: $extra-light-gray;
    border-radius: $border-radius-4;
    padding: size(9) size(16);
}

.otp-input {
    border: 1px solid $extra-light-gray !important;
    background-color: $extra-light-gray;
    padding: size(5) size(10);
    width: 100%;
    height: 56px;
    font-size: size(16) !important;
    @include media(down, $breakpoint-sm) {
        height: 46px;
    }
    &.focus,
    &:focus {
        @include placeholder {
            color: $light-gray;
        }
        border-color: $primary !important;
        background-color: $white;
    }
    &[readonly] {
        color: $gary;
    }
}

.has-danger {
    color: $danger;
    .form-control {
        border-color: $danger;
    }
}

.input-group-otp {
    display: flex;
    align-items: center;
    justify-content: center;
    .form-control {
        padding: size(10);
        max-width: 44px;
        width: 100%;
        margin-right: size(10);
        text-align: center;
        &:last-child {
            margin-right: 0;
        }
        @include media(down, $breakpoint-sm) {
            padding: size(6);
        }
    }
}

.select-box {
    margin-bottom: $margin-bottom-20;
}

ng-select {
    &.ng-select-opened,
    &.ng-select-focused {
        .ng-select-container {
            color: $black;
            background-color: $white !important;
            border-color: $primary !important;
        }
    }
    .ng-select-container {
        border: 1px solid $extra-light-gray !important;
        background-color: $extra-light-gray !important;
        padding: size(13) size(24) !important;
        width: 100% !important;
        .ng-value-container {
            padding-left: 0 !important;
            .ng-input {
                input {
                    color: $light-gray !important;
                    margin-top: 5px;
                    @include media(down, $breakpoint-sm-sm){
                        color: $secondary !important;
                    }
                }
            }
            .ng-value{
                text-align: left;
                .ng-value-label{
                    span{
                        margin-right: size(4);
                        &::before{
                            color: $black;
                        }
                    }
                }
            }
        }
        .ng-placeholder {
            color: $light-gray !important;
        }
        .ng-value-label {
            color: $black;
            .selected-icon{
                display: none;
            }
        }
        &.ng-has-value {
            background-color: $white !important;
            border-color: $light-gray !important;
        }
    }
    .ng-arrow-wrapper {
        padding-right: 0 !important;
        width: 10px !important;
        background-image: url('../../images/dropdown-arrow.svg');
        background-position: center center;
        background-repeat: no-repeat;
        .ng-arrow {
            border: none !important;
        }
    }
    .ng-dropdown-panel {
        .ng-dropdown-panel-items {
            .ng-option {
                text-align: left;
                color: $black !important;
                position: relative;
                padding-right: size(35);
                &:hover {
                    color: $primary !important;
                }
                .selected-icon{
                    position: absolute;
                    right: 15px;
                    display: none;
                    top: 26%;
                }
                &.ng-option-selected{
                    background-color: rgba(14, 55, 140, 0.05)!important;
                    .selected-icon{
                        display: block;
                    }
                }
                .ng-option-label{
                    span{
                        &::before{
                            color: $black;
                        }
                    }
                }
            }
        }
    }
    &.ng-select-multiple {
        .ng-select-container {
            .ng-value-container {
                padding-top: 0 !important;
                .ng-value {
                    position: relative;
                    padding: size(7) size(20) size(7) size(7);
                    background-color: $primary !important;
                    border-radius: $border-radius-4 !important;
                    text-align: left;
                    .ng-value-icon {
                        border-right: none !important;
                        color: rgba(255, 255, 255, 0.4);
                        position: absolute;
                        right: 0;
                        font-size: size(26);
                        line-height: 16px;
                        &:hover {
                            background-color: transparent !important;
                            color: $danger;
                        }
                    }
                    .ng-value-label {
                        color: $white;
                        text-transform: capitalize;
                        font-size: size(15);
                        
                    }
                }
                .ng-placeholder{
                    top: 35% !important;
                }
            }
        }
    }
    &.ng-select-single {
        .ng-select-container {
            &.ng-has-value {
                background-color: $white !important;
                border-color: $light-gray !important;
            }
            .ng-value-container{
                .ng-select-container {
                    .ng-value-container {
                        .ng-value{
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}


/* Upload file input */

.custom-file-input:focus~.custom-file-label {
    border-color: $primary;
    box-shadow: none;
    cursor: pointer;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Add Now";
    cursor: pointer;
}

.custom-file {
    position: relative;
    cursor: pointer;
    .form-control{
        padding: size(13) size(115) size(13) size(24);
        @include media(down, $breakpoint-xs-xs){
            padding: size(13) size(105) size(13) size(24);
        }
    }
    &.after-none{
        .custom-file-label{
            &:after{
                display: none;
            }
        }
    }
    .option-text{
        position: absolute;
        top: 20px;
        right: 45px;
        z-index: 9;
        color: $primary; 
        font-size: size(14);
        @include media(down, $breakpoint-xs-xs){
            top: 15px;
        }
    }
    .delet-option{
        position: absolute;
        right: 15px;
        top: 18px;
        font-size: size(18);
        z-index: 9;
        @include media(down, $breakpoint-xs-xs){
            top: 14px;
        }
        span{
            color: $primary;
        }
    }
    label {
        border: 1px solid $light-gray;
        background-color: $white;
        padding: size(13) size(24);
        width: 100%;
        height: 56px;
        border-radius: $border-radius-12;
        cursor: pointer;
        line-height: 1.8;
        margin-bottom: 0;
        overflow: hidden;
        @include media(down,
        $breakpoint-sm) {
            height: auto;
            line-height: 1.2;
            padding: size(13) size(15);
        }
        &.focus,
        &:focus {
            @include placeholder {
                color: $light-gray;
            }
        }
        &[readonly] {
            color: $gary;
        }
        &:placeholder-shown {
            border: 1px solid $extra-light-gray;
            background: $extra-light-gray;
        }
        &.custom-file-label {
            &::after {
                background-color: transparent;
                border-left: none;
                color: $primary;
                @include media(down,
                $breakpoint-sm) {
                    height: calc(1.25em + 1.66rem);
                    line-height: 0.8;
                }
            }
        }
    }
    .upload-item-action{
        position: absolute;
        top: 2px;
        right: 1px;
        background-color: $white;
        display: flex;
        padding: 5px;
        align-items: center;
        width: 91px;
        height: 52px;
        z-index: 9;
        justify-content: center;
        text-align: center;
        border-radius: 0 10px 10px 0;
        overflow: hidden;
        @include media(down, $breakpoint-sm){
            height: 40px;
        }
        .option-text, .delet-option{
            position: relative;
            margin: 0 3px;
            top: inherit;
            right: inherit;
            cursor: pointer;
        }
        .delet-option{
            font-size: size(16);
        }
    }
}


/*==Custom Radio & Checkbox ==*/

.custom-checkbox {
    text-align: left;
    a {
        font-weight: $font-semibold;
        margin-left: size(5);
    }
    &.vertical {
        label {
            display: block;
        }
    }
    label {
        margin: size(0) size(10) size(10) size(0);
        padding: size(0) size(0) size(0) size(35);
        line-height: 24px;
        cursor: pointer;
        position: relative;
        display: inline-block
    }
    input[type="checkbox"] {
        display: none;
        +span {
            content: "";
            display: inline-block;
            width: 24px;
            height: 24px;
            position: absolute;
            left: 0;
            background: $gary;
            border: 1px solid $extra-light-gray;
            border-radius: $border-radius;
        }
        &:checked {
            +span {
                border: 1px solid $primary;
                background: $primary url('../../images/white-tick.svg');
                background-repeat: no-repeat;
                background-position: center center;
            }
            ~span:after {
                top: 50%;
                left: 50%;
                margin-left: size(-2);
                margin-top: size(-7);
                width: 5px;
                height: 10px;
                border-width: 0 1px 1px 0 !important;
                display: block;
                border: solid $white;
                @include css3-prefix(transform,rotate(45deg));
            }
        }
        ~span:after {
            display: none;
            position: absolute;
            content: "";
        }
        &:disabled {
            +span {
                opacity: 0.7;
            }
        }
    }
}
.custom-radio-square {
    text-align: left;
    a {
        font-weight: $font-semibold;
        margin-left: 5px;
    }
    &.vertical {
        label {
            display: block;
        }
    }
    label {
        margin: size(0) size(10) size(20) size(0);
        padding: size(0) size(0) size(0) size(40);
        line-height: 24px;
        cursor: pointer;
        position: relative;
        display: block;
    }
    input[type="radio"] {
        display: none;
        +span {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            position: absolute;
            left: 0;
            background: transparent;
            border: 1px solid $light-gray;
            border-radius: $border-radius;
        }
        &:checked {
            +span {
                border: 1px solid $primary;
                background: $primary url('../../images/white-tick.svg');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 73%;
            }
            ~span:after {
                top: 50%;
                left: 50%;
                margin-left: size(-2);
                margin-top:size(-7);
                width: 5px;
                height: 10px;
                border-width: 0 1px 1px 0 !important;
                display: block;
                border: solid $white;
                @include css3-prefix(transform,rotate(45deg));
            }
        }
        ~span:after {
            display: none;
            position: absolute;
            content: "";
        }
        &:disabled {
            +span {
                opacity: 0.7;
            }
        }
    }
}

.custom-radio {
    &.vertical {
        label {
            display: block;
        }
    }
    label {
        margin: size(0) size(10) size(10) size(0);
        padding-left: size(40);
        vertical-align: -2px;
        cursor: pointer;
        position: relative;
        display: inline-block;
        font-size: size(17) !important;
        text-transform: capitalize !important;
        @include media(down, $breakpoint-sm-sm){
            padding-left:size(30);
            vertical-align: 1px;
        }
    }
    input[type="radio"] {
        display: none;
        +span {
            content: "";
            display: inline-block;
            width: 24px;
            height: 24px; 
            position: absolute;
            background: $white;
            border: 1px solid $light-gray;
            border-radius: 50%;
            @include media(down, $breakpoint-sm-sm){
                width: 18px;
                height: 18px;
            }
        }
        ~span:after {
            display: none;
            position: absolute;
            content: "";
        }
        &:disabled {
            +span {
                opacity: 0.7;
            }
        }
        &:checked {
            +span {
                background: $white;
                border: 1px solid $primary;
                @include css3-prefix(transition,all 0.3s);
            }
            ~span:after {
                top: 50%;
                left: 50%;
                margin-left: -8px;
                margin-top: -8px;
                width: 16px;
                height: 16px;
                display: block;
                background: $primary;
                @include border-radius(50%);
                @include media(down, $breakpoint-sm-sm){
                    width: 12px;
                    height: 12px;
                    margin-left: size(-6);
                    margin-top: size(-6);
                }
            }
        }
    }
}

.ie8 .custom-checkbox input[type="checkbox"],
.ie8 .custom-radio input[type="radio"] {
    display: inline-block;
}

.ie8 .custom-checkbox span,
.ie8 .custom-radio span {
    display: none;
}


/* input with icon */

.input-with-icon {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    .form-control {
        padding: size(13) size(70) size(13) size(24);
    }
    span {
        position: absolute;
        right: 25px;
        cursor: pointer;
    }
}


/* input with left icon  */

.left-icon-input {
    width: 100%;
    position: relative;
    input {
        width: 100%;
        background-color: $white;
        color: $light-gray;
        font-size: size(16);
        line-height: size(26);
        letter-spacing: size(-0.25);
        border-radius: $border-radius-12;
        padding: size(12) size(40) size(12) size(40);
        border-color: $gary;
    }
    .icon-icon-search {
        position: absolute;
        left: 13px;
        top: 20px;
        font-size: size(16);
    }
    .search-close {
        position: absolute;
        right: 13px;
        top: 20px;
        font-size: size(16);
        cursor: pointer;
    }
}

.input-with-right-text {
    position: relative;
    width: 100%;
    input {
        padding: size(12) size(50) size(12) size(24);
    }
    span {
        position: absolute;
        right: 0;
        top: 0;
        padding: size(18);
        background-color: transparent;
    }
}

.input-with-right-text-group {
    em {
        width: 100%;
        max-width: 26px;
        height: 2px;
        background-color: $light-gray;
        display: block;
        border-radius: $border-radius-4;
        margin: size(27) auto;
    }
}


/* Calender Style */

bs-datepicker-container {
    z-index: 1049;
    background-color: $white;
    border-radius: $border-radius-12;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    button {
        border: none;
        box-shadow: none;
        background-color: transparent;
        color: $white;
    }
    
    .bs-datepicker-head {
        display: flex;
        align-items: center;
        background-color: $primary;
        justify-content: center;
        padding: size(15);
        border-radius: 12px 12px 0 0;
    }
    .bs-datepicker-body {
        padding: size(15);
        table {
            tr {
                td {
                    span {
                        color: $black;
                        width: 30px;
                        height: 30px;
                        padding: 4px;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        &.selected {
                            color: $white;
                            background-color: $primary;
                        }
                        &.disabled{
                            color: $dark-gray;
                            cursor: no-drop;
                        }
                        &.is-other-month{
                            color: $dark-gray;
                            cursor: no-drop;
                        }
                    }
                    &.disabled{
                        span{
                            color: $dark-gray;
                            cursor: no-drop;
                        }
                    }
                }
            }
        }
        .years{
            .selected{
                border-radius: $border-radius-4;
            }
        }
    }
    bs-month-calendar-view {
        .bs-datepicker-body {
            padding: size(15);
            table {
                tr {
                    td {
                        span {
                            width: auto;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
    bs-years-calendar-view {
        .bs-datepicker-body {
            padding: size(15);
            table {
                tr {
                    td {
                        span {
                            width: auto;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
}
.modal-open{
    bs-datepicker-container{
        z-index: 9999;
    }
}

/* Selector Radio button */

.radio-button-box {
    padding: 0 size(15) size(15);
    max-width: 145px;
    text-align: center;
    @include media(down,
    $breakpoint-sm) {
        max-width: 125px;
    }
    h6 {
        font-weight: $font-semibold;
        @include media(down,
        $breakpoint-sm) {
            font-size: size(14);
        }
    }
    input[type=radio] {
        display: none;
        &:checked {
            &+.box {
                background-color: $primary;
                border-color: $primary;
                span {
                    &::before {
                        color: $white;
                    }
                }
            }
        }
    }
    .box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        background-color: $white;
        transition: all 250ms ease;
        will-change: transition;
        text-align: center;
        cursor: pointer;
        position: relative;
        border-radius: 100%;
        border: 2px solid $extra-light-gray;
        &:active {
            transform: translateY(10px);
            span {
                &::before {
                    color: $primary;
                }
            }
        }
        span {
            transition: all 300ms ease;
            font-size: size(30);
            user-select: none;
            &::before {
                color: $light-gray;
            }
        }
    }
}


/* Raounded Radio Button */


/* Customize the label (the container) */

.normal-radio-wrap {
    display: block;
    position: relative;
    padding-left: size(35);
    margin-bottom: size(12);
    cursor: pointer;
    font-size: size(17);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $black;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked {
            &~.checkmark {
                background-color: $white;
                &::after {
                    display: block;
                }
            }
        }
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: $white;
        border-radius: 50%;
        border: 1px solid $primary;
        &:after {
            content: "";
            position: absolute;
            display: none;
            top: 3px;
            left: 3px;
            width: 17px;
            height: 17px;
            border-radius: 50%;
            background: $primary;
        }
    }
}
.cross-wrap{
    position: relative;
    .cancel-btn{
        position: absolute;
        right: -80px;
        @include media(down, $breakpoint-md){
            right: -20px;
            width: auto;
            top: 7px;
        }
        @include media(down, $breakpoint-xs-xs){
            top: 0;
        }
    }
    .form-control{
        @include media(down, $breakpoint-md){
            padding:size(13) size(50) size(13) size(24);
        }
       
    }

}
.v-align-cross{ 
   &.cross-wrap{
        .cancel-btn{
            top: 95px;
        }
    }
}
.inner-side-close{
    .input-with-icon{
        width: 90%;
       
    }
    .cancel-btn{
        right: -35px;
    }
}
.invite-btn{
    min-width: 100px !important;
    display: -webkit-inline-box;
} 

/*Start of slect*/
.ng-select {
    &.ng-select-single {
        .ng-select-container {
            border-color: $extra-light-gray !important;
            border-radius:size(4);
            background-color: $white;
            font-size: size(16);
            color: $primary;
            font-weight: $font-regular;
            height: auto;
            .ng-value-container {
                padding-left: size(20);
                .ng-input {
                    padding-left: size(20);
                    padding-right: size(30);
                    margin-top: 0;
                    @include media(down, $breakpoint-sm-sm){
                        color: $secondary;
                    }
                }
            }
        }
    }
    .ng-select-container {
        @include border-radius(12px);
        border-color: $extra-light-gray !important;
        background-color: $white;
        @include font-size(16);
        color: $primary;
        font-weight: $font-regular;
        .ng-value-container {
            padding-left: size(20);
            .ng-input {
                padding-left: size(20);
                padding-right: size(30);
            }
        }
        .ng-value-container {
            .ng-input {
                >input {
                    height: 35px;
                    color: #495057 !important;
                    margin-top: 0;
                    @include media(down, $breakpoint-sm-sm){
                        color: $secondary;
                    }
                }
            }
            .ng-placeholder {
                color: $primary;
                @include font-size(16);
            }
        }
    }
    &.ng-select-focused {
        &:not(.ng-select-opened) {
            >.ng-select-container {
                box-shadow: none;
            }
        }
    }
    .ng-dropdown-panel {
        border: 1px solid $extra-light-gray;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
        border-radius: 0;
        z-index: 10;
        .ng-select-bottom {
            border-color: $extra-light-gray;
        }
        .ng-dropdown-panel-items {
            .ng-option {
                white-space: normal;
                padding: size(10) size(20);
                color: $primary;
                @include font-size(16);
                line-height: 1.5rem;
                &.ng-option-marked {
                    background-color: rgba($primary, 0.08);
                }
                &.ng-option-selected {
                    background-color: rgba($primary, 0.15);
                }
            }
        }
    }
    .ng-arrow-wrapper {
        padding-right: size(12);
        span.ng-arrow {
            width: auto;
            height: auto;
            &::after {
                font-size: size(18);
            }
        }
    }
    &.ng-select-opened {
        div.ng-select-container {
            z-index: 10;
        }
    }
    &.ng-select-disabled {
        .ng-select-container {
            background-color: $light;
            cursor: not-allowed;
        }
        .ng-arrow-wrapper {
            .ng-arrow {
                border-color: $gary transparent transparent;
            }
        }
    }
    &.ng-select-multiple {
        .ng-select-container {
            .ng-value-container {
                overflow: auto;
                padding-left: size(17);
                .ng-placeholder {
                    top: 15px;
                    opacity: 0.6;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 96%;
                }
                .ng-value {
                    margin-bottom: $margin-bottom-5;
                    margin-top: $margin-top-5;
                }
            }
        }
    }
}
/*End of select*/

/*custom yellow text*/
.custom-text-warning {
    color: $yellow;
}