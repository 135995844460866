.investment-opportunities-details{
  margin-top: $margin-top-30;
}
.left-bdr-nav{
    li{
      a{
        border-left: 2px solid $white;
        color: $black;
        display: flex;
        align-items: center;
        padding: size(14) size(35) size(14) size(20);
        font-weight: $font-semibold;
        em{
          font-style: inherit;
          margin-left: size(5);
        }
        span{
          margin-left: size(14);
          position: absolute;
          right: size(20);
          font-size: size(14);
          &::before{
            color: $light-gray;
          }
        }
      }
      &.active, &:hover{
        a{
          border-left: 2px solid $primary;
          color: $primary;
          span{
            &::before{
              color: $primary;
            }
          }
        }
      }
    }
}
.social-mendia-nav{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: $margin-top-20;
  li{
    margin:size(5);
    a{
      font-size: size(20);
      span{
        &::before{
          color: $primary;
        }
      }
    }
  }
}

.inv-teaser-wrap{
  table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    @include media(down, $breakpoint-sm-sm){
      border: 0;
    }
    &.responsive-table-wrap{
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;        
        padding: 0;        
          @include media(down, $breakpoint-sm-sm){
            position: absolute;
            width: 1px;
            overflow: hidden;
          }
        }
        td{
          padding: size(14) size(20);
          &::before{
            @include media(down, $breakpoint-sm-sm){
              content: attr(data-label);
              float: left;
              font-weight: bold;
              text-transform: uppercase;
            }
          }
        }
    }
    tr{
      &:nth-child(even) {
        background: $extra-light-gray;
      }
      &:nth-child(odd) {
        background: $white;
      }
    }
    th {
      font-size: size(15);
      padding: size(14) size(20);
      text-align: left;      
      color: $primary;
      font-weight: $font-regular;
      @include media(down, $breakpoint-sm-sm){
        padding: size(11.25);
      }
    }
    tr {
      background-color: #f8f8f8;
      border: 1px solid $white-light;
      padding: size(11.25);
      text-align: left;
      @include media(down, $breakpoint-sm-sm){
        border-bottom: 1px solid $white-light;
        display: block;
        margin-bottom: size(11.25);
        padding: 0;
      }
    }
    td{
      padding: size(11.25);
      &:last-child {
        border-bottom: 0;
      }
      @include media(down, $breakpoint-sm-sm){
        border-bottom: 1px solid $white-light;
        display: block;
        font-size: size(15);
        text-align: right;
      }
    }
    &.fund-use-table {
      td {
        
        @include media(down, $breakpoint-sm-sm){
          padding: size(11.25);
          display: initial;
          text-align: left;
          border-bottom: none;
        }
      }
      tr{
        padding: size(11.25);
      }
    }
  }
}


/* Document */
.inv-opp-document-wrap{
  .info{

    .document-media{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .imgbox {
        width: 60px;
        height: 60px;
        border-radius: $border-radius-12;
        margin-right: size(15);
        border: 1px solid $extra-light-gray;
        position: relative;
        background-color: $extra-light-gray;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: size(24);
        .icon-icon-pdf:before {
          color: $primary;
        }
      }
      .media-body {
        width: calc(100% - 75px);
        text-align: left;
        h6{
          margin-bottom: 0;
        }
        span{
          margin-top: $margin-top-5;
          color: $light-gray;
          display: block;
        }
      }
    }
  }
}

/* Investors */
.investors-profile-wrap{
  [class*=col-] {
    margin-bottom: $margin-bottom-15;
  }
}
.investor-profile-card  {  
  border: 1px solid $extra-light-gray;
  border-radius: $border-radius-12;
  padding: size(20) size(10);
  height: 100%;
  .imgbox {
    padding: 0 !important;
    width: 50px !important;
    height: 50px !important;
    border: 0 !important;
    margin: 0 auto size(10) !important;
    .play-btn{
      width: 20px !important;
      height: 20px !important;    
      min-height: inherit;
      min-width: inherit;
      span{
        margin: 0 !important;
      }
    }
  }
  .profile-contact  {    
    h6{
      margin-bottom: $margin-bottom-10 !important;
      &:hover{
        color: $primary;
      }
    }
    a{
      margin-bottom: $margin-bottom-5 !important;
      margin-top: $margin-top-10; 
      display: block;
      font-weight: $font-regular;
      font-size: size(13);
    }
    p{
      margin-bottom: $margin-bottom-5 !important;
      color: $light-gray;
      strong{
        font-weight: $font-regular;
        color: $black;
      }
    }
  }
}

/* Funding History */

.last-funding-round-wrpa{
  display: flex;
  align-items: center;
  justify-content: space-between;  
  margin-bottom: $margin-bottom-30;
  @include media(down,$breakpoint-sm-sm){
    flex-direction: column;
  }
  .funding-raund{    
    position: relative;
    min-height: 157px;
    width: 49%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include media(down,$breakpoint-sm-sm) {
      width: 100%;
      &:first-child{
        margin-bottom: $margin-bottom-10;
      }
    }
    &::after{
      content: '';
      width: 80%;
      height: 5px;
      border-radius: 15px;  
      position: absolute;
      bottom: 0;
      left: 10%;
      margin: 0 auto;
    }
    &::after{
      background-color: $danger;
    }
    p{
      margin-bottom: $margin-bottom-5;
    }
    
  }
  .last-round{
    background-color: rgba(231,57,69,0.05);
    border-radius: 24px 0 0 24px;
    @include media(down,$breakpoint-sm-sm){
      border-radius: $border-radius-24;
    }
  }
  .total-funds{
    background-color: rgba(14,55,140,0.05);
    border-radius: 0px 24px 24px 0px;
    .primary-text{
      font-size: size(18);
    }
    &::after{
      background-color: $primary;
    }
    @include media(down,$breakpoint-sm-sm){
      border-radius: $border-radius-24;
    }
  }
  
  
}
.timeline-wrap{
  h6{
    margin-bottom: $margin-bottom-10;
  }
  .fund-data-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-left: 5px;
    background-color: rgba(14,55,140,0.05);
    padding: size(20) size(20) size(20) size(25);
    border-radius: 0 24px 24px 0;
    min-height: 128px;
    margin-bottom: $margin-bottom-10;
    .primary-text{
      font-size: size(18);
    }
    h6{
      margin-bottom: size(7);
      font-size: size(18);
    }
    .series-info{
      
      span{
        text-transform: uppercase;
        font-size: size(12);
        font-weight: $font-semibold;
      }
    }
    .fund-target-info{
      text-align: right;
      @include media(down,$breakpoint-sm-sm){
        text-align: left;
        margin-top: $margin-top-15;
      }
      p{
        font-size: size(15);
        margin-bottom: $margin-bottom-5;
        @include media(down,$breakpoint-sm-sm){
          margin-bottom: size(3);
        }
      }
    }
    &:before{
      content: '';
      width: 5px;
      height: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 0;
      background-color: rgba($primary, 0.2);
      border-radius: size(15);
    }
    &:after{
      content: '';
      width: 5px;
      height: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: $primary;
      z-index: 1;
      border-radius: size(15);
    }
    @include media(down,$breakpoint-sm-sm){
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    } 
  }
  .fund-progress-data-box:after{
    content: '';
    width: 5px;
    height: 50% !important;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $primary;
    z-index: 1;
    border-radius: size(15);
  }
}


