// MEDIA BREAKPOINTS
@mixin media($breakpoint, $point) {
  @if $breakpoint == down {
    @media (max-width: $point - .1) { @content; }
  }
  @else if $breakpoint == up {
    @media (min-width: $point) { @content; }
  }
}
// How to use media mixin demo
// body {
//   background: $white;
//   @include media(down, $breakpoint-xl) {
//     color: $primary;
//   }
// }
// body {
//   background: $secondary;
//   @include media(up, $breakpoint-xl) {
//     color: $primary;
//   }
// }

// CALC FUNCTION RULE
@mixin calc($property, $expression) { 
  #{$property}: calc(#{$expression});
}
// Usage Calc Function demo 
// div {
//    @include calc( width, '100% - 2px');
// }

// PREFIX RULE 
@mixin css3-prefix($prop, $value) {
    -webkit-#{$prop}: #{$value};
    -moz-#{$prop}: #{$value};
    -ms-#{$prop}: #{$value};
    -o-#{$prop}: #{$value};
    #{$prop}: #{$value};
}
// Usage Prefix 
// div {
//    @include css3-prefix(transform, scale3d(2.5, 2, 1.5));
// }
  
// POSITION 
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// TEXT ELLIPSIS
@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Button
// @mixin button-size($btn-padding-y, $btn-padding-x, $font-size-base, $line-height-base, $box-shadow) {
//   padding: $btn-padding-y $btn-padding-x;
//   font-size: $font-size-base;
//   line-height:$line-height-base;
//   box-shadow: $box-shadow;
// }


// PLACEHOLDER RULE
@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}

// Gradients
@mixin x-linear-gradient($direction, $fallback, $from, $to) {
  $directions: helper-gradient-angle($direction);
  // Provide a fallback-color
  background-color: $fallback;
  // Cross-browser linear-gradients
  background-image: -webkit-gradient(linear,$directions, from($from), to($to)); // Android 2.1-3.0
  background-image: -webkit-linear-gradient($directions, $from, $to);
  background-image:linear-gradient($direction, $from, $to);
}


// transition
@mixin transition-0-2s{
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
@mixin transition-0-3s{
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
@mixin transition-0-5s{
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
// card mixin
@mixin common-card-sm {
  background-color: $primary;
  border-radius: $border-radius-12;
  padding: size(30);
  box-shadow: 0 0 15px rgba(0,0,0,0.15);
}


