/*===== Dropdown Start ======*/

.dropdown-menu {
    background-color: $white;
    border: none;
    border-radius: $border-radius-12;
    box-shadow: 4px 4px 32px 12px rgba(0, 0, 0, 0.05);
    margin-top: size(18);
    -webkit-animation: 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
    animation: 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
    @include media(down, $breakpoint-sm-sm) {
        margin-top: size(12);
    }
    &::before {
        border-top: 1px solid $white;
        border-left: 1px solid $white;
        content: '';
        position: absolute;
        top: -1px;
        right: 1.2rem;
        width: .75rem;
        height: .75rem;
        display: block;
        background: $white;
        transform: rotate(45deg) translate(-7px);
        z-index: -1;
        box-sizing: border-box;
    }
    .dropdown-item {
        color: $black;
        font-size: size(16) !important;
        padding: size(18) size(16);
        &:hover,
        &:focus {
            color: $primary;
            background-color: $white;
            span{
                margin-right: size(10);
                &::before{ 
                    color:$primary !important;
                }
            }
        }
        span{
            margin-right: size(10);
            &::before{
                color: black;
            }
        }
    }
    .dropdown-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: size(20);
        color: $black;
        h4 {
            font-size: size(18);
            color: $black;
            font-weight: $font-semibold;
            line-height: size(24);
            letter-spacing: size(-0.25);
            margin-bottom: 0;
        }
        a {
            font-size: size(15);
            color: $primary;
            font-weight: $font-semibold;
            line-height: size(22);
            letter-spacing: size(-0.12);
        }
    }
    &.dropdown-menu-lg {
        min-width: 320px;
        right: -21px !important;
        @include media(down, $breakpoint-sm) {
            min-width: 95%;
            right: 0 !important;
            left: 0 !important;
            width: 95%;
            top: 52px !important;
            margin: 0 auto;
        }
    }
    .dropdown-chat-list {
        max-height: 450px;
        overflow-y: auto;
        .dropdown-list-item {
            display: flex;
            align-items: flex-start;
            border-bottom: 1px solid $extra-light-gray;
            padding: size(10) size(20);
            position: relative;
            &:last-child {
                border-bottom: none;
            }
            .profile-img {
                flex: 0 0 auto;
                img {
                    width: 32px;
                    height: 32px;
                    object-fit: cover;
                    border-radius: $rounded-pill;
                }
            }
            .chat-info {
                width: calc(100% - 32px);
                margin-left: size(10);
                h5 {
                    font-size: size(16);
                    color: $black;
                    font-weight: $font-semibold;
                    line-height: size(26);
                    letter-spacing: size(-0.25);
                    margin-bottom: size(4);
                    margin-right: size(24);
                    height: auto;
                    .black-dot {
                        background: $black;
                        width: 4px;
                        height: 4px;
                        border-radius: $rounded-pill;
                        display: inline-block;
                        vertical-align: middle;
                        margin: size(0) size(6);
                    }
                }
                p {
                    font-size: size(15);
                    color: $black;
                    line-height: size(22);
                    letter-spacing: size(-0.12);
                    margin-bottom: size(4);
                }
                .msg-time {
                    display: block;
                    font-size: size(12);
                    color: $light-gray;
                    line-height: size(18);
                    font-weight: $font-semibold;
                }
            }
            .alert-msg {
                position: absolute;
                right: 20px;
                span {
                    background: $danger;
                    color: $white;
                    font-size: size(12);
                    line-height: size(18);
                    font-weight: $font-semibold;
                    width: 20px;
                    height: 20px;
                    border-radius: $rounded-pill;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: size(3);
                }
            }
        }
    }
    .dropdown-notification-list {
        max-height: 450px;
        overflow-y: auto;
        .dropdown-list-item {
            border-bottom: 1px solid $extra-light-gray;
            padding: size(10) size(20);
            display: block;
            &:last-child {
                border-bottom: none;
            }
            .notification-info {
                p {
                    font-size: size(15);
                    color: $black;
                    line-height: size(22);
                    letter-spacing: size(-0.12);
                    margin-bottom: size(4);
                    span {
                        font-size: size(15);
                        line-height: size(22);
                        letter-spacing: size(-0.12);
                        color: $primary;
                    }
                }
                .msg-time {
                    display: block;
                    font-size: size(12);
                    color: $light-gray;
                    line-height: size(18);
                    font-weight: $font-semibold;
                }
            }
        }
    }
}

@keyframes fadein {
    from {
        -webkit-transform: translate3d(0, 6px, 0);
        transform: translate3d(0, 6px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}


/*===== Dropdown End ======*/


/*===== Card Start ====*/

.card {
    background: $white;
    padding: size(30);
    border-radius: $border-radius-12;
    border: none;
    box-shadow: 2px 2px 18px 8px rgba(0, 0, 0, 0.03);
    margin-bottom: $margin-bottom-10;
    @include media(down, $breakpoint-sm-sm) {
        padding: size(15);
    }
    .card-title {
        margin-bottom: $margin-bottom-20;
        h3 {
            font-size: size(16);
            line-height: size(26);
            font-weight: $font-semibold;
            color: $black;
            letter-spacing: size(-0.25);
            margin-bottom: size(0);
        }
        a {
            font-size: size(13);
            line-height: size(22);
            font-weight: $font-semibold;
            color: $primary;
            letter-spacing: size(-0.03);
            margin-bottom: size(0);
        }
        &.card-flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .title-wrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $margin-bottom-20;
        padding-right: size(20);
        position: relative;
        a{
            font-weight: $font-semibold;
        }
        span{
            color: $light-gray;
            font-size: size(13);
            font-weight: normal;
        }
        
    }
    &.with-ad {
        background-color: $light-blue-btn;
        border: solid 1px $primary;
        margin-top: size(30)
    }
}


/*===== Card End ====*/

/* dropdown plugin css */
.pac-container {
    z-index: 10000;
}
.pac-logo:after{
    display: none !important;
}

.uploaded-file-list{
    max-height: 250px;
    overflow: auto;
}
/* Subscription */
.subscription-plan-list{
    .info{
        padding-right: 0 !important;
        .plan-item{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            p{
                margin-right: 15px;
            }
            span{
                flex-shrink: 0;
                color: $primary;
                font-size: size(14);
                margin-bottom: 15px;
                span{
                    font-size: size(14);
                }
            }
        }
    }
}