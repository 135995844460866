.login-signup-wrap{
  max-width: 970px !important;
  width: 100%;
  @include media(down, $breakpoint-xl){
    max-width: 750px;
  }
  .modal-content{
    background-color: transparent;
    border: none;
    position: relative;
    display: block;
    app-login{
      display: flex;
      align-items: center;
      @include media(down, $breakpoint-sm-sm){
        flex-direction: column;        
      }
      .login-signup-form{
        order: 1;
        @include media(down, $breakpoint-sm-sm){
          border-radius: size(24) size(24) size(0) size(0);       
        }
      }
      .info{
        order: 2;
        border-radius: size(0) size(24) size(24) size(0);       
        @include media(down, $breakpoint-sm-sm){
          border-radius: size(0) size(0) size(24) size(24);
        }
      }
    }
    app-register{
      display: flex;
      align-items: center;
      @include media(down, $breakpoint-sm-sm){
        flex-direction: column;        
      }
      .login-signup-form{
        order: 2;
        @include media(down, $breakpoint-sm-sm){
          order: 1;  
          border-radius: size(24) size(24) size(0) size(0);
        }
      }
      .info{
        order: 1;
        border-radius: size(24) size(0) size(0) size(24);
        @include media(down, $breakpoint-sm-sm){
          order: 2;    
          border-radius: size(0) size(0) size(24) size(24);
        }
      }
    }
    
    .login-signup-form{
      max-width: 470px;
      width: 100%;
      // position: initial;
      @include media(down, $breakpoint-xl){
        min-height: initial;
      }
      @include media(down, $breakpoint-sm-sm){
        max-width: 375px;
      }
      
      h3{
        text-align: center;
        margin-bottom: $margin-bottom-10;
      }
      p{
        text-align: center;
        margin-bottom: $margin-bottom-30;
        @include media(down, $breakpoint-lg){
          margin-bottom: $margin-bottom-20;
        }
      }
      .forgot-link{
        text-align: right;
        display: block;
        font-weight: $font-semibold;
        margin-bottom: $margin-bottom-20;
      }
      .btnbox{
        display: flex;
        align-items: center;
        margin-bottom: $margin-bottom-20;
        .btn{
          width: 100%;
        }
      }
      .social-media-btnbox{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: $margin-top-20;
        a{
          min-width: 175px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(50% - 10px);
          @include media(down, $breakpoint-x-xl){
            min-width: 85px;
          }
          @include media(down, $breakpoint-lg){
            min-width: 85px;
          }
          @include media(down, $breakpoint-sm-sm){
            min-width: 150px;
          }
          @include media(down, $breakpoint-sm){
            min-width: 100px;
          }
          &:first-child{
            margin-right: size(10);
          }
          &.btn{
            span{
              &:before{
                color: $white;
              }
            }
          }
          span{
            margin-right: size(20);
            font-size: size(22);
            @include media(down, $breakpoint-lg){
              margin-right: size(15);
            }
            @include media(down, $breakpoint-xs){
              margin-right: size(15);
              font-size: size(18);
            }
          }
        }
      }
    }    
  }
  .info{
    max-width: 970px;
    width: 100%;
    background-color: rgba($primary, 0.95);
    padding: size(50) size(45);
    min-height: 392px;
    border-radius: $border-radius-24;
    display: flex;
    text-align: center;
    align-items: center;
    @include media(down, $breakpoint-xl){
      min-height: 30px;
    }
    @include media(down, $breakpoint-sm-sm){
      max-width: 375px;     
      padding: size(20);  
    }
    @include media(down, $breakpoint-sm-sm){      
      padding: size(20) size(15);  
    }
    .info-wrap{
      max-width: 400px;
      width: 100%;
      h3{
        color: $white;
        margin-bottom: $margin-top-10;
      }
      p{
        margin-bottom: $margin-bottom-30;
        color: $white;
      }
      .btn{
        min-width: 100px;
      }
    }
  }
}