/*Headings*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight: $font-bold;
	font-family: $font-heading;
	
}
h1, .h1 { 
	font-size: $font-size-base * 4.062;line-height: 1.26; color: $black;
	@include media(down, $breakpoint-xl){
		font-size: 3.5rem;
	}
	@include media(down, $breakpoint-lg){
		font-size: 3rem;
	}
	@include media(down, $breakpoint-sm-sm){
		font-size: 2.7rem;
	}
	@include media(down, $breakpoint-sm){
		font-size: 2.4rem;
	}
	@include media(down, $breakpoint-xs){
		font-size: 1.8rem;
	}
}
h2, .h2 { 
	font-size: $font-size-base * 3; line-height: 1.25;color: $black;
	@include media(down, $breakpoint-xl){
		font-size: 2.2rem;
	}
	@include media(down, $breakpoint-lg){
		font-size: 2rem;
	}
	@include media(down, $breakpoint-sm-sm){
		font-size: 1.8rem;
	}
	@include media(down, $breakpoint-sm){
		font-size: 1.5rem;
	}
	@include media(down, $breakpoint-xs){
		font-size: 1.2rem;
	}
}
h3, .h3 {
	 font-size: $font-size-base * 2;line-height: 1.375;color: $black;
	 @include media(down, $breakpoint-xl){
		font-size: 1.5rem;
	}
	@include media(down, $breakpoint-lg){
		font-size: 1.35rem;
	}
	@include media(down, $breakpoint-sm-sm){
		font-size: 1.3rem;
	}
	@include media(down, $breakpoint-sm){
		font-size: 1.25rem;
	}
	// @include media(down, $breakpoint-xs){
	// 	font-size: 1.3rem;
	// }
}
h4, .h4 {
	font-size: $font-size-base * 1.5; line-height: 1.33;color: $black;
	@include media(down, $breakpoint-xxl){
		font-size: $font-size-base * 1.29;
	}
	@include media(down, $breakpoint-sm){
		font-size: 1.1rem;
	}
	@include media(down, $breakpoint-xs){
		font-size: 1rem;
	}
	// @include media(down, $breakpoint-x-xl){
	// 	font-size: $font-size-base * 1.29;
	// }
}
h5, .h5 { 
	font-size: $font-size-base * 1.127;line-height: 1.25;color: $black;
}
h6, .h6 { 
	font-size: $font-size-base; line-height: normal;color: $black;
}
