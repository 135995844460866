.mt-35{
  margin-top: $margin-top-35;
}
.settings-subscription{
  .btn{
    &.btn-small{
      min-height: 32px;
      padding: size(2) size(20);
    }
  }
}
.c-title{
  font-size: size(22);
  margin: 0;
  @include media(down, $breakpoint-xl){
    font-size: size(18);
  }
  span{
    width: 63px;
    height: 63px; 
    line-height: 63px;
    background-color: $white;
    border-radius: 100%;
    color: $primary;
    display: inline-block;
    text-align: center;
    box-shadow: 2px 3px 17px rgb($switch-gray, 50%);
    margin: size(0) size(20) size(20) size(0);
    font-size: size(28);
  }
  .icon-icon-plan-essential, .icon-icon-plan-premium{
    font-size: size(23);
  }
}
.card-inactive{
  background-color: transparent;
  box-shadow: none;
}
.table-card{
  padding: size(30) size(30) size(10) size(30);
  overflow: auto;
    min-width: 100%;
    .four-grid-table {
      overflow-x: hidden;
      min-width: 800px;
    }
}
.overflow-unset {
  @include media(up, $breakpoint-lg){
    overflow: unset;
    .table-responsive {
      overflow: unset;
    }
  }
}
.plans-table{
  th{
    padding-bottom: $padding-bottom-20;
    font-weight: $font-semibold;    
      &.sticky-th {
        background: $white;
        position: sticky;
        position: -webkit-sticky;
        top: 70px;
        z-index: 11;
        @include media(down, $breakpoint-lg){
          top: -20px;
          padding-bottom: 10px;
          padding-top: 10px;
        }
      }     
  }
    tr{
      td{
        width: 100%;
        padding-bottom: $padding-bottom-20;
        word-break: break-all;
      }
  }
  tr{
    td:nth-child(n+2){
      width: 150px;
      text-align: right !important;
    }
    td{
      span{
        font-size: size(14);
        display: inline-block;
      }
    }
  }

}
.four-grid-table{
  &.table-responsive{
    @include media(up, $breakpoint-xl){
      // overflow-x: initial;
    }
  }
  .c-title{
    display: block;
    text-align: center;
    word-break: break-word;
    @include media(down, $breakpoint-sm-sm){
      min-height: 129px;
    }
    @include media(down, $breakpoint-xs-xs){
     font-size: size(16);
    }
    span{
      margin: 0 auto size(20);
      display: block;
    }
  } 
  .plan-icon{
    font-size: size(23);
    &.icon-icon-plan-core{
      font-size: size(25);
    }
  }
  tr{
    td, th{
      width: 30%;
      padding-bottom: $padding-bottom-30;
      word-break: break-word;
      @include media(down, $breakpoint-lg){
        width: 18%;
      }
      &:nth-child(n+2){
        width: 10%;
        text-align: center !important;
        display: table-cell;
      }
    }
  }
  hr{
    border-color: $extra-light-gray;
    margin: size(0) size(-30) size(30) size(-30);
  }
}
.compare-plans{
  .table-card{
    padding: size(30) size(0) size(10) size(30);
    @include media(down, $breakpoint-xs-xs){
      padding: size(20);
    }
    @include media(down, $breakpoint-lg){
      max-height: calc(100vh - 200px);
    }
  }
}
.verify-otp{
  .text-danger{
    text-align: center !important;
  }
  .otp-input{
    font-size: size(32) !important;
    color: $black !important;
    @include media(up, $breakpoint-xl){
      width: 44px !important;
    }
  }
}
.form-otp{
  margin-top: $margin-top-35;
  .btnbox{
    margin: size(30) size(0);
  }
}
.resend-req{
  color: $light-gray;
}
.change-password-form{
  .form-control:placeholder-shown{
    background-color: transparent;
    border: 1px solid $light-gray;
  }
}
.instruction-wrap{
  margin-top: $margin-top-30;
}
.instruction-list{
  padding-left: size(20);
  li{
    list-style-type: disc;
    font-size: size(13);
    color: $light-gray;
    font-weight: $font-semibold;
    line-height:1.4;
  }
}
.change-password {
  .btnbox{
    @include media(down, $breakpoint-sm){
      margin-top: $margin-top-15;
    }
  }
}
.refer-and-earn{
  .card{
    padding: size(50);
    @include media(down, $breakpoint-sm-sm){
      padding: size(30);
    }
  }
  .img-wrap{
    @include media(up, $breakpoint-xl){
      padding-left: size(40);
    }
    img{
      @include media(down, $breakpoint-lg){
          object-fit: contain;
      }
      @include media(down, $breakpoint-sm-sm){
        height: auto;
      }
    }
  }
  .info-wrap{
    margin: size(50) size(0) size(0) size(100);
    max-width: 355px;
    @include media(down, $breakpoint-lg){
      margin: size(50) size(0) size(0) size(15);
    }
    @include media(down, $breakpoint-sm-sm){
      margin: size(35) size(0) size(0);
    }
    .btnbox{
      margin-top: $margin-top-30;
    }
  }
  .ref-code{
    margin-top: $margin-top-25;
    .code{
        background-color: $extra-light-gray;
        font-size: size(24);
        padding: size(15) size(5);
        text-align: center;
        border-radius: $border-radius-12;
        max-width: 218px;
        margin: size(5) size(0);
        letter-spacing: size(4);
        @include media(down, $breakpoint-sm-sm){
          font-size: size(18);
        }
    }
    span{
      font-size: size(13);
      color: $light-gray;
      font-weight: $font-semibold;
    }
    .copy{
        color: $black;
        font-size: size(11);
        text-transform: uppercase;
    }
  }
}
.verify-notes-wrap{
  margin-top: size(28);
  .custom-radio-square{
    input[type=radio] + span{
      top: 2px;
    }
  } 
  .list-wrap{
    margin-bottom: $margin-bottom-10;
  }
  ul{
    max-width: 329px;
    li{
      list-style-type: disc;
      font-size: size(13);
      list-style-position: inside;
      &::marker{
        font-size: size(8);
      }
    }
  }
}
.verify-page{
  .project-files-box{
    .icon-icon-plush{
      color: $light-gray;
    }
  }
  .btnbox{
    @include media(up, $breakpoint-md){
      margin-right: size(-70);
    }
  }
}

.verify-your-accrediation{
  .project-files-box{
    .icon-icon-plush{
      color: $light-gray;
    }
  }
  .or{
    font-size: size(13);
    color: $light-gray;
    font-weight: $font-semibold;
    margin: size(5) size(0);
    display: inline-block;
  }
  .custom-checkbox{
    label{
      padding-left: size(40);
      margin: size(0);
      @include media(down, $breakpoint-sm-sm){
        padding-left: size(35);
      }
    }
  } 
}
.accordion-wrap{
  margin-top: $margin-top-35;
  padding-left: size(30);
  @include media(down, $breakpoint-xs-xs){
    padding-left: size(0);
  }
  .card{
    padding: size(0);
    .accordion-toggle{
      padding: size(20);
      border: 1px solid $extra-light-gray;
      border-radius: $border-radius-12;
    }
  }
  .card-header{
    border: 0;
    padding: size(0);
    background-color: transparent;
  }
  .card-body{
    padding: size(0) size(20) size(20) size(0);
  }
  .panel-heading{
    p{
      padding-left: size(43);
      margin-bottom: 0;
    }
  }
  .custom-radio-square {
    label{
      margin: size(0) size(10) size(10) size(0);
    }
  }
  .panel-open{
   .card{
        background-color: rgba($extra-light-gray, 40%);
        border-radius: $border-radius-12;
        padding: size(20);
   }
    .accordion-toggle{
      border: 0;
    }
  }
}
.checked-services{
  input[type="checkbox"] {
      display: none;
      +span {
          background: transparent;
          border: 1px solid $gary;
          width: 20px;
          height: 20px;
      }
  }
}
.settings-privacy{
  .custom-checkbox {
    label{
      display: table;
      margin: size(0) size(10) size(20) size(0);
      font-weight: $font-regular !important;
      &:last-child{
        margin: size(0) size(10) size(0) size(0);
      }
    }
  }
  .card{
     + .card{
       margin: size(30) size(0) size(0);
       @include media(down, $breakpoint-sm-sm){
          margin: size(15) size(0) size(0);
       }
     }
     .mb-3{
      @include media(down, $breakpoint-sm-sm){
        margin: size(0) size(0) size(10) !important;
      }
     }
  }
}

.credits-tabs{
  .tab-info{
    @include media(down, $breakpoint-xs-xs){
      margin-top: size(80);
    }
  }
  .common-tabs{
    .search-filter-wrap{
      @include media(down,$breakpoint-lg){
        top: 0;
      }
      @include media(down, $breakpoint-xs-xs){
        top: 80px;
      }
    }
    .tab-nav-header{
      .tab-container{
        .nav-tabs{
          @include media(down,$breakpoint-lg){
           margin-bottom: $margin-bottom-30;
          }
          .nav-link{
            padding: size(18) size(15);
          }
        }
      }
    }
  }    
  .filter-btn-wrap{
    padding: size(6) size(18) size(0);
    border-left: 0;
    .filter-dropdown{
      background-color: $extra-light-gray;
      width: 163px;
      padding: size(8) size(10);
      margin-left: size(15);
      border-radius: $border-radius-4;
      span{
        width: 100%;
        margin: 0;
        text-align: right;
        justify-content: flex-end;
        color: $light-gray;
      }
    }
    .ng-select{
      margin-left: size(6);

      .ng-arrow-wrapper{
        margin-left: size(4);
      }

      .ng-option{
        padding: size(12) size(10);
      }
    }
  } 
}
.credits-available{
  background-color: $primary;
  padding: size(25) size(25) size(28) size(25);
  border-radius: $border-radius-12;
  background-image: url(../../images/card-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  .cr-info{
    h6{
      color: $white;
      font-weight: $font-regular;
      margin: size(0) size(0) size(3);
    }
    .digit{
      color: $white;
      font-size: size(32);
      font-weight: $font-bold;
    }
  }
  .btn{
    font-size: size(13);
    background-color: $white;
    color: $primary;
    padding: size(5) size(10);
    font-weight: $font-semibold;
    min-height: 32px;
    line-height: 1.5;
    border-radius: size(6);
    min-width: 70px;
  }
}
.status-list{
  .status-info{
    .status-icon{
      font-size: size(14);
      padding-top: $padding-top-10;
    }
    .text-wrap{
      padding: size(0) size(25);
      word-break: break-all;
    }
    h5{
      color: $primary;
      font-weight: $font-semibold;
      font-size: size(15);
      span{
        margin-bottom: size(3);
      }
    }
    p{
      font-size: size(13);
      color: $light-gray;
      font-weight: $font-semibold;
      margin: size(0);
      text-transform: capitalize;
    }
    .number{
      font-size: size(18);
      span{
        font-weight: $font-semibold;
      }
    }
  }
  hr{
    border-top: 1px solid $extra-light-gray;
  }
  &.card{
    @include media(down,$breakpoint-sm-sm){
      margin-top: $margin-top-30;
    }
    @include media(up, $breakpoint-xl){
      padding: size(30) size(30) size(30) size(40);
    }
  }
}
.credit-cards{
  .card{
    [class*=col-]{
      &:nth-child(n+4){
        margin-top: $margin-top-30;
      }
      &:nth-child(n+3){
        @include media(down, $breakpoint-xl){
          margin-top: $margin-top-30;
        }
      }
      &:nth-child(n+2){
        @include media(down, $breakpoint-lg){
          margin-top: $margin-top-30;
        }
      }
    }
  }
  .card-wrap{
    background-color:$primary;
    border-radius: $border-radius-12;
    padding: size(30) size(30) size(25) size(30);
    background-image: url(../../images/credit-card-bg.png);
    position: relative;
    @include media(down, $breakpoint-sm-sm){
        padding: size(25);
    }
    @include media(down, $breakpoint-lg){
      max-width: 380px;
    }
    &.text-danger{
      background-color: $danger;
    }
    &.text-success{
      background-color: $success;
    }
    &.text-yellow{
      background-color: $yellow;
    }
    &.text-light-gray{
      background-color: $light-gray;
    }
    .card-type{
      img{
        height: 100%;
        object-fit: scale-down;
      }
    }
    .card-number{
      padding: size(20) size(0);
      span{
        color: $white;
        font-size: size(20);
        font-weight: $font-semibold;
        letter-spacing: size(1);
        @include media(down, $breakpoint-sm-sm){
            font-size: size(18);
        }
        + span{
          padding-left: size(32);
          @include media(down, $breakpoint-xsw){
            padding-left: size(14);
          }
        }
      }
    }
  }
  .card-owner-detail{
    span{
      color: $white;
      display: block;
      font-weight: $font-semibold;
    }
    .c-text{
      font-size: size(12);
      padding-bottom:size(5);
    }
    .c-name{
      font-size: size(15);
    }
  }
  .card-ex-date{
    padding-left:size(55);
  }
  .edit-delet-option{
    position: absolute;
    top: 15px;
    right: 15px;
    color: $white;
    cursor: pointer;
    span{
      font-size: size(16);
      font-weight: $font-regular;
    }
  }
}
.add-new-card-wrap{
  height: 200px;
  background-color: rgba($extra-light-gray, 40%);
  border-radius: $border-radius-12;
  cursor: pointer;
  text-align: center;
.add-new-card{
  .add-option{
    width: 39px;
    height: 39px;
    line-height: 39px;
    text-align: center;
    background-color: $extra-light-gray;
    border-radius: $border-radius-12; 
    margin: size(0) auto size(20);
    span{
      color: $primary;
      &::before{
        font-weight: $font-semibold;
      }
    }
  }
}
}
.color-selection{
  input[type="radio"] {
    +span {
      background-color: $primary;
      width: 24px !important;
      height: 24px !important;
    }
    ~span:after {
      background-color: transparent !important;
      content: "\e94d";
      font-family: "icomoon" !important;
      color: $white;
      font-size: size(8);
      top: 64% !important;
      left: 65% !important;
      @include media(down, $breakpoint-sm-sm){
        margin-left: size(-8) !important;
        margin-top: size(-7) !important;
      }
    }
  }
  input.text-primary[type="radio"] {
    +span {
      background-color: $primary !important;
      border-color: $primary !important;
    }
  }
  input.text-danger[type="radio"] {
    +span {
      background-color: $danger;
      border-color: $danger;
    }
  }
  input.text-danger[type="radio"] {
    +span {
      background-color: $danger;
      border-color: $danger;
    }
  }
  input.text-success[type="radio"] {
    +span {
      background-color: $success;
      border-color: $success;
    }
  }
  input.text-yellow[type="radio"] {
    +span {
      background-color: $yellow;
      border-color: $yellow;
    }
  }
  input.text-light-gray[type="radio"] {
    +span {
      background-color: $light-gray;
      border-color: $light-gray;
 
    }
  }
  .custom-radio{
    .form-group{
      position: relative;
      padding: size(15) size(40) size(0) size(0);
      display: inline-block;
      label{
        position: absolute;
        left: 0;
        opacity: 0;
        padding: size(0);
      }
    }
  }
}
.add-card-modal{
  .btnbox{
    margin-top: $margin-top-30;
  }
}
.bank-account{
  .card-holder-name{
    max-width: 60%;
  }
  .card-ex-date{
    .c-name{
      padding-left: size(7);
      margin-top:size(-2);
    }
  }
}
.block-listing{
  .member-info-wrap{
    + .member-info-wrap{
      margin-top: $margin-top-30;
      padding-top: $padding-top-25;
      border-top: 1px solid $extra-light-gray;
    }
  }
  .designation{
    h6{
      @include media(up, $breakpoint-md){
        font-size: size(18);
      }
      color: $primary;
    }
    span{
      color: $black;
      font-size: size(14);
    }
  }
  .member-req{
    text-transform: uppercase;
    font-weight: $font-semibold;
    .package {
      width: 150px;
    }
  }
}
.close-account{
  .select-options{
    margin-top: size(30);
  }
  .subreasons-list{
    padding-left: size(40);
    @include media(down, $breakpoint-sm-sm){
      padding-left: size(30);
    }
    li{
      line-height: 1.7;
    }
  }
}