.home-feed-section {
    margin-top: $margin-top-30;
    padding:size(0) size(100);
    @include media(down, $breakpoint-xxl){
        padding: size(0) size(35);
    }
    @include media(down, $breakpoint-xl){
        padding: size(0) size(0);
    }
    .search-filter-sticky{
        .nav-tabs{
            position: sticky;
            top: 70px;
            z-index: 999;
        }
    }
 }
.home-feed-searchstickey{
    position: sticky;
    top: 70px;
    z-index: 1049;
    @include media(down, $breakpoint-sm-sm){ 
        top: 50px;
    }
}
.profile-info {
    text-align: center;
    .profile-progress {
        width: 114px;
        height: 114px;
        margin: 0 auto;
        position: relative;
        svg image {
            clip-path: circle(49%);
        }
        .play-ic {
            width: 32px;
            height: 32px;
            border-radius: $rounded-pill;
            background: $primary;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 6px;
            bottom: 6px;
            span {
                font-size: size(10);
            }
        }
    }
    .profile-details {
        margin-top: size(12);
        p {
            font-size: size(13);
            line-height: size(22);
            font-weight: $font-regular;
            color: $black;
            letter-spacing: size(-0.03);
            margin: size(3) size(0);
            span{
                color: $primary;
                padding-right: size(5);
                vertical-align: -1px;
                &:before{
                    font-weight: $font-bold;
                }
            }
            .date{
                font-size: size(13);
                color: $light-gray;
            }
        }
        .profile-contact {
            .profile-name{
                justify-content: center;
                .icon-Icon-green-tick{
                    line-height: 32px;
                }
                h4 {
                    font-size: size(18);
                    line-height: size(30);
                    font-weight: $font-semibold;
                    color: $black;
                    letter-spacing: size(-0.25);
                    margin-bottom: size(3);
                    align-items: center;
                    word-break: break-all;
                    span{
                        margin-left: size(4);
                    }
                }
            }
            h5 {
                font-size: size(15);
                line-height: size(26);
                font-weight: $font-semibold;
                color: $primary;
                letter-spacing: size(-0.25);
                margin-bottom: size(0);
            }
            .skill-lists{
                li{
                    background-color: $extra-light-gray;
                    border-radius: $border-radius-4;
                    color: $black;
                    padding: size(10);
                    margin: size(5) 0 0 size(5);
                }
            }
        }
        .profile-complete {
            border-top: 1px solid $extra-light-gray;
            padding-top: $padding-top-20;
            margin-top: $margin-top-20;
            p {
                margin-bottom: $margin-bottom-20;
            }
        }
    }
    
}

.current-plan {
    .plan-details {
        h4 {
            font-size: size(18);
            line-height: size(30);
            font-weight: $font-semibold;
            color: $primary;
            letter-spacing: size(-0.25);
            margin-bottom: size(0);
            display: flex;
            align-items: center;
            span {
                font-size: size(20);
                color: $primary;
                margin-right: size(12);
                &::before {
                    color: $primary;
                }
            }
        }
        .upgrade-plan {
            border-top: 1px solid $extra-light-gray;
            padding-top: $padding-top-20;
            margin-top: $margin-top-20;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            @include media(down, $breakpoint-xl) {
                justify-content: center;
                flex-direction: column;
            }
            p {
                font-size: size(13);
                line-height: size(22);
                font-weight: $font-regular;
                color: $black;
                letter-spacing: size(-0.03);
                margin-bottom: size(0);
                max-width: 150px;
                margin-right: size(30);
                @include media(down, $breakpoint-xl) {
                    max-width: 100%;
                    margin-right: 0;
                    margin-bottom: $margin-bottom-20;
                }
            }
            a {
                flex: 0 0 auto;
            }
        }
    }
}

.interests-box {
    .interests-details {
        .interests-item {
            display: flex;
            align-items: center;
            margin-bottom: $margin-bottom-20;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .img-box {
            flex: 0 0 auto;
            border-radius: 100%;
            overflow: hidden;
            img {
                width: 60px;
                height: 60px;
                object-fit: cover;
            }
        }
        .content-info {
            margin-left: size(20);
            width: calc(100% - 60px);
            h4, a {
                font-size: size(15);
                line-height: size(22);
                font-weight: $font-semibold;
                color: $black;
                letter-spacing: size(-0.12);
                margin-bottom: size(0);
                &:hover{
                    color: $primary;
                }
            }
            .d-flex{
                .icon-Icon-green-tick {
                    line-height: 22px;
                }
            }
            p {
                font-size: size(13);
                line-height: size(22);
                font-weight: $font-regular;
                color: $light-gray;
                letter-spacing: size(-0.03);
                margin-bottom: size(0);
            }
        }
    }
}

.quick-links {
    .quick-links-list {
        ul {
            li {
                margin-bottom: $margin-bottom-20;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    font-size: size(18);
                    line-height: size(30);
                    font-weight: $font-semibold;
                    color: $black;
                    letter-spacing: size(-0.25);
                    margin-bottom: size(0);
                    display: flex;
                    align-items: center;
                    span {color: $black;
                        letter-spacing: size(-0.25);
                        &.icon{
                            font-size: size(20);
                            color: $light-gray;
                            margin-right: size(12);
                            &::before {
                                color: $light-gray;
                            }
                        }
                        em{
                            font-size: size(12);color: $primary;
                            display: block;
                            line-height: 12px;
                        }
                    }
                    &:hover {
                        color: $primary;
                        span {
                            color: $primary;
                            &::before {
                                color: $primary;
                            }
                        }
                    }
                }
            }
        }
    }
}

.sidebar-footer {
    text-align: center;
    margin: size(30) size(0);
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include media(down, $breakpoint-xl){
            justify-content: flex-start;
        }
        li {
            padding: size(0) size(15) size(10) size(15);
            @include media(down, $breakpoint-xl){
                width: 50%;
                text-align: left;
            }
            @include media(down, $breakpoint-xs-xs){
                padding: size(0) size(5) size(10) size(5);
            }
            @include media(down, $breakpoint-xsww){
                padding: size(0) size(0) size(10);
            }
            a {
                font-size: size(15);
                line-height: size(22);
                font-weight: $font-regular;
                color: $black;
                letter-spacing: size(-0.12);
                &:hover {
                    color: $primary;
                }
            }
        }
    }
    .copyright {
        border-top: 1px solid rgba(34, 34, 34, 0.1);
        padding-top: $padding-top-20;
        margin-top: $margin-top-10;
        p {
            font-size: size(15);
            line-height: size(22);
            font-weight: $font-regular;
            color: $light-gray;
            letter-spacing: size(-0.12);
            margin-bottom: size(0);
            margin-top: $margin-top-10;
            a {
                font-weight: $font-semibold;
                color: $black;
                &:hover {
                    color: $primary;
                }
            }
        }
    }
}

.home-feed-wrap {
    .search-filter-wrap {
        .btn-box {
            border-top: 1px solid rgba(34, 34, 34, 0.1);
            padding-top: $padding-top-20;
            margin-top: $margin-top-20;
        }
    }
}

.with-ad {
    .with-ad-whitebg {
        background-color: $white;
        padding: size(24) size(26);
        border-radius: size(10);
        margin-top: size(20);
        @include media(down, $breakpoint-sm-sm){
            padding: size(16);
            margin-top: size(16);
        }
    }
    .home-feed-video {
        .with-ad-whitebg {
            .video-title {
                margin-top: 0;
            }
        }
    }
}

.home-feed-video {
    .feed-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon-icon-action{
            font-size: size(18);
        }
        .header-box {
            display: flex;
            align-items: center;
            cursor: inherit;
            .img-box {
                flex: 0 0 auto;
                img {
                    width: 32px;
                    height: 32px;
                    object-fit: cover;
                    border-radius: $rounded-pill;
                }
            }
            .header-title {
                margin-left: size(10);
                display: block;
                align-items: center;
                h5 {
                    font-size: size(18);
                    line-height: size(30);
                    font-weight: $font-semibold;
                    color: $primary;
                    letter-spacing: size(-0.25);
                    margin-bottom: size(0);
                    display: inline;
                    margin-right: 5px;
                }
                span {
                    margin-left: size(0);
                    display: inline;
                }
                .bxs-badge-check {
                    vertical-align: middle;
                    position: relative;
                    top: -2px;
                    margin-left: size(4);
                    color: $success;
                }
            }
        }
        .dropdown-box {
            .dropdown-menu {
                right: -22px !important;
                margin-top: $margin-top-10;
                box-shadow: 0 2px 4px 5px $extra-light-gray;
                &:before{
                display: none;
                }
                .dropdown-item {
                    display: flex;
                    align-items: center;
                    &:hover {
                        span {
                            &::before {
                                color: $primary;
                            }
                        }
                    }
                    span {
                        margin-right: size(12);
                        &::before {
                            color: $black;
                        }
                    }
                }
            }
        }
    }
    .video-title {
        margin: size(20) size(0) size(10) size(0);
        padding-right: size(30);
        position: relative;
        @include media(down, $breakpoint-sm-sm) {
            margin: size(10) size(0) size(10) size(0);
        }
        a {
            font-size: size(22);
            line-height: size(30);
            font-weight: $font-bold;
            color: $black;
            letter-spacing: size(-0.4);
            margin-bottom: size(0);
            &:hover{
                color: $primary;
            }
            @include media(down, $breakpoint-sm-sm) {
                font-size: size(20);
                line-height: size(28);
            }
        }
        .dropdown-box{
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    .video-status {
        margin-bottom: $margin-bottom-10;
        p {
            font-size: size(12);
            line-height: size(18);
            font-weight: $font-bold;
            color: $light-gray;
            text-transform: uppercase;
            margin-bottom: size(0);
            a{
                font-size: size(12);
                line-height: size(18);
                font-weight: $font-bold;
            }
        }
    }
    .tag-info {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $margin-bottom-20;
        @include media(down, $breakpoint-sm-sm) {
            margin-bottom: $margin-bottom-10;
        }
        
    }
    .video-box {
        position: relative;
        border-radius: $border-radius-12;
        overflow: hidden;
        .video-feed {
            width: 100%;
            object-fit: cover;
            border-radius: $border-radius-12;
            @include media(down, $breakpoint-sm-sm) {
                // object-fit: contain;
            }
        }
        vg-player{
            background-color: transparent;
        }
    }
    .video-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: $margin-top-30;
        @include media(down, $breakpoint-sm-sm) {
            margin-top: $margin-top-15;
        }
        ul {
            display: flex;
            align-items: center;
            li {
                margin-right: size(30);
                &:last-child {
                    margin-right: 0;
                }
                a {
                    font-size: size(15);
                    line-height: size(22);
                    font-weight: $font-semibold;
                    color: $black;
                    letter-spacing: size(-0.12);
                    display: flex;
                    align-items: center;
                    span {
                        font-size: size(18);
                        margin-right: size(10);
                    }
                    &:hover,
                    &.selected {
                        color: $primary;
                        span {
                            color: $primary;
                            &::before {
                                color: $primary;
                            }
                        }
                    }
                    .action-text {
                        font-style: normal;
                        margin-left: size(4);
                        @include media(down, $breakpoint-sm-sm) {
                            display: none;
                        }
                    }
                }
            }
        }
        .favorite {
            a {
                span {
                    font-size: size(18);
                }
                &:hover {
                    span {
                        color: $primary;
                        &::before {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
}

.filter-search {
    margin-bottom: $margin-bottom-20;
    h6 {
        font-size: size(15);
        line-height: size(22);
        font-weight: $font-semibold;
        color: $black;
        letter-spacing: size(-0.12);
        margin-bottom: $margin-bottom-10;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        li {
            margin: size(0) size(10) size(10) size(0);
            a {
                font-size: size(15);
                line-height: size(22);
                font-weight: $font-regular;
                color: $black;
                letter-spacing: size(-0.12);
                border: 1px solid rgba(34, 34, 34, 0.1);
                padding: size(8) size(9);
                display: inline-block;
                border-radius: $border-radius-4;
            }
        }
    }
}

.home-video-popup {
    max-width: 1170px !important;
    .modal-content {
        border: none;
        border-radius: size(24) !important;
        background-color: $white !important;
    }
    .close-ic {
        position: absolute;
        right: 30px;
        top: 30px;
        z-index: 9;
        span{
            font-size: size(18);
        }
        @include media(down, $breakpoint-xxl){
            right: 13px;
        }
        @include media(down, $breakpoint-sm-sm) {
            right: -10px;
            top: -10px;
            background: $white;
            box-shadow: 4px 4px 32px 12px rgb(0 0 0 / 5%);
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $rounded-pill;
        }
        span {
            @include media(down, $breakpoint-sm-sm) {
                font-size: size(12);
            }
        }
    }
    .video-wrap {
        background-color: $black;
        border-radius: size(24) 0 0 size(24);
        height: 100%;
        display: flex;
        align-items: center;
        @include media(down, $breakpoint-sm-sm) {
            background-color: $white;
            padding: size(15) size(15) size(0) size(15);
            border-radius: $border-radius-24;
        }
        .video-feed {
            width: 100%;
             @include media(down, $breakpoint-sm-sm) {
                border-radius: $border-radius-24;
            }
        }
    }
    .content-wrap {
        padding: size(30) size(30) size(0) size(0);
        height: 856px;
        overflow-y: auto;
        @include media(down, $breakpoint-xxxl) {
            height: 850px;
        }
        @include media(down, $breakpoint-xxl-b) {
            height: 620px;
        }
        @include media(down, $breakpoint-sm-sm) {
            padding: size(15);
            height: 100%;
        }
    }
    .video-title {
        margin: size(20) size(0) size(20) size(0);
        @include media(down, $breakpoint-sm-sm) {
            margin: size(0) size(0) size(20) size(0);
        }
        h3 {
            font-size: size(22);
            line-height: size(30);
            font-weight: $font-bold;
            color: $black;
            letter-spacing: size(-0.4);
            margin-bottom: size(0);
            @include media(down, $breakpoint-sm-sm) {
                font-size: size(20);
                line-height: size(28);
            }
        }
    }
    .video-status {
        margin-bottom: $margin-bottom-10;
        p {
            font-size: size(12);
            line-height: size(18);
            font-weight: $font-bold;
            color: $light-gray;
            text-transform: uppercase;
            margin-bottom: size(0);
        }
    }
    .in-info-wrap {
        margin-bottom: $margin-bottom-10;
        p {
            font-size: size(12);
            line-height: size(18);
            font-weight: $font-bold;
            color: $light-gray;
            text-transform: uppercase;
            margin-bottom: $margin-bottom-5;
        };
        a {
            font-size: size(12);
            line-height: size(18);
            font-weight: $font-bold;
        }
        
    }
    .tag-info {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $margin-bottom-10;
        .tag-item { 
            color: $white;
            font-size: size(13);
            line-height: size(22);
            font-weight: $font-semibold;
            letter-spacing: size(-0.03);
            border-radius: $border-radius-4;
            padding: size(4) size(10);
            margin: size(0) size(10) size(10) size(0);
            &.bg-light-gray {
                background-color: $extra-light-gray;
                color: $black;
            }
            &.series-tag {
                padding: 0;
                display: flex;
                .series {
                    padding: size(4) size(10);
                }
                .dollar {
                    background-color: #CFD7E8;
                    color: $primary;
                    padding: size(4) size(10);
                    border-radius: 0 4px 4px 0;
                }
            }
        }
    }
    .description {
        padding-right: size(15);
        max-height: 112px;
        overflow: scroll;
        p {
            margin-bottom: $margin-bottom-10;
        }
    }
    .video-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: $margin-top-30;
        padding-bottom: $padding-bottom-20;
        border-bottom: 1px solid $extra-light-gray;
        ul {
            display: flex;
            align-items: center;
            li {
                margin-right: size(30);
                &:last-child {
                    margin-right: 0;
                }
                a {
                    font-size: size(15);
                    line-height: size(22);
                    font-weight: $font-semibold;
                    color: $black;
                    letter-spacing: size(-0.12);
                    display: flex;
                    align-items: center;
                    span {
                        font-size: size(18);
                        margin-right: size(10);
                    }
                    &:hover,
                    &.selected {
                        color: $primary;
                        span {
                            color: $primary;
                            &::before {
                                color: $primary;
                            }
                        }
                    }
                }
            }
        }
        .favorite {
            a {
                span {
                    font-size: size(18);
                }
                &:hover {
                    span {
                        color: $primary;
                        &::before {
                            color: $primary;
                        }
                    }
                }
            }
            .share-ic {
                margin-right: size(30);
            }
        }
    }
    .follow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: $padding-top-20;
        padding-bottom: $padding-bottom-20;
        border-bottom: 1px solid $extra-light-gray;
        .follow-info {
            display: flex;
            align-items: center;
            margin-bottom: $margin-bottom-15;
            @include media(down, $breakpoint-xl) {
                width: 100%;
            }
            .profile-img {
                flex: 0 0 auto;
                img {
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                    border-radius: $rounded-pill;
                }
            }
            .profile-detail {
                margin-left: size(20);
                h5 {
                    font-size: size(17);
                    line-height: size(24);
                    font-weight: $font-semibold;
                    color: $primary;
                    letter-spacing: size(-0.22);
                    margin-bottom: size(0);
                }
                span {
                    font-size: size(12);
                    line-height: size(18);
                    font-weight: $font-bold;
                    color: $light-gray;
                    text-transform: uppercase;
                    margin-bottom: size(2);
                }
                p {
                    font-size: size(12);
                    line-height: size(18);
                    font-weight: $font-regular;
                    color: $black;
                    margin-bottom: 0;
                }
            }
        }
        .follow-btn {
            @include media(down, $breakpoint-xl) {
                width: 100%;
                margin-top: $margin-top-15;
            }
            a {
                flex: 0 0 auto;
            }
            span {
                font-size: size(12);
                margin-right: size(10);
                &:before{
                    color: $white;
                }
            }
            .btn{
                &:hover{
                    span{
                        &:before{
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
    .comment-box {
        padding-top: $padding-top-20;
        padding-bottom: $padding-bottom-40;
        @include media(down, $breakpoint-xxl){
            padding-bottom: size(65);
        }
        .dropdown-menu{
            box-shadow: 0 2px 4px 5px $extra-light-gray;
            margin-top: size(8);
            &:before{
                display: none;
            }
        }
        .comment-header {
            a {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .comment-text {
                    font-size: size(13);
                    line-height: size(22);
                    font-weight: $font-semibold;
                    color: $black;
                    display: flex;
                    align-items: center;
                    span {
                        font-size: size(16);
                        margin-right: size(5);
                        &::before {
                            color: $black;
                        }
                    }
                }
                .toogle-arrow {
                    span {
                        font-size: size(10);
                        color: $black;
                    }
                }
            }
        }
        .comments-view {
            display: none;
            &.signal-commetns-view{
                display: block;
            }
            .comments-item {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                border-bottom: 1px solid $extra-light-gray;
                padding-bottom: size(16);
                margin-top: $margin-top-20;
                .profile-img {
                    flex: 0 0 auto;
                    img {
                        width: 40px;
                        height: 40px;
                        object-fit: cover;
                        border-radius: $rounded-pill;
                    }
                }
                .comments-detail {
                    margin-left: size(10);
                    h5 {
                        font-size: size(15);
                        line-height: size(22);
                        font-weight: $font-semibold;
                        color: $primary;
                        letter-spacing: size(-0.12);
                        margin-bottom: 0;
                    }
                    p {
                        font-size: size(13);
                        line-height: size(18);
                        font-weight: $font-regular;
                        color: $black;
                        letter-spacing: size(-0.03);
                        margin-bottom: 0;
                    }
                    .video-action {
                        padding-bottom: 0;
                        margin-top: $margin-top-15;
                        border-bottom: none;
                    }
                }
            }
        }
        .add-comments {
            display: flex;
            align-items: center;
            padding: size(15) size(0);
            position: absolute;
            bottom: 0;
            width: calc(100% - 60px);
            background: $white;
            @include media(down, $breakpoint-sm-sm) {
                bottom: 15px;
            }
            .profile-img {
                flex: 0 0 auto;
                img {
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                    border-radius: $rounded-pill;
                }
            }
            .comment-input {
                width: calc(100% - 40px);
                margin-left: size(10);
                display: flex;
                align-items: center;
                input {
                    font-size: size(15);
                    line-height: size(22);
                    font-weight: $font-regular;
                    color: $black;
                    letter-spacing: size(-0.12);
                    border: none;
                    width: 99%;
                    padding: size(9) size(16);
                }
            }
            .send-btn {
                margin-left: size(10);
                flex: 0 0 auto;
                a {
                    background: $primary;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: $rounded-pill;
                }
                span {
                    color: $white;
                    font-size: size(14);
                }
            }
        }
        .add-comments-in-replay {
            display: flex;
            align-items: center;
            padding: size(15) size(0);
            position: static;
            bottom: 0;
            width: calc(100% - 60px);
            background: $white;
            margin-left: size(20);
            @include media(down, $breakpoint-sm-sm) {
                bottom: 15px;
            }
            .profile-img {
                flex: 0 0 auto;
                img {
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                    border-radius: $rounded-pill;
                }
            }
            .comment-input {
                width: calc(100% - 55px);
                margin-left: size(10);
                display: flex;
                align-items: center;
                input {
                    font-size: size(15);
                    line-height: size(22);
                    font-weight: $font-regular;
                    color: $black;
                    letter-spacing: size(-0.12);
                    border: none;
                    padding: size(9) size(16);
                    @include media(down, $breakpoint-xsw){
                        width: 140px;
                    }
                }
            }
            .send-btn {
                margin-left: size(10);
                flex: 0 0 auto;
                a {
                    background: $primary;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: $rounded-pill;
                }
                span {
                    color: $white;
                    font-size: size(14);
                }
            }
        }
    }
    .comments-fullview {
        @include media(down, $breakpoint-sm-sm) {
            position: relative;
        }
        .comment-box {
            // position: absolute;
            bottom: 0; 
            top: 0;
            background: $white;
            padding-right: size(30);
            border-radius: 0 size(24) size(24) 0;
            padding-top: $padding-top-30;
            z-index: 999;
            width: 100%;
            padding-bottom:size(0) ;
            max-height: 440px;
            overflow: scroll;
            @include media(down, $breakpoint-xxl-b) {
                max-height: 290px;
                padding-bottom: size(48); 
            }
            @include media(down, $breakpoint-sm-sm) {
                padding-top: $padding-top-15;
                padding-right: size(15);
                bottom: 15px;
                width: calc(100% - 15px);
            }
            .dropdown-box{
                margin-left: auto;
                margin-right: size(15);
            }
            .comment-header {
                padding-bottom: $padding-bottom-20;
            }
            .add-comments {
                width: calc(100% - 30px);
                @include media(down, $breakpoint-sm-sm) {
                    width: calc(100% - 15px);
                    bottom: 0;
                }
            }
            .comment-input{
                width: calc(100% - 80px);
            }
            .comments-view {
                height: calc(100% - 110px);
                display: block;
                .icon-dots{
                    &:before{
                        color: $light-gray;
                    }
                }
            }
            .inner-comments-item{
                display: flex;
                padding: size(15) size(0) size(0) size(15);

            }
            .toogle-arrow {
                transform: rotate(180deg);
            }
           
        }
        .follow, .description, .video-status{
            display: none;
        }
        .signal-commetns-view{
            display: none !important;
        }
    }
}
.view-more-comments{
    a{
        color: $black;
        position: relative;
        top: 15px;
    }
}

.mobile-profile-sidebar {
    display: none;
    @include media(down, $breakpoint-lg) {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 85px;
        right: auto;
        left: 0;
        background-color: $white;
        border-radius: $border-radius-12;
        padding: size(10) size(10);
        height:36px;
        width: 44px;
    }
}

.profile-sidebar {
    &.profile-sidebar-in {
        left: 0;
    }
    @include media(down, $breakpoint-lg) {
        background: $extra-light-gray;
        position: fixed;
        left: -100%;
        top: 70px;
        z-index: 1000;
        padding: size(15);
        max-width: 370px;
        overflow-y: auto; 
        height: calc(100% - 70px);
        @include transition-0-5s;
    }
    @include media(down, $breakpoint-sm) {
        max-width: 100%;
    }
    .mobile-profile-close {
        display: none;
        @include media(down, $breakpoint-lg) {
            display: block;
        }
        .sidebar-close {
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $extra-light-gray;
            border-radius: $rounded-pill;
            position: absolute;
            top: 5px;
            right: 5px;
            box-shadow: 4px 4px 32px 12px rgb(0 0 0 / 5%);
            span {
                font-size: size(14);
            }
        }
    }
}

.tag-item {
    color: $white;
    font-size: size(13);
    line-height: size(22);
    font-weight: $font-semibold;
    letter-spacing: size(-0.03);
    border-radius: $border-radius-4;
    padding: size(4) size(10);
    margin: size(0) size(10) size(10) size(0);
    &.bg-light-gray {
        background-color: $extra-light-gray;
        color: $black;
    }
    &.series-tag {
        padding: 0;
        display: flex;
        .series {
            padding: size(4) size(10);
        }
        .dollar {
            background-color: #CFD7E8;
            color: $primary;
            padding: size(4) size(10);
            border-radius: 0 4px 4px 0;
        }
    }
}
.add-bar{
    max-width: 315px;
    .img-wrap{
        position: relative;
        border-radius: 12px;
        overflow: hidden;
        &:after{
            content: "";
            position: absolute;
            background-color: rgba(0, 0, 0, 0.1);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
    .tag{
        width: 35px;
        height: 30px;
        background-color: rgba(34, 34, 34, 0.2);
        font-weight: $font-semibold;
        color: $white;
        border-radius: 4px;
        padding: size(0) size(9);
        display: inline-block;
        line-height: 30px;
        font-size: size(13);
        text-align: center;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 9;
    }
    .add-info{
        padding-bottom: size(42);
        h6{
            font-size: size(15);
            font-weight: $font-semibold;
            padding-top: $padding-top-20;
            line-height: 1.47;
        }
    }
}
.select-favorite-collection{
    .identity-modal-card{
        .profile-group-wrap{
            .profile-media{
                .media-body{
                    .selected-icon{
                        top: 0;
                    }
                }
            }
        }
    }
    .card{
        .add-indo-btn-wrap{
            padding: size(0) size(30) size(12) size(0);
            margin-top: $margin-top-25;
            .btn{
                font-size: size(16);
            }
        }
    }
}
    
.reply-box{
    padding-left: 20px;

    .add-comments-in-replay{
        margin-left: 0 !important;
    }
    .inner-comments-item{
        padding-left: 0 !important;
    }
}