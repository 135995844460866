.introduction-wrap {
    max-width: 870px !important;
    @include media(down, $breakpoint-lg) {
        padding: size(0) size(16);
    }
    @include media(down, $breakpoint-sm-sm) {
        padding: 0;
    }
    .modal-content {
        padding: size(50);
        border: none;
        border-radius: size(24) !important;
        background-color: $white !important;
        @include media(down, $breakpoint-sm-sm) {
            padding: size(48) size(16) size(16);
        }
    }
}

.introduction-modal {
    
    .modal-imgbox{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        overflow-y: auto;
        max-height: 600px;

        .ng-star-inserted {
            width: auto;
            object-fit: cover;
            object-position: center;
        }

        .slick-att {
            height: auto;
            width: 100%;
            display: flex;
            align-items: center;
            @include media(down,$breakpoint-xs){
                height: 200px;
              }
            img{
                max-width: 100% !important;
                height: auto;
                @include media(down,$breakpoint-md){
                    max-width: 300 !important;
                  }
            }
            
        }
        &.video-block {
            display: block;
            .slick-slider .slick-arrow.slick-next{
                right: 0;
            }
            .slick-slider .slick-arrow.slick-prev{
                left: 0;
                z-index: 99;
            }
            
        }
    }
    .modal-content{
        background-color: $white;
    }
    .content-header {
        text-align: center;
        margin-bottom: $margin-bottom-30;
        h3 {
            margin-bottom: size(12);
        }
    }
    .close-ic {
        position: absolute;
        right: 25px;
        top: 25px;
        @include media(down, $breakpoint-sm-sm) {
            right: 16px;
            top: 16px;
        }
    }
    
}
.modal-backdrop{
    z-index: 9999;
}
.modal{
    z-index: 9999;
}
