.active-dot {
  width: 9.33px;
  height: 9.33px;
  background: $success;
  border: 1px solid $white;
  border-radius: 50%;
  position: absolute;
  bottom: 0px;
  right: 0;
}
.popuptitlebar {
  .popup-btn {
    padding: 0;
    width: 24px !important;
    height: 24px !important;
    min-width: 0;
    min-height: 0;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    background: $extra-light-gray !important;
    justify-content: center;
    font-size: size(14);
    &:hover {
      .k-button:hover,
      .k-button.k-state-hover {
        &::before {
          opacity: 0;
        }
      }
    }
    &:hover {
      background: $extra-light-gray !important;
    }
    .k-icon {
      font-size: 9px;
    }
    span {
      &::before {
        content: "";
        font-family: "icomoon", sans-serif !important;
      }
    }
    img {
      mix-blend-mode: multiply;
      max-width: 15px;
    }
  }
  .icon-Icon-down-arrow-block {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-left: 1.875rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #efefef;
    font-size: 0.625rem;
    transform: rotate(0);
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
  }
}
.msgblock-box {
  flex-wrap: wrap;
  .dropdown-menu {
    position: static;
    box-shadow: none;
    border-radius: 0;
    min-width: 100%;
    border-top: 1px solid $extra-light-gray;
    padding: 10px 10px 10px 39px;
    margin-top: 1.25rem;
    margin-bottom: -1.25rem;
    background: transparent;
    animation: none;
    @include media(down, $breakpoint-xs) {
      margin-top: 10px;
      margin-bottom: -10px;
      padding: 5px 10px 0px 39px;
    }
    &::before {
      display: none;
    }
  }
}
.popuptitlebar {
  .imgbox {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    margin-right: 10px;
    img {
      border-radius: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
  .usermsg-titlename {
    width: 100%;
    flex-shrink: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    font-size: size(16);
    letter-spacing: -0.25px;
  }
}
.k-window {
  overflow: hidden;
  border-radius: 12px 12px 0 0 !important;
  min-height: auto !important;
  .userlist-popup.video-dialoguewrap & {
    position: fixed !important;
    top: auto !important;
    bottom: 0 !important;
    right: 26px !important;
    left: auto !important;
    box-shadow: 2px 2px 18px 8px rgba(31, 14, 14, 0.03) !important;
    transform: none !important;
    max-width: 320px;
    width: 100% !important;
    @include media(down, $breakpoint-lg) {
      right: 15px !important;
    }
    @include media(down, $breakpoint-md) {
      max-width: 300px;
    }
    @include media(down, $breakpoint-xs-xs) {
      right: 10px !important;
    }
  }
  .singlechat-popup & {
    position: fixed !important;
    top: auto !important;
    bottom: 0 !important;
    right: 374px !important;
    left: auto !important;
    box-shadow: 2px 2px 18px 8px rgba(0, 0, 0, 0.03) !important;
    transform: none !important;
    max-width: 315px;
    width: 100% !important;
    z-index: 1255 !important;
    @include media(down, $breakpoint-lg) {
      right: 350px !important;
    }
    @include media(down, $breakpoint-md) {
      max-width: 300px;
      right: 15px !important;
    }
    @include media(down, $breakpoint-xs-xs) {
      right: 10px !important;
    }
  }
  .k-content:empty {
    padding-bottom: 0 !important;
  }
}
.msg-chatbox {
  min-height: 175px;
  max-height: 320px;
  overflow: auto;
}
.video-dialoguewrap {
  &.userlist-popup {
    .k-window-content {
      padding-top: 0;
    }
  }
  .uploaded-imgbox {
    &.multipost {
      .imgbox {
        width: calc(50% - 10px) !important;
        &:nth-child(even) {
          margin-right: 0 !important;
        }
      }
    }
  }
  .msg-receive-time-outer {
    margin-bottom: 10px;
    text-align: center;
  }
  .uploaded-file-wrap {
    .file {
      p {
        flex-shrink: 1;
        word-break: break-all;
        max-width: calc(100% - 90px) !important;
        @include media(down, $breakpoint-xs) {
          max-width: 100% !important;
        }
      }
    }
  }
  .btnbox {
    .btn {
      font-size: size(14);
      min-height: auto;
    }
  }
  .message-layout {
    .msg-list-wrap {
      height: auto;
      margin-left: -15px;
      margin-right: -15px;
      .all-msg-box {
        max-height: calc(100vh - 200px);
        padding-left: 15px;
        padding-right: 15px;
      }
      li {
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        &:last-child {
          padding-bottom: 4px;
          border-bottom: 0;
          .msgblock-box {
            .dropdown-menu {
              margin-bottom: 0;
              padding-bottom: 0;
            }
          }
        }
        .imgbox {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
        }
        .msg-info {
          align-self: center;
          width: 100%;
          padding-right: 0;
          h6 {
            font-size: size(15);
          }
        }
      }
    }
  }
  .video-upload-wrap {
    ul {
      li {
        margin-bottom: 0;
      }
    }
  }
}
.video-upload-wrap .k-window-titlebar {
  background: rgba(34, 34, 34, 0.05);
  border: 0;
  color: $black;
  border-radius: 12px 12px 0 0;
  margin: 1px;
  padding: 9px 16px;
  .k-window-title {
    font-size: 15px;
  }
}

.video-dialoguewrap {
  .received-msg-card {
    height: auto;
  }
  .received-msg-card {
    .msg-outer {
      .msg-text-box {
        margin-bottom: 1rem;
        width: 100%;
      }
    }
    &.sentmsg {
      .msg-outer {
        .msg-text-box {
          .msg {
            max-width: 100%;
            background-color: rgba(14, 55, 140, 0.05);
          }
        }
      }
    }
  }
  .cardpopup-footer {
    .add-btn {
      width: 63px !important;
      font-size: 14px !important;
      text-align: left !important;
      display: flex;
      align-items: center;
    }
    .send-msg-btn {
      padding: 0;
      flex-shrink: 0;
      background-color: $extra-light-gray !important;
      border-color: $extra-light-gray !important;
      width: 32px !important;
      height: 32px !important;
      span {
        font-size: 13px !important;
        &::before {
          color: $primary !important;
        }
      }
    }
    .add-btn,
    .send-msg-btn {
      position: static !important;
    }
    .msg-type-input-outer {
      padding: 20px 16px 8px !important;
      border-top: 1px solid $extra-light-gray;
      margin: 0 -16px;
    }
    .msg-type-input-wrap {
      textarea {
        padding: 0 !important;
        min-height: 22px !important;
        height: 22px !important;
        background: $white;
        border-radius: 0;
      }
    }
  }
}
