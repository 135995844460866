.avatar-upload {
  position: relative;
  max-width: 120px;
  margin: size(30) auto;
  .avatar-edit {
    position: absolute;
    right: 0;
    z-index: 1;
    top: 0;
    input {
      display: none;
      & + label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin-bottom: 0;
        border-radius: 100%;
        background: $primary;
        box-shadow: 0px 2px 4px 0px rgba($dark-black, 0.12);
        cursor: pointer;
        font-weight: normal;  
        @include transition-0-2s;

      }
      & + label {
        &::after{
          content: "";
          color: $white;
          position: absolute;
          top: 10px;
          left: 0;
          right: 0;
          text-align: center;
          margin: auto;
        }
      }
    }
    label{
      span{
        &::after{
          color: $white;
        }
      }
    }
  }
  .avatar-preview {
    width: 120px;
    height: 120px;
    position: relative;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-color: $extra-light-gray;
    justify-content: center;
    img{
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      border-radius: 100%; 

    }
    & > div {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}