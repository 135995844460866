.create-meeting-wrap{
    margin-top: $margin-top-35;
    .select-box{
        .ng-arrow-wrapper{
            cursor: inherit;
        }
    }
    ng-select.ng-select-single{
        .ng-select-container{
            &.ng-has-value{
                border-radius: $border-radius-12;
            }
        }
    } 
    .ng-select{
        .ng-select-container{
            .ng-value-container{
                .ng-input{
                    top: auto;
                    padding-right: size(40);
                    padding-left: size(24);
                    input{
                        color: $dark !important;
                    }
                }
                .ng-input>input{
                    margin: 0;
                }
            }
        }
    }   
    .imgbox{
        img{
            border-radius: 100%;
            min-width: 40px;
        }
    }
    .user-info{
        margin-left: size(10);
        h6{
            color: $primary;
            margin: 0;
            @include media(down, $breakpoint-md){
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 160px;
            }
        }
        span{
            @include media(down, $breakpoint-sm){
                max-width: 180px;
                display: inline-block;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
    .timepciker-create{
        .btn{
            min-height: auto;
            padding: size(5) size(16);
            span{
                right: 44px;
                color: $light-gray;
            }
        }
        .btn-default{
            background-color: $primary;
            color: $white;
            min-width: auto;
        }
    }
    .bs-timepicker-field{
        padding: size(0);
        margin: 0 auto;
        background-image: none;
    }
}
.create-meeting{
    .section-title{
        margin-bottom: $margin-bottom-30;
        h2{
            font-size: size(32);
            margin-bottom: $margin-bottom-15;
            @include media(down, $breakpoint-sm-sm){
                font-size: size(24);
            }
        }
        h5{
            font-weight: $font-regular;
            @include media(down, $breakpoint-sm-sm){
                font-size: size(16);
            }
        }
    }
    .content-wrap{
        padding:size(70) size(0);
        text-align: center;
        margin: 0 auto; 
        @include media(up, $breakpoint-md){
            width: 570px;
        } 
        @include media(down, $breakpoint-sm-sm){
            padding:size(30) size(0);
            margin: 0;
        }
        @include media(down, $breakpoint-xs-xs){
            padding:size(15) size(0);
        }
    }
    .spacer-line{
        border-top: 1px solid $extra-light-gray;
        margin: size(20) size(0);
    }
    &.edit-metting{ 
        .form-control[readonly]{
            background-color: transparent;
            border: 1px solid $light-gray;
            color: #495057; 
        }
    }
}
.reminder-listing{
    .reminder-wrap{
        h5{
            margin: size(0);
            font-weight: $font-semibold;
        }
        .icon-info{
            color: $light-gray;
            font-size: size(26);
            padding-right: size(12);
            @include media(down, $breakpoint-sm-sm){
                font-size: size(20);
            }   
        }
        .info{
            span{
                color: $light-gray;
                padding-top: size(8);
                display: inline-block;
            }
        }
        .icon-icon-plush{
            color: $light-gray;
            font-size: size(18);
            cursor: pointer;
            display: flex;
            align-items: center;
            @include media(down, $breakpoint-sm-sm){
                font-size: size(15);
            }
        }
        .icon-icon-right-angle-arrow{
            color: $light-gray;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }
    .left-wrap{
        width: calc(100% - 25px);
    }
    .btn-wrap{
        margin: size(40) size(100) size(0);
        @include media(down, $breakpoint-xs-xs){
            margin: size(40) size(0) size(0);
        }
    }
}
.meeting-form{
    .form-group{
        label{
            font-size: size(13);
        }
    }
    textarea{
        line-height: 30px;
    }
    .icon-icon-info{
        font-size: size(16);
        margin-left: size(6) !important;
    }
    .input-with-icon{
        span{
            font-size: size(22);
            @include media(down, $breakpoint-sm-sm){
                font-size: size(18);
            }
            &:before{
                color: $light-gray;
            }
        }
    }
}
.select-participants{
    &.modal-dialog{
        max-width: 710px !important;
    }
    .participants-search{
        position: relative;
        input{
            width: 100%;
            background-color: $extra-light-gray;
            color: $light-gray;
            font-size: size(16);
            line-height: size(26);
            letter-spacing: size(-0.25);
            border-radius: $border-radius-12;
            border: none;
            padding: size(12) size(30) size(12) size(63);
            @include media(down, $breakpoint-sm-sm){
                padding: size(12) size(30) size(12) size(40);
            }
        }
        span{
            position: absolute;
            left: 33px;
            top:16px;
            font-size: size(16);
            @include media(down, $breakpoint-sm-sm){
                left: 14px;
            }
        }
    }
    .participants-list{
        .s-title{
            margin: size(20) size(0);
            font-weight: $font-semibold;
        }
        
        .reminder-wrap{
            border-bottom: 1px solid $extra-light-gray;
            padding: size(20) size(0);
            .img-wrap{
                min-width: 56px;
                img{
                    border-radius: 100%;
                }
            }
            h5{
                margin: size(0);
                font-weight: $font-semibold;
                @include media(down, $breakpoint-sm-sm){
                    font-size: size(16);
                }
            }
            .icon-icon-close{
                font-size: size(20);
                padding-top: size(12);
                cursor: pointer;
                @include media(down, $breakpoint-sm-sm){
                    font-size: size(14);
                }
            }
            .icon-info{
                color: $light-gray;
                font-size: size(26);
            }
            .info{
                padding: size(0) size(0) size(0) size(20);
                span{
                    color: $black;
                    padding-top: size(8);
                    display: inline-block;
                    @include media(down, $breakpoint-sm-sm){
                        font-size: size(13);
                    }
                }
            }
            .icon-icon-plush{
                color: $light-gray;
                font-size: size(18);
            }
        }
        .left-wrap{
            width: calc(100% - 25px);
        }
        .btn-wrap{
            margin: size(0) size(100) size(0);
            @include media(down, $breakpoint-xs-xs){
                margin: size(0);
            }
        }
    }
    .card{
        .btn{
            margin-top: $margin-top-30;
        }
    }
}
.create{
&.ng-select-single{
    .ng-select-container{
        &.ng-has-value{
            cursor: pointer;
        } 
    }
} 
.ng-select-container{
    .ng-value-container{
        .ng-input>input{
            cursor: pointer !important;
        }
    }
    }
}
.default-pointer {
    cursor: default !important;
}