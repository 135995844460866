.network-main-pro-card{
  padding: 0;

  .network-profile-card-info{
    padding: size(30) size(30) size(15) size(30);    

    .btnbox{
      margin: size(15) 0 0;
    }
  }
  .profile-inner-info{
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    a{
        padding:size(15) size(5);     
        background-color: rgba($primary, 0.05);   
        font-size: size(13);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $black;
        width: 33.33%;
        font-weight: $font-semibold;
        text-align: center;
        &.added-video{
          background-color: rgba($primary, 0.10);   
        }
        &:hover{
          background-color: rgba($primary, 1);
          color: $white;   
        }
        &:first-child{
          border-radius: 0 0 0 size(12);
        }
        &:last-child{
          border-radius: 0 0 size(12) 0;
        }
        @include media(down, $breakpoint-x-xl){
          width: 50%;
          &:last-child{
            width: 100%;
            border-top: 1px solid rgba($primary, 0.10);
            border-radius: 0 0 size(12) size(12);
          }
          &:first-child{
            border-radius: 0;
          }
        }
    }
  }
}
.media-view{
  display: block;
  position: relative;
  .title-box{
    display: flex;
    align-items: flex-start;
    .imgbox{
      margin-right: size(10);
      width: size(50);
      border-radius: $border-radius-12;
      height: size(50);
      overflow: hidden;
    }
    .title{
      width: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      h6{
        font-size: size(15);
        margin-bottom: 0;
      }
      span{
        margin-top: size(4);
        font-size: size(14);
      }
      p{
        margin-top: size(4);
        font-size: size(13);
      }
    }
  }
}
.info-licenses-media{
  .title-box{
    padding-right: size(30);
    .imgbox{
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $extra-light-gray;
    }
  }
  a{
    &.edit-icon{
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      span{
        &::before{
          color: $light-gray;
        }
      }
    }
    &:hover{
      span{
        &::before{
          color: $primary;
        }
      }
    }
  }
  
}
.info-interests-media{
  .title-box{
    padding-right: size(120);
    @include media(down, $breakpoint-xs-xs){
      padding-right: 0;
    }
  }
  .btn{
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    min-height: inherit;
    padding:size(5) size(15);
    border-radius:$border-radius-4;
    span{
      margin-right: size(8);
      font-size: size(13);
    }
    @include media(down, $breakpoint-xs-xs){
      position: inherit;
      float: right;
      margin-top: $margin-top-10;
    }
  }
}

.card-msg-nav-dropdown{
  margin-top: 0;
  padding: size(10);  
  min-width: 265px;
  @include media(down, $breakpoint-xs-xs){
    min-width: 240px;
  }
  a{
    display: flex;
    align-items: center;
    color: $black;
    padding: size(10);
    font-weight: $font-semibold;
    span{
      margin-right: size(10);
      &::before{
        color: $black;
      }
    }
  }
}

.connection-list{
  .module-search-wrap{
    .module-search {
      position: relative;
      input{
        background-color: $extra-light-gray;
        padding: size(12) size(55) size(12) size(60);
      }
      span{
        left: 30px;
      }
      .search-close{
        position: absolute;
        right: 25px;
        top: 25px;
        @include media(down, $breakpoint-lg){
          top: 17px; 
        }
      }
    }
  } 
}
.people-listing{
  .filter-btn-wrap{
    margin-left: size(10);
  }
}
.network-experience-wrap{
  .info-experience-media{
    p{
      margin: size(0);
    }
  }
}