.project-files-box {
    display: flex;
    flex-wrap: wrap;
    .project-files {
        margin-right: size(10);
        .add-files {
            border: none;
            background: $extra-light-gray;
            border-radius: $border-radius-12;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            padding: 0;
            color: $primary;
        }
    }
    .project-files-add {
        margin-right: 10px;
        .file-box {
            background: $extra-light-gray;
            border-radius: $border-radius-12;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            padding: 0;
            color: $primary;
            position: relative;
            span {
                font-size: size(28);
            }
            img {
                width: 60px;
                height: 60px;
                object-fit: cover;
                border-radius: $border-radius-12;
            }
            .close-btn {
                background: $light-gray;
                width: 14px;
                height: 14px;
                border-radius: $rounded-pill;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: -3px;
                top: -3px;
                em {
                    color: $white;
                    font-size: size(6);
                    &::before {
                        color: $white;
                    }
                }
            }
        }
    }
}

.project-details {
    text-align: left;
    .card-title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .edit-btn {
            border: none;
            background: transparent;
            color: $light-gray;
            font-size: size(18);
            padding-left: size(16);
            &:hover {
                color: $primary;
            }
        }
    } 
    .project-tag {
        margin-bottom: -15px;
        .industry-list {
            font-size: size(13) !important;
            line-height: size(18);
            font-weight: $font-semibold;
            color: $black;
            margin: size(5) 0 size(15) !important;
        } 
    } 
    .project-info {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $margin-bottom-10;
        .info-box {
            font-size: size(13);
            line-height: size(18);
            font-weight: $font-semibold;
            color: $black;
            display: flex;
            margin-right: size(30);
            &:last-child {
                margin-right: 0;
            }
            span {
                color: $primary;
                font-size: size(16);
                margin-right: size(5);
                &::before {
                    color: $primary;
                }
            }
        }
    }
    .description {
        p {
            margin-bottom: $margin-bottom-20 !important;
        }
    }
    .attachments {
        h6 {
            margin-bottom: $margin-bottom-20;
        }
    }
}
.services-search-box{
    .module-search-wrap{
        .filter-btn-wrap{
            .dropdown-menu{
                .action-wrapper{
                    margin: size(0);
                }
            }
        }
    }   
}