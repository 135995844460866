.sticky-row-wrap{
    position: sticky;
    top: 70px;
    z-index: 9;
}
.search-filter-sticky{
    .module-search{
        border-radius: size(0) size(0) size(7) size(7) !important;   
        box-shadow: 5px 2px 20px $light-gray !important;
    }
}
.module-search-wrap{
    padding-bottom: $padding-bottom-30;
    flex-wrap: wrap;

    @include media(down, $breakpoint-lg){
        padding-bottom: size(65);
    }
    .search-filter-wrap{
        @include media(down, $breakpoint-lg){
            top: 6px;
            box-shadow: none;
        }
        
        @include media(down, $breakpoint-sm-sm){
            top: 55px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
            padding: 0px 10px;
        }
    }
    .grid-list-icon{
        margin: size(0) size(10) size(0) size(0);
        display: flex;
        align-items: center;
        @include media(down, $breakpoint-lg){
            margin: size(0);
        }
        .icon-grid{
            &::before{
                color: $light-gray;
            }
        }
        span{
            margin: size(0) size(10);
            font-size: size(17);
            cursor: pointer;
            &::before{
                color: $black;
            }
            &.active{
                &::before{
                    color: $primary;
                }
            }
            @include media(down, $breakpoint-md){
                margin: size(0) size(5);
                font-size: size(15);
            }
        }
        .icon-list{
            font-size: size(13);
        }
    }
    .dropdown-menu{

        &:before{ 
            display: none;
        }
    }
    .module-search{
        box-shadow: 0 0 5px rgb(0 0 0 / 5%);
        border-radius: $border-radius-12;
        overflow: hidden;
        @include media(down, $breakpoint-xsw){
            position: relative;
        }
        input{
            width: 100%; 
            background-color: $white;
            color: $light-gray;
            font-size: size(16);
            line-height: size(26);
            letter-spacing: size(-0.25);
            border: none;
            padding: size(12) size(285) size(12) size(60);
            height: 60px;
            @include media(down, $breakpoint-lg){
                padding: size(12) size(175) size(12) size(34);
                height: 50px;
            }
            @include media(down, $breakpoint-md){
                font-size: size(14);
            }
            @include media(down, $breakpoint-xsw){
                padding: size(12) size(40) size(12) size(34);
            }
        }
        span{
            position: absolute;
            left: 44px;
            top:21px;
            font-size: size(17);
            &.search-close{
                font-size: size(15);
                @include media(down, $breakpoint-xsw){
                    display: block !important;
                    left: auto;
                    right: 15px;
                }
            }
            @include media(down, $breakpoint-lg){
                top: 16px;
                left: 22px;
            }
            @include media(down, $breakpoint-md){
                font-size: size(16);
            }
            @include media(down, $breakpoint-xsw){
                left: 12px;
            }
        }
    }
    .filter-dropdown{
        font-weight: $font-semibold;
        @include media(down, $breakpoint-lg){
            font-size: size(15);
        }
        @include media(down, $breakpoint-md){
            font-size: size(14);
        }
    }
    .common-tabs{
        width: auto;
        @include media(down, $breakpoint-xsw){
            .icon-icon-close-black{
                &.search-close{
                    display: none;
                }
            }
        }
    }
    .filter-btn-wrap{
        @include media(down, $breakpoint-md){
            padding: size(3) size(5);
            height: 45px;
        }
        // @include media(down, $breakpoint-xsw){
        //     height: 40px;
        // }
        .icon-Icon-down-arrow-block{
            @include media(down, $breakpoint-md){
                margin-left: size(5);
            }
        }
        .dropdown-menu{
            top: 65px;
            padding: size(25) size(0) size(70) size(0); 
            border-radius: size(0) size(0) size(12) size(12); 
            margin: size(0);
            @include media(down, $breakpoint-lg) {
                top: 50px; 
            }
            .dropdown-menu-wrapper{
                padding-left: size(20);
                padding-right: size(20);
                max-height: 600px;
                overflow-y: scroll;
                overflow-x: hidden;
                @include media(down, $breakpoint-xxxxl){
                    max-height: 470px;
                }
                @include media(down, $breakpoint-xxl-b){
                    max-height: 350px;
                }
                @include media(down, $breakpoint-x-xl){
                    max-height: 310px;
                }
                @include media(down, $breakpoint-sm-sm){
                    max-height: 280px;
                }
                // @include media(down, $breakpoint-xs-xs){
                //     max-height: 400px;
                // }
                &.investor-dropdown-menu-wrapper{
                    margin-top: -25px;
                }
                .switch-group-dropdown-wrap{
                    position: relative;
                    z-index: 0;
                    &::after{
                        content: "";
                        width: calc(100% + 40px);
                        left: -20px;
                        background-color: #f2f5f9;
                        z-index: -1;
                        top: 0;
                        position: absolute;
                        height: 100%;
                        border-radius: size(12) size(12) size(0) size(0); 
                    }
                    .switch-box{
                        padding: 10px 0;
                        border-bottom: 1px solid rgba(34, 34, 34, 0.1);
                        &:last-child{
                            border-bottom: none;
                            margin-bottom: 15px;
                        }
                    }
                }
            }
            .action-wrapper{
                    padding:size(15) size(0);
                    background-color: $extra-light-gray;
                    border-bottom-left-radius: size(12);
                    border-bottom-right-radius:size(12);
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    .btn-box{
                        padding: size(0) size(20);
                    }
            }
            .b-label{
                font-size: size(15);
                letter-spacing: 0;
                color: $black;
                text-transform: inherit;
                margin:size(0) size(0) size(20) size(0); 
            }
        }
        .btn-box{
            .btn-reset{
                color: $primary;
            }
        }
        .range-wrap{
            margin:size(0) size(0) size(20) size(0);
        }
        .r-text{
            text-transform: capitalize;
            font-size: size(15);
        }
        .primary-text{
            font-size: size(12);
        }
    } 
    .invesment-size{
       label{
            margin-bottom: $margin-bottom-20;
        }
        
    }
    ng-select{
        .ng-select-container{
            padding:size(6) size(14) !important;
            border-radius: $border-radius-4;
        }
        .ng-placeholder{
            position: static !important;
            font-size: size(15) !important;
            padding-bottom: 0 !important;
        }
    } 
    input::-webkit-input-placeholder{
            color: $light-gray;
       
    }
}
.category-filters{
    margin-bottom: $margin-bottom-25;
    
    h6{
        font-weight: $font-semibold;
    }
    ul{
        list-style: none;
        margin: 0;
        padding-bottom:size(5);
        white-space: nowrap;
        overflow: auto; 

        -ms-overflow-style: none;
        scrollbar-width: none;

        // &:hover{
        //     -ms-overflow-style: inherit;
        //     scrollbar-width: inherit;     

        //     &::-webkit-scrollbar {
        //         display: block; 
        //     }
        // }

        &::-webkit-scrollbar {
            display: none;
        }

        li{
            display: inline-block;
            vertical-align: top;
            font-size: size(15);
            color: $black;
            letter-spacing: -0.12px;
            line-height: size(22);
            margin-right: size(10);
            border-radius: $border-radius-4;
            padding:size(5) size(10);
            border: 1px solid $switch-gray;
            cursor: pointer;
            &.active{
                background-color: $primary;
                color: $white;
            }
            label{
                margin-bottom: 0;
                cursor: pointer;
            }
        }
    }
}

.search-custom-checkbox{
    .form-group{
        margin:size(0) size(0) size(10) size(0);
        cursor: pointer;
        input{
            display: none;
            cursor: pointer;
        }
        input:checked{
            + label{
                background-color: $primary;
                color: $white !important; 
                cursor: pointer;
            }
        }
    }
    label{
        padding: 0;
        margin:size(0) size(9) size(10) size(0);
    }
    .checkbox-list{
        label{
            background-color: $white;
            border: 1px solid $gary;
            padding: size(11);
            border-radius: $border-radius-4;
            text-transform: capitalize;
            letter-spacing: 0; 
            font-size: size(14);
            margin:size(0) size(9) size(0) size(0);
            cursor: pointer;
        }
    }
}
.listed-item-box{
    background-color: $white;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    .img-box-wrap{
        @include media(down, $breakpoint-xs-xs){
            margin: size(0) auto size(15);
        }
        img{
            @include media(down, $breakpoint-xs-xs){
                max-height: 150px;
            }
            @include media(down, $breakpoint-xsw){
                max-height: 140px;
            }
        }
    }
}
.rating-wrap{
    .bs-rating-star{
        color: $light-gray;
        font-size: size(15);
        margin-right: size(5);
        &.active{
            color: $primary;
        }
    }
    .rate-average{
        background-color: #0E378C;
        border-radius: 0.25rem;
        padding: 0.0625rem 0.3125rem;
        font-size: 0.6875rem;
        letter-spacing: 0.1px;
        line-height: 1.455;
        color: #ffffff;
        margin-right: 0.3125rem;
        margin-top: 0.125rem;
        vertical-align: top;
        display: inline-block;
      }
}
.module-info{
    padding: size(20);
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-end;
    @include media(down, $breakpoint-md){
        padding: size(15);
    }
    .info{
        min-height: 42px;
        max-height: 42px;
        overflow: hidden;
        width: 100%;
    }
    .rating-wrap{
        width: 100%;
    }
    h4 {
        font-size: size(18);
        line-height: size(26);
        font-weight: $font-semibold;
        color: $black;
        letter-spacing: size(-0.25);
        margin-bottom: size(3);
        width: 100%;
        @include media(down, $breakpoint-md){
            font-size: size(14);
            line-height: size(20);
            min-height: 43px;
            max-height: 43px;
        }
        span {
            margin-left: size(10);
            font-size: size(14);
        }
        .icon-blue{
            &::before{
                color: $primary;
            }
        }
        a{
            font-size: size(18);
            @include media(down, $breakpoint-md){
                font-size: size(14);
            }
            &:hover{
                color: $primary;
            }
        }
    }
    h5 {
        font-size: size(13);
        font-weight: $font-semibold;
        color: $primary;
    }
    
    p{ 
        font-size: size(13);
        margin-bottom: size(3);
        @include media(down, $breakpoint-md){
            font-size: size(12);
        }
    }
    .info{
        p{
            color: $light-gray;
        }
    }
    .price{
        font-size: size(13);
        color: $primary;
        letter-spacing: -0.03px;
        line-height: size(22);
        font-weight: $font-semibold;
        margin-top: $margin-top-5;
        display: block;
        width: 100%;
    } 
}
.img-box-wrap{
    position: relative;
    .icons-wrap{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .tag-item{
        display: inline-block;
        font-size: size(11);
        padding: size(4) size(12);
        border-radius: size(0) size(4) size(0) size(0);
        margin: size(0);
        @include media(down, $breakpoint-md){
            font-size: size(10);
            padding: size(4) size(5);
        }
    }
    .play-ic {
        width: 50px;
        height: 50px;
        border-radius: $rounded-pill;
        border: 2px solid $white;
        background: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 20px;
        bottom: -25px;
        @include media(down, $breakpoint-md){
            width: 30px;
            height: 30px;
            font-size: size(12);
            bottom: -15px;
        }
    }
}
.switch-box{
    .switch{
        width: 40px;
        height: 20px;
        min-width: auto;
        background-color: $switch-gray;
        small{
            width: 16px;
            height: 16px;
            top: 2px;
            right: calc(100% - 18px);
        }
        &.checked{
            background-color: $primary;
            small{
                right: calc(100% - 38px);
            }
        }
    }
    h6{
        color: $black;
        font-size: size(15);
        font-weight: $font-semibold;
        margin: size(0);
    }
    p{
        color: $light-gray;
        font-size: size(13);
    }
}
.text-btn{
    color: $primary;
    font-weight: $font-semibold;
    font-size: size(15);
   
}
.blue-text{
    color: $primary;
    font-size: size(13);
    font-weight: $font-semibold;
    margin: size(0) size(15) size(0) size(0);
}
.listed-item-list-view{
    box-shadow: 0 0 5px rgb(0 0 0 / 5%);
    border-radius: $border-radius-12; 
    border: size(12) solid $white;
    background-color: $white;
    .listed-item-box{
        padding: size(18);
    }
    .img-box-wrap{
        width: 100px;
        height: 100px;
        img{
            border-radius: 100%;
        }
    }
    .icons-wrap{
        @include media(down, $breakpoint-md){
            margin: size(10) size(0);
        }
    }
    .tag-item{
        font-size: size(11);
        font-weight: $font-bold;
        margin: 0;
        @include media(down, $breakpoint-md){
            font-size: size(10);
        }
    }
    .module-info{
        padding: size(0) size(0) size(0) size(20);
        width: calc(100% - 100px);
        @include media(down, $breakpoint-xs-xs){
            width: 100%;
        }
        p{
            font-size: size(13);
        }
        .info{
            margin-top:size(5);
            p{
                font-size: size(15);
            }
        }
    }
    .spacer-line {
        border-top: 1px solid rgb(210 207 207 / 10%);
        padding: size(0);
        margin: size(0) size(30) size(0) size(30);
    }
    .img-box-wrap{
        .play-ic{
            width: 28px;
            height: 28px;
            right: 0;
            bottom: 0;
            span{
                font-size: size(9);
            }
        }
    } 
}  
.without-grid-icons{
    .module-search-wrap{
        .module-search{
            input{
                padding:size(12) size(180) size(12) size(60);
                @include media(down, $breakpoint-lg){
                    padding:size(12) size(120) size(12) size(30);
                }
                @include media(down, $breakpoint-xsw){
                    padding:size(12) size(40) size(12) size(35);
                }
            }
        }
    }  
    .filter-btn-wrap{
        @include media(down, $breakpoint-xsw){
            border: 0;
        }
    }
} 
.next-prev-indicators{
    [class^=icon-]{ 
        position: absolute;
        width: 36px;
        height: 36px;
        line-height: 36px;
        display: inline-block;
        text-align: center;
        border-radius: 100%;
        background-color: $gary;
        color: $black;
        font-size: size(12);
        top: -1px; 
        left: -11px;
    }
    .icon-icon-right-angle-arrow{
        right: -12px;
        left: auto;
        @include media(down, $breakpoint-xs-xs){
            right: -11px; 
        }
    }
} 

