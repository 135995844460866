.left-tabs{
    flex-direction: column;
    @include media(down, $breakpoint-lg){
        flex-direction: initial;
		padding: size(0) size(30);
    }
    .nav-link{
        border-radius: 0;
        border-left: 2px solid $white;
        color: $black;
        display: flex;
        align-items: center;
        padding: size(14) size(20) size(14) size(20);
        font-weight: $font-semibold;
        font-size: size(15);
        position: relative;
        @include media(down, $breakpoint-lg){
            padding: size(8) size(10);
            background-color: $extra-light-gray;
            border-left:none;
            color: $black;
            border-radius: $border-radius-4;
            margin: size(0) size(10) size(10) size(0);
        }
        @include media(down, $breakpoint-md){
            font-size: size(14);
            padding: size(3) size(8);
        }
            &.active, &.show>.nav-link{
                background-color: transparent;
                border-left: 2px solid $primary;
                color: $primary;
                @include media(down, $breakpoint-lg){
                    background-color: $primary;
                    border-left:none;
                    color: $white;
                }
            }

            em{
                font-style: normal;
                margin-left: size(5);
            }
        }
        span{
            padding: size(0) size(0) size(0) size(14);
            position: absolute;
            right: 1.25rem;
            font-size: size(12);
            color: $wizard-border-bg;
            @include media(down, $breakpoint-lg){
                display: none;
            }
            em{
                font-style: inherit;
                margin-left: 0.3125rem;
            }
        }
    }
.investors-detail{
    margin: size(30) size(0) size(0) size(0);

    .profile-info{
        text-align: center;
        position: relative;
        .card-name-box{
            span{
                display: inline;
            }
        }
        span{
            color: $light-gray;
            margin-top: $margin-top-10;
            text-align:center;
            display: block;
            font-size: size(13);
            font-weight: $font-semibold;
        }
        h4{
            font-size: size(18);
            span{
                margin-top: 0;
            }
        }
        .d-flex{
            span{
               margin: 0 0 0 5px;;
            }
        }
        p{
            margin-bottom: $margin-bottom-5;
        }
        h6{
            margin-bottom: $margin-bottom-15;
        }
        .btnbox{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            .btn{
                font-size: size(14);
                margin: size(5) size(10);
                padding: size(10);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                min-height: 40px;
                line-height: normal;
                max-width: 200px;
                span{
                    margin:size(0) size(10) size(0) size(5);
                    &:before{
                        color: $white;
                    }
                }
                &:hover{
                    span{
                        &:before{
                            color: $primary;
                        }
                    }
                }
            }
        }
        .like-and-action-wrap{
            position: absolute;
            top: -10px;
            right: -5px;
            display: flex;
            align-items: center;
            a{
                padding: size(0) size(5);
                span{
                    font-size: size(16);
                    margin-top: 0;
                }
                &:hover{
                    span{
                        &:before{
                            color: $primary;
                        }
                    }
                }
                &:last-child{
                    margin-left: size(20);
                }
            }
        }
        .imgbox{
            margin: size(0) auto size(20);
            border-radius: 100%;
            position: relative;
            .play-btn{
                position: absolute;
                right: -5px;
                bottom: 0px;
                background-color: $primary;
                width: 32px;
                height: 32px;
                border-radius: 100%;
                font-size: size(15);
                display: flex;
                align-items: center;
                justify-content: center;
                span{
                    margin-left: size(6);
                    margin-top: 0px;
                }
            }
            img{
                border-radius: 100%;
            }
        }
    } 
    .page-title{
        margin: 0;
        padding: size(0) size(0) size(30) size(0);
        font-size: size(20);
        @include media(down, $breakpoint-md){
            padding: size(0) size(0) size(15) size(0);
        }
    }
    .video-box{
        margin: size(0) size(-30);
        @include media(down, $breakpoint-md){
            margin: size(0) size(-15);
        }
        .video-feed{
            border-radius: 0;
        }
    }
    .profile-details{
        .btnbox{
            margin: size(15) size(0) size(15) size(0);
            .btn{
                width: 114px;
                border-radius: $border-radius-4;
            }
        }
    }
    .dropdown-menu{
        box-shadow: 0 2px 4px 5px $extra-light-gray;
    }
    .profile-info{
        .like{
            span{
                font-size: size(17);
                font-weight: $font-regular;
                margin-top: size(0);
                &:before{
                    color: $primary;
                }
            }
            a{
                display: flex;
                align-items: center;
                margin:0 5px;
                
            }
            .dropdown-box{
                .dropdown-menu{
                    &.dropdown-menu-right{
                        right: -27px !important;
                        @include media(down, $breakpoint-sm-sm){
                            right: -20px !important;
                        }
                    }
                }
            }
           
        }
        .play-ic{
            width: 32px;
            height: 32px;
            border-radius: $rounded-pill;
            background: $primary;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: -4px;
            bottom: 0;
            span{
                font-size: size(10);
                margin: 0;
            }
        }
    }
    .social-media{
        li{
            margin: size(15) size(10) size(0) size(20);
            span{ 
                &:before{
                    color: $primary;
                }
            }
        }
    }
    .spacer-line{
        border-top: 1px solid rgb(101 101 101 / 10%); 
        padding: size(0);
        margin: size(20) size(0) size(20) size(0);
    }
    .primary-text{
        font-size: size(15);
        font-weight: $font-semibold;
    }
    .about-card{
        .info{
            padding: 0;
            p{
                @include media(down, $breakpoint-md){
                    padding: size(0) size(0) size(10) size(0);
                }
            }
        }
    }
    .investments{
        .text-blue{
            color: $primary;
            font-weight: $font-semibold;
        }
        .primary-text{
            color: $black !important;
            font-weight: $font-regular;
            padding: size(5) size(0);
        }
    }
    .badges{
        .badge{
            background-color: $extra-light-gray;
            font-weight: $font-semibold;
            padding: size(9) size(8);
            font-size: size(13);
            letter-spacing: 0;
            margin: size(0) size(15) size(10) size(0);
        }
    }
    .investment-list{
        li{
            font-size: size(15);
            line-height: size(22);
            list-style-type: disc;
            list-style-position: inside;
        }
    }
    .previous-investment{
        background: $white;
        box-shadow: none;
        margin-bottom: $margin-bottom-30;
        [class*=col-]{
            display: flex;
        }
        @include media(down, $breakpoint-lg){
            [class*=col-]:nth-child(n+4){
                margin-top: $margin-top-30;
            }
        }
        @include media(down, $breakpoint-md){
            [class*=col-]:nth-child(n+3){
                margin-top: $margin-top-30;
            }
        }
        @include media(down, $breakpoint-xs-xs){
            [class*=col-]:nth-child(n+2){
                margin-top: $margin-top-30;
            }
        }
        .imgbox{
             margin: size(0) auto size(10);
             border: 0;
             img{
                width: 50px;
                height: 50px;
             }
        }
        .profile-info{

            span{
                display: inline-block;
                padding-left: size(5);
                margin: size(0);
                color: $light-gray;
                font-weight: $font-regular;
            }
        } 
        .box-wrap{
            border: 1px solid $box-border-color;
            padding: size(20);
            border-radius: $border-radius-4;
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            @include media(down, $breakpoint-xs-xs){
                flex-direction: column;
                align-items: center;
                width: 100%;
            }
        }
        .btnbox{
            margin: size(15) size(0) size(0) size(0);
        }
        [class*=col-]:nth-child(n+4){
            margin-top: $margin-top-30;
        }
        .profile-contact{
            h4{
                font-size:size(16);
                line-height: size(22);
                &:hover{
                    color: $primary;
                }
            }
            
        }
    }
    .exit-investment{
        .profile-info{ 
            span{
                color: $black;
            }
        } 
    }
    .profile-info{
        .btn{
            justify-content: flex-start;
            span{
                margin: size(0) size(15) size(0) size(5);
            }
        }
    } 
    .other-pages{
        background-color: transparent;
        box-shadow: none;
        p{
            font-size:size(15);
            color: $light-gray;
        }
    }
    .btn-follow{
        justify-content: flex-start;
        color: $primary; 
        letter-spacing: 0;
        font-size: size(15); 
    }
    .item-listed-wrap{
        + .item-listed-wrap{
            margin-top: size(80);
            @include media(down, $breakpoint-md){
                margin-top: $margin-top-30;
            }
        }
    }
    .bg-transparant{
        background-color: transparent;
        box-shadow: none;
    }
    .card{
        .info{
            @include media(down, $breakpoint-md){
                padding: size(0);
            }
        }
    } 
}