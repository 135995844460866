.my-pages-section {
    margin-top: $margin-top-30;
    .card {
        height: calc(100% - 10px);
        &.pages-card {
            max-width: 970px;
            margin: 0 auto;
            height: inherit;
            padding: size(100) size(70);
            @include media(down, $breakpoint-sm) {
                padding: size(65) size(40);
            }
            @include media(down, $breakpoint-sm) {
                padding: size(40) size(15);
            }
            h2 {
                text-align: center;
                margin-bottom: $margin-bottom-10;
            }
            p {
                text-align: center;
                margin-bottom:size(45);
                @include media(up,$breakpoint-xl){
                    font-size: size(18);
                }
            }
            .type-list {
                max-width: 410px;
                margin: 0 auto size(45);
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-wrap: wrap;
            }
            .btnbox {
                display: flex;
                width: 100%;
                justify-content: center;
                .btn {
                    &.btn-lg {
                        max-width: 370px;
                        width: 100%;
                    }
                }
            }
        }
        .tag-comman {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    .upload-video-form {
        height: 100%;
        .upload-form {
            height: 100%;
        }
        .dropzone {
            transition: border-color 200ms ease-in;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px dashed $extra-light-gray;
            position: relative;
            flex-direction: column;
            padding: size(30);
            width: 100%;
            height: 100%;
            border-radius: $border-radius-12;
            background-color: $white;
            text-align: center;
            margin: 0 auto;
            &:hover {
                cursor: pointer;
                border-color: $primary;
                span {
                    &::before {
                        color: $primary;
                    }
                }
            }
            &.active {
                border-color: $light-gray;
            }
            .upload-input {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }
            span {
                color: $light-gray;
                font-size: size(38);
            }
            p {
                font-size: size(16);
                line-height: size(26);
                color: $black;
                font-weight: $font-semibold;
                letter-spacing: size(-0.25);
                margin-top: $margin-top-20;
                margin-bottom: 0;
            }
        }
    }
}

.mypage-box {
    position: relative;
    .profile-img {
        text-align: center;
        img {
            width: 80px;
            height: 80px;
            border-radius: $rounded-pill;
            object-fit: cover;
        }
        .tag-status {
            margin-top: size(-12);
            margin-bottom: $margin-bottom-10;
            span {
                font-size: size(11);
                line-height: size(16);
                color: $white;
                font-weight: $font-bold;
                letter-spacing: size(0.1);
                text-transform: uppercase;
                display: inline-block;
                padding: size(4) size(10);
                border-radius: $border-radius-4;
                &.bg-gray {
                    background-color: $light-gray;
                }
            }
        }
    }
    .profile-info {
        text-align: center;
        .d-flex {
            .icon-Icon-green-tick {
                line-height: 30px;
                margin-left: 5px;
                padding-left: 0;
            }
        }
        h3 {
            font-size: size(16);
            line-height: size(26);
            color: $black;
            font-weight: $font-semibold;
            letter-spacing: size(-0.25);
            margin-bottom: $margin-bottom-5;
            &:hover{
                color: $primary;
            }
            cursor: pointer;
            span{
                font-size: size(13);
                margin-left: size(3);
            }
        }
        p {
            font-size: size(13);
            line-height: size(22);
            color: $black;
            font-weight: $font-regular;
            letter-spacing: size(-0.03);
            margin-bottom: $margin-bottom-5;
        }
        .status {
            color: $primary;
            margin-bottom: 0;
        }
    }
    .dropdown-box {
        position: absolute;
        right: -14px;
        top: 0px;
        @include media(down,$breakpoint-sm-sm){
            right: 0;

        }
        .icon-Ver-action-btn{
            font-size: size(18);
        }
        .dropdown-menu {
            right: -29px !important;
            margin-top: $margin-top-10;
            .dropdown-item {
                display: flex;
                align-items: center;
                &:hover {
                    span {
                        &::before {
                            color: $primary;
                        }
                    }
                }
                span {
                    margin-right: size(12);
                    @include media(up, $breakpoint-lg){
                        font-size: size(18);
                    }
                    @include media(up, $breakpoint-xl){
                        font-size: size(20);
                    }
                    &::before {
                        color: $black;
                    }
                    &.icon-icon-edit{
                        @include media(up, $breakpoint-xl){
                            font-size: size(19);
                        }
                    }
                }
            }
        }
    }
    @include media(down, $breakpoint-xl) {
        margin: size(15);
    }
    @include media(down, $breakpoint-sm-sm) {
        margin: size(15);
    }
}

.inner-card {
    background-color: rgba($extra-light-gray, 0.4); 
    border-radius: $border-radius-12;
    padding: size(30);
    margin-bottom: $margin-bottom-10;
    @include media(down, $breakpoint-xs) {
        padding: size(15);
    }
    .ng-select-container{
        border-radius: size(12) !important;
        height: 56px !important;
        @include media(down, $breakpoint-sm) {
            height: 46px !important;
        }
    }
    .search-result{
        li{
          display: flex;
          align-items: center;
          margin-bottom: $margin-bottom-15; 
          p{
            margin: 0 !important;
            padding-left: size(15); 
            font-weight: $font-semibold;
            text-align: left !important;
          }
        }
        .img-box{
          width: 40px;
          height: 40px;
          border-radius: 100%;
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            margin-bottom: $margin-bottom-15;
          }
        }
      }
    .inner-card-info {
        border-bottom: 2px solid $extra-light-gray;
        padding-bottom: $padding-bottom-20;
        margin-bottom: $margin-bottom-20;
        &:last-child {
            border-bottom: none;
            padding-bottom: size(0);
            margin-bottom: size(0);
        }
        .round-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: $margin-bottom-30;
            h6 {
                margin-bottom: 0;
            }
            .btn {
                font-size: size(15);
                padding: 0;
                min-height: inherit;
                min-width: inherit;
                @include media(down, $breakpoint-xs) {
                    padding: size(13);
                }
            }
        }
    }
}

.inner-card-btnbox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include media(down, $breakpoint-xs) {
        justify-content: space-around;
    }
    .btn {
        font-size: size(15);
        min-height: inherit;
    }
}

.congratulation-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .imgbox {
        width: 320px;
        height: 320px;
        margin-bottom: $margin-bottom-30;
        border: none;
        @include media(down, $breakpoint-xs-xs){
            width: 100%;
            height: auto;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
}
.followers-list{
    .member-info{
        margin-bottom: $margin-bottom-15;
    }
    .member-img{
        padding-right: size(15);
        img{
            border-radius: 100%;
            min-width: 50px;
        }
    }
    .designation{
        h6{
            margin: 0;
        }
    }
    .btnbox{
        @include media(down, $breakpoint-xs-xs){
            margin-left: size(65);
        }
        .btn{
            min-height: 40px;
            font-size: size(16);
            padding: size(6) size(16);
            border-radius: $border-radius-4;
        }
    }
} 
.add-investor-modal{
    .inner-card{
        .inner-card-info{
            .round-title{
                align-items: start;
            }
        }
    }  
}