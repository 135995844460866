.add-team-member-modal {
    textarea {
        &.form-control {
            min-height: size(112);
            resize: vertical;
        }
    }
    .radio-btn-group {
        display: flex; 
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: $margin-top-5;
        .radio-btn-wrap{
            margin: 0 size(35) size(15) 0;
            display: flex;
            align-items: center;
            .tooltip-icon{
                position: relative;
                top: 2px;
            }
            .normal-radio-wrap {
                margin: 0;
                margin-right: 10px;
                height: 25px;
                font-size: size(16); 
                .checkmark{
                    border: 1px solid $light-gray;
                }
            }
        }   
    } 
    .checkbox-tooltip{
        margin: 0 size(35) size(15) 0;
        display: flex;
        align-items: center;
        .tooltip-icon{
            position: relative;
            top: 0px;
        }
        label{
            margin: 0 5px 0 0;
        }
    }
    .btnbox {
        flex-direction: column;
        .btn {
            &.text-danger {
                margin-top: $margin-top-20;
                text-align: center !important;
            }
        }
    }
}

.checkbox-btn-group {
    display: flex;
    flex-wrap: wrap;
    .custom-checkbox {
        margin: 0 size(30) size(15) 0;
    }
}