.team-member-req-list{
  .request-request-item{
    .imgbox{
      img{
        border-radius: 50rem;
        object-fit: cover;
      }
    }
    h6{
      color: $primary;
    }
    .primary-text{
      color: $black !important;
      font-size: size(13);
    }
  }
  .btnbox{
    margin-top: $margin-top-20; 
  }
  .btn{
    min-height: 40px;
    font-size: size(15);
    padding: size(4) size(16);
    border-radius: $border-radius-4;
    span{
      font-size: size(11);
      padding-right: size(5);
    }
     + .btn{
       margin-left: size(10);
     }
  }
}

.members-list-wrap{
  .req-info-wrap{
    margin-bottom: $margin-bottom-25;
  }
  .page-title{
    font-weight: $font-bold;
    font-size: size(20);
    padding: size(0);
  }
  .digit-wrap{
    .digit{
      width: 30px;
      height: 30px;
      line-height: 30px;
      color: $white;
      text-align: center;
      display: inline-block;
      background-color: $primary;
      border-radius: 100%;
      font-size: size(14);
      font-weight: $font-semibold;
      margin-right: size(15);
    }
    .icon-icon-right-angle-arrow{
      font-size: size(14);
      color: $light-gray;
    } 
  }
}