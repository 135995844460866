.documents-details{
  .module-search-wrap{
    position: sticky;
    top: 70px;
    z-index: 9;
    align-items: center;
    @include media(down, $breakpoint-xl){
      padding-bottom: $padding-bottom-25;
    }
    .module-search{
    input{
        padding: size(12) size(310) size(12) size(60);
        @include media(down, $breakpoint-lg){
          padding: size(12) size(280) size(12) size(60);
        }
        @include media(down,$breakpoint-sm-sm){
          padding: size(12) size(115) size(12) size(40);
        }
      }
      span{
        left: 32px;
        @include media(down, $breakpoint-md){
          left: 15px;
        }
      }
    } 
  }
  .text-blue{
    color: $primary !important;
  }
}
.upload-option{
  position: absolute;
  right: 25px;
  top: auto;
    a{
      &.create-btn{
        background-color: rgba($black, 0.1);
        border-radius: $border-radius-4;
        display: inline-flex;
        align-items: center;
      }
      &.upload-file{
        background-color: rgba($primary, 0.1);
        border-radius: $border-radius-4;
        color: $primary;
        .icon-upload-ic{
          &:before{
            color: $primary;
          }
        }
      }
    }
  @include media(down, $breakpoint-md){
    right: 5px;
  }
  .search-close{

    @include media(down, $breakpoint-sm-sm){
      margin-right: size(10);
    }
  }
  .ic-wrap{
    margin-left: size(10);
    font-size: size(15);
    color: $black;
    font-weight: $font-semibold;
    padding: size(7) size(10);
    @include media(down, $breakpoint-sm-sm){
      margin:size(0) size(5);
      padding:size(5) size(10);
    }
    span{
      padding-right: size(10);
      font-size: size(13);
      @include media(down, $breakpoint-sm-sm){
        padding-right: 0;
      }
      &::before{
        color: $black;
      }
    }
  }
} 
.icon-folder{
  position: relative;
  &:before{
    font-weight: $font-semibold;
  }
  .icon-icon-plush{ 
    position: absolute;
    font-size: size(5) !important;
    left: size(4);
    top: size(4);
  }
}
.rect-wrap{
  [class*=col-]{
    margin-bottom: 20px;
    // &:nth-child(n + 4){
    //     margin-top: $margin-top-30;
    // }
    // &:nth-child(n + 3){
    //   @include media(down, $breakpoint-xl){
    //     margin-top: $margin-top-30;
    //   }
    // }
    // &:nth-child(n + 2){
    //   @include media(down, $breakpoint-sm-sm){
    //     margin-top: $margin-top-15;
    //   }
    // }
  }
}
.rect-box{
  border: 1px solid $extra-light-gray;
  padding: size(20);
  @include media(down, $breakpoint-xs-xs){
    padding: size(10);
  }
  .flex-wrap {
    flex-wrap: inherit !important;
  }
  .info-bar{
    .icon-folder-icon{
      font-size: size(62);
      @include media(down, $breakpoint-xl){
        font-size: size(42);
      }
    }
    .content-wrap{
      padding-left: size(10);
      max-width: 196px;
      @include media(down, $breakpoint-lg){
        max-width: 145px;
      }
      @include media(down, $breakpoint-sm-sm){
        max-width: 196px;
      }
      @include media(down, $breakpoint-xs){
        max-width: 146px;
      }
      h6{
          font-size: size(15);
          font-weight: $font-semibold;
          margin: size(0) size(0) size(5);
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 176px;
          white-space: nowrap;
      }
    }
    .s-info{
      font-weight: $font-semibold;
      color: $light-gray;
      font-size: size(13);
    }
  }
  .icon-dots{
    &:before{
      color: $light-gray;
    }
  }
  .right-bar{
    span{
      font-size: size(22);
      @include media(down, $breakpoint-sm-sm){
        font-size: size(20);
      }
    }
    .icon-icon-connections{
      padding-right: size(20); 
      @include media(down, $breakpoint-sm-sm){
        padding-right: size(15);
      }
    }
    .icon-dots{
      font-size: size(19);
    }
    .info-bar{
      .s-info{
        padding-right: size(20);
      }
    }
  }
  .wrap-bg{
    width: 60px;
    height: 60px;
    border-radius: $border-radius-12;
    background-color: $extra-light-gray;
    @include media(down, $breakpoint-xl){
      width: 50px;
      height: 50px;
    }
    @include media(down, $breakpoint-xs-xs){
      width: 40px;
      height: 40px;
    }
    span{
      color: $primary;
      font-size: size(22);
      text-align: center;
      line-height: 60px;
      display: block;
      @include media(down, $breakpoint-xl){
        line-height: 48px;
      }
      @include media(down, $breakpoint-xs-xs){
        line-height: 40px;
      }
    }
  }
  .dropdown-menu{
    .dropdown-item{
      align-items: center;
      display: flex;
      width: 100%;
    }
  } 
}
.public-documents{
  .module-search-wrap{
    
    .module-search{
      input{
        padding:size(12) size(12) size(12) size(60);
        @include media(down, $breakpoint-lg){
          padding:size(12) size(12) size(12) size(45);
        }
      }
    }
  } 
}
.filter-by{
  margin-top: size(2);
  margin-bottom: $margin-bottom-20;
  .search-custom-checkbox{
    .checkbox-list{
      label{
        font-size: size(15);
      }
    }
  }  
}
.shared-with-pdf{
  .upload-img{
    iframe{
      width: 100%;
      height: 70vh;
      border: 0;
    }
  }
}