footer{
  border-top: 1px solid $gary;
  h5{
    color: $black;
    line-height: 22px;
    font-size: $font-15;
    font-weight: $font-semibold;
  }
  .footer-top{
    padding: size(100) size(0) size(70);
    @include media(down, $breakpoint-xl){
      padding: size(50) size(0);
    }
    .info{
      padding-right: size(10);
      @include media(down, $breakpoint-lg){
        padding-right: 0;
      }
      .ftr-logo{
        margin-bottom: $margin-bottom-30;
        display: block;
        @include media(down, $breakpoint-lg){
          img{
            width: 175px;
            height: auto;
          }
        }
      }
      p{
        margin-bottom: $margin-bottom-30;
        color: $black;
        line-height: 30px;
        font-size: $font-18;
        @include media(down, $breakpoint-lg){
          margin-bottom: $margin-bottom-20;
        }
        @include media(down, $breakpoint-sm-sm){
          font-size: $font-14;
        }
      }
      .follow-us-wrap{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @include media(down, $breakpoint-lg){
          margin-bottom: $margin-bottom-20;
        }
        h5{
          margin: 0;
        }
        ul{
          display: flex;
          align-items: center;
          margin-left: size(15);
          li{
            margin-right: size(20);
            margin-bottom: 0;
            span{
              &::before{
                color: $light-gray;
              }
            }
            a{
              &:hover{
                span{
                  &:before{
                    color: $primary;
                  }
                }
              }
            }
          }
        }
      }
    }
    .ftr-nav{
      padding-left: size(40);
      @include media(down, $breakpoint-xl){
        padding-left: 0px;
      }
      h5{
        display: block;
        margin-bottom: $margin-bottom-20;
      }
      ul{
        display: inline-block;
        width: 50%;
        vertical-align: top;
        li{
          margin-bottom: $margin-bottom-15;
          &:last-child{
            margin-bottom: 0;
          }
          a{
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
  ul{
    li{
      margin-bottom: $margin-bottom-15;
      a{        
        color: $black;
        line-height: 22px;
        font-size: $font-15;
        text-transform: uppercase;
        @include media(down, $breakpoint-xl){
          font-size: $font-13;
          line-height: normal;
        }
        &:hover{
          text-decoration: none;
          color: $primary;
        }
        
      }
    }
  }
  .footer-bottom{
    background-color: $extra-light-gray;
    padding: size(30) size(0);
    @include media(down, $breakpoint-xl){
      padding: size(15) size(0);
    }
    ul{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li{
        margin-right: size(30);
        @include media(down, $breakpoint-lg){
          margin: size(0) size(8) size(10);
        }
        @include media(down, $breakpoint-sm-sm){
          margin-bottom: $margin-bottom-10;         
        }
        a{
          @include media(down, $breakpoint-xl){
            font-size: $font-12;
          }
        }
      }
      @include media(down, $breakpoint-lg){
        justify-content: center;
      }
    }
    .copy-rights{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 0;
      @include media(down, $breakpoint-lg){
        justify-content: center;
      }
      a{
        color: $black;
        line-height: 22px;
        font-size: $font-15;
        margin: size(0) size(5);
        &:hover{
          color: $primary;          
        }
        @include media(down, $breakpoint-xl){
          font-size: $font-12;
          line-height: normal;
        }
      }
      @include media(down, $breakpoint-xl){
        font-size: $font-12;
      }
    }
  }
}