.left-tabs{
    flex-direction: column;
    @include media(down, $breakpoint-lg){
        flex-direction: initial;
		padding: size(0) size(30);
    }
    .nav-link{
        border-radius: 0;
        border-left: 2px solid $white;
        color: $black;
        display: flex;
        align-items: center;
        padding: size(14) size(20) size(14) size(35);
        font-weight: $font-semibold;
        font-size: size(15);
        position: relative;
        @include media(down, $breakpoint-lg){
            padding: size(8) size(10);
            background-color: $extra-light-gray;
            border-left:none;
            color: $black;
            border-radius: $border-radius-4;
            margin: size(0) size(10) size(10) size(0);
        }
        @include media(down, $breakpoint-md){
            font-size: size(14);
            padding: size(3) size(8);
        }
            &.active, &.show>.nav-link{
                background-color: transparent;
                border-left: 2px solid $primary;
                color: $primary;
                @include media(down, $breakpoint-lg){
                    background-color: $primary;
                    border-left:none;
                    color: $white;
                }
            }
        }
        span{
            padding: size(0) size(0) size(0) size(14);
            position: absolute;
            right: 1.25rem;
            font-size: size(12);
            color: $wizard-border-bg;
            @include media(down, $breakpoint-lg){
                display: none;
            }
            em{
                font-style: inherit;
                margin-left: 0.3125rem;
            }
        }
    }
.module-detail{
    margin: size(30) size(0) size(0) size(0);

    .bg-default{
        background-color: $extra-light-gray;
        color: $black;
    }
    .detal-view{
        h6{
            font-weight: $font-semibold;
            font-size: size(15);
            letter-spacing: -0.12px;
            line-height: 1.467;
            color: $primary;
            margin-bottom: $margin-bottom-5;
        }
        p{
            font-size: size(15);
            font-weight: $font-regular;
            letter-spacing: -0.12px;
            line-height: 1.467;
            color: $black;
            margin-bottom: $margin-bottom-25;
        }
    }
    .list-bg{
        ul{
            margin-bottom: $margin-bottom-15;

            li{
                display: inline-block;
                vertical-align: top;
                background-color: $extra-light-gray;
                border-radius: $border-radius-4;
                color: $black;
                font-size: size(13);
                line-height: 1.692;
                letter-spacing: -0.03px;
                padding: size(5) size(10);
                margin-right: size(10);
                margin-bottom: $margin-bottom-10;
                font-weight: $font-semibold;
            }
        }
    }
    .list-bg-none{
        p{
            margin-bottom: 0;
        }
    }
    .profile-info{
        text-align: center;
        position: relative;
        span{
            color: $light-gray; 
            text-align:center;
            font-size: size(15);
        }
        h4{
            font-size: size(18);
            span{
                margin-top: 0;
            }
        }
        p{
            margin-bottom: $margin-bottom-5;
        }
        h6{
            margin-bottom: $margin-bottom-15;
        }
        .btnbox{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            .btn{
                font-size: size(14);
                margin: size(5) size(8);
                padding: size(10);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                min-height: 40px;
                line-height: normal;
                max-width: 200px;
                span{
                    margin: size(0) size(10) size(0) size(5);
                    &:before{
                        color: $white;
                    }
                    &.icon-Group{
                        font-size: size(19);
                    }
                }
                &:hover{
                    span{
                        &:before{
                            color: $primary;
                        }
                    }
                }
            }
        }
        .like-and-action-wrap{
            position: absolute;
            top: -10px;
            right: -5px;
            display: flex;
            align-items: center;
            a{
                padding: 0 5px
                span{
                    font-size: size(16);
                    margin-top: 0;
                }
                &:hover{
                    span{
                        &:before{
                            color: $primary;
                        }
                    }
                }
                &:last-child{
                    margin-left: size(20);
                }
            }
        }
        .imgbox{
            width: 100px;
            height: 100px;
            margin: size(0) auto size(20);
            padding: size(10);
            border-radius: 100%;
            position: relative;
            border: 2px solid $extra-light-gray;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;

            .play-btn{
                position: absolute;
                right: -5px;
                bottom: 0px;
                background-color: $primary;
                width: 32px;
                height: 32px;
                border-radius: 100%;
                font-size: size(15);
                display: flex;
                align-items: center;
                justify-content: center;
                span{
                    margin-left: 0px;
                    margin-top: 0px;
                }
            }
            img{
                border-radius: 100%;
                width: 100%;
                object-fit: cover;
                height: 100%;
                object-position: center;
            }
        }
    } 
    .page-title{
        margin: 0;
        padding: size(0) size(0) size(30) size(0);
        font-size: size(20);
        @include media(down, $breakpoint-md){
            padding: size(0) size(0) size(15) size(0);
        }
    }
    .video-box{
        margin: size(0) size(-30);
        @include media(down, $breakpoint-md){
            margin: size(0) size(-15);
        }
        .video-feed{
            border-radius: 0;
        }
    }
    .profile-details{
        .btnbox{
            margin: size(15) size(0) size(15) size(0);
            .btn{
                width: 114px;
                border-radius: $border-radius-4;
            }
        }
        .rate-count{
            display: inline-block;
            font-size: size(11);
            font-weight: $font-bold;
            color: $primary;
            text-transform: uppercase;
            margin-left: size(10);
            vertical-align: top;
            line-height: 24px;
        }
        .profile-desc{
            .addr{
                font-weight: $font-regular;
                font-size: size(13);
                letter-spacing: -0.03px;
                line-height: 1.692;
                color: $black; 
            }
            .work-rate{
                font-weight: $font-semibold;
                font-size: size(16);
                line-height: 1.625;
                color: $primary;
            }
        }
    }
    .dropdown-menu{
        box-shadow: 0 2px 4px 5px $extra-light-gray;
    }
    #dropdown-msg{
        span{
            font-size: size(18);
        }
        a{
            margin-bottom: size(5);
            &:hover{
                color: $primary;
                span{
                    &:before{
                        color: $primary;
                    }
                }
            }
        }
    }
    .profile-info{
        .like{
            span{
                font-size: size(17);
                font-weight: $font-regular;
                margin-left: 0;
                &:before{
                    color: $primary;
                }
            }
            .dropdown-box{
                a {
                    margin-left: size(12);
                }
                .dropdown-menu-right {
                    right: -27px !important;
                    left: auto;
                    @include media(down, $breakpoint-sm){
                        right: -22px !important;
                    }
                }
                .dropdown-item{
                    span{
                        &::before{
                            color: $black;
                        }
                    }
                }
            }
        }
    }
    .social-media{
        li{
            margin: size(15) size(10) size(0) size(20);
            span{ 
                &:before{
                    color: $primary;
                }
            }
        }
    }
    .spacer-line{
        border-top: 1px solid rgb(101 101 101 / 10%); 
        padding: size(0);
        margin: size(20) size(0) size(20) size(0);
    }
    .primary-text{
        font-size: size(15);
        font-weight: $font-semibold;
    }
    .about-card{
        .info{
            padding: 0;
            p{
                @include media(down, $breakpoint-md){
                    padding: size(0) size(0) size(10) size(0);
                }
            }
        }
    }
    .investments{
        .text-blue{
            color: $primary;
            font-weight: $font-semibold;
        }
        .primary-text{
            color: $black !important;
            font-weight: $font-regular;
            padding: size(5) size(0);
        }
    }
    .badges{
        .badge{
            background-color: $extra-light-gray;
            font-weight: $font-semibold;
            padding: size(9) size(8);
            font-size: size(13);
            letter-spacing: 0;
            margin: size(0) size(15) size(10) size(0);
        }
    }
    .investment-list{
        li{
            font-size: size(15);
            line-height: size(22);
            list-style-type: disc;
            list-style-position: inside;
        }
    }
    .previous-investment{
        [class*=col-]{
            display: flex;
        }
        @include media(down, $breakpoint-lg){
            [class*=col-]:nth-child(n+4){
                margin-top: $margin-top-30;
            }
        }
        @include media(down, $breakpoint-md){
            [class*=col-]:nth-child(n+3){
                margin-top: $margin-top-30;
            }
        }
        @include media(down, $breakpoint-xs-xs){
            [class*=col-]:nth-child(n+2){
                margin-top: $margin-top-30;
            }
        }
        .imgbox{
            width: 100%;
             height: 50px;
             margin: size(0) auto size(10);
        }
        .profile-info{
            span{
                display: inline-block;
                padding-left: size(5);
                margin: size(0);
            }
        } 
        .box-wrap{
            border: 1px solid $extra-light-gray;
            padding: size(20);
            border-radius: $border-radius-4;
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            @include media(down, $breakpoint-xs-xs){
                flex-direction: column;
                align-items: center;
                width: 100%;
            }
        }
        .btnbox{
            margin: size(15) size(0) size(0) size(0);
        }
        [class*=col-]:nth-child(n+4){
            margin-top: $margin-top-30;
        }
        .profile-contact{
            h4{
                font-size:size(16);
                line-height: size(22);
            }
        }
    }
    .profile-info{
        .btn{
            justify-content: flex-start;
            span{
                margin: size(0) size(15) size(0) size(5);
            }
        }
    } 
    .other-pages{
        background-color: transparent;
        box-shadow: none;
        p{
            font-size:size(15);
            color: $light-gray;
        }
    }
    .btn-follow{
        justify-content: flex-start;
        color: $primary; 
        letter-spacing: 0;
        font-size: size(15); 
    }
    .item-listed-wrap{
        + .item-listed-wrap{
            margin-top: size(80);
            @include media(down, $breakpoint-md){
                margin-top: $margin-top-30;
            }
        }
    }
    .bg-transparant{
        background-color: transparent;
        box-shadow: none;
    }
    
    .card{
        .info{
            @include media(down, $breakpoint-md){
                padding: size(0);
            }
        }
    } 


    .portfolio-wrap{
        h2{
            font-size: size(22);
            font-weight: $font-bold;
            letter-spacing: -0.4px;
            line-height: 1.364;
            color: $primary;
            margin-bottom: size(12);
            @include media(down, $breakpoint-sm-sm){
                font-size: size(18);
            } 
        }
        .folio-sublist{
            font-size: size(18);
            font-weight: $font-semibold;
            letter-spacing: -0.25px;
            line-height: 1.5;
            color: $black;
            margin-bottom: size(12);
            display: block;
            @include media(down, $breakpoint-sm-sm){
                font-size: size(16);
            }
        } 
        p{
            margin-bottom: size(12);
            font-size: size(15);
            letter-spacing: -0.12px;
            line-height: 1.467;
            color: $black;
        }
        .folio-dt{
            font-size: size(12);
            font-weight: $font-bold;
            line-height: 1.5;
            color: $light-gray;
            text-transform: uppercase;
        }
        
    }

    .package-wrap{
        h2{
            font-size: size(22);
            font-weight: $font-bold;
            letter-spacing: -0.4px;
            line-height: 1.364;
            color: $black;
        }
        .folio-sublist{
            font-size: size(18);
            font-weight: $font-semibold;
            letter-spacing: -0.25px;
            line-height: 1.667;
            color: $black;
            margin-bottom: $margin-bottom-10;
            display: block;
        }
        .duration{
            li{
                display: inline-block;
                vertical-align: top;
                margin-right: size(30);
                margin-bottom: $margin-bottom-10;
                font-size: size(15);
                letter-spacing: -0.12px;
                line-height: 1.467;
                color: $black;
                img{
                    margin-right: size(10);
                }
            }
        }
        .histroy-rating{
            margin-bottom: $margin-bottom-10;

            .rate-average{
                background-color: $primary;
                border-radius: $border-radius-4;
                padding: size(1) size(5);
                font-size: size(11);
                letter-spacing: 0.1px;
                line-height: 1.455;
                color: $white;
                margin-right: size(5);
                margin-top: size(2);
                vertical-align: top;
                display: inline-block;
            }
        }
        .btn-primary{
            padding: 10px 20px;
            font-size: size(15);
            letter-spacing: -0.12px;
            line-height: 1.467;
            color: $white;
            min-height: auto;
            border-radius: $border-radius-4;
            margin-bottom: $margin-bottom-20;

            &:hover{
                color: $black;
            }
        }
        p{
            margin-bottom: $margin-bottom-5;
            font-size: size(15);
            letter-spacing: -0.12px;
            line-height: 1.467;
            color: $black;
        }
        .folio-dt{
            font-size: size(12);
            font-weight:$font-bold;
            line-height: 1.5;
            color: $light-gray;
            text-transform: uppercase;
        }
        .folio-media{
           margin-top: $margin-top-10;

            li{
                display: inline-block;
                vertical-align: top;
                margin-right: size(10);
                border-radius: $border-radius-12;
                overflow: hidden;

                img{
                    border: 1px solid $extra-light-gray;
                }
            }
        }
    }
}

.members-list{
    .member-img{
        position: relative;
        display: inline-block;
        min-width: 50px;
        max-height: 50px;
        img{
            border-radius: 100%;
        }
        .play-ic{
            right: 0;
            width: 20px;
            height: 20px;
            border: 0;
            bottom: 0;
            span{
                font-size: size(8);
            }
        }
   }
    .member-req{
        .btn{
            min-width: auto;
        }
        span{
            width: 32px;
            height: 32px;
            background: $primary;
            display: inline-block;
            border-radius: 100%;
            line-height: 32px;
            text-align: center;
            font-size: size(13);
            cursor: pointer;
            &:before{
                color: $white;
            }
            
            &.yellow-bg{
                font-size: size(15);
            }
        }
    }
    .designation{
        margin: size(5) size(0) size(0) size(15);
        h6{
            font-weight: $font-semibold;
            margin: size(0) size(10) size(5) size(0);
            cursor: pointer; 
            &:hover{
                color: $primary;
            }
        }
        span{
            color: $primary;
            font-size: size(15);
        }
        p{
            padding-top: $padding-top-15;
            max-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 600px;
            white-space: nowrap;
            margin-bottom: size(0);
            @include media(down, $breakpoint-xl){
                max-width: 410px;
            }
            @include media(down, $breakpoint-lg){
                max-width: 490px;
            }
            @include media(down, $breakpoint-sm-sm){
                max-width: 320px;
            }
            @include media(down, $breakpoint-xs-xs){
                max-width: 220px;
            }
            @include media(down, $breakpoint-xsw){
                max-width: 190px;
            }
            @include media(down, $breakpoint-xsww){
                max-width: 150px;
            }
        } 
    }
    .short-info{
        padding: size(10) size(0) size(0) size(65);
    }
    .spacer-line{
        margin: size(10) size(0) size(20) size(0);
    } 
}
.req-remove-btn{
    margin-left: auto;
    min-width: auto;
    @include media(down, $breakpoint-sm-sm){
        margin-left: 0;
       margin-top: $margin-top-10;;
    }
    span{
            width: 32px;
            height: 32px;
            background: $primary;
            display: inline-block;
            border-radius: 100%;
            line-height: 32px;
            text-align: center;
            font-size: size(13);
            cursor: pointer;
    }
    .icon-red{
        background-color: $light-red !important;
        &::before{
            color: $white;
        }
    }
} 

// sidebar dropdown
.dropdown-menu{
    &#dropdown-feed{
        // display: inline-block;  
        min-width: 130px;
       li{ 
        display: inline-block;   
        .dropdown-item {
            width: auto;
           
            [class*="icon-"]  {
            margin-right: 0.625rem;
            margin-left: 0px;
            }
        } }
    }   
}
// end  sidebar dropdown 
