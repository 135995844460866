.my-meetings-detail{
    margin-top: $margin-top-35;
    .breadcrumb{
        .breadcrumb-item{
            @include media(down, $breakpoint-xs-xs){
                padding-bottom: $padding-bottom-10;
            }
        }
    }
    .card{
        .info{
            p{
                font-size: size(16);
                line-height: 1.625;
            }
        }
    }
    h2{
        font-size: size(32);
    }
    .content-wrap{
        @include media(down, $breakpoint-xs-xs){
            flex-wrap: wrap;
        }
         h2{
             font-size: size(22);
             width: calc(100% - 70px);
             @include media(down, $breakpoint-sm-sm){
                font-size: size(20);
            }
             @include media(down, $breakpoint-xs-xs){
                 width: 100%;
             }
         }
         .icon-wrap{
            @include media(down, $breakpoint-sm-sm){
                padding-top: $padding-top-10;
            }
             span{
                 margin-left: size(25);
                 font-size: size(20);
                 cursor: pointer;
                 @include media(down, $breakpoint-sm-sm){
                     font-size: size(16);
                     margin-left: size(15);
                 }
                 @include media(down, $breakpoint-xs-xs){
                     margin: size(0) size(15) size(15) size(0);
                     display: inline-block;
                 }
                 &:hover{
                     color: $primary;
                 }
             }
         }
    }
    .date-time{
        margin-bottom: $margin-bottom-10;
        font-size: size(12);
        color: $light-gray;
        text-transform: uppercase;
        font-weight: $font-bold;
    }
    .spacer-line{
        border-bottom: 1px solid $extra-light-gray;
        margin-top: $margin-top-10;
        margin-bottom: $margin-bottom-30;
    }
    .location-link{
        span{
            width: 40px;
            height: 40px;
            line-height: 40px;
            border-radius: 100%;
            background-color: $extra-light-gray;
            display: inline-block;
            text-align: center;
            color: $primary;
            margin-right: size(10);
          }
        a{
            color: $primary;
            &:hover{
                color: $black;
            }
        }
    }
    .contnet-wrap{
        margin-bottom: $margin-bottom-25;
    }
    .participants{
        h5{
            margin: 0;
            font-weight: $font-semibold;
            max-width: 135px;
        }
        span{
            font-size: size(12);
            text-transform: uppercase;
            color:$success;
            margin: size(7) size(8) size(0) size(0);
            &.acc-await{
                color: $light-gray;
            }
            .gray-dot{
                width: 3px;
                height: 3px;
                margin: size(0) size(4);
                vertical-align: 2px;
            }
        }
    }
    .requsted-status{
        .spacer-line{
            margin-top: $margin-top-25;
            margin-bottom: $margin-bottom-20;
        }
        .req-info{
            margin-left: size(13);
            h5{
                font-size: size(15);
                margin: 0;
                font-weight: $font-semibold;
            }
            span{
                font-size: size(13);
                color: $primary;
                a{
                    font-size: size(13);
                    color: $light-gray;
                }
            }
        }
            .org{
                font-size: size(12);
                color: $primary;
                text-transform: uppercase;
            }
        .req-status{
                color: $success;
                margin-left: size(13);
                &.icon-icon-close-black{
                    color: $danger;
                }
        }
        .user-info{
            max-width: 200px;
            cursor: pointer;
            @include media(down, $breakpoint-lg){
                max-width: 340px;
            }
            @include media(down, $breakpoint-xs-xs){
                max-width: 200px;
            }
            @include media(down, $breakpoint-xsww){
                max-width: 100%;
            }
            .img-wrap{
                img{
                    min-width: 40px;
                    border-radius: 100%;
                }
            }
        }
        .right-wrap{
            max-width: 100px;
            word-break: break-all;
            @include media(down, $breakpoint-xsww){
                width: 100%;
                margin-top: $margin-top-10;
            }
        }
        &.rsvp{
            .req-info {
                span{
                    a{
                        color: $primary;
                    }
                }
            }
        }
    }
    .card{
        .info{
            padding-right: 0;
        }   
    }
}
.set-reminder-popup{
    &.modal-dialog{
            max-width: 510px !important;
        }
}
.co-info{
    .card{
        padding: size(70);
        border-radius: $border-radius-24;
        @include media(down, $breakpoint-xs-xs){
            padding: size(30);
        }
        h2{
            font-size: size(32);
            text-align: center;
            margin-bottom: $margin-bottom-35;
            @include media(down, $breakpoint-xs-xs){
                font-size: size(24);
                padding-right: size(25);
            }
        }
        .btn{
            margin-top: $margin-top-20;
        }
    }
    .close-ic{
        position: absolute;
        right: 30px;
        top: 35px;
        span{
            font-size: size(21);
            @include media(down, $breakpoint-xs-xs){
                font-size: size(18);
            }
        }
    }
    ul {
        padding-left: size(20);
        @include media(down, $breakpoint-xs-xs){
            padding-left: size(0);
        }
        li{
           font-size: size(18);
           margin-bottom: $margin-bottom-20;
           cursor: pointer;
           span{
               color: $light-gray;
               font-size: size(20);
               margin-right: size(18);
           }
           .icon-icon-tick{
               float: right;
               font-size: size(14);
               color: $primary;
           }
        }
    }
}
.identity{
    .img-wrap{
        border-radius: 100%;
        overflow: hidden;
        min-width: 40px;
        margin-right: size(15);
    }
    .info-bar{
        h6{
            margin: size(0);
            color: $primary;
            font-weight: $font-semibold;
        }
        p{
            margin: size(0);
            font-size: size(14);
        }
    }
}
.default-pointer {
    cursor: default !important;
}