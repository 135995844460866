.ng-select {
    &.ng-select-single {
        .ng-select-container {
            border-color: $extra-light-gray !important;
            @include border-radius(4px);
            background-color: $white;
            @include font-size(16);
            color: $primary;
            font-weight: $font-regular;
            .ng-value-container {
                padding-left: size(20);
                .ng-input {
                    padding-left: size(20);
                    padding-right: size(30);
                }
            }
        }
    }
    .ng-select-container {
        @include border-radius(12px);
        border-color: $extra-light-gray !important;
        background-color: $white;
        @include font-size(16);
        color: $primary;
        font-weight: $font-regular;
        .ng-value-container {
            padding-left: size(20);
            .ng-input {
                padding-left: size(20);
                padding-right: size(30);
            }
        } 
        .ng-value-container {
            .ng-input {
                >input {
                    height: 35px;
                    color: $primary;
                }
            }
            .ng-placeholder {
                color: $primary;
                @include font-size(16);
            }
        }
    }
    &.ng-select-focused {
        &:not(.ng-select-opened) {
            >.ng-select-container {
                box-shadow: none;
            }
        }
    }
    .ng-dropdown-panel {
        border: 1px solid $extra-light-gray;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
        border-radius: 0;
        z-index: 10;
        .ng-select-bottom {
            border-color: $extra-light-gray;
        }
        .ng-dropdown-panel-items {
            .ng-option {
                white-space: normal;
                padding: size(10) size(20);
                color: $primary;
                @include font-size(16);
                line-height: 1.5rem;
                &.ng-option-marked {
                    background-color: rgba($primary, 0.08);
                }
                &.ng-option-selected {
                    background-color: rgba($primary, 0.15);
                }
            }
        }
    }
    .ng-arrow-wrapper {
        padding-right: size(12);
        span.ng-arrow {
            width: auto;
            height: auto;
            &::after {
                font-size: size(18);
            }
        }
    }
    &.ng-select-opened {
        div.ng-select-container {
            z-index: 10;
        }
    }
    &.ng-select-disabled {
        .ng-select-container {
            background-color: $light;
            cursor: not-allowed;
        }
        .ng-arrow-wrapper {
            .ng-arrow {
                border-color: $gary transparent transparent;
            }
        }
    }
    &.ng-select-multiple {
        .ng-select-container {
            .ng-value-container {
                overflow: auto;
                padding-left: size(17);
                .ng-placeholder {
                    top: 15px;
                    opacity: 0.6;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 96%;
                }
                .ng-value {
                    margin-bottom: $margin-bottom-5;
                    margin-top: $margin-top-5;
                }
            }
        }
    }
}