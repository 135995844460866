.congratulation-wrap{
  margin: size(70) auto 0;
  .card{
    max-width: 970px;
    padding: size(100) size(50);
    @include media(down, $breakpoint-sm-sm){
      .card{
        padding: size(50) size(20);
      }
    }
    .btnbox{
      margin-top: $margin-top-15;
    }
    .imgbox{
      @include media(down, $breakpoint-sm-sm){
        .imgbox{
          width: 250px;
          height: 250px;
        }
      }
    }    
  }
}