.add-profile-details-layout{
  ng-wizard{
    .ng-wizard-theme-circles{
      ng-wizard-step{
        .step-content{
          h2{
            padding:size(0) size(40);
            margin-bottom: size(8);

          }
          p{
            @include media(up,$breakpoint-xl){
              font-size: size(18);
          }
          }
        }
      }
    } 
    .ng-wizard-theme-circles{
      ng-wizard-step{        
        .step-content{
          text-align: center;
          form{
            max-width: 570px;
            margin: 0 auto;
            textarea{
              min-height: size(110);
              resize: vertical;
            }
          }
          .added-info-wrap{
            .btnbox{
              max-width: 570px;
              margin: size(60) auto size(0);
            }
          }
          .btnbox{
            .btn{
              display: flex;
              align-items: center;
              justify-content: center;
              span{
                margin-right: size(10);
              }
              &.primary-link{
                &:hover{
                  text-decoration: none;
                }
              }
            }
          }
          .no-content-text{
            display: block;
            margin-bottom: $margin-bottom-50;
          }
          .exp-item-wrap{
            max-width: 570px;
            margin: 0 auto;
          }
        }
      }
    }
  }
  .upload-img{
    iframe{
      width: 570px;
      height: 370px;
      border: 0;
      border-radius: $border-radius-12;
      background-image: url(../../images/iframe-bg.png);
      @include media(down, $breakpoint-sm-sm){
          width: 100%;
          height: 270px;
      }
    }
  }
}
.teaser-preview-modal{
  .upload-img{
    iframe{
      width: 570px;
      height: 460px;
      border: 0; 
      border-radius: $border-radius-12;
      background-image: url(../../images/iframe-bg.png);
      @include media(down, $breakpoint-sm-sm){
          width: 100%;
          height: 270px;
      }
    }
  } 
}


/* New Wizard */
aw-wizard{
  position: relative;
  display: block;
  margin: 40px 15px 0;
  width: calc(100% - 30px);
  padding: 0;
  border-radius: .25rem!important;
  .exp-item-wrap{
    max-width: 570px;
    margin: 0 auto;
    .info-wrap{
      p{
        text-align: left;
      }
    }
  }
  
  aw-wizard-navigation-bar.horizontal.small{      
        ul.steps-indicator{
          &.steps-indicator {
            li{              
              &:not(:last-child):after{
                top: 0;
                z-index: 0;
                height: 2px;
                background-color: $wizard-border-bg;
              }
              &:after{
                content: '';
                display: block;
                height: 4px;
                content: "";
                display: block;
                height: 2px;
                width: calc(100% - 9px);
                background: #D6D6D6;
                position: absolute;
                left: calc(-50% + 7px);
                top: 0px !important;
                border-radius: 0.75rem 0rem 0rem 0.75rem;
                z-index: -1;
              }
              
              &:first-child{
                &::after{
                  width: 50%;
                  left: 0;
                  border-radius: 0.75rem 0rem 0rem 0.75rem;
                }
              }
              &:last-child{
                &::after{
                  width: 140%;
                  border-radius: 0.75rem 0rem 0rem 0.75rem;
                }
              }
              &.current{
                &::after{
                  background: #0E378C;
                  height: 4px;
                }
              }
              &.active,
              &.done {
                  &:after {
                      background: $primary;
                      height: 4px;
                      top: 20px;
                      @include media(down,
                      $breakpoint-lg) {
                          top: 7px;
                      }
                  }
              }
            }
          }
        }
      
    
    ul{
      position: relative;
      background: transparent !important;
      border: none;
      list-style: none;
      margin-bottom: 2.5rem;
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      li{
        margin-left: 0 !important;
        margin-bottom: 0px !important;
        position: relative;
        width: 100%;
        flex-shrink: 1;
        
        
        &.active, &.done {
          .step-indicator{
            background-color: $primary !important;
            color: $white;
            border-color: $primary;
          }
        }
        a{
          .step-indicator {
            content: "";
            width: 2rem !important;
            height: 2rem !important;
            padding: 0.3125rem !important;
            background-color: $wizard-border-bg !important;
            border: 2px solid $wizard-border-bg;
            display: block;
            border-radius: 100%;
            position: absolute;
            left: 50%;
            top: 7px;
            transform: translateX(-50%);
            z-index: 1;
          }
          .label{
            color: $black !important;
            font-size: 1rem !important;
            position: relative;
            font-weight: 400 !important;
            margin-top: 15px;
            text-transform: capitalize !important;
            @include media(down,$breakpoint-lg) {
              display: none !important;
            }
          }
        }
        &.current, &.editing {
          .step-indicator{
            content: "";
            width: 2rem !important;
            height: 2rem !important;
            padding: 0.3125rem !important;
            background-color: $white !important;
            border-color: $primary;
            color: $primary !important;
          }
        }
      }
      
    }
  }
  .wizard-steps{
    max-width: 970px;
    width: 100%;
    margin: 50px auto;
    padding: size(100);
    box-shadow: 0 0 15px rgb(0 0 0 / 5%);
    border-radius: 1.5rem;
    background-color: $white;
    @include media(down,$breakpoint-lg) {
      padding: size(60);
    }
    @include media(down,$breakpoint-sm-sm) {
        padding: size(30);
    }
    @include media(down,$breakpoint-xs) {
        padding: size(15);
    }
    h2{
      padding:size(0) size(40);
      margin-bottom: size(8);
      text-align: center;
    
    }
    p{
      text-align: center;
      margin-bottom: 1.875rem;
      word-break: break-word;
      @include media(up,$breakpoint-xl){
        font-size: size(18);
        
      }
    }
    .btnbox{
      display: flex;
    }
    form {
      max-width: 570px;
      margin: 0 auto;
      p{
        text-align: left;
      }
      .btnbox{
        display: flex;
        flex-wrap: wrap;
        @include media(down,$breakpoint-sm-sm) {
          justify-content: center !important;
        }
        .btn{
          margin: 5px 10px;
          min-width: 170px;       
          span{
            margin-right: 8px !important;
          }   
          .btn-md {
            max-width: 185px;
            width: 100%;
          }
        }
        
      }
    }
  }
}
