.my-favorites-details{
  .left-icon-input{
    margin-bottom: $margin-bottom-30;
    .form-control {
      background-color: $white;
      box-shadow: 2px 2px 18px 8px rgb(0,0,0,0.03);
    }
  }
  .nav-card{
    h5{
      padding: size(20);
      margin-bottom: 0;
    }
  }
  .favorites-nav{
    border-top: 1px solid $extra-light-gray;
    li{
      a{
        border-left: 2px solid $white;
        color: $black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: size(14) size(20);
        font-weight: $font-semibold;
        span{
          margin-left: size(14);
          &::before{
            color: $light-gray;
          }
        }
      }
      &.active, &:hover{
        a{
          border-left: 2px solid $primary;
          color: $primary;
          span{
            &::before{
              color: $primary;
            }
          }
        }
      }
    }
  }
  .fav-page-list{
    [class*=col-]{
      margin-bottom: $margin-bottom-30;
    }
    .mypage-profile-card{
      padding: size(20) size(15);
      margin-bottom: $margin-bottom-30;
      height: 100%;
      .mypage-box{
        .profile-info{
          h3{
            font-size: size(15);
          }
        }
      }      
    }
  }
  
}
.fav-network-list{
  [class*=col-]{
    margin-bottom: $margin-bottom-30;
  }
  
}
.network-item-card{
  height: 100%;
  padding-bottom: size(70);
  .network-item-box{
    position: relative;
    .profile-img{
      position: relative;
      width: size(80);
      height: size(80);
      border-radius: 100%;
      margin: 0 auto size(15);
      img{
        border-radius: 100%;
        width: size(80);
        height: size(80);
      }
      .play-btn{
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: $primary;
        width: size(22);
        height: size(22);
        min-height: inherit;
        min-width: inherit;
        font-size: size(10);
        display: flex;
        align-items: center;
        justify-content: center;
        span{
          margin-left: size(3);
        }
      }
    }
    
  }
  .btnbox{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: size(25);
    left: 0;
    width: 100%;
    .btn{
      margin: size(5);
      min-height: inherit;
      min-width: inherit;
      width: size(32);
      height: size(32);
      border-radius: 100%;
      font-size: size(14);
      &.chat-btn{
        background-color: $extra-light-gray;
        span{
          &::before{
            color: $light-gray;
          }
        }
      }
      &.add-btn{
        background-color: $primary;
        span{
          &::before{
            color: $white;
          }
        }
      }
      &.remove-btn{
        background-color: $danger;
        span{
          &::before{
            color: $white;
          }
        }
      }
      &.yellow-btn{
        background-color: $yellow;
        span{
          &:before{
            color: $white;
            font-size: size(16);
          }
        }
      }
    }
  }
}
.icon-blue::before {
  color: $primary !important;
}
.remove-btn{
  span{
    background-color: $danger !important;
  }
}