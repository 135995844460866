@charset "UTF-8";
/* =========== IMAGE PATH VARIABLE ============= */
/* Usage of bacground image */
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import "../../../node_modules/angular-calendar/css/angular-calendar.css";
@import "../../../node_modules/angular-archwizard/styles/archwizard.css";
@import url("https://fonts.googleapis.com/css?family=Spectral+SC:700");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #F3C32F;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0E378C;
  --secondary: #6c757d;
  --success: #47B872;
  --info: #17a2b8;
  --warning: #fd7e14;
  --danger: #E73945;
  --light: #f8f9fa;
  --dark: #474747;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(34, 34, 34, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #222222;
  text-align: left;
  background-color: #EFEFEF;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #222222;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: black;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}
@media (max-width: 1200px) {
  h1, .h1 {
    font-size: calc(1.375rem + 1.5vw);
  }
}

h2, .h2 {
  font-size: 2rem;
}
@media (max-width: 1200px) {
  h2, .h2 {
    font-size: calc(1.325rem + 0.9vw);
  }
}

h3, .h3 {
  font-size: 1.75rem;
}
@media (max-width: 1200px) {
  h3, .h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
}

h4, .h4 {
  font-size: 1.5rem;
}
@media (max-width: 1200px) {
  h4, .h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.725rem + 5.7vw);
  }
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.675rem + 5.1vw);
  }
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.575rem + 3.9vw);
  }
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(34, 34, 34, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #EFEFEF;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #222222;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(34, 34, 34, 0.05);
}

.table-hover tbody tr:hover {
  color: #222222;
  background-color: rgba(34, 34, 34, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bcc7df;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8297c3;
}

.table-hover .table-primary:hover {
  background-color: #abb9d7;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #abb9d7;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cbebd8;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #9fdab6;
}

.table-hover .table-success:hover {
  background-color: #b9e4ca;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b9e4ca;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fedbbd;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #febc85;
}

.table-hover .table-warning:hover {
  background-color: #fecda4;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fecda4;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f8c8cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f3989e;
}

.table-hover .table-danger:hover {
  background-color: #f5b1b6;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f5b1b6;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #cbcbcb;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #9f9f9f;
}

.table-hover .table-dark:hover {
  background-color: #bebebe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #bebebe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(34, 34, 34, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(21, 21, 21, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(21, 21, 21, 0.075);
}

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #ffffff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.25em + 2rem + 2px);
  padding: 1rem 1rem;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #2f6cea;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(14, 55, 140, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.25;
}

.col-form-label-lg {
  padding-top: calc(1.25rem + 1px);
  padding-bottom: calc(1.25rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.25;
  color: #222222;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 2.5rem + 2px);
  padding: 1.25rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #47B872;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #ffffff;
  background-color: rgba(71, 184, 114, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #47B872;
  padding-right: calc(1.25em + 2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2347B872' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3125em + 0.5rem) center;
  background-size: calc(0.625em + 1rem) calc(0.625em + 1rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #47B872;
  box-shadow: 0 0 0 0.2rem rgba(71, 184, 114, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.25em + 2rem);
  background-position: top calc(0.3125em + 0.5rem) right calc(0.3125em + 0.5rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #47B872;
  padding-right: calc(0.75em + 3.5rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2347B872' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #ffffff no-repeat center right 2rem/calc(0.625em + 1rem) calc(0.625em + 1rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #47B872;
  box-shadow: 0 0 0 0.2rem rgba(71, 184, 114, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #47B872;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #47B872;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #47B872;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #6cc68e;
  background-color: #6cc68e;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(71, 184, 114, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #47B872;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #47B872;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #47B872;
  box-shadow: 0 0 0 0.2rem rgba(71, 184, 114, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #E73945;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #ffffff;
  background-color: rgba(231, 57, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #E73945;
  padding-right: calc(1.25em + 2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E73945' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E73945' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3125em + 0.5rem) center;
  background-size: calc(0.625em + 1rem) calc(0.625em + 1rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #E73945;
  box-shadow: 0 0 0 0.2rem rgba(231, 57, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.25em + 2rem);
  background-position: top calc(0.3125em + 0.5rem) right calc(0.3125em + 0.5rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #E73945;
  padding-right: calc(0.75em + 3.5rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E73945' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E73945' stroke='none'/%3e%3c/svg%3e") #ffffff no-repeat center right 2rem/calc(0.625em + 1rem) calc(0.625em + 1rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #E73945;
  box-shadow: 0 0 0 0.2rem rgba(231, 57, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #E73945;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #E73945;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #E73945;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ed6670;
  background-color: #ed6670;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(231, 57, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #E73945;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #E73945;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #E73945;
  box-shadow: 0 0 0 0.2rem rgba(231, 57, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  color: #222222;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 1rem 1rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #222222;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(14, 55, 140, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #ffffff;
  background-color: #0E378C;
  border-color: #0E378C;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #0b2969;
  border-color: #09255e;
}
.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: #0b2969;
  border-color: #09255e;
  box-shadow: 0 0 0 0.2rem rgba(50, 85, 157, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #0E378C;
  border-color: #0E378C;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #09255e;
  border-color: #082052;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(50, 85, 157, 0.5);
}

.btn-secondary {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #ffffff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #ffffff;
  background-color: #47B872;
  border-color: #47B872;
}
.btn-success:hover {
  color: #ffffff;
  background-color: #3c9c61;
  border-color: #39935b;
}
.btn-success:focus, .btn-success.focus {
  color: #ffffff;
  background-color: #3c9c61;
  border-color: #39935b;
  box-shadow: 0 0 0 0.2rem rgba(99, 195, 135, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #ffffff;
  background-color: #47B872;
  border-color: #47B872;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #39935b;
  border-color: #358a56;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 195, 135, 0.5);
}

.btn-info {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #ffffff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #ffffff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-warning:hover {
  color: #ffffff;
  background-color: #e96b02;
  border-color: #dc6502;
}
.btn-warning:focus, .btn-warning.focus {
  color: #ffffff;
  background-color: #e96b02;
  border-color: #dc6502;
  box-shadow: 0 0 0 0.2rem rgba(220, 113, 23, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #dc6502;
  border-color: #cf5f02;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 113, 23, 0.5);
}

.btn-danger {
  color: #ffffff;
  background-color: #E73945;
  border-color: #E73945;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #df1b29;
  border-color: #d31a26;
}
.btn-danger:focus, .btn-danger.focus {
  color: #ffffff;
  background-color: #df1b29;
  border-color: #d31a26;
  box-shadow: 0 0 0 0.2rem rgba(235, 87, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #ffffff;
  background-color: #E73945;
  border-color: #E73945;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #d31a26;
  border-color: #c81824;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 87, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #ffffff;
  background-color: #474747;
  border-color: #474747;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: #343434;
  border-color: #2e2e2e;
}
.btn-dark:focus, .btn-dark.focus {
  color: #ffffff;
  background-color: #343434;
  border-color: #2e2e2e;
  box-shadow: 0 0 0 0.2rem rgba(99, 99, 99, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #ffffff;
  background-color: #474747;
  border-color: #474747;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #2e2e2e;
  border-color: #272727;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 99, 99, 0.5);
}

.btn-outline-primary {
  color: #0E378C;
  border-color: #0E378C;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #0E378C;
  border-color: #0E378C;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(14, 55, 140, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #0E378C;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #0E378C;
  border-color: #0E378C;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(14, 55, 140, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #47B872;
  border-color: #47B872;
}
.btn-outline-success:hover {
  color: #ffffff;
  background-color: #47B872;
  border-color: #47B872;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 184, 114, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #47B872;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #47B872;
  border-color: #47B872;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 184, 114, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #fd7e14;
  border-color: #fd7e14;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #fd7e14;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.5);
}

.btn-outline-danger {
  color: #E73945;
  border-color: #E73945;
}
.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #E73945;
  border-color: #E73945;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 57, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #E73945;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #E73945;
  border-color: #E73945;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 57, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #474747;
  border-color: #474747;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #474747;
  border-color: #474747;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 71, 71, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #474747;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #474747;
  border-color: #474747;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 71, 71, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #222222;
  text-decoration: none;
}
.btn-link:hover {
  color: black;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 1.25rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.5rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #222222;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(34, 34, 34, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #0E378C;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 2.5rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 1.25rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.5rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.25rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.125rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #0E378C;
  background-color: #0E378C;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(14, 55, 140, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #2f6cea;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #5e8def;
  border-color: #5e8def;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.125rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.125rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #0E378C;
  background-color: #0E378C;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(14, 55, 140, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(14, 55, 140, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(14, 55, 140, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.125rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(14, 55, 140, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.25em + 2rem + 2px);
  padding: 1rem 2rem 1rem 1rem;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #2f6cea;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(14, 55, 140, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 2.5rem + 2px);
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.25rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.25em + 2rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.25em + 2rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #2f6cea;
  box-shadow: 0 0 0 0.2rem rgba(14, 55, 140, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.25em + 2rem + 2px);
  padding: 1rem 1rem;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  line-height: 1.25;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.25em + 2rem);
  padding: 1rem 1rem;
  line-height: 1.25;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #EFEFEF, 0 0 0 0.2rem rgba(14, 55, 140, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #EFEFEF, 0 0 0 0.2rem rgba(14, 55, 140, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #EFEFEF, 0 0 0 0.2rem rgba(14, 55, 140, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0E378C;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #5e8def;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0E378C;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #5e8def;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #0E378C;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #5e8def;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #EFEFEF;
  border-color: #dee2e6 #dee2e6 #EFEFEF;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #0E378C;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.34375rem;
  padding-bottom: 0.34375rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(34, 34, 34, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(34, 34, 34, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(34, 34, 34, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(34, 34, 34, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(34, 34, 34, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(34, 34, 34, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(34, 34, 34, 0.5);
  border-color: rgba(34, 34, 34, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(34, 34, 34, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(34, 34, 34, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(34, 34, 34, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(34, 34, 34, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #ffffff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(34, 34, 34, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(34, 34, 34, 0.03);
  border-bottom: 1px solid rgba(34, 34, 34, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(34, 34, 34, 0.03);
  border-top: 1px solid rgba(34, 34, 34, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #222222;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: black;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(14, 55, 140, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #0E378C;
  border-color: #0E378C;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #0E378C;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #ffffff;
  background-color: #09255e;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(14, 55, 140, 0.5);
}

.badge-secondary {
  color: #ffffff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #ffffff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: #47B872;
}
a.badge-success:hover, a.badge-success:focus {
  color: #ffffff;
  background-color: #39935b;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(71, 184, 114, 0.5);
}

.badge-info {
  color: #ffffff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #ffffff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #fd7e14;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #dc6502;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #E73945;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #ffffff;
  background-color: #d31a26;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(231, 57, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #474747;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #ffffff;
  background-color: #2e2e2e;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(71, 71, 71, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #182d59;
  background-color: #cfd7e8;
  border-color: #bcc7df;
}
.alert-primary hr {
  border-top-color: #abb9d7;
}
.alert-primary .alert-link {
  color: #0d1931;
}

.alert-secondary {
  color: #484d51;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #303336;
}

.alert-success {
  color: #35704c;
  background-color: #daf1e3;
  border-color: #cbebd8;
}
.alert-success hr {
  border-top-color: #b9e4ca;
}
.alert-success .alert-link {
  color: #254d35;
}

.alert-info {
  color: #1c6570;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #124047;
}

.alert-warning {
  color: #94521b;
  background-color: #ffe5d0;
  border-color: #fedbbd;
}
.alert-warning hr {
  border-top-color: #fecda4;
}
.alert-warning .alert-link {
  color: #693a13;
}

.alert-danger {
  color: #882e34;
  background-color: #fad7da;
  border-color: #f8c8cb;
}
.alert-danger hr {
  border-top-color: #f5b1b6;
}
.alert-danger .alert-link {
  color: #622125;
}

.alert-light {
  color: #919292;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #777979;
}

.alert-dark {
  color: #353535;
  background-color: #dadada;
  border-color: #cbcbcb;
}
.alert-dark hr {
  border-top-color: #bebebe;
}
.alert-dark .alert-link {
  color: #1c1c1c;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #0E378C;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #222222;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(34, 34, 34, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #0E378C;
  border-color: #0E378C;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #182d59;
  background-color: #bcc7df;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #182d59;
  background-color: #abb9d7;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #182d59;
  border-color: #182d59;
}

.list-group-item-secondary {
  color: #484d51;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #484d51;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #484d51;
  border-color: #484d51;
}

.list-group-item-success {
  color: #35704c;
  background-color: #cbebd8;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #35704c;
  background-color: #b9e4ca;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #35704c;
  border-color: #35704c;
}

.list-group-item-info {
  color: #1c6570;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #1c6570;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #1c6570;
  border-color: #1c6570;
}

.list-group-item-warning {
  color: #94521b;
  background-color: #fedbbd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #94521b;
  background-color: #fecda4;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #94521b;
  border-color: #94521b;
}

.list-group-item-danger {
  color: #882e34;
  background-color: #f8c8cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #882e34;
  background-color: #f5b1b6;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #882e34;
  border-color: #882e34;
}

.list-group-item-light {
  color: #919292;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #919292;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #919292;
  border-color: #919292;
}

.list-group-item-dark {
  color: #353535;
  background-color: #cbcbcb;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #353535;
  background-color: #bebebe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #353535;
  border-color: #353535;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #222222;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
}
@media (max-width: 1200px) {
  .close {
    font-size: calc(1.275rem + 0.3vw);
  }
}
.close:hover {
  color: #222222;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(34, 34, 34, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(34, 34, 34, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #222222;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.25;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #222222;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #222222;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #222222;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #222222;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #222222;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(34, 34, 34, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(34, 34, 34, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(34, 34, 34, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(34, 34, 34, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(34, 34, 34, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #222222;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #0E378C !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #09255e !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #47B872 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #39935b !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #fd7e14 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #dc6502 !important;
}

.bg-danger {
  background-color: #E73945 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #d31a26 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #474747 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #2e2e2e !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0E378C !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #47B872 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #fd7e14 !important;
}

.border-danger {
  border-color: #E73945 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #474747 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(34, 34, 34, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(34, 34, 34, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(34, 34, 34, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #0E378C !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #071c46 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #47B872 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #328150 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #fd7e14 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #c35a02 !important;
}

.text-danger {
  color: #E73945 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd1722 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #474747 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #212121 !important;
}

.text-body {
  color: #222222 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(34, 34, 34, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #222222;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #ffffff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container .ngx-toastr {
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 0.75rem 1.25rem 0.75rem 50px;
  width: 300px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
  pointer-events: auto;
}
.toast-container .ngx-toastr .toast-title {
  font-weight: bold;
}
.toast-container .ngx-toastr .toast-message {
  word-wrap: break-word;
}
.toast-container .ngx-toastr .toast-message a:hover {
  text-decoration: underline;
}
.toast-container .ngx-toastr .toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: inherit;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  background: transparent;
  border: 0;
  padding: 0;
}
.toast-container .ngx-toastr .toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.3;
}
.toast-container .ngx-toastr .toast-close-button:hover,
.toast-container .ngx-toastr .toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.75;
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  opacity: 1;
  cursor: pointer;
}

.toast-success {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/check.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgba(53, 112, 76, 0.999999)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
  color: #35704c;
  background-color: #daf1e3;
  border: 1px solid #cbebd8;
}

.toast-error {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/times-circle.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgba(136, 46, 52, 0.999999)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
  color: #882e34;
  background-color: #fad7da;
  border: 1px solid #f8c8cb;
}

.toast-info {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/info-circle.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgba(28, 101, 112, 0.999999)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
  color: #1c6570;
  background-color: #d1ecf1;
  border: 1px solid #bee5eb;
}

.toast-warning {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/exclamation-triangle.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgba(148, 82, 27, 0.999999)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
  color: #94521b;
  background-color: #ffe5d0;
  border: 1px solid #fedbbd;
}

@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
/*Headings*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 700;
  font-family: "Source Sans Pro", sans-serif;
}

h1, .h1 {
  font-size: 4.062rem;
  line-height: 1.26;
  color: #222222;
}
@media (max-width: 1200.81px) {
  h1, .h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 991.9px) {
  h1, .h1 {
    font-size: 3rem;
  }
}
@media (max-width: 767.81px) {
  h1, .h1 {
    font-size: 2.7rem;
  }
}
@media (max-width: 576.81px) {
  h1, .h1 {
    font-size: 2.4rem;
  }
}
@media (max-width: 414.81px) {
  h1, .h1 {
    font-size: 1.8rem;
  }
}

h2, .h2 {
  font-size: 3rem;
  line-height: 1.25;
  color: #222222;
}
@media (max-width: 1200.81px) {
  h2, .h2 {
    font-size: 2.2rem;
  }
}
@media (max-width: 991.9px) {
  h2, .h2 {
    font-size: 2rem;
  }
}
@media (max-width: 767.81px) {
  h2, .h2 {
    font-size: 1.8rem;
  }
}
@media (max-width: 576.81px) {
  h2, .h2 {
    font-size: 1.5rem;
  }
}
@media (max-width: 414.81px) {
  h2, .h2 {
    font-size: 1.2rem;
  }
}

h3, .h3 {
  font-size: 2rem;
  line-height: 1.375;
  color: #222222;
}
@media (max-width: 1200.81px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}
@media (max-width: 991.9px) {
  h3, .h3 {
    font-size: 1.35rem;
  }
}
@media (max-width: 767.81px) {
  h3, .h3 {
    font-size: 1.3rem;
  }
}
@media (max-width: 576.81px) {
  h3, .h3 {
    font-size: 1.25rem;
  }
}

h4, .h4 {
  font-size: 1.5rem;
  line-height: 1.33;
  color: #222222;
}
@media (max-width: 1440.81px) {
  h4, .h4 {
    font-size: 1.29rem;
  }
}
@media (max-width: 576.81px) {
  h4, .h4 {
    font-size: 1.1rem;
  }
}
@media (max-width: 414.81px) {
  h4, .h4 {
    font-size: 1rem;
  }
}

h5, .h5 {
  font-size: 1.127rem;
  line-height: 1.25;
  color: #222222;
}

h6, .h6 {
  font-size: 1rem;
  line-height: normal;
  color: #222222;
}

.btn {
  padding: 1rem 1rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: none;
  min-height: 56px;
  padding: 0.8125rem 1rem;
  border-radius: 0.75rem;
  font-size: 1.125rem;
  line-height: 1.66;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
  letter-spacing: -0.12px;
  font-weight: 600;
  min-width: 100px;
}
@media (max-width: 767.81px) {
  .btn {
    padding: 0.625rem 1rem;
    min-height: initial;
    font-size: 0.9375rem;
  }
}
.btn.btn-sm, .btn-group-sm > .btn {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  min-height: 40px;
  padding: 0.5rem 1.25rem;
  border-radius: 0.25rem;
}
.btn.btn-primary {
  background-color: #0E378C;
  color: #ffffff;
  border: 1px solid #0E378C;
}
.btn.btn-primary:hover {
  background-color: #ffffff;
  color: #0E378C;
}
.btn.btn-primary:disabled {
  color: #ffffff;
  background-color: #CCCCCC;
  border-color: #CCCCCC;
  cursor: no-drop;
}
.btn.btn-tertiary {
  background-color: #f2f5f9;
  color: #0E378C;
  border: 1px solid #f2f5f9;
}
.btn.btn-tertiary:hover {
  background-color: #0E378C;
  color: #ffffff;
}
.btn.btn-tertiary:hover span:before {
  color: #ffffff !important;
}
.btn.btn-tertiary:disabled {
  color: #ffffff;
  background-color: #CCCCCC;
  border-color: #CCCCCC;
  cursor: no-drop;
}
.btn.btn-primary:hover, .btn.btn-primary, .btn.btn-primary:not(:disabled):not(.disabled):active, .show > .btn.btn-primary.dropdown-toggle, .btn.btn-primary:not(:disabled):not(.disabled).active:focus, .btn.btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  text-decoration: none;
}
.btn.btn-default {
  background-color: #ffffff;
  color: #0E378C;
  border: 1px solid #ffffff;
}
.btn.btn-default:hover {
  background-color: #0E378C;
  color: #ffffff;
  border: 1px solid #ffffff;
}
.btn.btn-small {
  font-size: 1rem;
  border-radius: 0.25rem;
  padding: 0.3125rem 1rem;
  min-height: 36px;
  line-height: 1.625rem;
  font-weight: 400;
}
.btn.btn-dark {
  background-color: #222222;
  color: #ffffff;
  border: 1px solid #222222;
}
.btn.btn-dark:hover {
  background-color: #ffffff;
  color: #0E378C;
  border: 1px solid #0E378C;
}
.btn.border-btn {
  background-color: transparent;
  border: 2px solid #0E378C;
  color: #0E378C;
}
.btn.border-btn:hover {
  background-color: #0E378C;
  color: #ffffff;
}
.btn.facebook-btn {
  background-color: #405993;
  color: #ffffff;
  border: 1px solid #405993;
}
.btn.facebook-btn:hover {
  background-color: #405993;
  color: #ffffff;
  border: 1px solid #405993;
}
.btn.linkedin-btn {
  background-color: #2F72AC;
  color: #ffffff;
  border: 1px solid #2F72AC;
}
.btn.linkedin-btn:hover {
  background-color: #2F72AC;
  color: #ffffff;
  border: 1px solid #2F72AC;
}
.btn.disabled {
  color: #ffffff;
  background-color: #CCCCCC;
  border-color: #CCCCCC;
  cursor: no-drop;
}
.btn.btn-accept {
  background-color: #47B872;
  color: #ffffff;
  border: 1px solid #47B872;
}
.btn.btn-accept:hover {
  background-color: #ffffff;
  color: #47B872;
}
.btn.btn-accept:disabled {
  color: #ffffff;
  background-color: #CCCCCC;
  border-color: #CCCCCC;
  cursor: no-drop;
}
.btn.btn-reject {
  background-color: #E73945;
  color: #ffffff;
  border: 1px solid #E73945;
}
.btn.btn-reject:hover {
  background-color: #ffffff;
  color: #E73945;
}
.btn.btn-reject:disabled {
  color: #ffffff;
  background-color: #CCCCCC;
  border-color: #CCCCCC;
  cursor: no-drop;
}
.btn.btn-pending {
  background-color: #F3C32F;
  color: #ffffff;
  border: 1px solid #F3C32F;
}
.btn.btn-pending:hover {
  background-color: #ffffff;
  color: #F3C32F;
}
.btn.btn-pending:disabled {
  color: #ffffff;
  background-color: #CCCCCC;
  border-color: #CCCCCC;
  cursor: no-drop;
}
.btn.btn-lg, .btn-group-lg > .btn {
  max-width: 370px;
  width: 100%;
}
.btn.btn-dashed {
  border: 2px dashed #EFEFEF;
  background-color: transparent;
  color: #0E378C;
}
.btn.btn-dashed:hover {
  border-color: #0E378C;
}
.btn.btn-danger {
  background-color: #E73945;
  border-color: #E73945;
  color: #ffffff;
}
.btn.btn-yellow {
  background-color: #F3C32F;
  color: #ffffff;
  border: 1px solid #F3C32F;
}
.btn.btn-yellow:hover {
  background-color: #ffffff;
  color: #F3C32F;
}
.btn.btn-yellow:disabled {
  color: #ffffff;
  background-color: #CCCCCC;
  border-color: #CCCCCC;
  cursor: no-drop;
}
.btn.btn-teritary {
  background-color: #f2f5f9;
  color: #0E378C;
  border: 1px solid #f2f5f9;
}
.btn.btn-teritary:hover {
  background-color: #0E378C;
  color: #ffffff;
}
.btn.btn-teritary:hover span:before {
  color: #ffffff !important;
}
.btn.btn-teritary:disabled {
  color: #ffffff;
  background-color: #CCCCCC;
  border-color: #CCCCCC;
  cursor: no-drop;
}
.btn.btn-alert {
  min-width: min-content;
  padding: 0.25rem 0.625rem;
  min-height: 30px;
  line-height: inherit;
}
.btn.mw-114 {
  min-width: 114px !important;
}
.btn.mw-335 {
  max-width: 335px !important;
}
.btn.mw-180 {
  min-width: 180px !important;
}
@media (max-width: 767.81px) {
  .btn.mw-180 {
    min-width: 130px !important;
  }
}
.btn.mw-185 {
  min-width: 185px !important;
}
@media (max-width: 767.81px) {
  .btn.mw-185 {
    min-width: 135px !important;
  }
}
.btn.mw-200 {
  min-width: 200px !important;
}
@media (max-width: 767.81px) {
  .btn.mw-200 {
    min-width: 140px !important;
  }
}

.form-group {
  margin-bottom: 1.25rem;
}
.form-group label {
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}
.form-group label sup {
  top: -3px;
  left: 4px;
}
.form-group label .icon-icon-info {
  margin-left: 0.625rem;
  cursor: pointer;
}
.form-group.field-error label sup {
  color: #E73945;
}
.form-group .input-info-span {
  display: flex;
  justify-content: flex-end;
  color: #9D9D9D;
  margin-top: 0.3125rem;
}
.form-group .input-note-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-group .input-note-wrap span {
  color: #9D9D9D;
  font-size: 0.8125rem;
}
.form-group .input-note-wrap .btn.text-danger {
  color: #E73945;
  font-size: 0.8125rem;
  min-height: inherit;
  min-width: inherit;
}

/* border input  */
.border-btm-input {
  position: relative;
}
.border-btm-input .form-control.normal-input {
  border: none;
  border-bottom: 2px solid #EFEFEF;
  border-radius: 0;
  padding: 13px 105px 13px 0;
  background: transparent;
}
.border-btm-input .form-control.normal-input:placeholder-shown {
  border-color: #EFEFEF;
  background: transparent;
}
.border-btm-input .btn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.8125rem 0;
  text-align: right;
  min-width: inherit;
  font-size: 0.875rem;
}

textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}

input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.form-control {
  border: 1px solid #9D9D9D;
  background-color: #ffffff;
  padding: 0.8125rem 1.5rem;
  width: 100%;
  height: 56px;
  border-radius: 0.75rem;
}
@media (max-width: 576.81px) {
  .form-control {
    height: 46px;
  }
}
.form-control.focus::-webkit-input-placeholder, .form-control:focus::-webkit-input-placeholder {
  color: #9D9D9D;
}
.form-control.focus:-moz-placeholder, .form-control:focus:-moz-placeholder {
  color: #9D9D9D;
}
.form-control.focus::-moz-placeholder, .form-control:focus::-moz-placeholder {
  color: #9D9D9D;
}
.form-control.focus:-ms-input-placeholder, .form-control:focus:-ms-input-placeholder {
  color: #9D9D9D;
}
.form-control[readonly] {
  color: #9D9D9D;
  border: 0;
  background-color: #EFEFEF;
}
.form-control:placeholder-shown {
  background: #EFEFEF;
  border: 1px solid #EFEFEF;
  text-overflow: ellipsis !important;
}

.sm-form-control {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #222222;
  width: 100%;
  height: 41px;
  border: none;
  background: #EFEFEF;
  border-radius: 0.25rem;
  padding: 0.5625rem 1rem;
}

.otp-input {
  border: 1px solid #EFEFEF !important;
  background-color: #EFEFEF;
  padding: 0.3125rem 0.625rem;
  width: 100%;
  height: 56px;
  font-size: 1rem !important;
}
@media (max-width: 576.81px) {
  .otp-input {
    height: 46px;
  }
}
.otp-input.focus, .otp-input:focus {
  border-color: #0E378C !important;
  background-color: #ffffff;
}
.otp-input.focus::-webkit-input-placeholder, .otp-input:focus::-webkit-input-placeholder {
  color: #9D9D9D;
}
.otp-input.focus:-moz-placeholder, .otp-input:focus:-moz-placeholder {
  color: #9D9D9D;
}
.otp-input.focus::-moz-placeholder, .otp-input:focus::-moz-placeholder {
  color: #9D9D9D;
}
.otp-input.focus:-ms-input-placeholder, .otp-input:focus:-ms-input-placeholder {
  color: #9D9D9D;
}
.otp-input[readonly] {
  color: #E3E3E3;
}

.has-danger {
  color: #E73945;
}
.has-danger .form-control {
  border-color: #E73945;
}

.input-group-otp {
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-group-otp .form-control {
  padding: 0.625rem;
  max-width: 44px;
  width: 100%;
  margin-right: 0.625rem;
  text-align: center;
}
.input-group-otp .form-control:last-child {
  margin-right: 0;
}
@media (max-width: 576.81px) {
  .input-group-otp .form-control {
    padding: 0.375rem;
  }
}

.select-box {
  margin-bottom: 1.25rem;
}

ng-select.ng-select-opened .ng-select-container, ng-select.ng-select-focused .ng-select-container {
  color: #222222;
  background-color: #ffffff !important;
  border-color: #0E378C !important;
}
ng-select .ng-select-container {
  border: 1px solid #EFEFEF !important;
  background-color: #EFEFEF !important;
  padding: 0.8125rem 1.5rem !important;
  width: 100% !important;
}
ng-select .ng-select-container .ng-value-container {
  padding-left: 0 !important;
}
ng-select .ng-select-container .ng-value-container .ng-input input {
  color: #9D9D9D !important;
  margin-top: 5px;
}
@media (max-width: 767.81px) {
  ng-select .ng-select-container .ng-value-container .ng-input input {
    color: #6c757d !important;
  }
}
ng-select .ng-select-container .ng-value-container .ng-value {
  text-align: left;
}
ng-select .ng-select-container .ng-value-container .ng-value .ng-value-label span {
  margin-right: 0.25rem;
}
ng-select .ng-select-container .ng-value-container .ng-value .ng-value-label span::before {
  color: #222222;
}
ng-select .ng-select-container .ng-placeholder {
  color: #9D9D9D !important;
}
ng-select .ng-select-container .ng-value-label {
  color: #222222;
}
ng-select .ng-select-container .ng-value-label .selected-icon {
  display: none;
}
ng-select .ng-select-container.ng-has-value {
  background-color: #ffffff !important;
  border-color: #9D9D9D !important;
}
ng-select .ng-arrow-wrapper {
  padding-right: 0 !important;
  width: 10px !important;
  background-image: url("../../images/dropdown-arrow.svg");
  background-position: center center;
  background-repeat: no-repeat;
}
ng-select .ng-arrow-wrapper .ng-arrow {
  border: none !important;
}
ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  text-align: left;
  color: #222222 !important;
  position: relative;
  padding-right: 2.1875rem;
}
ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  color: #0E378C !important;
}
ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .selected-icon {
  position: absolute;
  right: 15px;
  display: none;
  top: 26%;
}
ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: rgba(14, 55, 140, 0.05) !important;
}
ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .selected-icon {
  display: block;
}
ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label span::before {
  color: #222222;
}
ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 0 !important;
}
ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  position: relative;
  padding: 0.4375rem 1.25rem 0.4375rem 0.4375rem;
  background-color: #0E378C !important;
  border-radius: 0.25rem !important;
  text-align: left;
}
ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  border-right: none !important;
  color: rgba(255, 255, 255, 0.4);
  position: absolute;
  right: 0;
  font-size: 1.625rem;
  line-height: 16px;
}
ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: transparent !important;
  color: #E73945;
}
ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  color: #ffffff;
  text-transform: capitalize;
  font-size: 0.9375rem;
}
ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 35% !important;
}
ng-select.ng-select-single .ng-select-container.ng-has-value {
  background-color: #ffffff !important;
  border-color: #9D9D9D !important;
}
ng-select.ng-select-single .ng-select-container .ng-value-container .ng-select-container .ng-value-container .ng-value {
  text-align: left;
}

/* Upload file input */
.custom-file-input:focus ~ .custom-file-label {
  border-color: #0E378C;
  box-shadow: none;
  cursor: pointer;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Add Now";
  cursor: pointer;
}

.custom-file {
  position: relative;
  cursor: pointer;
}
.custom-file .form-control {
  padding: 0.8125rem 7.1875rem 0.8125rem 1.5rem;
}
@media (max-width: 480.81px) {
  .custom-file .form-control {
    padding: 0.8125rem 6.5625rem 0.8125rem 1.5rem;
  }
}
.custom-file.after-none .custom-file-label:after {
  display: none;
}
.custom-file .option-text {
  position: absolute;
  top: 20px;
  right: 45px;
  z-index: 9;
  color: #0E378C;
  font-size: 0.875rem;
}
@media (max-width: 480.81px) {
  .custom-file .option-text {
    top: 15px;
  }
}
.custom-file .delet-option {
  position: absolute;
  right: 15px;
  top: 18px;
  font-size: 1.125rem;
  z-index: 9;
}
@media (max-width: 480.81px) {
  .custom-file .delet-option {
    top: 14px;
  }
}
.custom-file .delet-option span {
  color: #0E378C;
}
.custom-file label {
  border: 1px solid #9D9D9D;
  background-color: #ffffff;
  padding: 0.8125rem 1.5rem;
  width: 100%;
  height: 56px;
  border-radius: 0.75rem;
  cursor: pointer;
  line-height: 1.8;
  margin-bottom: 0;
  overflow: hidden;
}
@media (max-width: 576.81px) {
  .custom-file label {
    height: auto;
    line-height: 1.2;
    padding: 0.8125rem 0.9375rem;
  }
}
.custom-file label.focus::-webkit-input-placeholder, .custom-file label:focus::-webkit-input-placeholder {
  color: #9D9D9D;
}
.custom-file label.focus:-moz-placeholder, .custom-file label:focus:-moz-placeholder {
  color: #9D9D9D;
}
.custom-file label.focus::-moz-placeholder, .custom-file label:focus::-moz-placeholder {
  color: #9D9D9D;
}
.custom-file label.focus:-ms-input-placeholder, .custom-file label:focus:-ms-input-placeholder {
  color: #9D9D9D;
}
.custom-file label[readonly] {
  color: #E3E3E3;
}
.custom-file label:placeholder-shown {
  border: 1px solid #EFEFEF;
  background: #EFEFEF;
}
.custom-file label.custom-file-label::after {
  background-color: transparent;
  border-left: none;
  color: #0E378C;
}
@media (max-width: 576.81px) {
  .custom-file label.custom-file-label::after {
    height: calc(1.25em + 1.66rem);
    line-height: 0.8;
  }
}
.custom-file .upload-item-action {
  position: absolute;
  top: 2px;
  right: 1px;
  background-color: #ffffff;
  display: flex;
  padding: 5px;
  align-items: center;
  width: 91px;
  height: 52px;
  z-index: 9;
  justify-content: center;
  text-align: center;
  border-radius: 0 10px 10px 0;
  overflow: hidden;
}
@media (max-width: 576.81px) {
  .custom-file .upload-item-action {
    height: 40px;
  }
}
.custom-file .upload-item-action .option-text, .custom-file .upload-item-action .delet-option {
  position: relative;
  margin: 0 3px;
  top: inherit;
  right: inherit;
  cursor: pointer;
}
.custom-file .upload-item-action .delet-option {
  font-size: 1rem;
}

/*==Custom Radio & Checkbox ==*/
.custom-checkbox {
  text-align: left;
}
.custom-checkbox a {
  font-weight: 600;
  margin-left: 0.3125rem;
}
.custom-checkbox.vertical label {
  display: block;
}
.custom-checkbox label {
  margin: 0rem 0.625rem 0.625rem 0rem;
  padding: 0rem 0rem 0rem 2.1875rem;
  line-height: 24px;
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.custom-checkbox input[type=checkbox] {
  display: none;
}
.custom-checkbox input[type=checkbox] + span {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  background: #E3E3E3;
  border: 1px solid #EFEFEF;
  border-radius: 0.25rem;
}
.custom-checkbox input[type=checkbox]:checked + span {
  border: 1px solid #0E378C;
  background: #0E378C url("../../images/white-tick.svg");
  background-repeat: no-repeat;
  background-position: center center;
}
.custom-checkbox input[type=checkbox]:checked ~ span:after {
  top: 50%;
  left: 50%;
  margin-left: -0.125rem;
  margin-top: -0.4375rem;
  width: 5px;
  height: 10px;
  border-width: 0 1px 1px 0 !important;
  display: block;
  border: solid #ffffff;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.custom-checkbox input[type=checkbox] ~ span:after {
  display: none;
  position: absolute;
  content: "";
}
.custom-checkbox input[type=checkbox]:disabled + span {
  opacity: 0.7;
}

.custom-radio-square {
  text-align: left;
}
.custom-radio-square a {
  font-weight: 600;
  margin-left: 5px;
}
.custom-radio-square.vertical label {
  display: block;
}
.custom-radio-square label {
  margin: 0rem 0.625rem 1.25rem 0rem;
  padding: 0rem 0rem 0rem 2.5rem;
  line-height: 24px;
  cursor: pointer;
  position: relative;
  display: block;
}
.custom-radio-square input[type=radio] {
  display: none;
}
.custom-radio-square input[type=radio] + span {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  background: transparent;
  border: 1px solid #9D9D9D;
  border-radius: 0.25rem;
}
.custom-radio-square input[type=radio]:checked + span {
  border: 1px solid #0E378C;
  background: #0E378C url("../../images/white-tick.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 73%;
}
.custom-radio-square input[type=radio]:checked ~ span:after {
  top: 50%;
  left: 50%;
  margin-left: -0.125rem;
  margin-top: -0.4375rem;
  width: 5px;
  height: 10px;
  border-width: 0 1px 1px 0 !important;
  display: block;
  border: solid #ffffff;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.custom-radio-square input[type=radio] ~ span:after {
  display: none;
  position: absolute;
  content: "";
}
.custom-radio-square input[type=radio]:disabled + span {
  opacity: 0.7;
}

.custom-radio.vertical label {
  display: block;
}
.custom-radio label {
  margin: 0rem 0.625rem 0.625rem 0rem;
  padding-left: 2.5rem;
  vertical-align: -2px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  font-size: 1.0625rem !important;
  text-transform: capitalize !important;
}
@media (max-width: 767.81px) {
  .custom-radio label {
    padding-left: 1.875rem;
    vertical-align: 1px;
  }
}
.custom-radio input[type=radio] {
  display: none;
}
.custom-radio input[type=radio] + span {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  background: #ffffff;
  border: 1px solid #9D9D9D;
  border-radius: 50%;
}
@media (max-width: 767.81px) {
  .custom-radio input[type=radio] + span {
    width: 18px;
    height: 18px;
  }
}
.custom-radio input[type=radio] ~ span:after {
  display: none;
  position: absolute;
  content: "";
}
.custom-radio input[type=radio]:disabled + span {
  opacity: 0.7;
}
.custom-radio input[type=radio]:checked + span {
  background: #ffffff;
  border: 1px solid #0E378C;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.custom-radio input[type=radio]:checked ~ span:after {
  top: 50%;
  left: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  display: block;
  background: #0E378C;
  border-radius: 50%;
}
@media (max-width: 767.81px) {
  .custom-radio input[type=radio]:checked ~ span:after {
    width: 12px;
    height: 12px;
    margin-left: -0.375rem;
    margin-top: -0.375rem;
  }
}

.ie8 .custom-checkbox input[type=checkbox],
.ie8 .custom-radio input[type=radio] {
  display: inline-block;
}

.ie8 .custom-checkbox span,
.ie8 .custom-radio span {
  display: none;
}

/* input with icon */
.input-with-icon {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
.input-with-icon .form-control {
  padding: 0.8125rem 4.375rem 0.8125rem 1.5rem;
}
.input-with-icon span {
  position: absolute;
  right: 25px;
  cursor: pointer;
}

/* input with left icon  */
.left-icon-input {
  width: 100%;
  position: relative;
}
.left-icon-input input {
  width: 100%;
  background-color: #ffffff;
  color: #9D9D9D;
  font-size: 1rem;
  line-height: 1.625rem;
  letter-spacing: -0.015625rem;
  border-radius: 0.75rem;
  padding: 0.75rem 2.5rem 0.75rem 2.5rem;
  border-color: #E3E3E3;
}
.left-icon-input .icon-icon-search {
  position: absolute;
  left: 13px;
  top: 20px;
  font-size: 1rem;
}
.left-icon-input .search-close {
  position: absolute;
  right: 13px;
  top: 20px;
  font-size: 1rem;
  cursor: pointer;
}

.input-with-right-text {
  position: relative;
  width: 100%;
}
.input-with-right-text input {
  padding: 0.75rem 3.125rem 0.75rem 1.5rem;
}
.input-with-right-text span {
  position: absolute;
  right: 0;
  top: 0;
  padding: 1.125rem;
  background-color: transparent;
}

.input-with-right-text-group em {
  width: 100%;
  max-width: 26px;
  height: 2px;
  background-color: #9D9D9D;
  display: block;
  border-radius: 0.25rem;
  margin: 1.6875rem auto;
}

/* Calender Style */
bs-datepicker-container {
  z-index: 1049;
  background-color: #ffffff;
  border-radius: 0.75rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}
bs-datepicker-container button {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #ffffff;
}
bs-datepicker-container .bs-datepicker-head {
  display: flex;
  align-items: center;
  background-color: #0E378C;
  justify-content: center;
  padding: 0.9375rem;
  border-radius: 12px 12px 0 0;
}
bs-datepicker-container .bs-datepicker-body {
  padding: 0.9375rem;
}
bs-datepicker-container .bs-datepicker-body table tr td span {
  color: #222222;
  width: 30px;
  height: 30px;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
bs-datepicker-container .bs-datepicker-body table tr td span.selected {
  color: #ffffff;
  background-color: #0E378C;
}
bs-datepicker-container .bs-datepicker-body table tr td span.disabled {
  color: #afa6a6;
  cursor: no-drop;
}
bs-datepicker-container .bs-datepicker-body table tr td span.is-other-month {
  color: #afa6a6;
  cursor: no-drop;
}
bs-datepicker-container .bs-datepicker-body table tr td.disabled span {
  color: #afa6a6;
  cursor: no-drop;
}
bs-datepicker-container .bs-datepicker-body .years .selected {
  border-radius: 0.25rem;
}
bs-datepicker-container bs-month-calendar-view .bs-datepicker-body {
  padding: 0.9375rem;
}
bs-datepicker-container bs-month-calendar-view .bs-datepicker-body table tr td span {
  width: auto;
  height: auto;
}
bs-datepicker-container bs-years-calendar-view .bs-datepicker-body {
  padding: 0.9375rem;
}
bs-datepicker-container bs-years-calendar-view .bs-datepicker-body table tr td span {
  width: auto;
  height: auto;
}

.modal-open bs-datepicker-container {
  z-index: 9999;
}

/* Selector Radio button */
.radio-button-box {
  padding: 0 0.9375rem 0.9375rem;
  max-width: 145px;
  text-align: center;
}
@media (max-width: 576.81px) {
  .radio-button-box {
    max-width: 125px;
  }
}
.radio-button-box h6 {
  font-weight: 600;
}
@media (max-width: 576.81px) {
  .radio-button-box h6 {
    font-size: 0.875rem;
  }
}
.radio-button-box input[type=radio] {
  display: none;
}
.radio-button-box input[type=radio]:checked + .box {
  background-color: #0E378C;
  border-color: #0E378C;
}
.radio-button-box input[type=radio]:checked + .box span::before {
  color: #ffffff;
}
.radio-button-box .box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  transition: all 250ms ease;
  will-change: transition;
  text-align: center;
  cursor: pointer;
  position: relative;
  border-radius: 100%;
  border: 2px solid #EFEFEF;
}
.radio-button-box .box:active {
  transform: translateY(10px);
}
.radio-button-box .box:active span::before {
  color: #0E378C;
}
.radio-button-box .box span {
  transition: all 300ms ease;
  font-size: 1.875rem;
  user-select: none;
}
.radio-button-box .box span::before {
  color: #9D9D9D;
}

/* Raounded Radio Button */
/* Customize the label (the container) */
.normal-radio-wrap {
  display: block;
  position: relative;
  padding-left: 2.1875rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  font-size: 1.0625rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #222222;
}
.normal-radio-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.normal-radio-wrap input:checked ~ .checkmark {
  background-color: #ffffff;
}
.normal-radio-wrap input:checked ~ .checkmark::after {
  display: block;
}
.normal-radio-wrap .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #0E378C;
}
.normal-radio-wrap .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 3px;
  left: 3px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #0E378C;
}

.cross-wrap {
  position: relative;
}
.cross-wrap .cancel-btn {
  position: absolute;
  right: -80px;
}
@media (max-width: 768.81px) {
  .cross-wrap .cancel-btn {
    right: -20px;
    width: auto;
    top: 7px;
  }
}
@media (max-width: 480.81px) {
  .cross-wrap .cancel-btn {
    top: 0;
  }
}
@media (max-width: 768.81px) {
  .cross-wrap .form-control {
    padding: 0.8125rem 3.125rem 0.8125rem 1.5rem;
  }
}

.v-align-cross.cross-wrap .cancel-btn {
  top: 95px;
}

.inner-side-close .input-with-icon {
  width: 90%;
}
.inner-side-close .cancel-btn {
  right: -35px;
}

.invite-btn {
  min-width: 100px !important;
  display: -webkit-inline-box;
}

/*Start of slect*/
.ng-select.ng-select-single .ng-select-container {
  border-color: #EFEFEF !important;
  border-radius: 0.25rem;
  background-color: #ffffff;
  font-size: 1rem;
  color: #0E378C;
  font-weight: 400;
  height: auto;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container {
  padding-left: 1.25rem;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-left: 1.25rem;
  padding-right: 1.875rem;
  margin-top: 0;
}
@media (max-width: 767.81px) {
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    color: #6c757d;
  }
}
.ng-select .ng-select-container {
  border-radius: 12px;
  border-color: #EFEFEF !important;
  background-color: #ffffff;
  font-size: 1rem;
  color: #0E378C;
  font-weight: 400;
}
.ng-select .ng-select-container .ng-value-container {
  padding-left: 1.25rem;
}
.ng-select .ng-select-container .ng-value-container .ng-input {
  padding-left: 1.25rem;
  padding-right: 1.875rem;
}
.ng-select .ng-select-container .ng-value-container .ng-input > input {
  height: 35px;
  color: #495057 !important;
  margin-top: 0;
}
@media (max-width: 767.81px) {
  .ng-select .ng-select-container .ng-value-container .ng-input > input {
    color: #6c757d;
  }
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #0E378C;
  font-size: 1rem;
}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: none;
}
.ng-select .ng-dropdown-panel {
  border: 1px solid #EFEFEF;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  border-radius: 0;
  z-index: 10;
}
.ng-select .ng-dropdown-panel .ng-select-bottom {
  border-color: #EFEFEF;
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal;
  padding: 0.625rem 1.25rem;
  color: #0E378C;
  font-size: 1rem;
  line-height: 1.5rem;
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: rgba(14, 55, 140, 0.08);
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: rgba(14, 55, 140, 0.15);
}
.ng-select .ng-arrow-wrapper {
  padding-right: 0.75rem;
}
.ng-select .ng-arrow-wrapper span.ng-arrow {
  width: auto;
  height: auto;
}
.ng-select .ng-arrow-wrapper span.ng-arrow::after {
  font-size: 1.125rem;
}
.ng-select.ng-select-opened div.ng-select-container {
  z-index: 10;
}
.ng-select.ng-select-disabled .ng-select-container {
  background-color: #f8f9fa;
  cursor: not-allowed;
}
.ng-select.ng-select-disabled .ng-arrow-wrapper .ng-arrow {
  border-color: #E3E3E3 transparent transparent;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  overflow: auto;
  padding-left: 1.0625rem;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 15px;
  opacity: 0.6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 96%;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin-bottom: 0.3125rem;
  margin-top: 0.3125rem;
}

/*End of select*/
/*custom yellow text*/
.custom-text-warning {
  color: #F3C32F;
}

/* ============================================================================= Header Start */
header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 70px;
  z-index: 1050;
}
header .container-fluid, header .container-sm, header .container-md, header .container-lg, header .container-xl {
  padding: 0rem 6.25rem;
}
@media (max-width: 1440.81px) {
  header .container-fluid, header .container-sm, header .container-md, header .container-lg, header .container-xl {
    padding: 1.25rem 3.125rem;
  }
}
@media (max-width: 1280.81px) {
  header .container-fluid, header .container-sm, header .container-md, header .container-lg, header .container-xl {
    padding: 1.25rem 0.9375rem;
  }
}
header .search-result {
  max-height: 240px;
  overflow: scroll;
  position: absolute;
  background: #ffffff;
  left: 0;
  right: 0;
  padding: 0;
  box-shadow: 0 4px 6px rgba(32, 33, 36, 0.28);
  z-index: 99999999;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
header .search-result li {
  padding: 0.9375rem 1.25rem;
}
@media (max-width: 991.9px) {
  header .search-result {
    margin: 0rem 0.9375rem;
  }
}
header .nav-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
header .nav-wrap .header-logo {
  max-width: 172px;
}
header .nav-wrap .header-logo img {
  width: 100%;
}
@media (max-width: 767.81px) {
  header .nav-wrap .header-logo {
    max-width: 100px;
  }
}
header .nav-wrap .menu {
  display: flex;
  align-items: center;
}
header .nav-wrap .menu .close-btnbox {
  display: none;
}
@media (max-width: 991.9px) {
  header .nav-wrap .menu .close-btnbox {
    display: block;
    position: fixed;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
}
@media (max-width: 991.9px) {
  header .nav-wrap .menu {
    background-color: #ffffff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    -webkit-transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  header .nav-wrap .menu.act {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  header .nav-wrap .menu.act ul li {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  header .nav-wrap .menu ul {
    display: flex;
    flex-direction: column;
  }
  header .nav-wrap .menu ul li {
    padding: 0.5rem 0rem;
    -webkit-transition: all 400ms 510ms;
    transition: all 400ms 510ms;
    opacity: 1;
  }
}
header .nav-wrap .menu ul {
  display: flex;
  align-items: center;
}
header .nav-wrap .menu ul li {
  padding: 0rem 0.9375rem;
}
@media (max-width: 1200.81px) {
  header .nav-wrap .menu ul li {
    padding: 0rem 0.5rem;
  }
}
@media (max-width: 991.9px) {
  header .nav-wrap .menu ul li {
    padding: 0.9375rem 0.5rem;
  }
}
header .nav-wrap .menu ul li a {
  color: #222222;
  line-height: 22px;
  font-size: 0.9375rem;
  text-transform: uppercase;
}
header .nav-wrap .menu ul li a:hover {
  text-decoration: none;
  color: #0E378C;
}
@media (max-width: 1200.81px) {
  header .nav-wrap .menu ul li a {
    font-size: 0.875rem;
  }
}
@media (max-width: 991.9px) {
  header .nav-wrap .menu ul li a {
    font-size: 1.25rem;
  }
}
header .hdr-login-wrap {
  display: flex;
  align-items: center;
}
header .hdr-login-wrap a {
  margin-left: 1.875rem;
  font-size: 0.9375rem;
  color: #222222;
  line-height: 22px;
  font-weight: 600;
}
@media (max-width: 1200.81px) {
  header .hdr-login-wrap a {
    font-size: 0.875rem;
    margin-left: 0.75rem;
  }
}
header .hdr-login-wrap a:hover {
  color: #0E378C;
}
header .hdr-login-wrap a.btn-primary {
  color: #ffffff;
  border-radius: 0.25rem;
  min-width: 90px;
  min-height: 40px;
  line-height: normal;
  padding: 0.5rem 0.9375rem;
}
header .hdr-login-wrap a.btn-primary:hover {
  background-color: #ffffff;
  color: #0E378C;
  border: 1px solid #0E378C;
  box-shadow: none;
}
@media (max-width: 1200.81px) {
  header .hdr-login-wrap a.btn-primary {
    min-width: inherit;
    padding: 0.375rem 0.625rem;
    min-height: inherit;
    font-size: 0.875rem;
  }
}
@media (max-width: 767.81px) {
  header .hdr-login-wrap a.btn-primary {
    padding: 0.25rem 0.5rem;
  }
}
header .befor-login-menu-btn {
  display: none;
}
@media (max-width: 991.9px) {
  header .befor-login-menu-btn {
    display: block;
    margin-left: 0.9375rem;
    cursor: pointer;
    border: 1px solid #0E378C;
    border-radius: 0.25rem;
    padding: 0.3125rem;
    min-height: 32px;
    line-height: 12px;
    min-width: 32px;
  }
  header .befor-login-menu-btn span:before {
    color: #0E378C;
  }
}
@media (max-width: 767.81px) {
  header .befor-login-menu-btn {
    padding: 4px 5px;
    min-height: 27px;
    line-height: 12px;
    min-width: 28px;
  }
}
header .back-to-home-btn {
  margin-right: 1.5625rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
}
@media (max-width: 767.81px) {
  header .back-to-home-btn {
    margin-right: 0.625rem;
  }
}
header .back-to-home-btn:hover {
  text-decoration: none;
}
header .back-to-home-btn span {
  font-size: 0.8125rem;
  margin-right: 1.25rem;
}
@media (max-width: 767.81px) {
  header .back-to-home-btn span {
    margin-right: 0.625rem;
    font-size: 0.75rem;
  }
}
header .back-to-home-btn span::before {
  color: #0E378C;
}

.nav-header {
  background: #ffffff;
}
.nav-header .container-fluid, .nav-header .container-sm, .nav-header .container-md, .nav-header .container-lg, .nav-header .container-xl {
  padding: 0.625rem 6.25rem;
}
@media (max-width: 1440.81px) {
  .nav-header .container-fluid, .nav-header .container-sm, .nav-header .container-md, .nav-header .container-lg, .nav-header .container-xl {
    padding: 0.625rem 3.125rem;
  }
}
@media (max-width: 1280.81px) {
  .nav-header .container-fluid, .nav-header .container-sm, .nav-header .container-md, .nav-header .container-lg, .nav-header .container-xl {
    padding: 0.625rem 0.9375rem;
  }
}
@media (max-width: 991.9px) {
  .nav-header .container-fluid, .nav-header .container-sm, .nav-header .container-md, .nav-header .container-lg, .nav-header .container-xl {
    padding: 1.1875rem 0.9375rem;
  }
}
@media (max-width: 767.81px) {
  .nav-header .container-fluid, .nav-header .container-sm, .nav-header .container-md, .nav-header .container-lg, .nav-header .container-xl {
    padding: 0.9375rem;
  }
}
.nav-header .nav-login-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-header .header-logo {
  display: flex;
  align-items: center;
}
.nav-header .header-logo .nav-icon {
  margin-right: 1.25rem;
}
@media (max-width: 767.81px) {
  .nav-header .header-logo .nav-icon {
    margin-right: 0.625rem;
  }
}
.nav-header .header-logo .nav-icon a:hover span::before {
  color: #0E378C;
}
.nav-header .header-logo .logo-nav img {
  max-width: inherit;
}
@media (max-width: 767.81px) {
  .nav-header .header-logo .logo-nav img {
    max-width: 110px;
  }
}
.nav-header .header-search {
  max-width: 1170px;
  width: 100%;
  position: relative;
  margin: 0rem 2.5rem;
}
@media (max-width: 991.9px) {
  .nav-header .header-search {
    display: none;
  }
}
.nav-header .header-search input {
  width: 100%;
  background-color: #EFEFEF;
  color: #9D9D9D;
  font-size: 1rem;
  line-height: 1.625rem;
  letter-spacing: -0.015625rem;
  border-radius: 0.75rem;
  border: none;
  padding: 0.75rem 3.125rem 0.75rem 3.75rem;
}
.nav-header .header-search .icon-icon-search {
  position: absolute;
  left: 16px;
  top: 16px;
  font-size: 1rem;
  z-index: 10;
}
.nav-header .header-search .search-close {
  right: 25px;
  left: auto;
}
.nav-header .header-search ng-select {
  height: 50px;
}
.nav-header .header-search ng-select .ng-arrow-wrapper {
  display: none;
}
.nav-header .header-search ng-select .ng-option-disabled {
  display: none !important;
}
.nav-header .header-search ng-select .ng-option {
  display: flex;
  align-items: center;
}
.nav-header .header-search ng-select .ng-option .img-box {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 10px;
  border: 1px solid #EFEFEF;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-header .header-search ng-select .ng-select-container {
  padding-left: 50px !important;
  height: 100% !important;
}
.nav-header .header-search ng-select .ng-select-container .ng-input {
  left: 30px !important;
  position: absolute;
  width: calc(100% - 30px) !important;
  height: 100%;
  top: 0 !important;
}
.nav-header .header-search ng-select .ng-select-container .ng-input input {
  margin-top: 0;
  height: 100%;
  color: #9D9D9D !important;
}
.nav-header .header-search ng-select .ng-select-container .ng-clear-wrapper {
  position: absolute;
  right: 0;
  display: block !important;
}
.nav-header .header-right {
  display: flex;
  align-items: center;
}
.nav-header .header-right .box-icon {
  margin-right: 1.5625rem;
}
@media (max-width: 767.81px) {
  .nav-header .header-right .box-icon {
    margin-right: 0.75rem;
  }
}
@media (max-width: 320.11px) {
  .nav-header .header-right .box-icon {
    margin-right: 0.3125rem;
  }
}
.nav-header .header-right .box-icon a {
  position: relative;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.nav-header .header-right .box-icon a span {
  font-size: 1.125rem;
}
@media (max-width: 767.81px) {
  .nav-header .header-right .box-icon a span {
    font-size: 1rem;
  }
}
.nav-header .header-right .box-icon a:hover span::before {
  color: #0E378C;
}
.nav-header .header-right .box-icon a .red-dots {
  background: #E73945;
  width: 8px;
  height: 8px;
  border-radius: 50rem;
  position: absolute;
  right: -2px;
  top: -2px;
}
.nav-header .header-right .video-box a span {
  font-size: 1.375rem;
}
@media (max-width: 767.81px) {
  .nav-header .header-right .video-box a span {
    font-size: 1.25rem;
  }
}
@media (max-width: 576.81px) {
  .nav-header .header-right .chat-box .btn-group, .nav-header .header-right .notification-box .btn-group {
    position: inherit;
  }
  .nav-header .header-right .chat-box .dropdown-menu::before, .nav-header .header-right .notification-box .dropdown-menu::before {
    display: none;
  }
}
.nav-header .header-right .user-profile {
  border-left: 1px solid #E3E3E3;
  padding-left: 1.5625rem;
}
@media (max-width: 767.81px) {
  .nav-header .header-right .user-profile {
    padding-left: 0.75rem;
  }
}
@media (max-width: 320.11px) {
  .nav-header .header-right .user-profile {
    padding-left: 0.3125rem;
  }
}
.nav-header .header-right .user-profile .profile-dropdown {
  display: flex;
  align-items: center;
}
.nav-header .header-right .user-profile .profile-dropdown img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50rem;
  max-width: inherit;
}
@media (max-width: 767.81px) {
  .nav-header .header-right .user-profile .profile-dropdown img {
    width: 28px;
    height: 28px;
  }
}
.nav-header .header-right .user-profile .profile-dropdown span {
  font-size: 0.75rem;
  padding-left: 0.375rem;
}
@media (max-width: 767.81px) {
  .nav-header .header-right .user-profile .profile-dropdown span {
    font-size: 0.625rem;
    padding-left: 0.25rem;
  }
}
.nav-header .header-right .header-mobile-search {
  display: none;
}
@media (max-width: 991.9px) {
  .nav-header .header-right .header-mobile-search {
    display: block;
  }
}
.nav-header .header-right .header-mobile-search .search-box {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 70px;
  background: #ffffff;
  padding: 0.625rem;
  border-top: 1px solid #EFEFEF;
  display: none;
}
@media (max-width: 767.81px) {
  .nav-header .header-right .header-mobile-search .search-box {
    top: 58px;
  }
}
.nav-header .header-right .header-mobile-search .search-box input {
  width: 100%;
  background-color: #EFEFEF;
  color: #9D9D9D;
  font-size: 1rem;
  line-height: 1.625rem;
  letter-spacing: -0.015625rem;
  border-radius: 0.75rem;
  border: none;
  padding: 0.75rem 2.8125rem 0.75rem 2.8125rem;
}
.nav-header .header-right .header-mobile-search .search-box .icon-icon-search {
  position: absolute;
  left: 25px;
  top: 26px;
  font-size: 1rem;
}
.nav-header .header-right .header-mobile-search .search-box .icon-icon-close {
  position: absolute;
  right: 25px;
  top: 26px;
  font-size: 1rem;
}
.nav-header .header-right .header-mobile-search .search-box ng-select .ng-arrow-wrapper {
  display: none;
}
.nav-header .header-right .header-mobile-search .search-box ng-select .ng-option-disabled {
  display: none !important;
}
.nav-header .header-right .header-mobile-search .search-box ng-select .ng-option {
  display: flex;
  align-items: center;
}
.nav-header .header-right .header-mobile-search .search-box ng-select .ng-option .img-box {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 10px;
  border: 1px solid #EFEFEF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.siderbar-wrap {
  position: fixed;
  top: 0;
  left: -100%;
  bottom: 0;
  background: #ffffff;
  max-width: 493px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 99999999999;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.siderbar-wrap.sidebar-in {
  left: 0;
}
.siderbar-wrap .sidebar-menu-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: -100%;
  max-width: 500px;
  width: 100%;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.siderbar-wrap .sidebar-menu-wrap.slide-menu-open {
  margin-left: 0;
}
.siderbar-wrap .sidebar-inner {
  padding: 4.375rem 2.75rem 6.25rem 2.6875rem;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .sidebar-inner {
    padding: 1.875rem;
  }
}
.siderbar-wrap .logo-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .logo-wrap img {
    max-width: 120px;
  }
}
.siderbar-wrap .logo-wrap .sidebar-close {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 50rem;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.siderbar-wrap .logo-wrap .sidebar-close:hover {
  background-color: #0E378C;
  color: #ffffff;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .logo-wrap .sidebar-close {
    width: 40px;
    height: 40px;
  }
}
.siderbar-wrap .logo-wrap .sidebar-close span {
  font-size: 0.875rem;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .logo-wrap .sidebar-close span {
    font-size: 0.75rem;
  }
}
.siderbar-wrap .sidebar-nav {
  margin-top: 5rem;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .sidebar-nav {
    margin-top: 1.875rem;
  }
}
.siderbar-wrap .sidebar-nav li {
  padding-bottom: 2.8125rem;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .sidebar-nav li {
    padding-bottom: 1.25rem;
  }
}
.siderbar-wrap .sidebar-nav li:last-child {
  padding-bottom: 0rem;
}
.siderbar-wrap .sidebar-nav li a {
  color: #222222;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: -0.02125rem;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .sidebar-nav li a {
    font-size: 1rem;
  }
}
.siderbar-wrap .sidebar-nav li a span {
  font-size: 1.75rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 1.875rem;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.siderbar-wrap .sidebar-nav li a span::before {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  color: #9D9D9D;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .sidebar-nav li a span {
    font-size: 1.25rem;
    width: 20px;
    height: 20px;
    margin-right: 0.9375rem;
  }
}
.siderbar-wrap .sidebar-nav li a span.icon-Icon-suitcase {
  font-size: 1.6875rem;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .sidebar-nav li a span.icon-Icon-suitcase {
    font-size: 1.25rem;
  }
}
.siderbar-wrap .sidebar-nav li a span.icon-icon-meeting {
  font-size: 2rem;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .sidebar-nav li a span.icon-icon-meeting {
    font-size: 1.375rem;
  }
}
.siderbar-wrap .sidebar-nav li a:hover, .siderbar-wrap .sidebar-nav li a.active {
  color: #0E378C;
}
.siderbar-wrap .sidebar-nav li a:hover span::before, .siderbar-wrap .sidebar-nav li a.active span::before {
  color: #0E378C;
}
.siderbar-wrap .user-profile-nav {
  background: #EFEFEF;
  padding: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .user-profile-nav {
    padding: 1.875rem;
  }
}
.siderbar-wrap .user-profile-nav .profile-info {
  display: flex;
  align-items: center;
}
.siderbar-wrap .user-profile-nav .profile-info .profile-img {
  flex: 0 0 auto;
  border-radius: 100%;
  overflow: hidden;
}
.siderbar-wrap .user-profile-nav .profile-info .profile-img img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .user-profile-nav .profile-info .profile-img img {
    width: 40px;
    height: 40px;
  }
}
.siderbar-wrap .user-profile-nav .profile-info .user-info {
  margin-left: 1.25rem;
  width: calc(100% - 80px);
}
@media (max-width: 767.81px) {
  .siderbar-wrap .user-profile-nav .profile-info .user-info {
    margin-left: 0.9375rem;
    width: calc(100% - 40px);
  }
}
.siderbar-wrap .user-profile-nav .profile-info .user-info h4 {
  color: #0E378C;
  margin-bottom: 0.25rem;
  text-align: left;
}
.siderbar-wrap .user-profile-nav .profile-info .user-info p {
  color: #222222;
  margin-bottom: 0rem;
  word-break: break-word;
  text-align: left;
}
.siderbar-wrap .user-profile-nav .profile-setting a {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 50rem;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .user-profile-nav .profile-setting a {
    width: 40px;
    height: 40px;
  }
}
.siderbar-wrap .user-profile-nav .profile-setting a span {
  font-size: 1.25rem;
}
.siderbar-wrap .user-profile-nav .profile-setting a span::before {
  color: #222222;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .user-profile-nav .profile-setting a span {
    font-size: 1rem;
  }
}
.siderbar-wrap .user-profile-nav .profile-setting a:hover {
  background-color: #0E378C;
}
.siderbar-wrap .user-profile-nav .profile-setting a:hover span:before {
  color: #ffffff;
}
.siderbar-wrap .sidebar-settings-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: -100%;
  max-width: 500px;
  width: 100%;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.siderbar-wrap .sidebar-settings-wrap.slide-menu-open {
  margin-left: 0;
}
.siderbar-wrap .sidebar-settings-wrap .setting-header {
  display: flex;
  align-items: center;
}
.siderbar-wrap .sidebar-settings-wrap .setting-header a {
  font-size: 1.375rem;
  line-height: 1.875rem;
  color: #0E378C;
  font-weight: 700;
  letter-spacing: -0.025rem;
}
.siderbar-wrap .sidebar-settings-wrap .setting-header a span {
  font-size: 16px;
  margin-right: 1.25rem;
}
.siderbar-wrap .sidebar-settings-wrap .setting-header a span::before {
  color: #0E378C;
}
.siderbar-wrap .sidebar-settings-wrap .setting-menu {
  margin-top: 5rem;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .sidebar-settings-wrap .setting-menu {
    margin-top: 1.875rem;
  }
}
.siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #EFEFEF;
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
}
.siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item .setting-info {
  display: flex;
  align-items: center;
  width: 100%;
}
.siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item .setting-info .setting-icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EFEFEF;
  border-radius: 50rem;
  flex: 0 0 auto;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item .setting-info .setting-icon {
    width: 40px;
    height: 40px;
  }
}
.siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item .setting-info .setting-icon span {
  font-size: 1.375rem;
  color: #0E378C;
}
.siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item .setting-info .setting-icon span::before {
  color: #0E378C;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item .setting-info .setting-icon span {
    font-size: 1.125rem;
  }
}
.siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item .setting-info .setting-icon span.icon-icon-credit-card, .siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item .setting-info .setting-icon span.icon-icon-email {
  font-size: 1.125rem;
}
.siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item .setting-info .setting-title {
  margin-left: 0.9375rem;
  width: 100%;
}
.siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item .setting-info .setting-title h4 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: -0.02125rem;
  color: #222222;
  margin-bottom: 0.25rem;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item .setting-info .setting-title h4 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
.siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item .setting-info .setting-title p {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 400;
  letter-spacing: -0.0075rem;
  color: #9D9D9D;
  margin-bottom: 0rem;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item .setting-info .setting-title p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item .setting-info .setting-title .status {
  font-size: 0.6875rem;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: 0.00625rem;
}
.siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item .setting-info .setting-title .status.verified {
  color: #47B872;
}
.siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item .setting-info .setting-title .status.request {
  color: #F3C32F;
}
.siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item .setting-view {
  margin-left: 0.9375rem;
}
.siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item .setting-view span {
  font-size: 1rem;
  color: #222222;
}
.siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item:hover .setting-title h4 {
  color: #0E378C;
}
.siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item:hover .setting-view span {
  color: #0E378C;
}
.siderbar-wrap .sidebar-settings-wrap .setting-menu .setting-item:hover .setting-view span::before {
  color: #0E378C;
}
.siderbar-wrap .tool-trip-wrapper {
  position: relative;
}
.siderbar-wrap .tool-trip-wrapper .tooltip .tooltip-inner {
  background-color: #ffffff;
  text-align: left;
  font-size: 0.875rem;
  color: #222222;
  line-height: 1.5rem;
  letter-spacing: -0.01375rem;
  max-height: 650px;
  overflow: scroll;
}
.siderbar-wrap .tool-trip-wrapper .tooltip.show {
  opacity: 1;
}
.siderbar-wrap .tool-trip-wrapper .tooltip-icon {
  position: absolute;
  top: 4px;
  right: 110px;
  font-size: 1.3125rem;
  cursor: pointer;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .tool-trip-wrapper .tooltip-icon {
    right: 195px;
  }
}
@media (max-width: 480.81px) {
  .siderbar-wrap .tool-trip-wrapper .tooltip-icon {
    right: 165px;
  }
}
@media (max-width: 414.81px) {
  .siderbar-wrap .tool-trip-wrapper .tooltip-icon {
    right: 115px;
  }
}
@media (max-width: 375.81px) {
  .siderbar-wrap .tool-trip-wrapper .tooltip-icon {
    right: 60px;
  }
}
@media (max-width: 320.11px) {
  .siderbar-wrap .tool-trip-wrapper .tooltip-icon {
    right: 20px;
  }
}
.siderbar-wrap .tool-trip-wrapper.verify-your-page .tooltip-icon {
  right: 175px;
}
@media (max-width: 767.81px) {
  .siderbar-wrap .tool-trip-wrapper.verify-your-page .tooltip-icon {
    right: 245px;
  }
}
@media (max-width: 480.81px) {
  .siderbar-wrap .tool-trip-wrapper.verify-your-page .tooltip-icon {
    right: 115px;
  }
}
@media (max-width: 414.81px) {
  .siderbar-wrap .tool-trip-wrapper.verify-your-page .tooltip-icon {
    right: 165px;
  }
}
@media (max-width: 375.81px) {
  .siderbar-wrap .tool-trip-wrapper.verify-your-page .tooltip-icon {
    right: 112px;
  }
}
@media (max-width: 320.11px) {
  .siderbar-wrap .tool-trip-wrapper.verify-your-page .tooltip-icon {
    right: 70px;
  }
}

.body-sidebar-open .overlay-full {
  background: rgba(34, 34, 34, 0.1);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

footer {
  border-top: 1px solid #E3E3E3;
}
footer h5 {
  color: #222222;
  line-height: 22px;
  font-size: 0.9375rem;
  font-weight: 600;
}
footer .footer-top {
  padding: 6.25rem 0rem 4.375rem;
}
@media (max-width: 1200.81px) {
  footer .footer-top {
    padding: 3.125rem 0rem;
  }
}
footer .footer-top .info {
  padding-right: 0.625rem;
}
@media (max-width: 991.9px) {
  footer .footer-top .info {
    padding-right: 0;
  }
}
footer .footer-top .info .ftr-logo {
  margin-bottom: 1.875rem;
  display: block;
}
@media (max-width: 991.9px) {
  footer .footer-top .info .ftr-logo img {
    width: 175px;
    height: auto;
  }
}
footer .footer-top .info p {
  margin-bottom: 1.875rem;
  color: #222222;
  line-height: 30px;
  font-size: 1.125rem;
}
@media (max-width: 991.9px) {
  footer .footer-top .info p {
    margin-bottom: 1.25rem;
  }
}
@media (max-width: 767.81px) {
  footer .footer-top .info p {
    font-size: 0.875rem;
  }
}
footer .footer-top .info .follow-us-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 991.9px) {
  footer .footer-top .info .follow-us-wrap {
    margin-bottom: 1.25rem;
  }
}
footer .footer-top .info .follow-us-wrap h5 {
  margin: 0;
}
footer .footer-top .info .follow-us-wrap ul {
  display: flex;
  align-items: center;
  margin-left: 0.9375rem;
}
footer .footer-top .info .follow-us-wrap ul li {
  margin-right: 1.25rem;
  margin-bottom: 0;
}
footer .footer-top .info .follow-us-wrap ul li span::before {
  color: #9D9D9D;
}
footer .footer-top .info .follow-us-wrap ul li a:hover span:before {
  color: #0E378C;
}
footer .footer-top .ftr-nav {
  padding-left: 2.5rem;
}
@media (max-width: 1200.81px) {
  footer .footer-top .ftr-nav {
    padding-left: 0px;
  }
}
footer .footer-top .ftr-nav h5 {
  display: block;
  margin-bottom: 1.25rem;
}
footer .footer-top .ftr-nav ul {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
footer .footer-top .ftr-nav ul li {
  margin-bottom: 0.9375rem;
}
footer .footer-top .ftr-nav ul li:last-child {
  margin-bottom: 0;
}
footer .footer-top .ftr-nav ul li a {
  padding: 0;
  margin: 0;
}
footer ul li {
  margin-bottom: 0.9375rem;
}
footer ul li a {
  color: #222222;
  line-height: 22px;
  font-size: 0.9375rem;
  text-transform: uppercase;
}
@media (max-width: 1200.81px) {
  footer ul li a {
    font-size: 0.8125rem;
    line-height: normal;
  }
}
footer ul li a:hover {
  text-decoration: none;
  color: #0E378C;
}
footer .footer-bottom {
  background-color: #EFEFEF;
  padding: 1.875rem 0rem;
}
@media (max-width: 1200.81px) {
  footer .footer-bottom {
    padding: 0.9375rem 0rem;
  }
}
footer .footer-bottom ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
footer .footer-bottom ul li {
  margin-right: 1.875rem;
}
@media (max-width: 991.9px) {
  footer .footer-bottom ul li {
    margin: 0rem 0.5rem 0.625rem;
  }
}
@media (max-width: 767.81px) {
  footer .footer-bottom ul li {
    margin-bottom: 0.625rem;
  }
}
@media (max-width: 1200.81px) {
  footer .footer-bottom ul li a {
    font-size: 0.75rem;
  }
}
@media (max-width: 991.9px) {
  footer .footer-bottom ul {
    justify-content: center;
  }
}
footer .footer-bottom .copy-rights {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0;
}
@media (max-width: 991.9px) {
  footer .footer-bottom .copy-rights {
    justify-content: center;
  }
}
footer .footer-bottom .copy-rights a {
  color: #222222;
  line-height: 22px;
  font-size: 0.9375rem;
  margin: 0rem 0.3125rem;
}
footer .footer-bottom .copy-rights a:hover {
  color: #0E378C;
}
@media (max-width: 1200.81px) {
  footer .footer-bottom .copy-rights a {
    font-size: 0.75rem;
    line-height: normal;
  }
}
@media (max-width: 1200.81px) {
  footer .footer-bottom .copy-rights {
    font-size: 0.75rem;
  }
}

.hm-sec-welcome {
  position: relative;
  padding: 4.375rem 0rem;
  background-color: #ffffff;
}
.hm-sec-welcome::before {
  content: "";
  background-image: url("../../images/hm-top-shape-right.svg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 150px;
  height: 208px;
  position: absolute;
  right: 70px;
  top: 50px;
  z-index: 1;
}
@media (max-width: 1440.81px) {
  .hm-sec-welcome::before {
    width: 110px;
    height: 145px;
    top: 22px;
  }
}
@media (max-width: 1280.81px) {
  .hm-sec-welcome::before {
    width: 80px;
    height: 115px;
    top: 10px;
  }
}
@media (max-width: 767.81px) {
  .hm-sec-welcome::before {
    width: 58px;
    height: 80px;
    right: 20px;
  }
}
.hm-sec-welcome::after {
  content: "";
  background-image: url("../../images/hm-top-shape-left.svg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 233px;
  height: 322px;
  position: absolute;
  left: 36px;
  bottom: -120px;
  z-index: -1;
}
@media (max-width: 1440.81px) {
  .hm-sec-welcome::after {
    width: 195px;
    height: 285px;
  }
}
@media (max-width: 1280.81px) {
  .hm-sec-welcome::after {
    width: 140px;
    height: 200px;
  }
}
@media (max-width: 576.81px) {
  .hm-sec-welcome::after {
    bottom: -40px;
  }
}
@media (max-width: 767.81px) {
  .hm-sec-welcome::after {
    width: 100px;
    height: 140px;
  }
}
.hm-sec-welcome .welcome-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
@media (max-width: 768.81px) {
  .hm-sec-welcome .welcome-content {
    flex-wrap: wrap;
  }
}
.hm-sec-welcome .welcome-content .info {
  max-width: 472px;
  position: relative;
}
@media (max-width: 768.81px) {
  .hm-sec-welcome .welcome-content .info {
    order: 2;
  }
}
.hm-sec-welcome .welcome-content .info h1 {
  margin-bottom: 1.25rem;
}
.hm-sec-welcome .welcome-content .info h3 {
  font-weight: 500;
  margin-bottom: 1.875rem;
}
.hm-sec-welcome .welcome-content .info .link-group {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}
.hm-sec-welcome .welcome-content .info .link-group a {
  color: #0E378C;
  font-weight: 600;
  margin-bottom: 1.875rem;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.hm-sec-welcome .welcome-content .info .link-group a:last-child {
  margin-bottom: none;
}
.hm-sec-welcome .welcome-content .info .link-group a:hover {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.hm-sec-welcome .welcome-content .info .link-group a:hover span {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  margin-left: 15px;
}
.hm-sec-welcome .welcome-content .info .link-group a:hover span:before {
  color: #0E378C;
}
.hm-sec-welcome .welcome-content .info .link-group a span {
  margin-left: 0.625rem;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.hm-sec-welcome .welcome-content .imgbox {
  position: relative;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../images/hm-top-img-bg.svg");
  max-width: 720px;
  min-height: 734px;
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 768.81px) {
  .hm-sec-welcome .welcome-content .imgbox {
    order: 1;
    margin: 0 auto 30px;
  }
}
.hm-sec-welcome .welcome-content .imgbox picture {
  position: absolute;
  top: 50%;
  left: 62%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 570px;
}
.hm-sec-welcome .welcome-content .imgbox picture img {
  width: 100%;
  height: auto;
}
@media (max-width: 767.81px) {
  .hm-sec-welcome .welcome-content .imgbox picture {
    left: 50%;
  }
}
@media (max-width: 1200.81px) {
  .hm-sec-welcome .welcome-content .imgbox {
    max-width: 458px;
    min-height: 530px;
  }
}
@media (max-width: 991.9px) {
  .hm-sec-welcome .welcome-content .imgbox {
    max-width: 319px;
    min-height: 375px;
  }
}
@media (max-width: 767.81px) {
  .hm-sec-welcome .welcome-content .imgbox {
    max-width: 319px;
    min-height: 335px;
  }
}

.sec-title-wrap {
  margin-bottom: 70px;
  background-color: #ffffff;
}
.sec-title-wrap h2 {
  margin-bottom: 0.625rem;
}
.sec-title-wrap h3 {
  font-weight: 500;
  margin-bottom: 1.875rem;
}

.sec-hm-getstarted {
  padding: 9.375rem 0rem;
  background-color: #ffffff;
}
@media (max-width: 1200.81px) {
  .sec-hm-getstarted {
    padding: 6.25rem 0rem;
  }
}
@media (max-width: 991.9px) {
  .sec-hm-getstarted {
    padding: 4.375rem 0rem;
  }
}
.sec-hm-getstarted .sec-title-wrap {
  text-align: center;
}
.sec-hm-getstarted .journey-card {
  max-width: 360px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 767.81px) {
  .sec-hm-getstarted .journey-card {
    margin: 0.9375rem auto 1.875rem;
  }
}
.sec-hm-getstarted .journey-card .videobox {
  width: 100%;
  height: auto;
}
.sec-hm-getstarted .journey-card .videobox video {
  width: 100%;
  height: 100%;
}
.sec-hm-getstarted .journey-card .imgbox {
  position: relative;
}
.sec-hm-getstarted .journey-card .imgbox .inner-img {
  position: absolute;
  bottom: 15px;
  left: 20px;
}
.sec-hm-getstarted .journey-card .info {
  text-align: center;
  padding-top: 1.875rem;
}
.sec-hm-getstarted .journey-card .info h4 {
  margin-bottom: 0.625rem;
}
.sec-hm-getstarted .journey-card .info p {
  line-height: 1.62;
  margin-bottom: 1.25rem;
  letter-spacing: -0.25px;
}
.sec-hm-getstarted .journey-card .btn {
  min-width: 139px;
}
@media (max-width: 576.81px) {
  .sec-hm-getstarted .journey-card .btn {
    margin-top: 0.625rem !important;
  }
}

.sec-app-download {
  background-color: #ffffff;
  border-bottom: 200px solid #0E378C;
  padding: 12.5rem 0rem 13.375rem;
  position: relative;
}
@media (max-width: 1440.81px) {
  .sec-app-download {
    padding: 6.25rem 0rem;
    border-bottom: 130px solid #0E378C;
  }
}
@media (max-width: 991.9px) {
  .sec-app-download {
    padding: 4.375rem 0rem;
    border-bottom: 0 solid #0E378C;
  }
}
@media (max-width: 576.81px) {
  .sec-app-download {
    padding: 3.125rem 0rem;
  }
}
.sec-app-download .info {
  max-width: 590px;
}
@media (max-width: 991.9px) {
  .sec-app-download .info {
    max-width: 100%;
    text-align: center;
  }
}
.sec-app-download .info h2 {
  margin-bottom: 1.875rem;
}
@media (max-width: 1440.81px) {
  .sec-app-download .info h2 {
    margin-bottom: 1.25rem;
  }
}
.sec-app-download .info h3 {
  font-weight: 500;
  margin-bottom: 1.875rem;
}
@media (max-width: 1440.81px) {
  .sec-app-download .info h3 {
    margin-bottom: 1.25rem;
  }
}
.sec-app-download .info .btnbox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 991.9px) {
  .sec-app-download .info .btnbox {
    max-width: 100%;
    justify-content: center;
  }
}
.sec-app-download .info .btnbox a:first-child {
  margin-right: 15px;
}
@media (max-width: 768.81px) {
  .sec-app-download .info .btnbox a img {
    width: 120px;
  }
}
.sec-app-download .imgbox {
  position: absolute;
  right: 0;
  bottom: -100px;
  width: 48%;
  height: auto;
}
.sec-app-download .imgbox img {
  height: auto;
}
.sec-app-download .imgbox .arrow-img {
  position: absolute;
  right: 0;
  top: 0;
  height: auto;
  width: 27%;
}
.sec-app-download .imgbox .arrow-img img {
  height: auto;
}
@media (max-width: 1440.81px) {
  .sec-app-download .imgbox {
    bottom: -70px;
    width: 42%;
  }
}
@media (max-width: 1200.81px) {
  .sec-app-download .imgbox {
    bottom: -70px;
    width: 42%;
  }
}
@media (max-width: 991.9px) {
  .sec-app-download .imgbox {
    position: inherit;
    max-width: 600px;
    margin: 0 auto 80px;
    bottom: 0;
    width: 100%;
  }
}
@media (max-width: 768.81px) {
  .sec-app-download .imgbox {
    max-width: 500px;
  }
}
@media (max-width: 576.81px) {
  .sec-app-download .imgbox {
    max-width: 380px;
    padding: 15px;
  }
}

.achieve-exponential {
  background-color: #0E378C;
  overflow: hidden;
}
@media (max-width: 1200.81px) {
  .achieve-exponential {
    padding: 6.25rem 0rem 9.375rem;
  }
}
@media (max-width: 991.9px) {
  .achieve-exponential {
    padding: 4.375rem 0rem 9.375rem;
  }
}
.achieve-exponential .left-bar {
  margin-left: 16.6666666667%;
}
@media (max-width: 1680.87px) {
  .achieve-exponential .left-bar {
    margin-left: 10.666667%;
  }
}
@media (max-width: 1540.81px) {
  .achieve-exponential .left-bar {
    margin-left: 7.666667%;
  }
}
@media (max-width: 1440.81px) {
  .achieve-exponential .left-bar {
    margin-left: 5%;
  }
}
@media (max-width: 1200.81px) {
  .achieve-exponential .left-bar {
    margin-left: 20px;
  }
}
@media (max-width: 767.81px) {
  .achieve-exponential .left-bar {
    margin-left: 0;
    text-align: center;
  }
}
@media (min-width: 1200.91px) {
  .achieve-exponential {
    padding: 9.375rem 0rem 7.5rem 0rem;
  }
}
@media (min-width: 1200.91px) {
  .achieve-exponential .container-fluid, .achieve-exponential .container-sm, .achieve-exponential .container-md, .achieve-exponential .container-lg, .achieve-exponential .container-xl {
    padding-right: 0rem;
  }
}
.achieve-exponential .left-info-wrap {
  padding: 1.875rem 0rem 0rem 1.3125rem;
}
@media (max-width: 1280.81px) {
  .achieve-exponential .left-info-wrap {
    padding: 1.875rem 0rem 3.125rem 0rem;
  }
}
@media (max-width: 991.9px) {
  .achieve-exponential .left-info-wrap {
    padding: 0rem 0rem 3.125rem 0rem;
  }
}
.achieve-exponential .left-info-wrap h2, .achieve-exponential .left-info-wrap h3, .achieve-exponential .left-info-wrap .sign-in-btn {
  color: #ffffff;
}
@media (max-width: 1680.87px) {
  .achieve-exponential .left-info-wrap h2 {
    font-size: 2.25rem;
  }
}
@media (max-width: 1540.81px) {
  .achieve-exponential .left-info-wrap h2 {
    font-size: 2.125rem;
  }
}
@media (max-width: 767.81px) {
  .achieve-exponential .left-info-wrap h2 {
    font-size: 1.75rem;
  }
}
@media (max-width: 480.81px) {
  .achieve-exponential .left-info-wrap h2 {
    font-size: 1.1875rem;
  }
}
.achieve-exponential .left-info-wrap h3 {
  font-weight: 500;
  padding: 1.5625rem 3.75rem 1.875rem 0rem;
}
@media (max-width: 1680.87px) {
  .achieve-exponential .left-info-wrap h3 {
    font-size: 1.625rem;
  }
}
@media (max-width: 1540.81px) {
  .achieve-exponential .left-info-wrap h3 {
    padding: 1.5625rem 0rem 1.875rem 0rem;
  }
}
@media (max-width: 767.81px) {
  .achieve-exponential .left-info-wrap h3 {
    font-size: 1.125rem;
  }
}
@media (max-width: 480.81px) {
  .achieve-exponential .left-info-wrap h3 {
    font-size: 1rem;
  }
}
.achieve-exponential .left-info-wrap .sign-in-btn span {
  padding-left: 0.625rem;
  vertical-align: middle;
}
.achieve-exponential .left-info-wrap .sign-in-btn span::before {
  color: #ffffff;
}
.achieve-exponential .left-info-wrap .sign-in-btn:hover {
  color: #E3E3E3;
}
.achieve-exponential .left-info-wrap .sign-in-btn:hover span:before {
  color: #E3E3E3;
}
.achieve-exponential .achieve-slider {
  margin-right: -70px;
}
@media (max-width: 1680.87px) {
  .achieve-exponential .achieve-slider {
    margin-right: -250px;
  }
}
@media (max-width: 1540.81px) {
  .achieve-exponential .achieve-slider {
    margin-right: -350px;
  }
}
@media (max-width: 1440.81px) {
  .achieve-exponential .achieve-slider {
    margin-right: -400px;
  }
}
@media (max-width: 1200.81px) {
  .achieve-exponential .achieve-slider {
    margin-right: 0;
  }
}
.achieve-exponential .achieve-slider .slick-track {
  padding-left: 70px;
}
@media (max-width: 1680.87px) {
  .achieve-exponential .achieve-slider .slick-track {
    padding-left: 250px;
  }
}
@media (max-width: 1540.81px) {
  .achieve-exponential .achieve-slider .slick-track {
    padding-left: 350px;
  }
}
@media (max-width: 1440.81px) {
  .achieve-exponential .achieve-slider .slick-track {
    padding-left: 400px;
  }
}
@media (max-width: 1200.81px) {
  .achieve-exponential .achieve-slider .slick-track {
    padding-left: 0;
  }
}
.achieve-exponential .achieve-slider .slick-arrow {
  border: 1px solid #ffffff;
  background-image: none !important;
  bottom: 0;
  left: -40%;
  line-height: 3rem;
  text-align: center;
  cursor: pointer;
  margin-bottom: 1.875rem;
}
@media (max-width: 1680.87px) {
  .achieve-exponential .achieve-slider .slick-arrow {
    left: -33%;
    margin-bottom: 4.375rem;
  }
}
@media (max-width: 1540.81px) {
  .achieve-exponential .achieve-slider .slick-arrow {
    left: -30%;
    margin-bottom: 2.375rem;
  }
}
@media (max-width: 1540.81px) {
  .achieve-exponential .achieve-slider .slick-arrow {
    margin-bottom: 6.875rem;
  }
}
@media (max-width: 1440.81px) {
  .achieve-exponential .achieve-slider .slick-arrow {
    margin-bottom: 3.125rem;
    left: -28%;
  }
}
@media (max-width: 1200.81px) {
  .achieve-exponential .achieve-slider .slick-arrow {
    bottom: -120px !important;
    left: 45% !important;
  }
}
@media (max-width: 991.9px) {
  .achieve-exponential .achieve-slider .slick-arrow {
    bottom: -140px !important;
    line-height: 2.5rem;
    left: 41% !important;
  }
}
@media (max-width: 480.81px) {
  .achieve-exponential .achieve-slider .slick-arrow {
    line-height: 1.875rem;
    left: 40% !important;
    font-size: 0.6875rem;
  }
}
@media (max-width: 375.81px) {
  .achieve-exponential .achieve-slider .slick-arrow {
    left: 39% !important;
  }
}
.achieve-exponential .achieve-slider .slick-arrow.icon-Icon-arrow-right {
  bottom: 0;
  margin-left: 4.6875rem;
}
@media (max-width: 1680.87px) {
  .achieve-exponential .achieve-slider .slick-arrow.icon-Icon-arrow-right {
    margin-left: 5rem;
  }
}
@media (max-width: 767.81px) {
  .achieve-exponential .achieve-slider .slick-arrow.icon-Icon-arrow-right {
    margin-left: 3.4375rem;
  }
}
@media (max-width: 480.81px) {
  .achieve-exponential .achieve-slider .slick-arrow.icon-Icon-arrow-right {
    margin-left: 2.5rem;
  }
}
.achieve-exponential .achieve-slider .slick-arrow::before {
  color: #ffffff;
}
.achieve-exponential .achieve-slider .slick-arrow:hover {
  border-color: #E3E3E3;
}
.achieve-exponential .achieve-slider .slick-arrow:hover:before {
  color: #E3E3E3;
}
@media (max-width: 1200.81px) {
  .achieve-exponential .achieve-slider .slick-list {
    padding: 0 !important;
  }
}
@media (max-width: 991.9px) {
  .achieve-exponential .achieve-slider .slick-list {
    max-width: 100%;
  }
}
.achieve-exponential .achieve-slider .slick-list .slick-track .slick-slide img {
  border-top-right-radius: 15px;
  margin: 0;
  padding: 0;
}
@media (max-width: 1280.81px) {
  .achieve-exponential .achieve-slider .slick-list .slick-track .slick-slide img {
    max-width: 100%;
  }
}
.achieve-exponential .achieve-slider .box-wrap {
  border-radius: 15px;
  overflow: hidden;
  background-color: #164095;
  max-width: 348px;
  margin: 0rem 1.5625rem;
}
@media (max-width: 1200.81px) {
  .achieve-exponential .achieve-slider .box-wrap {
    margin: 0rem 0.9375rem;
  }
}
.achieve-exponential .achieve-slider .contnet-box {
  padding: 1.875rem;
  min-height: 190px;
}
@media (max-width: 480.81px) {
  .achieve-exponential .achieve-slider .contnet-box {
    min-height: 100%;
  }
}
.achieve-exponential .achieve-slider .contnet-box h4, .achieve-exponential .achieve-slider .contnet-box p {
  color: #ffffff;
}

.no-padding {
  padding: 0 !important;
}

.started-slider {
  background-color: #ffffff;
}

.investor-slider {
  background-color: #ffffff;
}

.sec-hm-jobgallery {
  background-color: #ffffff;
  background-image: url("../../images/job-gallery-bg.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.sec-hm-jobgallery .info {
  padding-right: 4.0625rem;
}
@media (max-width: 1200.81px) {
  .sec-hm-jobgallery .info {
    padding-right: 0px;
  }
}
@media (max-width: 767.81px) {
  .sec-hm-jobgallery .info {
    text-align: center;
  }
}
.sec-hm-jobgallery .info h2 {
  margin-bottom: 1.875rem;
}
.sec-hm-jobgallery .info h3 {
  font-weight: 500;
  margin-bottom: 1.875rem;
}
.sec-hm-jobgallery .info a {
  color: #0E378C;
  font-weight: 600;
  margin-bottom: 1.875rem;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
@media (max-width: 767.81px) {
  .sec-hm-jobgallery .info a {
    display: block;
  }
}
.sec-hm-jobgallery .info a:last-child {
  margin-bottom: none;
}
.sec-hm-jobgallery .info a:hover {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.sec-hm-jobgallery .info a:hover span {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  margin-left: 0.9375rem;
}
.sec-hm-jobgallery .info a:hover span:before {
  color: #0E378C;
}
.sec-hm-jobgallery .info a span {
  margin-left: 0.625rem;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.sec-hm-jobgallery ul li {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  position: relative;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
  margin-bottom: 1.875rem;
  padding: 2.375rem 1.875rem;
  border-radius: 0.75rem;
}
@media (max-width: 1200.81px) {
  .sec-hm-jobgallery ul li {
    padding: 1.25rem 1.875rem;
  }
}
.sec-hm-jobgallery ul li span {
  width: 56px;
  height: 56px;
  border-radius: 50px;
  background-color: #EFEFEF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.875rem;
}
.sec-hm-jobgallery ul li span:before {
  color: #0E378C;
}
.sec-hm-jobgallery ul li .content {
  width: calc(100% - 90px);
}
.sec-hm-jobgallery ul li .content p {
  margin-bottom: 0;
}

.login-signup-wrap {
  max-width: 970px !important;
  width: 100%;
}
@media (max-width: 1200.81px) {
  .login-signup-wrap {
    max-width: 750px;
  }
}
.login-signup-wrap .modal-content {
  background-color: transparent;
  border: none;
  position: relative;
  display: block;
}
.login-signup-wrap .modal-content app-login {
  display: flex;
  align-items: center;
}
@media (max-width: 767.81px) {
  .login-signup-wrap .modal-content app-login {
    flex-direction: column;
  }
}
.login-signup-wrap .modal-content app-login .login-signup-form {
  order: 1;
}
@media (max-width: 767.81px) {
  .login-signup-wrap .modal-content app-login .login-signup-form {
    border-radius: 1.5rem 1.5rem 0rem 0rem;
  }
}
.login-signup-wrap .modal-content app-login .info {
  order: 2;
  border-radius: 0rem 1.5rem 1.5rem 0rem;
}
@media (max-width: 767.81px) {
  .login-signup-wrap .modal-content app-login .info {
    border-radius: 0rem 0rem 1.5rem 1.5rem;
  }
}
.login-signup-wrap .modal-content app-register {
  display: flex;
  align-items: center;
}
@media (max-width: 767.81px) {
  .login-signup-wrap .modal-content app-register {
    flex-direction: column;
  }
}
.login-signup-wrap .modal-content app-register .login-signup-form {
  order: 2;
}
@media (max-width: 767.81px) {
  .login-signup-wrap .modal-content app-register .login-signup-form {
    order: 1;
    border-radius: 1.5rem 1.5rem 0rem 0rem;
  }
}
.login-signup-wrap .modal-content app-register .info {
  order: 1;
  border-radius: 1.5rem 0rem 0rem 1.5rem;
}
@media (max-width: 767.81px) {
  .login-signup-wrap .modal-content app-register .info {
    order: 2;
    border-radius: 0rem 0rem 1.5rem 1.5rem;
  }
}
.login-signup-wrap .modal-content .login-signup-form {
  max-width: 470px;
  width: 100%;
}
@media (max-width: 1200.81px) {
  .login-signup-wrap .modal-content .login-signup-form {
    min-height: initial;
  }
}
@media (max-width: 767.81px) {
  .login-signup-wrap .modal-content .login-signup-form {
    max-width: 375px;
  }
}
.login-signup-wrap .modal-content .login-signup-form h3 {
  text-align: center;
  margin-bottom: 0.625rem;
}
.login-signup-wrap .modal-content .login-signup-form p {
  text-align: center;
  margin-bottom: 1.875rem;
}
@media (max-width: 991.9px) {
  .login-signup-wrap .modal-content .login-signup-form p {
    margin-bottom: 1.25rem;
  }
}
.login-signup-wrap .modal-content .login-signup-form .forgot-link {
  text-align: right;
  display: block;
  font-weight: 600;
  margin-bottom: 1.25rem;
}
.login-signup-wrap .modal-content .login-signup-form .btnbox {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
}
.login-signup-wrap .modal-content .login-signup-form .btnbox .btn {
  width: 100%;
}
.login-signup-wrap .modal-content .login-signup-form .social-media-btnbox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1.25rem;
}
.login-signup-wrap .modal-content .login-signup-form .social-media-btnbox a {
  min-width: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 10px);
}
@media (max-width: 1280.81px) {
  .login-signup-wrap .modal-content .login-signup-form .social-media-btnbox a {
    min-width: 85px;
  }
}
@media (max-width: 991.9px) {
  .login-signup-wrap .modal-content .login-signup-form .social-media-btnbox a {
    min-width: 85px;
  }
}
@media (max-width: 767.81px) {
  .login-signup-wrap .modal-content .login-signup-form .social-media-btnbox a {
    min-width: 150px;
  }
}
@media (max-width: 576.81px) {
  .login-signup-wrap .modal-content .login-signup-form .social-media-btnbox a {
    min-width: 100px;
  }
}
.login-signup-wrap .modal-content .login-signup-form .social-media-btnbox a:first-child {
  margin-right: 0.625rem;
}
.login-signup-wrap .modal-content .login-signup-form .social-media-btnbox a.btn span:before {
  color: #ffffff;
}
.login-signup-wrap .modal-content .login-signup-form .social-media-btnbox a span {
  margin-right: 1.25rem;
  font-size: 1.375rem;
}
@media (max-width: 991.9px) {
  .login-signup-wrap .modal-content .login-signup-form .social-media-btnbox a span {
    margin-right: 0.9375rem;
  }
}
@media (max-width: 414.81px) {
  .login-signup-wrap .modal-content .login-signup-form .social-media-btnbox a span {
    margin-right: 0.9375rem;
    font-size: 1.125rem;
  }
}
.login-signup-wrap .info {
  max-width: 970px;
  width: 100%;
  background-color: rgba(14, 55, 140, 0.95);
  padding: 3.125rem 2.8125rem;
  min-height: 392px;
  border-radius: 1.5rem;
  display: flex;
  text-align: center;
  align-items: center;
}
@media (max-width: 1200.81px) {
  .login-signup-wrap .info {
    min-height: 30px;
  }
}
@media (max-width: 767.81px) {
  .login-signup-wrap .info {
    max-width: 375px;
    padding: 1.25rem;
  }
}
@media (max-width: 767.81px) {
  .login-signup-wrap .info {
    padding: 1.25rem 0.9375rem;
  }
}
.login-signup-wrap .info .info-wrap {
  max-width: 400px;
  width: 100%;
}
.login-signup-wrap .info .info-wrap h3 {
  color: #ffffff;
  margin-bottom: 0.625rem;
}
.login-signup-wrap .info .info-wrap p {
  margin-bottom: 1.875rem;
  color: #ffffff;
}
.login-signup-wrap .info .info-wrap .btn {
  min-width: 100px;
}

.ng-select.ng-select-single .ng-select-container {
  border-color: #EFEFEF !important;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 1rem;
  color: #0E378C;
  font-weight: 400;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container {
  padding-left: 1.25rem;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-left: 1.25rem;
  padding-right: 1.875rem;
}
.ng-select .ng-select-container {
  border-radius: 12px;
  border-color: #EFEFEF !important;
  background-color: #ffffff;
  font-size: 1rem;
  color: #0E378C;
  font-weight: 400;
}
.ng-select .ng-select-container .ng-value-container {
  padding-left: 1.25rem;
}
.ng-select .ng-select-container .ng-value-container .ng-input {
  padding-left: 1.25rem;
  padding-right: 1.875rem;
}
.ng-select .ng-select-container .ng-value-container .ng-input > input {
  height: 35px;
  color: #0E378C;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #0E378C;
  font-size: 1rem;
}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: none;
}
.ng-select .ng-dropdown-panel {
  border: 1px solid #EFEFEF;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  border-radius: 0;
  z-index: 10;
}
.ng-select .ng-dropdown-panel .ng-select-bottom {
  border-color: #EFEFEF;
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal;
  padding: 0.625rem 1.25rem;
  color: #0E378C;
  font-size: 1rem;
  line-height: 1.5rem;
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: rgba(14, 55, 140, 0.08);
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: rgba(14, 55, 140, 0.15);
}
.ng-select .ng-arrow-wrapper {
  padding-right: 0.75rem;
}
.ng-select .ng-arrow-wrapper span.ng-arrow {
  width: auto;
  height: auto;
}
.ng-select .ng-arrow-wrapper span.ng-arrow::after {
  font-size: 1.125rem;
}
.ng-select.ng-select-opened div.ng-select-container {
  z-index: 10;
}
.ng-select.ng-select-disabled .ng-select-container {
  background-color: #f8f9fa;
  cursor: not-allowed;
}
.ng-select.ng-select-disabled .ng-arrow-wrapper .ng-arrow {
  border-color: #E3E3E3 transparent transparent;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  overflow: auto;
  padding-left: 1.0625rem;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 15px;
  opacity: 0.6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 96%;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin-bottom: 0.3125rem;
  margin-top: 0.3125rem;
}

.introduction-wrap {
  max-width: 870px !important;
}
@media (max-width: 991.9px) {
  .introduction-wrap {
    padding: 0rem 1rem;
  }
}
@media (max-width: 767.81px) {
  .introduction-wrap {
    padding: 0;
  }
}
.introduction-wrap .modal-content {
  padding: 3.125rem;
  border: none;
  border-radius: 1.5rem !important;
  background-color: #ffffff !important;
}
@media (max-width: 767.81px) {
  .introduction-wrap .modal-content {
    padding: 3rem 1rem 1rem;
  }
}

.introduction-modal .modal-imgbox {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
  max-height: 600px;
}
.introduction-modal .modal-imgbox .ng-star-inserted {
  width: auto;
  object-fit: cover;
  object-position: center;
}
.introduction-modal .modal-imgbox .slick-att {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 414.81px) {
  .introduction-modal .modal-imgbox .slick-att {
    height: 200px;
  }
}
.introduction-modal .modal-imgbox .slick-att img {
  max-width: 100% !important;
  height: auto;
}
@media (max-width: 768.81px) {
  .introduction-modal .modal-imgbox .slick-att img {
    max-width: 300 !important;
  }
}
.introduction-modal .modal-imgbox.video-block {
  display: block;
}
.introduction-modal .modal-imgbox.video-block .slick-slider .slick-arrow.slick-next {
  right: 0;
}
.introduction-modal .modal-imgbox.video-block .slick-slider .slick-arrow.slick-prev {
  left: 0;
  z-index: 99;
}
.introduction-modal .modal-content {
  background-color: #ffffff;
}
.introduction-modal .content-header {
  text-align: center;
  margin-bottom: 1.875rem;
}
.introduction-modal .content-header h3 {
  margin-bottom: 0.75rem;
}
.introduction-modal .close-ic {
  position: absolute;
  right: 25px;
  top: 25px;
}
@media (max-width: 767.81px) {
  .introduction-modal .close-ic {
    right: 16px;
    top: 16px;
  }
}

.modal-backdrop {
  z-index: 9999;
}

.modal {
  z-index: 9999;
}

/*===== Dropdown Start ======*/
.dropdown-menu {
  background-color: #ffffff;
  border: none;
  border-radius: 0.75rem;
  box-shadow: 4px 4px 32px 12px rgba(0, 0, 0, 0.05);
  margin-top: 1.125rem;
  -webkit-animation: 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
  animation: 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
}
@media (max-width: 767.81px) {
  .dropdown-menu {
    margin-top: 0.75rem;
  }
}
.dropdown-menu::before {
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  content: "";
  position: absolute;
  top: -1px;
  right: 1.2rem;
  width: 0.75rem;
  height: 0.75rem;
  display: block;
  background: #ffffff;
  transform: rotate(45deg) translate(-7px);
  z-index: -1;
  box-sizing: border-box;
}
.dropdown-menu .dropdown-item {
  color: #222222;
  font-size: 1rem !important;
  padding: 1.125rem 1rem;
}
.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus {
  color: #0E378C;
  background-color: #ffffff;
}
.dropdown-menu .dropdown-item:hover span, .dropdown-menu .dropdown-item:focus span {
  margin-right: 0.625rem;
}
.dropdown-menu .dropdown-item:hover span::before, .dropdown-menu .dropdown-item:focus span::before {
  color: #0E378C !important;
}
.dropdown-menu .dropdown-item span {
  margin-right: 0.625rem;
}
.dropdown-menu .dropdown-item span::before {
  color: black;
}
.dropdown-menu .dropdown-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem;
  color: #222222;
}
.dropdown-menu .dropdown-header h4 {
  font-size: 1.125rem;
  color: #222222;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.015625rem;
  margin-bottom: 0;
}
.dropdown-menu .dropdown-header a {
  font-size: 0.9375rem;
  color: #0E378C;
  font-weight: 600;
  line-height: 1.375rem;
  letter-spacing: -0.0075rem;
}
.dropdown-menu.dropdown-menu-lg {
  min-width: 320px;
  right: -21px !important;
}
@media (max-width: 576.81px) {
  .dropdown-menu.dropdown-menu-lg {
    min-width: 95%;
    right: 0 !important;
    left: 0 !important;
    width: 95%;
    top: 52px !important;
    margin: 0 auto;
  }
}
.dropdown-menu .dropdown-chat-list {
  max-height: 450px;
  overflow-y: auto;
}
.dropdown-menu .dropdown-chat-list .dropdown-list-item {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #EFEFEF;
  padding: 0.625rem 1.25rem;
  position: relative;
}
.dropdown-menu .dropdown-chat-list .dropdown-list-item:last-child {
  border-bottom: none;
}
.dropdown-menu .dropdown-chat-list .dropdown-list-item .profile-img {
  flex: 0 0 auto;
}
.dropdown-menu .dropdown-chat-list .dropdown-list-item .profile-img img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50rem;
}
.dropdown-menu .dropdown-chat-list .dropdown-list-item .chat-info {
  width: calc(100% - 32px);
  margin-left: 0.625rem;
}
.dropdown-menu .dropdown-chat-list .dropdown-list-item .chat-info h5 {
  font-size: 1rem;
  color: #222222;
  font-weight: 600;
  line-height: 1.625rem;
  letter-spacing: -0.015625rem;
  margin-bottom: 0.25rem;
  margin-right: 1.5rem;
  height: auto;
}
.dropdown-menu .dropdown-chat-list .dropdown-list-item .chat-info h5 .black-dot {
  background: #222222;
  width: 4px;
  height: 4px;
  border-radius: 50rem;
  display: inline-block;
  vertical-align: middle;
  margin: 0rem 0.375rem;
}
.dropdown-menu .dropdown-chat-list .dropdown-list-item .chat-info p {
  font-size: 0.9375rem;
  color: #222222;
  line-height: 1.375rem;
  letter-spacing: -0.0075rem;
  margin-bottom: 0.25rem;
}
.dropdown-menu .dropdown-chat-list .dropdown-list-item .chat-info .msg-time {
  display: block;
  font-size: 0.75rem;
  color: #9D9D9D;
  line-height: 1.125rem;
  font-weight: 600;
}
.dropdown-menu .dropdown-chat-list .dropdown-list-item .alert-msg {
  position: absolute;
  right: 20px;
}
.dropdown-menu .dropdown-chat-list .dropdown-list-item .alert-msg span {
  background: #E73945;
  color: #ffffff;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 600;
  width: 20px;
  height: 20px;
  border-radius: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1875rem;
}
.dropdown-menu .dropdown-notification-list {
  max-height: 450px;
  overflow-y: auto;
}
.dropdown-menu .dropdown-notification-list .dropdown-list-item {
  border-bottom: 1px solid #EFEFEF;
  padding: 0.625rem 1.25rem;
  display: block;
}
.dropdown-menu .dropdown-notification-list .dropdown-list-item:last-child {
  border-bottom: none;
}
.dropdown-menu .dropdown-notification-list .dropdown-list-item .notification-info p {
  font-size: 0.9375rem;
  color: #222222;
  line-height: 1.375rem;
  letter-spacing: -0.0075rem;
  margin-bottom: 0.25rem;
}
.dropdown-menu .dropdown-notification-list .dropdown-list-item .notification-info p span {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  letter-spacing: -0.0075rem;
  color: #0E378C;
}
.dropdown-menu .dropdown-notification-list .dropdown-list-item .notification-info .msg-time {
  display: block;
  font-size: 0.75rem;
  color: #9D9D9D;
  line-height: 1.125rem;
  font-weight: 600;
}

@keyframes fadein {
  from {
    -webkit-transform: translate3d(0, 6px, 0);
    transform: translate3d(0, 6px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
/*===== Dropdown End ======*/
/*===== Card Start ====*/
.card {
  background: #ffffff;
  padding: 1.875rem;
  border-radius: 0.75rem;
  border: none;
  box-shadow: 2px 2px 18px 8px rgba(0, 0, 0, 0.03);
  margin-bottom: 0.625rem;
}
@media (max-width: 767.81px) {
  .card {
    padding: 0.9375rem;
  }
}
.card .card-title {
  margin-bottom: 1.25rem;
}
.card .card-title h3 {
  font-size: 1rem;
  line-height: 1.625rem;
  font-weight: 600;
  color: #222222;
  letter-spacing: -0.015625rem;
  margin-bottom: 0rem;
}
.card .card-title a {
  font-size: 0.8125rem;
  line-height: 1.375rem;
  font-weight: 600;
  color: #0E378C;
  letter-spacing: -0.001875rem;
  margin-bottom: 0rem;
}
.card .card-title.card-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card .title-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  padding-right: 1.25rem;
  position: relative;
}
.card .title-wrap a {
  font-weight: 600;
}
.card .title-wrap span {
  color: #9D9D9D;
  font-size: 0.8125rem;
  font-weight: normal;
}
.card.with-ad {
  background-color: #f2f5f9;
  border: solid 1px #0E378C;
  margin-top: 1.875rem;
}

/*===== Card End ====*/
/* dropdown plugin css */
.pac-container {
  z-index: 10000;
}

.pac-logo:after {
  display: none !important;
}

.uploaded-file-list {
  max-height: 250px;
  overflow: auto;
}

/* Subscription */
.subscription-plan-list .info {
  padding-right: 0 !important;
}
.subscription-plan-list .info .plan-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.subscription-plan-list .info .plan-item p {
  margin-right: 15px;
}
.subscription-plan-list .info .plan-item span {
  flex-shrink: 0;
  color: #0E378C;
  font-size: 0.875rem;
  margin-bottom: 15px;
}
.subscription-plan-list .info .plan-item span span {
  font-size: 0.875rem;
}

.reset-pass-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6.875rem 0rem;
}
@media (max-width: 768.81px) {
  .reset-pass-wrap {
    padding: 3.125rem 0rem;
  }
}
.reset-pass-wrap .card {
  border: none;
  border-radius: 1.5rem;
  background-color: #ffffff;
  padding: 100px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
  flex-direction: inherit;
  width: 100%;
  max-width: 1064px;
  margin: 0 auto;
}
@media (max-width: 991.9px) {
  .reset-pass-wrap .card {
    padding: 3.75rem 2.1875rem;
  }
}
@media (max-width: 768.81px) {
  .reset-pass-wrap .card {
    flex-direction: column;
    padding: 2.1875rem;
  }
}
.reset-pass-wrap .card .imgbox {
  width: 100%;
  max-width: 409px;
}
@media (max-width: 991.9px) {
  .reset-pass-wrap .card .imgbox {
    max-width: 280px;
  }
}
@media (max-width: 768.81px) {
  .reset-pass-wrap .card .imgbox {
    margin: 0 auto 2.1875rem;
    max-width: 400px;
  }
}
.reset-pass-wrap .card form {
  text-align: center;
  width: 100%;
  max-width: 390px;
}
@media (max-width: 991.9px) {
  .reset-pass-wrap .card form {
    max-width: 290px;
  }
}
@media (max-width: 768.81px) {
  .reset-pass-wrap .card form {
    max-width: 310px;
  }
}
.reset-pass-wrap .card form h3 {
  text-align: center;
  margin-bottom: 0.625rem;
}
.reset-pass-wrap .card form p {
  text-align: center;
  margin-bottom: 1.875rem;
}
@media (max-width: 991.9px) {
  .reset-pass-wrap .card form p {
    margin-bottom: 1.25rem;
  }
}
.reset-pass-wrap .card form .btngroup {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reset-pass-wrap .card form .btngroup .btn {
  max-width: 185px;
  width: 100%;
}
.reset-pass-wrap .card form .btngroup .btn:first-child {
  margin-right: 1.25rem;
}

.avatar-upload {
  position: relative;
  max-width: 120px;
  margin: 1.875rem auto;
}
.avatar-upload .avatar-edit {
  position: absolute;
  right: 0;
  z-index: 1;
  top: 0;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #0E378C;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.avatar-upload .avatar-edit input + label::after {
  content: "";
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.avatar-upload .avatar-edit label span::after {
  color: #ffffff;
}
.avatar-upload .avatar-preview {
  width: 120px;
  height: 120px;
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #EFEFEF;
  justify-content: center;
}
.avatar-upload .avatar-preview img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.my-video-layout .upload-video-card {
  padding: 8.75rem 1.25rem;
}
@media (max-width: 768.81px) {
  .my-video-layout .upload-video-card {
    padding: 6.25rem 3.75rem;
  }
}
@media (max-width: 768.81px) {
  .my-video-layout .upload-video-card {
    padding: 5.625rem 3.75rem;
  }
}
@media (max-width: 768.81px) {
  .my-video-layout .upload-video-card {
    padding: 3.125rem 1.875rem;
  }
}
.my-video-layout .upload-video-card .upload-video-form .dropzone {
  transition: border-color 200ms ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed lightgrey;
  min-height: 492px;
  max-width: 770px;
  position: relative;
  flex-direction: column;
  padding: 3.125rem;
  width: 100%;
  border-radius: 0.75rem;
  background-color: #ffffff;
  text-align: center;
  margin: 0 auto;
}
@media (max-width: 991.9px) {
  .my-video-layout .upload-video-card .upload-video-form .dropzone {
    min-height: 400px;
  }
}
@media (max-width: 768.81px) {
  .my-video-layout .upload-video-card .upload-video-form .dropzone {
    padding: 1.875rem;
    min-height: 360px;
  }
}
@media (max-width: 576.81px) {
  .my-video-layout .upload-video-card .upload-video-form .dropzone {
    min-height: 280px;
  }
}
.my-video-layout .upload-video-card .upload-video-form .dropzone:hover {
  cursor: pointer;
  border-color: #0E378C;
}
.my-video-layout .upload-video-card .upload-video-form .dropzone:hover span::before {
  color: #0E378C;
}
.my-video-layout .upload-video-card .upload-video-form .dropzone.active {
  border-color: grey;
}
.my-video-layout .upload-video-card .upload-video-form .dropzone .upload-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.my-video-layout .upload-video-card .upload-video-form .dropzone span {
  font-size: 4.5rem;
}
.my-video-layout .upload-video-card .upload-video-form .dropzone p {
  margin: 1.875rem 0rem;
}
.my-video-layout .upload-video-card .upload-video-form .dropzone .btn {
  width: 100%;
  max-width: 370px;
}

/* ================================ My video list */
.all-video-layout .video-filter-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 0.75rem;
  padding: 0rem 1.25rem;
  margin-bottom: 1.875rem;
}
.all-video-layout .video-filter-header .video-list-nav {
  display: flex;
  align-items: center;
}
.all-video-layout .video-filter-header .video-list-nav li.active a, .all-video-layout .video-filter-header .video-list-nav li:hover a {
  color: #0E378C;
  border-color: #0E378C;
}
.all-video-layout .video-filter-header .video-list-nav li a {
  padding: 1.25rem 0.9375rem;
  color: #222222;
  border-bottom: 2px solid transparent;
  display: flex;
}

.video-card-item {
  background-color: #ffffff;
  border-radius: 0.75rem;
  min-height: 430px;
  margin-bottom: 1.875rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.video-card-item .video-block {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 0.75rem;
  height: 250px;
}
.video-card-item .video-block video {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.video-card-item .video-block .tag-comman {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.video-card-item .info {
  padding: 1.875rem 3.125rem 1.875rem 1.875rem;
  position: relative;
}
.video-card-item .info .dropdown-btn-wrap {
  position: absolute;
  right: 20px;
  top: 35px;
}
.video-card-item .info .dropdown-btn-wrap .action-btn {
  padding: 0rem 0.1875rem;
  line-height: normal;
  display: block;
}
.video-card-item .info .dropdown-btn-wrap .action-btn span {
  margin-bottom: 0;
  display: block;
}
.video-card-item .info .dropdown-btn-wrap .action-btn:hover span::before {
  color: #0E378C;
}
.video-card-item .info .dropdown-btn-wrap .dropdown-menu {
  margin-top: 0.625rem;
  right: -10px !important;
}
.video-card-item .info .dropdown-btn-wrap .dropdown-menu::before {
  right: 0.25rem;
}
.video-card-item .info .dropdown-btn-wrap .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
}
.video-card-item .info .dropdown-btn-wrap .dropdown-menu .dropdown-item:hover span::before {
  color: #0E378C;
}
.video-card-item .info .dropdown-btn-wrap .dropdown-menu .dropdown-item span {
  margin-right: 0.75rem;
}
.video-card-item .info .dropdown-btn-wrap .dropdown-menu .dropdown-item span::before {
  color: #222222;
}
.video-card-item .info h6 {
  line-height: 26px;
  margin-bottom: 0.625rem;
  padding-right: 2.5rem;
}
.video-card-item .info span {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.video-card-item .info span.tag-name {
  font-weight: 600;
  font-size: 0.9375rem;
  margin-bottom: 0.625rem;
}
.video-card-item .info span.tag-name .d-flex .icon-Icon-green-tick {
  line-height: 20px;
  margin-left: 5px;
}
.video-card-item .info span.date-view {
  font-size: 0.8125rem;
}

.upload-video-box {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0.75rem;
  padding: 1.875rem;
  min-height: 430px;
  margin-bottom: 1.875rem;
}
.upload-video-box .info {
  border-radius: 0.75rem;
  border: 2px dashed #EFEFEF;
  padding: 10px;
  text-align: center;
  min-height: 368px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 350px;
  width: 100%;
}
.upload-video-box .info:hover {
  border-color: #0E378C;
}
.upload-video-box .info:hover span:before {
  color: #0E378C;
}
.upload-video-box .info span {
  font-size: 4.6875rem;
  margin-bottom: 1.25rem;
}
.upload-video-box .info span:before {
  color: #222222;
}
.upload-video-box .info p {
  color: #222222;
}

/* Filter Button */
.filter-btn-wrap {
  display: flex;
  align-items: center;
  position: relative;
  padding: 1.125rem;
  border-left: 1px solid #EFEFEF;
}
@media (max-width: 991.9px) {
  .filter-btn-wrap {
    padding: 0.375rem 1.25rem;
  }
}
.filter-btn-wrap.show .icon-Icon-down-arrow-block, .filter-btn-wrap.open .icon-Icon-down-arrow-block {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.filter-btn-wrap .filter-dropdown {
  color: #222222;
  display: flex;
  align-items: center;
}
.filter-btn-wrap .filter-dropdown .icon-icon-filter {
  margin-right: 0.4375rem;
  position: relative;
}
.filter-btn-wrap .filter-dropdown .icon-icon-filter.filtered::after {
  content: "";
  background: #e73945;
  width: 8px;
  height: 8px;
  border-radius: 50rem;
  position: absolute;
  right: -2px;
  top: -2px;
}
.filter-btn-wrap .form-control {
  height: 41px;
  padding: 0.8125rem 4.375rem 0.8125rem 0.9375rem;
}
.filter-btn-wrap .icon-Icon-down-arrow-block {
  width: 24px;
  height: 24px;
  margin-left: 1.875rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EFEFEF;
  font-size: 0.625rem;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.filter-btn-wrap .dropdown-menu {
  padding: 1.5625rem 0rem 4.375rem 0rem;
  min-width: 295px;
  top: 50px;
}
@media (max-width: 767.81px) {
  .filter-btn-wrap .dropdown-menu {
    top: 32px;
  }
}
.filter-btn-wrap .dropdown-menu::before {
  display: none;
}
.filter-btn-wrap .dropdown-menu.dropdown-menu-right {
  right: 0px;
}
.filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media (max-width: 1680.87px) {
  .filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper {
    max-height: 470px;
  }
}
@media (max-width: 1400.81px) {
  .filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper {
    max-height: 360px;
  }
}
@media (max-width: 1280.81px) {
  .filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper {
    max-height: 310px;
  }
}
@media (max-width: 767.81px) {
  .filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper {
    max-height: 280px;
  }
}
.filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper.investor-dropdown-menu-wrapper {
  margin-top: -25px;
}
.filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper .switch-group-dropdown-wrap {
  position: relative;
  z-index: 0;
}
.filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper .switch-group-dropdown-wrap::after {
  content: "";
  width: calc(100% + 40px);
  left: -20px;
  background-color: #f2f5f9;
  z-index: -1;
  top: 0;
  position: absolute;
  height: 100%;
  border-radius: 0.75rem 0.75rem 0rem 0rem;
}
.filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper .switch-group-dropdown-wrap .switch-box {
  padding: 10px 0;
  border-bottom: 1px solid rgba(34, 34, 34, 0.1);
}
.filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper .switch-group-dropdown-wrap .switch-box:last-child {
  border-bottom: none;
  margin-bottom: 15px;
}
.filter-btn-wrap .dropdown-menu label {
  text-transform: uppercase;
  font-size: 0.6875rem;
  letter-spacing: -0.1px;
  line-height: 16px;
  margin-bottom: 0.3125rem;
}
.filter-btn-wrap .dropdown-menu .select-box ng-select .ng-select-container {
  padding: 0.1875rem 0.75rem !important;
  min-height: 41px;
  border-radius: 0.25rem;
}
.filter-btn-wrap .dropdown-menu .select-box ng-select .ng-select-container .ng-placeholder {
  top: 25% !important;
}
.filter-btn-wrap .dropdown-menu .select-box ng-select .ng-select-container .ng-value-container .ng-input input {
  height: 25px !important;
}
.filter-btn-wrap .dropdown-menu .identiy-group {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1.25rem;
  max-height: 220px;
  overflow: scroll;
}
@media (max-width: 1400.81px) {
  .filter-btn-wrap .dropdown-menu .identiy-group {
    max-height: 140px;
  }
}
.filter-btn-wrap .dropdown-menu .identiy-group .title-span {
  font-size: 0.9375rem;
  display: block;
  margin-bottom: 1.25rem;
  line-height: 22px;
}
.filter-btn-wrap .dropdown-menu .identiy-group .media {
  cursor: pointer;
}
.filter-btn-wrap .dropdown-menu .identiy-group .media span {
  color: #0E378C;
}
.filter-btn-wrap .dropdown-menu .action-wrapper {
  padding: 0.9375rem 0rem;
  background-color: #EFEFEF;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.filter-btn-wrap .dropdown-menu .action-wrapper .btn-box {
  padding: 0rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: none;
  margin-top: 0;
}
.filter-btn-wrap .dropdown-menu .action-wrapper .btn-box .btn {
  padding: 0.3125rem 0.9375rem 0.3125rem 0rem;
  text-align: left;
  min-height: 40px;
  font-size: 0.9375rem;
  line-height: 22px;
  color: #0E378C;
  border-radius: 0.25rem;
}
.filter-btn-wrap .dropdown-menu .action-wrapper .btn-box .btn.btn-primary {
  min-width: 100px;
  padding: 0.3125rem 0.9375rem;
  text-align: center;
  color: #ffffff;
}
.filter-btn-wrap .dropdown-menu .action-wrapper .btn-box .btn.btn-primary.btn-primary:hover {
  color: #0E378C;
}
.filter-btn-wrap .spacer-line {
  border-top: 1px solid rgba(34, 34, 34, 0.1);
  margin-top: 1.25rem;
}

/* ==================================================== Video Details page */
.video-detial-card {
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-detial-card .card-title-p {
  padding: 0rem 7.5rem 0.625rem;
}
@media (max-width: 767.81px) {
  .video-detial-card .card-title-p {
    padding: 0rem 3.125rem 0.625rem;
  }
}
@media (max-width: 414.81px) {
  .video-detial-card .card-title-p {
    padding: 0rem 0.3125rem 0.3125rem;
  }
}
.video-detial-card form {
  max-width: 570px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
.video-detial-card form textarea {
  resize: vertical;
  min-height: 58px;
}
.video-detial-card form .btnbox {
  display: flex;
  justify-content: flex-end;
}
.video-detial-card form .btnbox .btn {
  min-width: 180px;
}
.video-detial-card .img-with-text .ng-value-label {
  display: flex;
  align-items: center;
}
.video-detial-card .img-with-text .ng-value-label img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50rem;
  flex: 0 0 auto;
}
.video-detial-card .img-with-text .ng-value-label .user-info {
  margin-left: 0.75rem;
  text-align: left;
}
.video-detial-card .img-with-text .ng-value-label .user-info h6 {
  font-size: 1.0625rem;
  color: #0E378C;
  line-height: 1.375rem;
  font-weight: 600;
  margin-bottom: 0;
  text-align: left;
}
.video-detial-card .img-with-text .ng-value-label .user-info span {
  font-size: 0.8125rem;
  color: #222222;
  line-height: 1.375rem;
  font-weight: 400;
}

/* Profile select Modal */
.identity-modal-card .profile-group-wrap {
  margin-top: 1.25rem;
  max-height: 300px;
  overflow: scroll;
}
.identity-modal-card .profile-group-wrap .profile-media {
  padding: 0.9375rem;
  margin-bottom: 0;
  word-break: break-all;
}
.identity-modal-card .profile-group-wrap .profile-media.selected {
  background-color: #EFEFEF;
}
.identity-modal-card .profile-group-wrap .profile-media.selected .media-body .selected-icon {
  display: block;
}
.identity-modal-card .profile-group-wrap .profile-media .media-body {
  padding-right: 3.125rem;
  position: relative;
}
.identity-modal-card .profile-group-wrap .profile-media .media-body .selected-icon {
  display: none;
  position: absolute;
  right: 0;
  top: 12px;
}
.identity-modal-card .btnbox {
  margin-top: 1.25rem;
  margin-bottom: 0rem !important;
}

/* Common tabs */
.common-tabs {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.common-tabs .search-filter-wrap {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 999;
  background-color: #ffffff;
  border-radius: 0.75rem;
}
@media (max-width: 991.9px) {
  .common-tabs .search-filter-wrap {
    top: 15px;
    border-radius: 0.75rem;
  }
}
@media (max-width: 767.81px) {
  .common-tabs .search-filter-wrap {
    top: 79px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    padding: 3px 10px;
  }
}
.common-tabs .search-filter-wrap .search-wrap {
  margin-right: 1.5625rem;
  cursor: pointer;
}
@media (max-width: 991.9px) {
  .common-tabs .search-filter-wrap .search-wrap {
    padding: 0.3125rem 0.9375rem;
    margin-right: 0;
  }
}
.common-tabs .search-filter-wrap .video-search-inputbox {
  width: 100%;
  position: absolute;
  min-width: 300px;
  right: 5px;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 100ms ease;
  z-index: 1;
  opacity: 0;
}
.common-tabs .search-filter-wrap .video-search-inputbox.show {
  transform: scaleX(1);
  opacity: 1;
  margin-top: 0.25rem;
}
@media (max-width: 991.9px) {
  .common-tabs .search-filter-wrap .video-search-inputbox {
    min-width: 100%;
    right: 0;
  }
  .common-tabs .search-filter-wrap .video-search-inputbox .icon-icon-search {
    top: 18px;
    font-size: 0.8125rem;
  }
  .common-tabs .search-filter-wrap .video-search-inputbox .search-close {
    top: 18px;
    font-size: 0.8125rem;
  }
}
.common-tabs .tab-nav-header {
  width: 100%;
}
.common-tabs .tab-nav-header .tab-content {
  width: 100%;
}
.common-tabs .tab-nav-header .tab-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
.common-tabs .tab-nav-header .tab-container .nav-tabs {
  border-bottom: none;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0.75rem;
  padding: 0rem 1.25rem;
  margin-bottom: 1.875rem;
  width: 100%;
  flex-wrap: nowrap;
  overflow-y: auto;
}
@media (max-width: 991.9px) {
  .common-tabs .tab-nav-header .tab-container .nav-tabs {
    margin-bottom: 65px;
    justify-content: flex-start;
  }
}
.common-tabs .tab-nav-header .tab-container .nav-tabs .nav-link {
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom: 2px solid transparent;
  padding: 1.25rem 0.9375rem;
  font-size: 0.9375rem;
  line-height: 22px;
  color: #222222;
  font-weight: 600;
}
.common-tabs .tab-nav-header .tab-container .nav-tabs .nav-link.active {
  color: #0E378C;
  background-color: transparent;
  border-color: #0E378C;
}
.common-tabs .tab-nav-header .tab-container .nav-tabs .nav-item {
  width: auto;
  flex-shrink: 0;
  margin-bottom: 0;
}
.common-tabs .tab-nav-header .tab-container .nav-tabs .nav-item.show .nav-link {
  color: #0E378C;
  background-color: transparent;
  border-color: #0E378C;
}
.common-tabs .tab-nav-header.search-filter-sticky .tab-container .nav-tabs {
  position: sticky;
  top: 70px;
  z-index: 999;
}

/* Uploading Video style */
.uploading-video-card .uploading-video-wrap {
  max-width: 770px;
  margin: 0 auto;
  width: 100%;
}
.uploading-video-card .uploading-video-wrap .uploading-wrap {
  margin-bottom: 1.875rem;
  position: relative;
  width: 100%;
  border-radius: 1.5rem;
  overflow: hidden;
}
.uploading-video-card .uploading-video-wrap .uploading-wrap img {
  height: auto;
}
.uploading-video-card .uploading-video-wrap .uploading-wrap .overlay {
  background-color: rgba(14, 55, 140, 0.7);
  height: 100%;
  right: 0;
  top: 0;
  position: absolute;
}
.uploading-video-card .uploading-video-wrap .status-wrap {
  margin-bottom: 1.875rem;
}
.uploading-video-card .uploading-video-wrap .btnbox .btn {
  min-width: 180px;
}

/* Video croping */
.video-croping-card .video-block {
  margin-bottom: 1.875rem;
  border-radius: 12px;
  overflow: hidden;
}
.video-croping-card .video-crop-slider {
  width: 100%;
  height: 68px;
  position: relative;
}
.video-croping-card .video-crop-slider .video-box {
  height: 56px;
  overflow: hidden;
  position: relative;
  top: -5px;
  border-radius: 0;
}
.video-croping-card .video-crop-slider .video-box video {
  border-radius: 0;
}
.video-croping-card .video-crop-slider mv-slider {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
}
.video-croping-card .video-crop-slider mv-slider .slider-handle {
  width: 10px;
  height: 68px;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
  margin-left: 0;
  margin-top: -0.3125rem;
}
.video-croping-card .video-crop-slider mv-slider .slider-handle.min-slider-handle {
  background-image: url("../../../assets/images/left-thumb.svg");
  background-repeat: no-repeat;
  margin-left: -0.375rem;
  width: 14px;
}
.video-croping-card .video-crop-slider mv-slider .slider-handle.max-slider-handle {
  background-image: url("../../../assets/images/right-thumb.svg");
  background-repeat: no-repeat;
}
.video-croping-card .video-crop-slider mv-slider .slider.slider-horizontal .slider-track {
  border-radius: 0;
  height: 56px;
  top: 0;
  width: 100%;
  background-color: transparent;
  background-image: none;
}
.video-croping-card .video-crop-slider mv-slider .slider-selection {
  background-image: linear-gradient(to bottom, rgba(14, 55, 140, 0.4) 0, rgba(14, 55, 140, 0.4) 100%);
  border-radius: 0;
}

.video-details-layout ng-select.ng-select-single .ng-select-container .ng-has-value {
  height: 56px;
  border-radius: 0.75rem;
}
.video-details-layout .select-box .ng-select .ng-select-container {
  border-radius: 0.75rem;
}

.home-feed-section {
  margin-top: 1.875rem;
  padding: 0rem 6.25rem;
}
@media (max-width: 1440.81px) {
  .home-feed-section {
    padding: 0rem 2.1875rem;
  }
}
@media (max-width: 1200.81px) {
  .home-feed-section {
    padding: 0rem 0rem;
  }
}
.home-feed-section .search-filter-sticky .nav-tabs {
  position: sticky;
  top: 70px;
  z-index: 999;
}

.home-feed-searchstickey {
  position: sticky;
  top: 70px;
  z-index: 1049;
}
@media (max-width: 767.81px) {
  .home-feed-searchstickey {
    top: 50px;
  }
}

.profile-info {
  text-align: center;
}
.profile-info .profile-progress {
  width: 114px;
  height: 114px;
  margin: 0 auto;
  position: relative;
}
.profile-info .profile-progress svg image {
  clip-path: circle(49%);
}
.profile-info .profile-progress .play-ic {
  width: 32px;
  height: 32px;
  border-radius: 50rem;
  background: #0E378C;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 6px;
  bottom: 6px;
}
.profile-info .profile-progress .play-ic span {
  font-size: 0.625rem;
}
.profile-info .profile-details {
  margin-top: 0.75rem;
}
.profile-info .profile-details p {
  font-size: 0.8125rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: #222222;
  letter-spacing: -0.001875rem;
  margin: 0.1875rem 0rem;
}
.profile-info .profile-details p span {
  color: #0E378C;
  padding-right: 0.3125rem;
  vertical-align: -1px;
}
.profile-info .profile-details p span:before {
  font-weight: 700;
}
.profile-info .profile-details p .date {
  font-size: 0.8125rem;
  color: #9D9D9D;
}
.profile-info .profile-details .profile-contact .profile-name {
  justify-content: center;
}
.profile-info .profile-details .profile-contact .profile-name .icon-Icon-green-tick {
  line-height: 32px;
}
.profile-info .profile-details .profile-contact .profile-name h4 {
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: #222222;
  letter-spacing: -0.015625rem;
  margin-bottom: 0.1875rem;
  align-items: center;
  word-break: break-all;
}
.profile-info .profile-details .profile-contact .profile-name h4 span {
  margin-left: 0.25rem;
}
.profile-info .profile-details .profile-contact h5 {
  font-size: 0.9375rem;
  line-height: 1.625rem;
  font-weight: 600;
  color: #0E378C;
  letter-spacing: -0.015625rem;
  margin-bottom: 0rem;
}
.profile-info .profile-details .profile-contact .skill-lists li {
  background-color: #EFEFEF;
  border-radius: 0.25rem;
  color: #222222;
  padding: 0.625rem;
  margin: 0.3125rem 0 0 0.3125rem;
}
.profile-info .profile-details .profile-complete {
  border-top: 1px solid #EFEFEF;
  padding-top: 1.25rem;
  margin-top: 1.25rem;
}
.profile-info .profile-details .profile-complete p {
  margin-bottom: 1.25rem;
}

.current-plan .plan-details h4 {
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: #0E378C;
  letter-spacing: -0.015625rem;
  margin-bottom: 0rem;
  display: flex;
  align-items: center;
}
.current-plan .plan-details h4 span {
  font-size: 1.25rem;
  color: #0E378C;
  margin-right: 0.75rem;
}
.current-plan .plan-details h4 span::before {
  color: #0E378C;
}
.current-plan .plan-details .upgrade-plan {
  border-top: 1px solid #EFEFEF;
  padding-top: 1.25rem;
  margin-top: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 1200.81px) {
  .current-plan .plan-details .upgrade-plan {
    justify-content: center;
    flex-direction: column;
  }
}
.current-plan .plan-details .upgrade-plan p {
  font-size: 0.8125rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: #222222;
  letter-spacing: -0.001875rem;
  margin-bottom: 0rem;
  max-width: 150px;
  margin-right: 1.875rem;
}
@media (max-width: 1200.81px) {
  .current-plan .plan-details .upgrade-plan p {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 1.25rem;
  }
}
.current-plan .plan-details .upgrade-plan a {
  flex: 0 0 auto;
}

.interests-box .interests-details .interests-item {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
}
.interests-box .interests-details .interests-item:last-child {
  margin-bottom: 0;
}
.interests-box .interests-details .img-box {
  flex: 0 0 auto;
  border-radius: 100%;
  overflow: hidden;
}
.interests-box .interests-details .img-box img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.interests-box .interests-details .content-info {
  margin-left: 1.25rem;
  width: calc(100% - 60px);
}
.interests-box .interests-details .content-info h4, .interests-box .interests-details .content-info a {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 600;
  color: #222222;
  letter-spacing: -0.0075rem;
  margin-bottom: 0rem;
}
.interests-box .interests-details .content-info h4:hover, .interests-box .interests-details .content-info a:hover {
  color: #0E378C;
}
.interests-box .interests-details .content-info .d-flex .icon-Icon-green-tick {
  line-height: 22px;
}
.interests-box .interests-details .content-info p {
  font-size: 0.8125rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: #9D9D9D;
  letter-spacing: -0.001875rem;
  margin-bottom: 0rem;
}

.quick-links .quick-links-list ul li {
  margin-bottom: 1.25rem;
}
.quick-links .quick-links-list ul li:last-child {
  margin-bottom: 0;
}
.quick-links .quick-links-list ul li a {
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: #222222;
  letter-spacing: -0.015625rem;
  margin-bottom: 0rem;
  display: flex;
  align-items: center;
}
.quick-links .quick-links-list ul li a span {
  color: #222222;
  letter-spacing: -0.015625rem;
}
.quick-links .quick-links-list ul li a span.icon {
  font-size: 1.25rem;
  color: #9D9D9D;
  margin-right: 0.75rem;
}
.quick-links .quick-links-list ul li a span.icon::before {
  color: #9D9D9D;
}
.quick-links .quick-links-list ul li a span em {
  font-size: 0.75rem;
  color: #0E378C;
  display: block;
  line-height: 12px;
}
.quick-links .quick-links-list ul li a:hover {
  color: #0E378C;
}
.quick-links .quick-links-list ul li a:hover span {
  color: #0E378C;
}
.quick-links .quick-links-list ul li a:hover span::before {
  color: #0E378C;
}

.sidebar-footer {
  text-align: center;
  margin: 1.875rem 0rem;
}
.sidebar-footer ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (max-width: 1200.81px) {
  .sidebar-footer ul {
    justify-content: flex-start;
  }
}
.sidebar-footer ul li {
  padding: 0rem 0.9375rem 0.625rem 0.9375rem;
}
@media (max-width: 1200.81px) {
  .sidebar-footer ul li {
    width: 50%;
    text-align: left;
  }
}
@media (max-width: 480.81px) {
  .sidebar-footer ul li {
    padding: 0rem 0.3125rem 0.625rem 0.3125rem;
  }
}
@media (max-width: 320.11px) {
  .sidebar-footer ul li {
    padding: 0rem 0rem 0.625rem;
  }
}
.sidebar-footer ul li a {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: #222222;
  letter-spacing: -0.0075rem;
}
.sidebar-footer ul li a:hover {
  color: #0E378C;
}
.sidebar-footer .copyright {
  border-top: 1px solid rgba(34, 34, 34, 0.1);
  padding-top: 1.25rem;
  margin-top: 0.625rem;
}
.sidebar-footer .copyright p {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: #9D9D9D;
  letter-spacing: -0.0075rem;
  margin-bottom: 0rem;
  margin-top: 0.625rem;
}
.sidebar-footer .copyright p a {
  font-weight: 600;
  color: #222222;
}
.sidebar-footer .copyright p a:hover {
  color: #0E378C;
}

.home-feed-wrap .search-filter-wrap .btn-box {
  border-top: 1px solid rgba(34, 34, 34, 0.1);
  padding-top: 1.25rem;
  margin-top: 1.25rem;
}

.with-ad .with-ad-whitebg {
  background-color: #ffffff;
  padding: 1.5rem 1.625rem;
  border-radius: 0.625rem;
  margin-top: 1.25rem;
}
@media (max-width: 767.81px) {
  .with-ad .with-ad-whitebg {
    padding: 1rem;
    margin-top: 1rem;
  }
}
.with-ad .home-feed-video .with-ad-whitebg .video-title {
  margin-top: 0;
}

.home-feed-video .feed-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-feed-video .feed-header .icon-icon-action {
  font-size: 1.125rem;
}
.home-feed-video .feed-header .header-box {
  display: flex;
  align-items: center;
  cursor: inherit;
}
.home-feed-video .feed-header .header-box .img-box {
  flex: 0 0 auto;
}
.home-feed-video .feed-header .header-box .img-box img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50rem;
}
.home-feed-video .feed-header .header-box .header-title {
  margin-left: 0.625rem;
  display: block;
  align-items: center;
}
.home-feed-video .feed-header .header-box .header-title h5 {
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: #0E378C;
  letter-spacing: -0.015625rem;
  margin-bottom: 0rem;
  display: inline;
  margin-right: 5px;
}
.home-feed-video .feed-header .header-box .header-title span {
  margin-left: 0rem;
  display: inline;
}
.home-feed-video .feed-header .header-box .header-title .bxs-badge-check {
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-left: 0.25rem;
  color: #47B872;
}
.home-feed-video .feed-header .dropdown-box .dropdown-menu {
  right: -22px !important;
  margin-top: 0.625rem;
  box-shadow: 0 2px 4px 5px #EFEFEF;
}
.home-feed-video .feed-header .dropdown-box .dropdown-menu:before {
  display: none;
}
.home-feed-video .feed-header .dropdown-box .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
}
.home-feed-video .feed-header .dropdown-box .dropdown-menu .dropdown-item:hover span::before {
  color: #0E378C;
}
.home-feed-video .feed-header .dropdown-box .dropdown-menu .dropdown-item span {
  margin-right: 0.75rem;
}
.home-feed-video .feed-header .dropdown-box .dropdown-menu .dropdown-item span::before {
  color: #222222;
}
.home-feed-video .video-title {
  margin: 1.25rem 0rem 0.625rem 0rem;
  padding-right: 1.875rem;
  position: relative;
}
@media (max-width: 767.81px) {
  .home-feed-video .video-title {
    margin: 0.625rem 0rem 0.625rem 0rem;
  }
}
.home-feed-video .video-title a {
  font-size: 1.375rem;
  line-height: 1.875rem;
  font-weight: 700;
  color: #222222;
  letter-spacing: -0.025rem;
  margin-bottom: 0rem;
}
.home-feed-video .video-title a:hover {
  color: #0E378C;
}
@media (max-width: 767.81px) {
  .home-feed-video .video-title a {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
.home-feed-video .video-title .dropdown-box {
  position: absolute;
  right: 0;
  top: 0;
}
.home-feed-video .video-status {
  margin-bottom: 0.625rem;
}
.home-feed-video .video-status p {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 700;
  color: #9D9D9D;
  text-transform: uppercase;
  margin-bottom: 0rem;
}
.home-feed-video .video-status p a {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 700;
}
.home-feed-video .tag-info {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.25rem;
}
@media (max-width: 767.81px) {
  .home-feed-video .tag-info {
    margin-bottom: 0.625rem;
  }
}
.home-feed-video .video-box {
  position: relative;
  border-radius: 0.75rem;
  overflow: hidden;
}
.home-feed-video .video-box .video-feed {
  width: 100%;
  object-fit: cover;
  border-radius: 0.75rem;
}
.home-feed-video .video-box vg-player {
  background-color: transparent;
}
.home-feed-video .video-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.875rem;
}
@media (max-width: 767.81px) {
  .home-feed-video .video-action {
    margin-top: 0.9375rem;
  }
}
.home-feed-video .video-action ul {
  display: flex;
  align-items: center;
}
.home-feed-video .video-action ul li {
  margin-right: 1.875rem;
}
.home-feed-video .video-action ul li:last-child {
  margin-right: 0;
}
.home-feed-video .video-action ul li a {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 600;
  color: #222222;
  letter-spacing: -0.0075rem;
  display: flex;
  align-items: center;
}
.home-feed-video .video-action ul li a span {
  font-size: 1.125rem;
  margin-right: 0.625rem;
}
.home-feed-video .video-action ul li a:hover, .home-feed-video .video-action ul li a.selected {
  color: #0E378C;
}
.home-feed-video .video-action ul li a:hover span, .home-feed-video .video-action ul li a.selected span {
  color: #0E378C;
}
.home-feed-video .video-action ul li a:hover span::before, .home-feed-video .video-action ul li a.selected span::before {
  color: #0E378C;
}
.home-feed-video .video-action ul li a .action-text {
  font-style: normal;
  margin-left: 0.25rem;
}
@media (max-width: 767.81px) {
  .home-feed-video .video-action ul li a .action-text {
    display: none;
  }
}
.home-feed-video .video-action .favorite a span {
  font-size: 1.125rem;
}
.home-feed-video .video-action .favorite a:hover span {
  color: #0E378C;
}
.home-feed-video .video-action .favorite a:hover span::before {
  color: #0E378C;
}

.filter-search {
  margin-bottom: 1.25rem;
}
.filter-search h6 {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 600;
  color: #222222;
  letter-spacing: -0.0075rem;
  margin-bottom: 0.625rem;
}
.filter-search ul {
  display: flex;
  flex-wrap: wrap;
}
.filter-search ul li {
  margin: 0rem 0.625rem 0.625rem 0rem;
}
.filter-search ul li a {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: #222222;
  letter-spacing: -0.0075rem;
  border: 1px solid rgba(34, 34, 34, 0.1);
  padding: 0.5rem 0.5625rem;
  display: inline-block;
  border-radius: 0.25rem;
}

.home-video-popup {
  max-width: 1170px !important;
}
.home-video-popup .modal-content {
  border: none;
  border-radius: 1.5rem !important;
  background-color: #ffffff !important;
}
.home-video-popup .close-ic {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 9;
}
.home-video-popup .close-ic span {
  font-size: 1.125rem;
}
@media (max-width: 1440.81px) {
  .home-video-popup .close-ic {
    right: 13px;
  }
}
@media (max-width: 767.81px) {
  .home-video-popup .close-ic {
    right: -10px;
    top: -10px;
    background: #ffffff;
    box-shadow: 4px 4px 32px 12px rgba(0, 0, 0, 0.05);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50rem;
  }
}
@media (max-width: 767.81px) {
  .home-video-popup .close-ic span {
    font-size: 0.75rem;
  }
}
.home-video-popup .video-wrap {
  background-color: #222222;
  border-radius: 1.5rem 0 0 1.5rem;
  height: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 767.81px) {
  .home-video-popup .video-wrap {
    background-color: #ffffff;
    padding: 0.9375rem 0.9375rem 0rem 0.9375rem;
    border-radius: 1.5rem;
  }
}
.home-video-popup .video-wrap .video-feed {
  width: 100%;
}
@media (max-width: 767.81px) {
  .home-video-popup .video-wrap .video-feed {
    border-radius: 1.5rem;
  }
}
.home-video-popup .content-wrap {
  padding: 1.875rem 1.875rem 0rem 0rem;
  height: 856px;
  overflow-y: auto;
}
@media (max-width: 1540.81px) {
  .home-video-popup .content-wrap {
    height: 850px;
  }
}
@media (max-width: 1400.81px) {
  .home-video-popup .content-wrap {
    height: 620px;
  }
}
@media (max-width: 767.81px) {
  .home-video-popup .content-wrap {
    padding: 0.9375rem;
    height: 100%;
  }
}
.home-video-popup .video-title {
  margin: 1.25rem 0rem 1.25rem 0rem;
}
@media (max-width: 767.81px) {
  .home-video-popup .video-title {
    margin: 0rem 0rem 1.25rem 0rem;
  }
}
.home-video-popup .video-title h3 {
  font-size: 1.375rem;
  line-height: 1.875rem;
  font-weight: 700;
  color: #222222;
  letter-spacing: -0.025rem;
  margin-bottom: 0rem;
}
@media (max-width: 767.81px) {
  .home-video-popup .video-title h3 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
.home-video-popup .video-status {
  margin-bottom: 0.625rem;
}
.home-video-popup .video-status p {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 700;
  color: #9D9D9D;
  text-transform: uppercase;
  margin-bottom: 0rem;
}
.home-video-popup .in-info-wrap {
  margin-bottom: 0.625rem;
}
.home-video-popup .in-info-wrap p {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 700;
  color: #9D9D9D;
  text-transform: uppercase;
  margin-bottom: 0.3125rem;
}
.home-video-popup .in-info-wrap a {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 700;
}
.home-video-popup .tag-info {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.625rem;
}
.home-video-popup .tag-info .tag-item {
  color: #ffffff;
  font-size: 0.8125rem;
  line-height: 1.375rem;
  font-weight: 600;
  letter-spacing: -0.001875rem;
  border-radius: 0.25rem;
  padding: 0.25rem 0.625rem;
  margin: 0rem 0.625rem 0.625rem 0rem;
}
.home-video-popup .tag-info .tag-item.bg-light-gray {
  background-color: #EFEFEF;
  color: #222222;
}
.home-video-popup .tag-info .tag-item.series-tag {
  padding: 0;
  display: flex;
}
.home-video-popup .tag-info .tag-item.series-tag .series {
  padding: 0.25rem 0.625rem;
}
.home-video-popup .tag-info .tag-item.series-tag .dollar {
  background-color: #CFD7E8;
  color: #0E378C;
  padding: 0.25rem 0.625rem;
  border-radius: 0 4px 4px 0;
}
.home-video-popup .description {
  padding-right: 0.9375rem;
  max-height: 112px;
  overflow: scroll;
}
.home-video-popup .description p {
  margin-bottom: 0.625rem;
}
.home-video-popup .video-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.875rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #EFEFEF;
}
.home-video-popup .video-action ul {
  display: flex;
  align-items: center;
}
.home-video-popup .video-action ul li {
  margin-right: 1.875rem;
}
.home-video-popup .video-action ul li:last-child {
  margin-right: 0;
}
.home-video-popup .video-action ul li a {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 600;
  color: #222222;
  letter-spacing: -0.0075rem;
  display: flex;
  align-items: center;
}
.home-video-popup .video-action ul li a span {
  font-size: 1.125rem;
  margin-right: 0.625rem;
}
.home-video-popup .video-action ul li a:hover, .home-video-popup .video-action ul li a.selected {
  color: #0E378C;
}
.home-video-popup .video-action ul li a:hover span, .home-video-popup .video-action ul li a.selected span {
  color: #0E378C;
}
.home-video-popup .video-action ul li a:hover span::before, .home-video-popup .video-action ul li a.selected span::before {
  color: #0E378C;
}
.home-video-popup .video-action .favorite a span {
  font-size: 1.125rem;
}
.home-video-popup .video-action .favorite a:hover span {
  color: #0E378C;
}
.home-video-popup .video-action .favorite a:hover span::before {
  color: #0E378C;
}
.home-video-popup .video-action .favorite .share-ic {
  margin-right: 1.875rem;
}
.home-video-popup .follow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #EFEFEF;
}
.home-video-popup .follow .follow-info {
  display: flex;
  align-items: center;
  margin-bottom: 0.9375rem;
}
@media (max-width: 1200.81px) {
  .home-video-popup .follow .follow-info {
    width: 100%;
  }
}
.home-video-popup .follow .follow-info .profile-img {
  flex: 0 0 auto;
}
.home-video-popup .follow .follow-info .profile-img img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50rem;
}
.home-video-popup .follow .follow-info .profile-detail {
  margin-left: 1.25rem;
}
.home-video-popup .follow .follow-info .profile-detail h5 {
  font-size: 1.0625rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: #0E378C;
  letter-spacing: -0.01375rem;
  margin-bottom: 0rem;
}
.home-video-popup .follow .follow-info .profile-detail span {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 700;
  color: #9D9D9D;
  text-transform: uppercase;
  margin-bottom: 0.125rem;
}
.home-video-popup .follow .follow-info .profile-detail p {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
  color: #222222;
  margin-bottom: 0;
}
@media (max-width: 1200.81px) {
  .home-video-popup .follow .follow-btn {
    width: 100%;
    margin-top: 0.9375rem;
  }
}
.home-video-popup .follow .follow-btn a {
  flex: 0 0 auto;
}
.home-video-popup .follow .follow-btn span {
  font-size: 0.75rem;
  margin-right: 0.625rem;
}
.home-video-popup .follow .follow-btn span:before {
  color: #ffffff;
}
.home-video-popup .follow .follow-btn .btn:hover span:before {
  color: #0E378C;
}
.home-video-popup .comment-box {
  padding-top: 1.25rem;
  padding-bottom: 2.5rem;
}
@media (max-width: 1440.81px) {
  .home-video-popup .comment-box {
    padding-bottom: 4.0625rem;
  }
}
.home-video-popup .comment-box .dropdown-menu {
  box-shadow: 0 2px 4px 5px #EFEFEF;
  margin-top: 0.5rem;
}
.home-video-popup .comment-box .dropdown-menu:before {
  display: none;
}
.home-video-popup .comment-box .comment-header a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-video-popup .comment-box .comment-header a .comment-text {
  font-size: 0.8125rem;
  line-height: 1.375rem;
  font-weight: 600;
  color: #222222;
  display: flex;
  align-items: center;
}
.home-video-popup .comment-box .comment-header a .comment-text span {
  font-size: 1rem;
  margin-right: 0.3125rem;
}
.home-video-popup .comment-box .comment-header a .comment-text span::before {
  color: #222222;
}
.home-video-popup .comment-box .comment-header a .toogle-arrow span {
  font-size: 0.625rem;
  color: #222222;
}
.home-video-popup .comment-box .comments-view {
  display: none;
}
.home-video-popup .comment-box .comments-view.signal-commetns-view {
  display: block;
}
.home-video-popup .comment-box .comments-view .comments-item {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border-bottom: 1px solid #EFEFEF;
  padding-bottom: 1rem;
  margin-top: 1.25rem;
}
.home-video-popup .comment-box .comments-view .comments-item .profile-img {
  flex: 0 0 auto;
}
.home-video-popup .comment-box .comments-view .comments-item .profile-img img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50rem;
}
.home-video-popup .comment-box .comments-view .comments-item .comments-detail {
  margin-left: 0.625rem;
}
.home-video-popup .comment-box .comments-view .comments-item .comments-detail h5 {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 600;
  color: #0E378C;
  letter-spacing: -0.0075rem;
  margin-bottom: 0;
}
.home-video-popup .comment-box .comments-view .comments-item .comments-detail p {
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-weight: 400;
  color: #222222;
  letter-spacing: -0.001875rem;
  margin-bottom: 0;
}
.home-video-popup .comment-box .comments-view .comments-item .comments-detail .video-action {
  padding-bottom: 0;
  margin-top: 0.9375rem;
  border-bottom: none;
}
.home-video-popup .comment-box .add-comments {
  display: flex;
  align-items: center;
  padding: 0.9375rem 0rem;
  position: absolute;
  bottom: 0;
  width: calc(100% - 60px);
  background: #ffffff;
}
@media (max-width: 767.81px) {
  .home-video-popup .comment-box .add-comments {
    bottom: 15px;
  }
}
.home-video-popup .comment-box .add-comments .profile-img {
  flex: 0 0 auto;
}
.home-video-popup .comment-box .add-comments .profile-img img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50rem;
}
.home-video-popup .comment-box .add-comments .comment-input {
  width: calc(100% - 40px);
  margin-left: 0.625rem;
  display: flex;
  align-items: center;
}
.home-video-popup .comment-box .add-comments .comment-input input {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: #222222;
  letter-spacing: -0.0075rem;
  border: none;
  width: 99%;
  padding: 0.5625rem 1rem;
}
.home-video-popup .comment-box .add-comments .send-btn {
  margin-left: 0.625rem;
  flex: 0 0 auto;
}
.home-video-popup .comment-box .add-comments .send-btn a {
  background: #0E378C;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50rem;
}
.home-video-popup .comment-box .add-comments .send-btn span {
  color: #ffffff;
  font-size: 0.875rem;
}
.home-video-popup .comment-box .add-comments-in-replay {
  display: flex;
  align-items: center;
  padding: 0.9375rem 0rem;
  position: static;
  bottom: 0;
  width: calc(100% - 60px);
  background: #ffffff;
  margin-left: 1.25rem;
}
@media (max-width: 767.81px) {
  .home-video-popup .comment-box .add-comments-in-replay {
    bottom: 15px;
  }
}
.home-video-popup .comment-box .add-comments-in-replay .profile-img {
  flex: 0 0 auto;
}
.home-video-popup .comment-box .add-comments-in-replay .profile-img img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50rem;
}
.home-video-popup .comment-box .add-comments-in-replay .comment-input {
  width: calc(100% - 55px);
  margin-left: 0.625rem;
  display: flex;
  align-items: center;
}
.home-video-popup .comment-box .add-comments-in-replay .comment-input input {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: #222222;
  letter-spacing: -0.0075rem;
  border: none;
  padding: 0.5625rem 1rem;
}
@media (max-width: 375.81px) {
  .home-video-popup .comment-box .add-comments-in-replay .comment-input input {
    width: 140px;
  }
}
.home-video-popup .comment-box .add-comments-in-replay .send-btn {
  margin-left: 0.625rem;
  flex: 0 0 auto;
}
.home-video-popup .comment-box .add-comments-in-replay .send-btn a {
  background: #0E378C;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50rem;
}
.home-video-popup .comment-box .add-comments-in-replay .send-btn span {
  color: #ffffff;
  font-size: 0.875rem;
}
@media (max-width: 767.81px) {
  .home-video-popup .comments-fullview {
    position: relative;
  }
}
.home-video-popup .comments-fullview .comment-box {
  bottom: 0;
  top: 0;
  background: #ffffff;
  padding-right: 1.875rem;
  border-radius: 0 1.5rem 1.5rem 0;
  padding-top: 1.875rem;
  z-index: 999;
  width: 100%;
  padding-bottom: 0rem;
  max-height: 440px;
  overflow: scroll;
}
@media (max-width: 1400.81px) {
  .home-video-popup .comments-fullview .comment-box {
    max-height: 290px;
    padding-bottom: 3rem;
  }
}
@media (max-width: 767.81px) {
  .home-video-popup .comments-fullview .comment-box {
    padding-top: 0.9375rem;
    padding-right: 0.9375rem;
    bottom: 15px;
    width: calc(100% - 15px);
  }
}
.home-video-popup .comments-fullview .comment-box .dropdown-box {
  margin-left: auto;
  margin-right: 0.9375rem;
}
.home-video-popup .comments-fullview .comment-box .comment-header {
  padding-bottom: 1.25rem;
}
.home-video-popup .comments-fullview .comment-box .add-comments {
  width: calc(100% - 30px);
}
@media (max-width: 767.81px) {
  .home-video-popup .comments-fullview .comment-box .add-comments {
    width: calc(100% - 15px);
    bottom: 0;
  }
}
.home-video-popup .comments-fullview .comment-box .comment-input {
  width: calc(100% - 80px);
}
.home-video-popup .comments-fullview .comment-box .comments-view {
  height: calc(100% - 110px);
  display: block;
}
.home-video-popup .comments-fullview .comment-box .comments-view .icon-dots:before {
  color: #9D9D9D;
}
.home-video-popup .comments-fullview .comment-box .inner-comments-item {
  display: flex;
  padding: 0.9375rem 0rem 0rem 0.9375rem;
}
.home-video-popup .comments-fullview .comment-box .toogle-arrow {
  transform: rotate(180deg);
}
.home-video-popup .comments-fullview .follow, .home-video-popup .comments-fullview .description, .home-video-popup .comments-fullview .video-status {
  display: none;
}
.home-video-popup .comments-fullview .signal-commetns-view {
  display: none !important;
}

.view-more-comments a {
  color: #222222;
  position: relative;
  top: 15px;
}

.mobile-profile-sidebar {
  display: none;
}
@media (max-width: 991.9px) {
  .mobile-profile-sidebar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 85px;
    right: auto;
    left: 0;
    background-color: #ffffff;
    border-radius: 0.75rem;
    padding: 0.625rem 0.625rem;
    height: 36px;
    width: 44px;
  }
}

.profile-sidebar.profile-sidebar-in {
  left: 0;
}
@media (max-width: 991.9px) {
  .profile-sidebar {
    background: #EFEFEF;
    position: fixed;
    left: -100%;
    top: 70px;
    z-index: 1000;
    padding: 0.9375rem;
    max-width: 370px;
    overflow-y: auto;
    height: calc(100% - 70px);
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
  }
}
@media (max-width: 576.81px) {
  .profile-sidebar {
    max-width: 100%;
  }
}
.profile-sidebar .mobile-profile-close {
  display: none;
}
@media (max-width: 991.9px) {
  .profile-sidebar .mobile-profile-close {
    display: block;
  }
}
.profile-sidebar .mobile-profile-close .sidebar-close {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EFEFEF;
  border-radius: 50rem;
  position: absolute;
  top: 5px;
  right: 5px;
  box-shadow: 4px 4px 32px 12px rgba(0, 0, 0, 0.05);
}
.profile-sidebar .mobile-profile-close .sidebar-close span {
  font-size: 0.875rem;
}

.tag-item {
  color: #ffffff;
  font-size: 0.8125rem;
  line-height: 1.375rem;
  font-weight: 600;
  letter-spacing: -0.001875rem;
  border-radius: 0.25rem;
  padding: 0.25rem 0.625rem;
  margin: 0rem 0.625rem 0.625rem 0rem;
}
.tag-item.bg-light-gray {
  background-color: #EFEFEF;
  color: #222222;
}
.tag-item.series-tag {
  padding: 0;
  display: flex;
}
.tag-item.series-tag .series {
  padding: 0.25rem 0.625rem;
}
.tag-item.series-tag .dollar {
  background-color: #CFD7E8;
  color: #0E378C;
  padding: 0.25rem 0.625rem;
  border-radius: 0 4px 4px 0;
}

.add-bar {
  max-width: 315px;
}
.add-bar .img-wrap {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}
.add-bar .img-wrap:after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.add-bar .tag {
  width: 35px;
  height: 30px;
  background-color: rgba(34, 34, 34, 0.2);
  font-weight: 600;
  color: #ffffff;
  border-radius: 4px;
  padding: 0rem 0.5625rem;
  display: inline-block;
  line-height: 30px;
  font-size: 0.8125rem;
  text-align: center;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
}
.add-bar .add-info {
  padding-bottom: 2.625rem;
}
.add-bar .add-info h6 {
  font-size: 0.9375rem;
  font-weight: 600;
  padding-top: 1.25rem;
  line-height: 1.47;
}

.select-favorite-collection .identity-modal-card .profile-group-wrap .profile-media .media-body .selected-icon {
  top: 0;
}
.select-favorite-collection .card .add-indo-btn-wrap {
  padding: 0rem 1.875rem 0.75rem 0rem;
  margin-top: 1.5625rem;
}
.select-favorite-collection .card .add-indo-btn-wrap .btn {
  font-size: 1rem;
}

.reply-box {
  padding-left: 20px;
}
.reply-box .add-comments-in-replay {
  margin-left: 0 !important;
}
.reply-box .inner-comments-item {
  padding-left: 0 !important;
}

.my-profile-layout {
  padding-top: 1.875rem;
}
.my-profile-layout .coneection-wrap .imgbox, .my-profile-layout .coneection-wrap .name-wrap {
  cursor: pointer;
}
.my-profile-layout .interests-box .interests-details .img-box, .my-profile-layout .interests-box .interests-details .content-info {
  cursor: pointer;
}
.my-profile-layout .interests-box .interests-details .content-info .d-flex .icon-Icon-green-tick {
  line-height: 22px;
}
.my-profile-layout .common-tabs {
  margin-top: 1.875rem;
}
.my-profile-layout .common-tabs .tab-nav-header .tab-container .nav-tabs {
  margin-bottom: 0.625rem;
}

.card.card-padding-0 {
  padding: 0;
}
.card .main-profile-info {
  position: relative;
}
.card .main-profile-info .profile-details {
  display: flex;
  align-items: center;
  padding: 1.875rem;
  position: relative;
}
@media (max-width: 480.81px) {
  .card .main-profile-info .profile-details {
    flex-direction: column;
  }
}
.card .main-profile-info .profile-details .profile-progress {
  width: 8.75rem;
  height: 8.75rem;
  margin-right: 1.875rem;
}
@media (max-width: 480.81px) {
  .card .main-profile-info .profile-details .profile-progress {
    margin: 0 auto 1.5625rem;
  }
}
.card .main-profile-info .profile-details .profile-contact {
  position: relative;
  width: calc(100% - 170px);
  text-align: left;
}
@media (max-width: 480.81px) {
  .card .main-profile-info .profile-details .profile-contact {
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }
}
.card .main-profile-info .profile-details .profile-contact h4 {
  font-size: 1.375rem;
}
.card .main-profile-info .profile-details .edit-btn {
  position: absolute;
  right: 1.875rem;
  top: 1.25rem;
  font-size: 0.8125rem;
  color: #0E378C;
}
@media (max-width: 480.81px) {
  .card .main-profile-info .profile-details .edit-btn {
    right: 1.875rem;
    top: 0.75rem;
  }
}
.card .main-profile-info .profile-details .edit-btn span {
  margin-right: 0.3125rem;
}
.card .main-profile-info .profile-details .edit-btn span::before {
  color: #0E378C;
}
.card .main-profile-info .profile-complete {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background-color: rgba(14, 55, 140, 0.05);
  padding: 1.875rem;
}
@media (max-width: 991.9px) {
  .card .main-profile-info .profile-complete {
    text-align: center;
    justify-content: center;
  }
}
.card .main-profile-info .profile-complete p {
  margin-bottom: 0;
  font-size: 0.8125rem;
}
@media (max-width: 991.9px) {
  .card .main-profile-info .profile-complete p {
    margin-bottom: 1.25rem;
  }
}
.card .main-profile-info .profile-complete .btn {
  margin-left: 0.9375rem;
}
@media (max-width: 991.9px) {
  .card .main-profile-info .profile-complete .btn {
    margin: 0 auto;
  }
}
.card .info {
  position: relative;
  padding-right: 1.875rem;
}
.card .info h5 {
  margin-bottom: 1.25rem;
}
.card .info p {
  font-size: 0.9375rem;
}
.card .info p:last-child {
  margin-bottom: 0 !important;
}
.card .info .edit-btn {
  position: absolute;
  right: 0px;
  top: 0px;
}
.card .edit-btn {
  padding: 0;
  min-height: inherit;
  min-width: inherit;
}
.card .edit-btn .icon-icon-edit::before {
  color: #9D9D9D;
}
.card .edit-btn .icon-icon-edit:hover::before {
  color: #0E378C;
}
.card .inner-info {
  padding: 1.875rem;
}
.card .inner-info .experience-info {
  padding: 1.25rem 0;
  border-top: 1px solid rgba(34, 34, 34, 0.2);
}
.card .inner-info .experience-info:last-child {
  padding-bottom: 0;
}
.card .inner-info .experience-info:first-child {
  border-top: none !important;
  padding-top: 0;
}
.card .inner-info .title-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}
.card .inner-info .title-wrap h5 {
  margin-bottom: 0;
}
.card .inner-info .title-wrap a {
  font-weight: 600;
}
.card .inner-info .imgbox {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.75rem;
  overflow: hidden;
  position: relative;
  background-color: #EFEFEF;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card .inner-info .imgbox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.card .inner-info .imgbox span::before {
  color: #9D9D9D;
}
.card .inner-info .item {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border-top: 1px solid rgba(34, 34, 34, 0.2);
  padding: 1.25rem 0;
}
.card .inner-info .item:last-child {
  padding-bottom: 0;
}
.card .inner-info .item:first-child {
  border-top: none !important;
  padding-top: 0;
}
.card .inner-info .item .imgbox {
  margin-right: 0.625rem;
}
.card .inner-info .item .info-wrap {
  width: calc(100% - 60px);
  position: relative;
  padding-right: 1.875rem;
}
.card .inner-info .item .info-wrap p {
  text-align: left;
}
.card .inner-info .item .info-wrap .edit-btn {
  position: absolute;
  right: 0;
  top: 0;
}
.card .inner-info .item .info-wrap .item-info {
  margin-bottom: 0.9375rem;
  position: relative;
}
.card .inner-info .item .info-wrap .item-info .tooltip-icon::before {
  color: #47B872;
}
.card .inner-info .item .info-wrap .item-info .edit-btn {
  position: absolute;
  right: -30px;
  top: 0;
}
.card .inner-info .item .info-wrap .item-info::before {
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  position: absolute;
  left: -2.375rem;
  top: 0.5rem;
  background-color: #EFEFEF;
  border-radius: 100%;
}
.card .inner-info .item .info-wrap .item-info::after {
  content: "";
  width: 0.125rem;
  height: 100%;
  position: absolute;
  left: -2.25rem;
  top: 1.125rem;
  background-color: #EFEFEF;
  border-radius: 0.25rem;
}
.card .inner-info .item .info-wrap .item-info:first-child::before {
  display: none;
}
.card .inner-info .item .info-wrap .item-info:first-child::after {
  display: none;
}
.card .inner-info .item .info-wrap .item-info:last-child::after {
  display: none;
}
.card .inner-info .item .info-wrap h6 {
  margin-bottom: 0;
}
.card .inner-info .item .info-wrap h6 span {
  margin-left: 0.125rem;
  position: relative;
  top: 2px;
  line-height: normal;
}
.card .inner-info .item .info-wrap .tag-span {
  line-height: 1.125rem;
}
.card .inner-info .item .info-wrap p {
  margin-bottom: 0;
  font-size: 0.8125rem;
}
.card .inner-info .item .extra-info {
  font-size: 0.75rem;
  margin-top: 0.625rem;
  margin-bottom: 0;
  word-break: break-all;
}
.card .add-indo-btn-wrap {
  padding: 0.75rem 1.875rem;
  border-top: 1px solid rgba(34, 34, 34, 0.2);
}
.card .add-indo-btn-wrap .btn {
  padding: 0;
  min-height: inherit;
  min-width: inherit;
  display: flex;
  align-items: center;
  color: #0E378C;
  font-size: 0.8125rem;
}
.card .add-indo-btn-wrap .btn:hover {
  color: #222222;
}
.card .add-indo-btn-wrap .btn:hover span::before {
  color: #222222;
}
.card .add-indo-btn-wrap .btn span {
  margin-right: 0.625rem;
  font-size: 0.8125rem;
}
.card .add-indo-btn-wrap .btn span::before {
  color: #0E378C;
}

.exp-item-wrap {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 1.25rem 0rem;
  position: relative;
  text-align: left;
}
.exp-item-wrap h6 {
  margin-bottom: 0;
}
.exp-item-wrap h6 .tooltip-icon::before {
  color: #47B872;
}
.exp-item-wrap .tag-span {
  line-height: 1.125rem;
}
.exp-item-wrap .imgbox {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.75rem;
  overflow: hidden;
  position: relative;
  background-color: #EFEFEF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.625rem;
}
.exp-item-wrap .imgbox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.exp-item-wrap .imgbox span::before {
  color: #9D9D9D;
}
.exp-item-wrap .info-wrap {
  width: calc(100% - 60px);
  position: relative;
  padding-right: 1.875rem;
}
.exp-item-wrap .edit-btn {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.8125rem;
  color: #0E378C;
  padding: 0;
  min-height: inherit;
  min-width: inherit;
}
@media (max-width: 480.81px) {
  .exp-item-wrap .edit-btn {
    right: 1.875rem;
    top: 0.75rem;
  }
}
.exp-item-wrap .edit-btn:hover span {
  margin-right: 0.3125rem;
}
.exp-item-wrap .edit-btn:hover span::before {
  color: #0E378C;
}
.exp-item-wrap .edit-btn span {
  margin-right: 0.3125rem;
}
.exp-item-wrap .edit-btn span::before {
  color: #9D9D9D;
}

/* Modal */
.my-favorite-modal {
  max-width: 710px !important;
}
.my-favorite-modal .btnbox .btn-lg, .my-favorite-modal .btnbox .btn-group-lg > .btn {
  max-width: 370px;
}

.services-search-box .coneection-wrap {
  margin-bottom: 0rem;
}

.add-profile-details-layout ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content h2 {
  padding: 0rem 2.5rem;
  margin-bottom: 0.5rem;
}
@media (min-width: 1200.91px) {
  .add-profile-details-layout ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content p {
    font-size: 1.125rem;
  }
}
.add-profile-details-layout ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content {
  text-align: center;
}
.add-profile-details-layout ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content form {
  max-width: 570px;
  margin: 0 auto;
}
.add-profile-details-layout ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content form textarea {
  min-height: 6.875rem;
  resize: vertical;
}
.add-profile-details-layout ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content .added-info-wrap .btnbox {
  max-width: 570px;
  margin: 3.75rem auto 0rem;
}
.add-profile-details-layout ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content .btnbox .btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-profile-details-layout ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content .btnbox .btn span {
  margin-right: 0.625rem;
}
.add-profile-details-layout ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content .btnbox .btn.primary-link:hover {
  text-decoration: none;
}
.add-profile-details-layout ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content .no-content-text {
  display: block;
  margin-bottom: 3.125rem;
}
.add-profile-details-layout ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content .exp-item-wrap {
  max-width: 570px;
  margin: 0 auto;
}
.add-profile-details-layout .upload-img iframe {
  width: 570px;
  height: 370px;
  border: 0;
  border-radius: 0.75rem;
  background-image: url(../../images/iframe-bg.png);
}
@media (max-width: 767.81px) {
  .add-profile-details-layout .upload-img iframe {
    width: 100%;
    height: 270px;
  }
}

.teaser-preview-modal .upload-img iframe {
  width: 570px;
  height: 460px;
  border: 0;
  border-radius: 0.75rem;
  background-image: url(../../images/iframe-bg.png);
}
@media (max-width: 767.81px) {
  .teaser-preview-modal .upload-img iframe {
    width: 100%;
    height: 270px;
  }
}

/* New Wizard */
aw-wizard {
  position: relative;
  display: block;
  margin: 40px 15px 0;
  width: calc(100% - 30px);
  padding: 0;
  border-radius: 0.25rem !important;
}
aw-wizard .exp-item-wrap {
  max-width: 570px;
  margin: 0 auto;
}
aw-wizard .exp-item-wrap .info-wrap p {
  text-align: left;
}
aw-wizard aw-wizard-navigation-bar.horizontal.small ul.steps-indicator.steps-indicator li:not(:last-child):after {
  top: 0;
  z-index: 0;
  height: 2px;
  background-color: #D6D6D6;
}
aw-wizard aw-wizard-navigation-bar.horizontal.small ul.steps-indicator.steps-indicator li:after {
  content: "";
  display: block;
  height: 4px;
  content: "";
  display: block;
  height: 2px;
  width: calc(100% - 9px);
  background: #D6D6D6;
  position: absolute;
  left: calc(-50% + 7px);
  top: 0px !important;
  border-radius: 0.75rem 0rem 0rem 0.75rem;
  z-index: -1;
}
aw-wizard aw-wizard-navigation-bar.horizontal.small ul.steps-indicator.steps-indicator li:first-child::after {
  width: 50%;
  left: 0;
  border-radius: 0.75rem 0rem 0rem 0.75rem;
}
aw-wizard aw-wizard-navigation-bar.horizontal.small ul.steps-indicator.steps-indicator li:last-child::after {
  width: 140%;
  border-radius: 0.75rem 0rem 0rem 0.75rem;
}
aw-wizard aw-wizard-navigation-bar.horizontal.small ul.steps-indicator.steps-indicator li.current::after {
  background: #0E378C;
  height: 4px;
}
aw-wizard aw-wizard-navigation-bar.horizontal.small ul.steps-indicator.steps-indicator li.active:after, aw-wizard aw-wizard-navigation-bar.horizontal.small ul.steps-indicator.steps-indicator li.done:after {
  background: #0E378C;
  height: 4px;
  top: 20px;
}
@media (max-width: 991.9px) {
  aw-wizard aw-wizard-navigation-bar.horizontal.small ul.steps-indicator.steps-indicator li.active:after, aw-wizard aw-wizard-navigation-bar.horizontal.small ul.steps-indicator.steps-indicator li.done:after {
    top: 7px;
  }
}
aw-wizard aw-wizard-navigation-bar.horizontal.small ul {
  position: relative;
  background: transparent !important;
  border: none;
  list-style: none;
  margin-bottom: 2.5rem;
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
}
aw-wizard aw-wizard-navigation-bar.horizontal.small ul li {
  margin-left: 0 !important;
  margin-bottom: 0px !important;
  position: relative;
  width: 100%;
  flex-shrink: 1;
}
aw-wizard aw-wizard-navigation-bar.horizontal.small ul li.active .step-indicator, aw-wizard aw-wizard-navigation-bar.horizontal.small ul li.done .step-indicator {
  background-color: #0E378C !important;
  color: #ffffff;
  border-color: #0E378C;
}
aw-wizard aw-wizard-navigation-bar.horizontal.small ul li a .step-indicator {
  content: "";
  width: 2rem !important;
  height: 2rem !important;
  padding: 0.3125rem !important;
  background-color: #D6D6D6 !important;
  border: 2px solid #D6D6D6;
  display: block;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 7px;
  transform: translateX(-50%);
  z-index: 1;
}
aw-wizard aw-wizard-navigation-bar.horizontal.small ul li a .label {
  color: #222222 !important;
  font-size: 1rem !important;
  position: relative;
  font-weight: 400 !important;
  margin-top: 15px;
  text-transform: capitalize !important;
}
@media (max-width: 991.9px) {
  aw-wizard aw-wizard-navigation-bar.horizontal.small ul li a .label {
    display: none !important;
  }
}
aw-wizard aw-wizard-navigation-bar.horizontal.small ul li.current .step-indicator, aw-wizard aw-wizard-navigation-bar.horizontal.small ul li.editing .step-indicator {
  content: "";
  width: 2rem !important;
  height: 2rem !important;
  padding: 0.3125rem !important;
  background-color: #ffffff !important;
  border-color: #0E378C;
  color: #0E378C !important;
}
aw-wizard .wizard-steps {
  max-width: 970px;
  width: 100%;
  margin: 50px auto;
  padding: 6.25rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  border-radius: 1.5rem;
  background-color: #ffffff;
}
@media (max-width: 991.9px) {
  aw-wizard .wizard-steps {
    padding: 3.75rem;
  }
}
@media (max-width: 767.81px) {
  aw-wizard .wizard-steps {
    padding: 1.875rem;
  }
}
@media (max-width: 414.81px) {
  aw-wizard .wizard-steps {
    padding: 0.9375rem;
  }
}
aw-wizard .wizard-steps h2 {
  padding: 0rem 2.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}
aw-wizard .wizard-steps p {
  text-align: center;
  margin-bottom: 1.875rem;
  word-break: break-word;
}
@media (min-width: 1200.91px) {
  aw-wizard .wizard-steps p {
    font-size: 1.125rem;
  }
}
aw-wizard .wizard-steps .btnbox {
  display: flex;
}
aw-wizard .wizard-steps form {
  max-width: 570px;
  margin: 0 auto;
}
aw-wizard .wizard-steps form p {
  text-align: left;
}
aw-wizard .wizard-steps form .btnbox {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 767.81px) {
  aw-wizard .wizard-steps form .btnbox {
    justify-content: center !important;
  }
}
aw-wizard .wizard-steps form .btnbox .btn {
  margin: 5px 10px;
  min-width: 170px;
}
aw-wizard .wizard-steps form .btnbox .btn span {
  margin-right: 8px !important;
}
aw-wizard .wizard-steps form .btnbox .btn .btn-md {
  max-width: 185px;
  width: 100%;
}

.my-pages-section {
  margin-top: 1.875rem;
}
.my-pages-section .card {
  height: calc(100% - 10px);
}
.my-pages-section .card.pages-card {
  max-width: 970px;
  margin: 0 auto;
  height: inherit;
  padding: 6.25rem 4.375rem;
}
@media (max-width: 576.81px) {
  .my-pages-section .card.pages-card {
    padding: 4.0625rem 2.5rem;
  }
}
@media (max-width: 576.81px) {
  .my-pages-section .card.pages-card {
    padding: 2.5rem 0.9375rem;
  }
}
.my-pages-section .card.pages-card h2 {
  text-align: center;
  margin-bottom: 0.625rem;
}
.my-pages-section .card.pages-card p {
  text-align: center;
  margin-bottom: 2.8125rem;
}
@media (min-width: 1200.91px) {
  .my-pages-section .card.pages-card p {
    font-size: 1.125rem;
  }
}
.my-pages-section .card.pages-card .type-list {
  max-width: 410px;
  margin: 0 auto 2.8125rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.my-pages-section .card.pages-card .btnbox {
  display: flex;
  width: 100%;
  justify-content: center;
}
.my-pages-section .card.pages-card .btnbox .btn.btn-lg, .my-pages-section .card.pages-card .btnbox .btn-group-lg > .btn {
  max-width: 370px;
  width: 100%;
}
.my-pages-section .card .tag-comman {
  position: absolute;
  right: 0;
  top: 0;
}
.my-pages-section .upload-video-form {
  height: 100%;
}
.my-pages-section .upload-video-form .upload-form {
  height: 100%;
}
.my-pages-section .upload-video-form .dropzone {
  transition: border-color 200ms ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #EFEFEF;
  position: relative;
  flex-direction: column;
  padding: 1.875rem;
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  background-color: #ffffff;
  text-align: center;
  margin: 0 auto;
}
.my-pages-section .upload-video-form .dropzone:hover {
  cursor: pointer;
  border-color: #0E378C;
}
.my-pages-section .upload-video-form .dropzone:hover span::before {
  color: #0E378C;
}
.my-pages-section .upload-video-form .dropzone.active {
  border-color: #9D9D9D;
}
.my-pages-section .upload-video-form .dropzone .upload-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.my-pages-section .upload-video-form .dropzone span {
  color: #9D9D9D;
  font-size: 2.375rem;
}
.my-pages-section .upload-video-form .dropzone p {
  font-size: 1rem;
  line-height: 1.625rem;
  color: #222222;
  font-weight: 600;
  letter-spacing: -0.015625rem;
  margin-top: 1.25rem;
  margin-bottom: 0;
}

.mypage-box {
  position: relative;
}
.mypage-box .profile-img {
  text-align: center;
}
.mypage-box .profile-img img {
  width: 80px;
  height: 80px;
  border-radius: 50rem;
  object-fit: cover;
}
.mypage-box .profile-img .tag-status {
  margin-top: -0.75rem;
  margin-bottom: 0.625rem;
}
.mypage-box .profile-img .tag-status span {
  font-size: 0.6875rem;
  line-height: 1rem;
  color: #ffffff;
  font-weight: 700;
  letter-spacing: 0.00625rem;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.25rem 0.625rem;
  border-radius: 0.25rem;
}
.mypage-box .profile-img .tag-status span.bg-gray {
  background-color: #9D9D9D;
}
.mypage-box .profile-info {
  text-align: center;
}
.mypage-box .profile-info .d-flex .icon-Icon-green-tick {
  line-height: 30px;
  margin-left: 5px;
  padding-left: 0;
}
.mypage-box .profile-info h3 {
  font-size: 1rem;
  line-height: 1.625rem;
  color: #222222;
  font-weight: 600;
  letter-spacing: -0.015625rem;
  margin-bottom: 0.3125rem;
  cursor: pointer;
}
.mypage-box .profile-info h3:hover {
  color: #0E378C;
}
.mypage-box .profile-info h3 span {
  font-size: 0.8125rem;
  margin-left: 0.1875rem;
}
.mypage-box .profile-info p {
  font-size: 0.8125rem;
  line-height: 1.375rem;
  color: #222222;
  font-weight: 400;
  letter-spacing: -0.001875rem;
  margin-bottom: 0.3125rem;
}
.mypage-box .profile-info .status {
  color: #0E378C;
  margin-bottom: 0;
}
.mypage-box .dropdown-box {
  position: absolute;
  right: -14px;
  top: 0px;
}
@media (max-width: 767.81px) {
  .mypage-box .dropdown-box {
    right: 0;
  }
}
.mypage-box .dropdown-box .icon-Ver-action-btn {
  font-size: 1.125rem;
}
.mypage-box .dropdown-box .dropdown-menu {
  right: -29px !important;
  margin-top: 0.625rem;
}
.mypage-box .dropdown-box .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
}
.mypage-box .dropdown-box .dropdown-menu .dropdown-item:hover span::before {
  color: #0E378C;
}
.mypage-box .dropdown-box .dropdown-menu .dropdown-item span {
  margin-right: 0.75rem;
}
@media (min-width: 992px) {
  .mypage-box .dropdown-box .dropdown-menu .dropdown-item span {
    font-size: 1.125rem;
  }
}
@media (min-width: 1200.91px) {
  .mypage-box .dropdown-box .dropdown-menu .dropdown-item span {
    font-size: 1.25rem;
  }
}
.mypage-box .dropdown-box .dropdown-menu .dropdown-item span::before {
  color: #222222;
}
@media (min-width: 1200.91px) {
  .mypage-box .dropdown-box .dropdown-menu .dropdown-item span.icon-icon-edit {
    font-size: 1.1875rem;
  }
}
@media (max-width: 1200.81px) {
  .mypage-box {
    margin: 0.9375rem;
  }
}
@media (max-width: 767.81px) {
  .mypage-box {
    margin: 0.9375rem;
  }
}

.inner-card {
  background-color: rgba(239, 239, 239, 0.4);
  border-radius: 0.75rem;
  padding: 1.875rem;
  margin-bottom: 0.625rem;
}
@media (max-width: 414.81px) {
  .inner-card {
    padding: 0.9375rem;
  }
}
.inner-card .ng-select-container {
  border-radius: 0.75rem !important;
  height: 56px !important;
}
@media (max-width: 576.81px) {
  .inner-card .ng-select-container {
    height: 46px !important;
  }
}
.inner-card .search-result li {
  display: flex;
  align-items: center;
  margin-bottom: 0.9375rem;
}
.inner-card .search-result li p {
  margin: 0 !important;
  padding-left: 0.9375rem;
  font-weight: 600;
  text-align: left !important;
}
.inner-card .search-result .img-box {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
}
.inner-card .search-result .img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 0.9375rem;
}
.inner-card .inner-card-info {
  border-bottom: 2px solid #EFEFEF;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}
.inner-card .inner-card-info:last-child {
  border-bottom: none;
  padding-bottom: 0rem;
  margin-bottom: 0rem;
}
.inner-card .inner-card-info .round-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.875rem;
}
.inner-card .inner-card-info .round-title h6 {
  margin-bottom: 0;
}
.inner-card .inner-card-info .round-title .btn {
  font-size: 0.9375rem;
  padding: 0;
  min-height: inherit;
  min-width: inherit;
}
@media (max-width: 414.81px) {
  .inner-card .inner-card-info .round-title .btn {
    padding: 0.8125rem;
  }
}

.inner-card-btnbox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 414.81px) {
  .inner-card-btnbox {
    justify-content: space-around;
  }
}
.inner-card-btnbox .btn {
  font-size: 0.9375rem;
  min-height: inherit;
}

.congratulation-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.congratulation-wrap .imgbox {
  width: 320px;
  height: 320px;
  margin-bottom: 1.875rem;
  border: none;
}
@media (max-width: 480.81px) {
  .congratulation-wrap .imgbox {
    width: 100%;
    height: auto;
  }
}
.congratulation-wrap .imgbox img {
  width: 100%;
  height: auto;
}

.followers-list .member-info {
  margin-bottom: 0.9375rem;
}
.followers-list .member-img {
  padding-right: 0.9375rem;
}
.followers-list .member-img img {
  border-radius: 100%;
  min-width: 50px;
}
.followers-list .designation h6 {
  margin: 0;
}
@media (max-width: 480.81px) {
  .followers-list .btnbox {
    margin-left: 4.0625rem;
  }
}
.followers-list .btnbox .btn {
  min-height: 40px;
  font-size: 1rem;
  padding: 0.375rem 1rem;
  border-radius: 0.25rem;
}

.add-investor-modal .inner-card .inner-card-info .round-title {
  align-items: start;
}

.profile-edit-add-modal {
  max-width: 710px !important;
}
.profile-edit-add-modal .custom-checkbox {
  margin-bottom: 1.25rem;
}
.profile-edit-add-modal textarea {
  min-height: 140px;
  resize: vertical;
}
.profile-edit-add-modal .add-info-btnbox .btn {
  min-height: auto;
}
.profile-edit-add-modal .add-info-btnbox .btn:hover {
  text-decoration: none;
}
.profile-edit-add-modal .btnbox {
  flex-direction: column;
  margin-top: 2.5rem;
}
.profile-edit-add-modal .btnbox .btn-lg, .profile-edit-add-modal .btnbox .btn-group-lg > .btn {
  max-width: 370px;
}
.profile-edit-add-modal .search-result li {
  display: flex;
  align-items: center;
  margin-bottom: 0.9375rem;
}
.profile-edit-add-modal .search-result li p {
  margin: 0 !important;
  padding-left: 0.9375rem;
  font-weight: 600;
  text-align: left !important;
}
.profile-edit-add-modal .search-result .img-box {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
}
.profile-edit-add-modal .search-result .img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 0.9375rem;
}

.add-team-member-modal textarea.form-control {
  min-height: 7rem;
  resize: vertical;
}
.add-team-member-modal .radio-btn-group {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 0.3125rem;
}
.add-team-member-modal .radio-btn-group .radio-btn-wrap {
  margin: 0 2.1875rem 0.9375rem 0;
  display: flex;
  align-items: center;
}
.add-team-member-modal .radio-btn-group .radio-btn-wrap .tooltip-icon {
  position: relative;
  top: 2px;
}
.add-team-member-modal .radio-btn-group .radio-btn-wrap .normal-radio-wrap {
  margin: 0;
  margin-right: 10px;
  height: 25px;
  font-size: 1rem;
}
.add-team-member-modal .radio-btn-group .radio-btn-wrap .normal-radio-wrap .checkmark {
  border: 1px solid #9D9D9D;
}
.add-team-member-modal .checkbox-tooltip {
  margin: 0 2.1875rem 0.9375rem 0;
  display: flex;
  align-items: center;
}
.add-team-member-modal .checkbox-tooltip .tooltip-icon {
  position: relative;
  top: 0px;
}
.add-team-member-modal .checkbox-tooltip label {
  margin: 0 5px 0 0;
}
.add-team-member-modal .btnbox {
  flex-direction: column;
}
.add-team-member-modal .btnbox .btn.text-danger {
  margin-top: 1.25rem;
  text-align: center !important;
}

.checkbox-btn-group {
  display: flex;
  flex-wrap: wrap;
}
.checkbox-btn-group .custom-checkbox {
  margin: 0 1.875rem 0.9375rem 0;
}

.congratulation-wrap {
  margin: 4.375rem auto 0;
}
.congratulation-wrap .card {
  max-width: 970px;
  padding: 6.25rem 3.125rem;
}
@media (max-width: 767.81px) {
  .congratulation-wrap .card .card {
    padding: 3.125rem 1.25rem;
  }
}
.congratulation-wrap .card .btnbox {
  margin-top: 0.9375rem;
}
@media (max-width: 767.81px) {
  .congratulation-wrap .card .imgbox .imgbox {
    width: 250px;
    height: 250px;
  }
}

.my-favorites-details .left-icon-input {
  margin-bottom: 1.875rem;
}
.my-favorites-details .left-icon-input .form-control {
  background-color: #ffffff;
  box-shadow: 2px 2px 18px 8px rgba(0, 0, 0, 0.03);
}
.my-favorites-details .nav-card h5 {
  padding: 1.25rem;
  margin-bottom: 0;
}
.my-favorites-details .favorites-nav {
  border-top: 1px solid #EFEFEF;
}
.my-favorites-details .favorites-nav li a {
  border-left: 2px solid #ffffff;
  color: #222222;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.875rem 1.25rem;
  font-weight: 600;
}
.my-favorites-details .favorites-nav li a span {
  margin-left: 0.875rem;
}
.my-favorites-details .favorites-nav li a span::before {
  color: #9D9D9D;
}
.my-favorites-details .favorites-nav li.active a, .my-favorites-details .favorites-nav li:hover a {
  border-left: 2px solid #0E378C;
  color: #0E378C;
}
.my-favorites-details .favorites-nav li.active a span::before, .my-favorites-details .favorites-nav li:hover a span::before {
  color: #0E378C;
}
.my-favorites-details .fav-page-list [class*=col-] {
  margin-bottom: 1.875rem;
}
.my-favorites-details .fav-page-list .mypage-profile-card {
  padding: 1.25rem 0.9375rem;
  margin-bottom: 1.875rem;
  height: 100%;
}
.my-favorites-details .fav-page-list .mypage-profile-card .mypage-box .profile-info h3 {
  font-size: 0.9375rem;
}

.fav-network-list [class*=col-] {
  margin-bottom: 1.875rem;
}

.network-item-card {
  height: 100%;
  padding-bottom: 4.375rem;
}
.network-item-card .network-item-box {
  position: relative;
}
.network-item-card .network-item-box .profile-img {
  position: relative;
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  margin: 0 auto 0.9375rem;
}
.network-item-card .network-item-box .profile-img img {
  border-radius: 100%;
  width: 5rem;
  height: 5rem;
}
.network-item-card .network-item-box .profile-img .play-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #0E378C;
  width: 1.375rem;
  height: 1.375rem;
  min-height: inherit;
  min-width: inherit;
  font-size: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.network-item-card .network-item-box .profile-img .play-btn span {
  margin-left: 0.1875rem;
}
.network-item-card .btnbox {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 1.5625rem;
  left: 0;
  width: 100%;
}
.network-item-card .btnbox .btn {
  margin: 0.3125rem;
  min-height: inherit;
  min-width: inherit;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  font-size: 0.875rem;
}
.network-item-card .btnbox .btn.chat-btn {
  background-color: #EFEFEF;
}
.network-item-card .btnbox .btn.chat-btn span::before {
  color: #9D9D9D;
}
.network-item-card .btnbox .btn.add-btn {
  background-color: #0E378C;
}
.network-item-card .btnbox .btn.add-btn span::before {
  color: #ffffff;
}
.network-item-card .btnbox .btn.remove-btn {
  background-color: #E73945;
}
.network-item-card .btnbox .btn.remove-btn span::before {
  color: #ffffff;
}
.network-item-card .btnbox .btn.yellow-btn {
  background-color: #F3C32F;
}
.network-item-card .btnbox .btn.yellow-btn span:before {
  color: #ffffff;
  font-size: 1rem;
}

.icon-blue::before {
  color: #0E378C !important;
}

.remove-btn span {
  background-color: #E73945 !important;
}

.investment-opportunities-details {
  margin-top: 1.875rem;
}

.left-bdr-nav li a {
  border-left: 2px solid #ffffff;
  color: #222222;
  display: flex;
  align-items: center;
  padding: 0.875rem 2.1875rem 0.875rem 1.25rem;
  font-weight: 600;
}
.left-bdr-nav li a em {
  font-style: inherit;
  margin-left: 0.3125rem;
}
.left-bdr-nav li a span {
  margin-left: 0.875rem;
  position: absolute;
  right: 1.25rem;
  font-size: 0.875rem;
}
.left-bdr-nav li a span::before {
  color: #9D9D9D;
}
.left-bdr-nav li.active a, .left-bdr-nav li:hover a {
  border-left: 2px solid #0E378C;
  color: #0E378C;
}
.left-bdr-nav li.active a span::before, .left-bdr-nav li:hover a span::before {
  color: #0E378C;
}

.social-mendia-nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1.25rem;
}
.social-mendia-nav li {
  margin: 0.3125rem;
}
.social-mendia-nav li a {
  font-size: 1.25rem;
}
.social-mendia-nav li a span::before {
  color: #0E378C;
}

.inv-teaser-wrap table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}
@media (max-width: 767.81px) {
  .inv-teaser-wrap table {
    border: 0;
  }
}
.inv-teaser-wrap table.responsive-table-wrap thead {
  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  padding: 0;
}
@media (max-width: 767.81px) {
  .inv-teaser-wrap table.responsive-table-wrap thead {
    position: absolute;
    width: 1px;
    overflow: hidden;
  }
}
.inv-teaser-wrap table.responsive-table-wrap td {
  padding: 0.875rem 1.25rem;
}
@media (max-width: 767.81px) {
  .inv-teaser-wrap table.responsive-table-wrap td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
}
.inv-teaser-wrap table tr:nth-child(even) {
  background: #EFEFEF;
}
.inv-teaser-wrap table tr:nth-child(odd) {
  background: #ffffff;
}
.inv-teaser-wrap table th {
  font-size: 0.9375rem;
  padding: 0.875rem 1.25rem;
  text-align: left;
  color: #0E378C;
  font-weight: 400;
}
@media (max-width: 767.81px) {
  .inv-teaser-wrap table th {
    padding: 0.703125rem;
  }
}
.inv-teaser-wrap table tr {
  background-color: #f8f8f8;
  border: 1px solid #dddddd;
  padding: 0.703125rem;
  text-align: left;
}
@media (max-width: 767.81px) {
  .inv-teaser-wrap table tr {
    border-bottom: 1px solid #dddddd;
    display: block;
    margin-bottom: 0.703125rem;
    padding: 0;
  }
}
.inv-teaser-wrap table td {
  padding: 0.703125rem;
}
.inv-teaser-wrap table td:last-child {
  border-bottom: 0;
}
@media (max-width: 767.81px) {
  .inv-teaser-wrap table td {
    border-bottom: 1px solid #dddddd;
    display: block;
    font-size: 0.9375rem;
    text-align: right;
  }
}
@media (max-width: 767.81px) {
  .inv-teaser-wrap table.fund-use-table td {
    padding: 0.703125rem;
    display: initial;
    text-align: left;
    border-bottom: none;
  }
}
.inv-teaser-wrap table.fund-use-table tr {
  padding: 0.703125rem;
}

/* Document */
.inv-opp-document-wrap .info .document-media {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.inv-opp-document-wrap .info .document-media .imgbox {
  width: 60px;
  height: 60px;
  border-radius: 0.75rem;
  margin-right: 0.9375rem;
  border: 1px solid #EFEFEF;
  position: relative;
  background-color: #EFEFEF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}
.inv-opp-document-wrap .info .document-media .imgbox .icon-icon-pdf:before {
  color: #0E378C;
}
.inv-opp-document-wrap .info .document-media .media-body {
  width: calc(100% - 75px);
  text-align: left;
}
.inv-opp-document-wrap .info .document-media .media-body h6 {
  margin-bottom: 0;
}
.inv-opp-document-wrap .info .document-media .media-body span {
  margin-top: 0.3125rem;
  color: #9D9D9D;
  display: block;
}

/* Investors */
.investors-profile-wrap [class*=col-] {
  margin-bottom: 0.9375rem;
}

.investor-profile-card {
  border: 1px solid #EFEFEF;
  border-radius: 0.75rem;
  padding: 1.25rem 0.625rem;
  height: 100%;
}
.investor-profile-card .imgbox {
  padding: 0 !important;
  width: 50px !important;
  height: 50px !important;
  border: 0 !important;
  margin: 0 auto 0.625rem !important;
}
.investor-profile-card .imgbox .play-btn {
  width: 20px !important;
  height: 20px !important;
  min-height: inherit;
  min-width: inherit;
}
.investor-profile-card .imgbox .play-btn span {
  margin: 0 !important;
}
.investor-profile-card .profile-contact h6 {
  margin-bottom: 0.625rem !important;
}
.investor-profile-card .profile-contact h6:hover {
  color: #0E378C;
}
.investor-profile-card .profile-contact a {
  margin-bottom: 0.3125rem !important;
  margin-top: 0.625rem;
  display: block;
  font-weight: 400;
  font-size: 0.8125rem;
}
.investor-profile-card .profile-contact p {
  margin-bottom: 0.3125rem !important;
  color: #9D9D9D;
}
.investor-profile-card .profile-contact p strong {
  font-weight: 400;
  color: #222222;
}

/* Funding History */
.last-funding-round-wrpa {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.875rem;
}
@media (max-width: 767.81px) {
  .last-funding-round-wrpa {
    flex-direction: column;
  }
}
.last-funding-round-wrpa .funding-raund {
  position: relative;
  min-height: 157px;
  width: 49%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 767.81px) {
  .last-funding-round-wrpa .funding-raund {
    width: 100%;
  }
  .last-funding-round-wrpa .funding-raund:first-child {
    margin-bottom: 0.625rem;
  }
}
.last-funding-round-wrpa .funding-raund::after {
  content: "";
  width: 80%;
  height: 5px;
  border-radius: 15px;
  position: absolute;
  bottom: 0;
  left: 10%;
  margin: 0 auto;
}
.last-funding-round-wrpa .funding-raund::after {
  background-color: #E73945;
}
.last-funding-round-wrpa .funding-raund p {
  margin-bottom: 0.3125rem;
}
.last-funding-round-wrpa .last-round {
  background-color: rgba(231, 57, 69, 0.05);
  border-radius: 24px 0 0 24px;
}
@media (max-width: 767.81px) {
  .last-funding-round-wrpa .last-round {
    border-radius: 1.5rem;
  }
}
.last-funding-round-wrpa .total-funds {
  background-color: rgba(14, 55, 140, 0.05);
  border-radius: 0px 24px 24px 0px;
}
.last-funding-round-wrpa .total-funds .primary-text {
  font-size: 1.125rem;
}
.last-funding-round-wrpa .total-funds::after {
  background-color: #0E378C;
}
@media (max-width: 767.81px) {
  .last-funding-round-wrpa .total-funds {
    border-radius: 1.5rem;
  }
}

.timeline-wrap h6 {
  margin-bottom: 0.625rem;
}
.timeline-wrap .fund-data-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-left: 5px;
  background-color: rgba(14, 55, 140, 0.05);
  padding: 1.25rem 1.25rem 1.25rem 1.5625rem;
  border-radius: 0 24px 24px 0;
  min-height: 128px;
  margin-bottom: 0.625rem;
}
.timeline-wrap .fund-data-box .primary-text {
  font-size: 1.125rem;
}
.timeline-wrap .fund-data-box h6 {
  margin-bottom: 0.4375rem;
  font-size: 1.125rem;
}
.timeline-wrap .fund-data-box .series-info span {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
}
.timeline-wrap .fund-data-box .fund-target-info {
  text-align: right;
}
@media (max-width: 767.81px) {
  .timeline-wrap .fund-data-box .fund-target-info {
    text-align: left;
    margin-top: 0.9375rem;
  }
}
.timeline-wrap .fund-data-box .fund-target-info p {
  font-size: 0.9375rem;
  margin-bottom: 0.3125rem;
}
@media (max-width: 767.81px) {
  .timeline-wrap .fund-data-box .fund-target-info p {
    margin-bottom: 0.1875rem;
  }
}
.timeline-wrap .fund-data-box:before {
  content: "";
  width: 5px;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  background-color: rgba(14, 55, 140, 0.2);
  border-radius: 0.9375rem;
}
.timeline-wrap .fund-data-box:after {
  content: "";
  width: 5px;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #0E378C;
  z-index: 1;
  border-radius: 0.9375rem;
}
@media (max-width: 767.81px) {
  .timeline-wrap .fund-data-box {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}
.timeline-wrap .fund-progress-data-box:after {
  content: "";
  width: 5px;
  height: 50% !important;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #0E378C;
  z-index: 1;
  border-radius: 0.9375rem;
}

.add-investors-list ul li a {
  font-size: 1rem;
  line-height: 1.625rem;
  color: #222222;
  letter-spacing: -0.015625rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 767.81px) {
  .add-investors-list ul li a {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0.625rem;
  }
}
.add-investors-list ul li a span {
  color: #9D9D9D;
  margin-left: 0.625rem;
}
@media (max-width: 767.81px) {
  .add-investors-list ul li a span {
    margin-left: 0rem;
  }
}

.add-round-startup {
  margin-top: 1.25rem;
}

.tabset-line-ui .nav-tabs {
  border-bottom: none;
  margin: 0 -0.3125rem;
}
.tabset-line-ui .nav-tabs .nav-item {
  padding: 0 0.3125rem;
}
.tabset-line-ui .nav-tabs .nav-link {
  border: none;
  color: #ffffff;
  border-radius: 1.5rem;
  background-color: #CCCCCC;
  padding: 0;
  font-size: 0;
  line-height: 0;
  height: 5px;
  width: 100%;
  display: block;
}
.tabset-line-ui .nav-tabs .nav-link.active {
  background-color: #0E378C;
}
.tabset-line-ui .tab-content {
  margin-top: 1.875rem;
}
.tabset-line-ui .note-text {
  font-size: 0.8125rem;
  line-height: 1.375rem;
  letter-spacing: -0.001875rem;
  text-align: left;
  margin: 20px 0;
}
.tabset-line-ui .note-text p {
  margin-bottom: 0;
}
.tabset-line-ui .upload-img {
  display: flex;
  justify-content: center;
}
.tabset-line-ui .upload-img img {
  border-radius: 0.75rem;
}
.tabset-line-ui .upload-img iframe {
  width: 100%;
  max-width: 350px;
  min-height: 400px;
}

.confirm-details-section {
  text-align: left;
}
.confirm-details-section h5 {
  font-size: 1rem;
  line-height: 1.625rem;
  letter-spacing: -0.015625rem;
  color: #0E378C;
  margin-bottom: 1.25rem;
  font-weight: 600;
}
.confirm-details-section h4 {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  letter-spacing: -0.0075rem;
  color: #0E378C;
  margin-bottom: 0.3125rem;
  font-weight: 600;
}
.confirm-details-section p {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  letter-spacing: -0.0075rem;
  color: #222222;
  margin-bottom: 0rem !important;
  font-weight: 400;
}
.confirm-details-section table {
  border: 1px solid #EFEFEF;
  margin-bottom: 0;
}
.confirm-details-section table th {
  background: #EFEFEF;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  letter-spacing: -0.0075rem;
  color: #0E378C;
  font-weight: 600;
}
.confirm-details-section table td {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  letter-spacing: -0.0075rem;
  color: #222222;
  font-weight: 400;
}
.confirm-details-section table.table-md th {
  background: transparent;
}
.confirm-details-section table.table-md tr:nth-child(even) {
  background: #EFEFEF;
}
.confirm-details-section .confirm-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(14, 55, 140, 0.05);
  padding: 0.625rem 1.25rem;
}
.confirm-details-section .confirm-title h3 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  letter-spacing: -0.015625rem;
  color: #222222;
  margin-bottom: 0;
}
.confirm-details-section .confirm-title a {
  color: #9D9D9D;
}
.confirm-details-section .confirm-title a:hover {
  color: #0E378C;
}
.confirm-details-section .detail-list {
  border-bottom: 1px solid #EFEFEF;
  padding: 1.25rem;
}
.confirm-details-section .detail-list p {
  text-align: left;
  margin-bottom: 1.25rem !important;
}
.confirm-details-section .detail-list p:last-child {
  margin-bottom: 0 !important;
}
.confirm-details-section .founding-team {
  padding: 1.25rem;
}
.confirm-details-section .founding-team .member-info {
  border: 1px solid #EFEFEF;
  border-radius: 0.75rem;
  padding: 1.25rem;
  margin-bottom: 1.875rem;
  height: calc(100% - 30px);
}
.confirm-details-section .founding-team .member-info p {
  text-align: left;
}
.confirm-details-section .founding-team .member-info .left-img-avtar {
  margin-bottom: 1.25rem;
}
.confirm-details-section .founding-team .member-info .left-img-avtar .imgbox {
  position: relative;
}
.confirm-details-section .founding-team .member-info .left-img-avtar .imgbox .play-ic {
  width: 20px;
  height: 20px;
  border-radius: 50rem;
  background: #0E378C;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
}
.confirm-details-section .founding-team .member-info .left-img-avtar .imgbox .play-ic span {
  font-size: 0.5625rem;
  margin-left: 0.1875rem;
}
.confirm-details-section .founding-team .member-info .avtar-title h5 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  letter-spacing: -0.015625rem;
  color: #222222;
  margin-bottom: 0;
}

.teaser-preview-modal {
  text-align: center;
}
.teaser-preview-modal .upload-img img {
  border-radius: 0.75rem;
}
.teaser-preview-modal .file-name {
  margin-top: 1.875rem;
}
.teaser-preview-modal .file-name span {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  letter-spacing: -0.0075rem;
  color: #222222;
  font-weight: 400;
  display: block;
}
.teaser-preview-modal .btnbox {
  margin-top: 3.125rem;
}

.confirm-teaser-modal .btnbox {
  flex-wrap: wrap;
  margin-bottom: 0;
  margin-top: 1.875rem;
}
.confirm-teaser-modal .btnbox .btn-primary {
  margin-bottom: 1.875rem;
}
.confirm-teaser-modal .btnbox .btn-primary:last-child {
  margin-bottom: 0;
}
.confirm-teaser-modal textarea.form-control {
  resize: vertical;
}

.add-investor-modal .send-invite {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.25rem;
}
.add-investor-modal .send-invite span {
  font-size: 1.125rem;
  line-height: 1.75rem;
  letter-spacing: -0.015625rem;
  color: #9D9D9D;
}
.add-investor-modal .send-invite a {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  letter-spacing: -0.0075rem;
  color: #0E378C;
  font-weight: 600;
}
.add-investor-modal .btnbox {
  margin-top: 1.875rem;
}

.project-files-box {
  display: flex;
  flex-wrap: wrap;
}
.project-files-box .project-files {
  margin-right: 0.625rem;
}
.project-files-box .project-files .add-files {
  border: none;
  background: #EFEFEF;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  padding: 0;
  color: #0E378C;
}
.project-files-box .project-files-add {
  margin-right: 10px;
}
.project-files-box .project-files-add .file-box {
  background: #EFEFEF;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  padding: 0;
  color: #0E378C;
  position: relative;
}
.project-files-box .project-files-add .file-box span {
  font-size: 1.75rem;
}
.project-files-box .project-files-add .file-box img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 0.75rem;
}
.project-files-box .project-files-add .file-box .close-btn {
  background: #9D9D9D;
  width: 14px;
  height: 14px;
  border-radius: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -3px;
  top: -3px;
}
.project-files-box .project-files-add .file-box .close-btn em {
  color: #ffffff;
  font-size: 0.375rem;
}
.project-files-box .project-files-add .file-box .close-btn em::before {
  color: #ffffff;
}

.project-details {
  text-align: left;
}
.project-details .card-title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.project-details .card-title-box .edit-btn {
  border: none;
  background: transparent;
  color: #9D9D9D;
  font-size: 1.125rem;
  padding-left: 1rem;
}
.project-details .card-title-box .edit-btn:hover {
  color: #0E378C;
}
.project-details .project-tag {
  margin-bottom: -15px;
}
.project-details .project-tag .industry-list {
  font-size: 0.8125rem !important;
  line-height: 1.125rem;
  font-weight: 600;
  color: #222222;
  margin: 0.3125rem 0 0.9375rem !important;
}
.project-details .project-info {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.625rem;
}
.project-details .project-info .info-box {
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-weight: 600;
  color: #222222;
  display: flex;
  margin-right: 1.875rem;
}
.project-details .project-info .info-box:last-child {
  margin-right: 0;
}
.project-details .project-info .info-box span {
  color: #0E378C;
  font-size: 1rem;
  margin-right: 0.3125rem;
}
.project-details .project-info .info-box span::before {
  color: #0E378C;
}
.project-details .description p {
  margin-bottom: 1.25rem !important;
}
.project-details .attachments h6 {
  margin-bottom: 1.25rem;
}

.services-search-box .module-search-wrap .filter-btn-wrap .dropdown-menu .action-wrapper {
  margin: 0rem;
}

.sticky-row-wrap {
  position: sticky;
  top: 70px;
  z-index: 9;
}

.search-filter-sticky .module-search {
  border-radius: 0rem 0rem 0.4375rem 0.4375rem !important;
  box-shadow: 5px 2px 20px #9D9D9D !important;
}

.module-search-wrap {
  padding-bottom: 1.875rem;
  flex-wrap: wrap;
}
@media (max-width: 991.9px) {
  .module-search-wrap {
    padding-bottom: 4.0625rem;
  }
}
@media (max-width: 991.9px) {
  .module-search-wrap .search-filter-wrap {
    top: 6px;
    box-shadow: none;
  }
}
@media (max-width: 767.81px) {
  .module-search-wrap .search-filter-wrap {
    top: 55px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    padding: 0px 10px;
  }
}
.module-search-wrap .grid-list-icon {
  margin: 0rem 0.625rem 0rem 0rem;
  display: flex;
  align-items: center;
}
@media (max-width: 991.9px) {
  .module-search-wrap .grid-list-icon {
    margin: 0rem;
  }
}
.module-search-wrap .grid-list-icon .icon-grid::before {
  color: #9D9D9D;
}
.module-search-wrap .grid-list-icon span {
  margin: 0rem 0.625rem;
  font-size: 1.0625rem;
  cursor: pointer;
}
.module-search-wrap .grid-list-icon span::before {
  color: #222222;
}
.module-search-wrap .grid-list-icon span.active::before {
  color: #0E378C;
}
@media (max-width: 768.81px) {
  .module-search-wrap .grid-list-icon span {
    margin: 0rem 0.3125rem;
    font-size: 0.9375rem;
  }
}
.module-search-wrap .grid-list-icon .icon-list {
  font-size: 0.8125rem;
}
.module-search-wrap .dropdown-menu:before {
  display: none;
}
.module-search-wrap .module-search {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  border-radius: 0.75rem;
  overflow: hidden;
}
@media (max-width: 375.81px) {
  .module-search-wrap .module-search {
    position: relative;
  }
}
.module-search-wrap .module-search input {
  width: 100%;
  background-color: #ffffff;
  color: #9D9D9D;
  font-size: 1rem;
  line-height: 1.625rem;
  letter-spacing: -0.015625rem;
  border: none;
  padding: 0.75rem 17.8125rem 0.75rem 3.75rem;
  height: 60px;
}
@media (max-width: 991.9px) {
  .module-search-wrap .module-search input {
    padding: 0.75rem 10.9375rem 0.75rem 2.125rem;
    height: 50px;
  }
}
@media (max-width: 768.81px) {
  .module-search-wrap .module-search input {
    font-size: 0.875rem;
  }
}
@media (max-width: 375.81px) {
  .module-search-wrap .module-search input {
    padding: 0.75rem 2.5rem 0.75rem 2.125rem;
  }
}
.module-search-wrap .module-search span {
  position: absolute;
  left: 44px;
  top: 21px;
  font-size: 1.0625rem;
}
.module-search-wrap .module-search span.search-close {
  font-size: 0.9375rem;
}
@media (max-width: 375.81px) {
  .module-search-wrap .module-search span.search-close {
    display: block !important;
    left: auto;
    right: 15px;
  }
}
@media (max-width: 991.9px) {
  .module-search-wrap .module-search span {
    top: 16px;
    left: 22px;
  }
}
@media (max-width: 768.81px) {
  .module-search-wrap .module-search span {
    font-size: 1rem;
  }
}
@media (max-width: 375.81px) {
  .module-search-wrap .module-search span {
    left: 12px;
  }
}
.module-search-wrap .filter-dropdown {
  font-weight: 600;
}
@media (max-width: 991.9px) {
  .module-search-wrap .filter-dropdown {
    font-size: 0.9375rem;
  }
}
@media (max-width: 768.81px) {
  .module-search-wrap .filter-dropdown {
    font-size: 0.875rem;
  }
}
.module-search-wrap .common-tabs {
  width: auto;
}
@media (max-width: 375.81px) {
  .module-search-wrap .common-tabs .icon-icon-close-black.search-close {
    display: none;
  }
}
@media (max-width: 768.81px) {
  .module-search-wrap .filter-btn-wrap {
    padding: 0.1875rem 0.3125rem;
    height: 45px;
  }
}
@media (max-width: 768.81px) {
  .module-search-wrap .filter-btn-wrap .icon-Icon-down-arrow-block {
    margin-left: 0.3125rem;
  }
}
.module-search-wrap .filter-btn-wrap .dropdown-menu {
  top: 65px;
  padding: 1.5625rem 0rem 4.375rem 0rem;
  border-radius: 0rem 0rem 0.75rem 0.75rem;
  margin: 0rem;
}
@media (max-width: 991.9px) {
  .module-search-wrap .filter-btn-wrap .dropdown-menu {
    top: 50px;
  }
}
.module-search-wrap .filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media (max-width: 1680.87px) {
  .module-search-wrap .filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper {
    max-height: 470px;
  }
}
@media (max-width: 1400.81px) {
  .module-search-wrap .filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper {
    max-height: 350px;
  }
}
@media (max-width: 1280.81px) {
  .module-search-wrap .filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper {
    max-height: 310px;
  }
}
@media (max-width: 767.81px) {
  .module-search-wrap .filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper {
    max-height: 280px;
  }
}
.module-search-wrap .filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper.investor-dropdown-menu-wrapper {
  margin-top: -25px;
}
.module-search-wrap .filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper .switch-group-dropdown-wrap {
  position: relative;
  z-index: 0;
}
.module-search-wrap .filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper .switch-group-dropdown-wrap::after {
  content: "";
  width: calc(100% + 40px);
  left: -20px;
  background-color: #f2f5f9;
  z-index: -1;
  top: 0;
  position: absolute;
  height: 100%;
  border-radius: 0.75rem 0.75rem 0rem 0rem;
}
.module-search-wrap .filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper .switch-group-dropdown-wrap .switch-box {
  padding: 10px 0;
  border-bottom: 1px solid rgba(34, 34, 34, 0.1);
}
.module-search-wrap .filter-btn-wrap .dropdown-menu .dropdown-menu-wrapper .switch-group-dropdown-wrap .switch-box:last-child {
  border-bottom: none;
  margin-bottom: 15px;
}
.module-search-wrap .filter-btn-wrap .dropdown-menu .action-wrapper {
  padding: 0.9375rem 0rem;
  background-color: #EFEFEF;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.module-search-wrap .filter-btn-wrap .dropdown-menu .action-wrapper .btn-box {
  padding: 0rem 1.25rem;
}
.module-search-wrap .filter-btn-wrap .dropdown-menu .b-label {
  font-size: 0.9375rem;
  letter-spacing: 0;
  color: #222222;
  text-transform: inherit;
  margin: 0rem 0rem 1.25rem 0rem;
}
.module-search-wrap .filter-btn-wrap .btn-box .btn-reset {
  color: #0E378C;
}
.module-search-wrap .filter-btn-wrap .range-wrap {
  margin: 0rem 0rem 1.25rem 0rem;
}
.module-search-wrap .filter-btn-wrap .r-text {
  text-transform: capitalize;
  font-size: 0.9375rem;
}
.module-search-wrap .filter-btn-wrap .primary-text {
  font-size: 0.75rem;
}
.module-search-wrap .invesment-size label {
  margin-bottom: 1.25rem;
}
.module-search-wrap ng-select .ng-select-container {
  padding: 0.375rem 0.875rem !important;
  border-radius: 0.25rem;
}
.module-search-wrap ng-select .ng-placeholder {
  position: static !important;
  font-size: 0.9375rem !important;
  padding-bottom: 0 !important;
}
.module-search-wrap input::-webkit-input-placeholder {
  color: #9D9D9D;
}

.category-filters {
  margin-bottom: 1.5625rem;
}
.category-filters h6 {
  font-weight: 600;
}
.category-filters ul {
  list-style: none;
  margin: 0;
  padding-bottom: 0.3125rem;
  white-space: nowrap;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.category-filters ul::-webkit-scrollbar {
  display: none;
}
.category-filters ul li {
  display: inline-block;
  vertical-align: top;
  font-size: 0.9375rem;
  color: #222222;
  letter-spacing: -0.12px;
  line-height: 1.375rem;
  margin-right: 0.625rem;
  border-radius: 0.25rem;
  padding: 0.3125rem 0.625rem;
  border: 1px solid #c2c2c2;
  cursor: pointer;
}
.category-filters ul li.active {
  background-color: #0E378C;
  color: #ffffff;
}
.category-filters ul li label {
  margin-bottom: 0;
  cursor: pointer;
}

.search-custom-checkbox .form-group {
  margin: 0rem 0rem 0.625rem 0rem;
  cursor: pointer;
}
.search-custom-checkbox .form-group input {
  display: none;
  cursor: pointer;
}
.search-custom-checkbox .form-group input:checked + label {
  background-color: #0E378C;
  color: #ffffff !important;
  cursor: pointer;
}
.search-custom-checkbox label {
  padding: 0;
  margin: 0rem 0.5625rem 0.625rem 0rem;
}
.search-custom-checkbox .checkbox-list label {
  background-color: #ffffff;
  border: 1px solid #E3E3E3;
  padding: 0.6875rem;
  border-radius: 0.25rem;
  text-transform: capitalize;
  letter-spacing: 0;
  font-size: 0.875rem;
  margin: 0rem 0.5625rem 0rem 0rem;
  cursor: pointer;
}

.listed-item-box {
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
@media (max-width: 480.81px) {
  .listed-item-box .img-box-wrap {
    margin: 0rem auto 0.9375rem;
  }
}
@media (max-width: 480.81px) {
  .listed-item-box .img-box-wrap img {
    max-height: 150px;
  }
}
@media (max-width: 375.81px) {
  .listed-item-box .img-box-wrap img {
    max-height: 140px;
  }
}

.rating-wrap .bs-rating-star {
  color: #9D9D9D;
  font-size: 0.9375rem;
  margin-right: 0.3125rem;
}
.rating-wrap .bs-rating-star.active {
  color: #0E378C;
}
.rating-wrap .rate-average {
  background-color: #0E378C;
  border-radius: 0.25rem;
  padding: 0.0625rem 0.3125rem;
  font-size: 0.6875rem;
  letter-spacing: 0.1px;
  line-height: 1.455;
  color: #ffffff;
  margin-right: 0.3125rem;
  margin-top: 0.125rem;
  vertical-align: top;
  display: inline-block;
}

.module-info {
  padding: 1.25rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-end;
}
@media (max-width: 768.81px) {
  .module-info {
    padding: 0.9375rem;
  }
}
.module-info .info {
  min-height: 42px;
  max-height: 42px;
  overflow: hidden;
  width: 100%;
}
.module-info .rating-wrap {
  width: 100%;
}
.module-info h4 {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 600;
  color: #222222;
  letter-spacing: -0.015625rem;
  margin-bottom: 0.1875rem;
  width: 100%;
}
@media (max-width: 768.81px) {
  .module-info h4 {
    font-size: 0.875rem;
    line-height: 1.25rem;
    min-height: 43px;
    max-height: 43px;
  }
}
.module-info h4 span {
  margin-left: 0.625rem;
  font-size: 0.875rem;
}
.module-info h4 .icon-blue::before {
  color: #0E378C;
}
.module-info h4 a {
  font-size: 1.125rem;
}
@media (max-width: 768.81px) {
  .module-info h4 a {
    font-size: 0.875rem;
  }
}
.module-info h4 a:hover {
  color: #0E378C;
}
.module-info h5 {
  font-size: 0.8125rem;
  font-weight: 600;
  color: #0E378C;
}
.module-info p {
  font-size: 0.8125rem;
  margin-bottom: 0.1875rem;
}
@media (max-width: 768.81px) {
  .module-info p {
    font-size: 0.75rem;
  }
}
.module-info .info p {
  color: #9D9D9D;
}
.module-info .price {
  font-size: 0.8125rem;
  color: #0E378C;
  letter-spacing: -0.03px;
  line-height: 1.375rem;
  font-weight: 600;
  margin-top: 0.3125rem;
  display: block;
  width: 100%;
}

.img-box-wrap {
  position: relative;
}
.img-box-wrap .icons-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.img-box-wrap .tag-item {
  display: inline-block;
  font-size: 0.6875rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0rem 0.25rem 0rem 0rem;
  margin: 0rem;
}
@media (max-width: 768.81px) {
  .img-box-wrap .tag-item {
    font-size: 0.625rem;
    padding: 0.25rem 0.3125rem;
  }
}
.img-box-wrap .play-ic {
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  border: 2px solid #ffffff;
  background: #0E378C;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  bottom: -25px;
}
@media (max-width: 768.81px) {
  .img-box-wrap .play-ic {
    width: 30px;
    height: 30px;
    font-size: 0.75rem;
    bottom: -15px;
  }
}

.switch-box .switch {
  width: 40px;
  height: 20px;
  min-width: auto;
  background-color: #c2c2c2;
}
.switch-box .switch small {
  width: 16px;
  height: 16px;
  top: 2px;
  right: calc(100% - 18px);
}
.switch-box .switch.checked {
  background-color: #0E378C;
}
.switch-box .switch.checked small {
  right: calc(100% - 38px);
}
.switch-box h6 {
  color: #222222;
  font-size: 0.9375rem;
  font-weight: 600;
  margin: 0rem;
}
.switch-box p {
  color: #9D9D9D;
  font-size: 0.8125rem;
}

.text-btn {
  color: #0E378C;
  font-weight: 600;
  font-size: 0.9375rem;
}

.blue-text {
  color: #0E378C;
  font-size: 0.8125rem;
  font-weight: 600;
  margin: 0rem 0.9375rem 0rem 0rem;
}

.listed-item-list-view {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  border-radius: 0.75rem;
  border: 0.75rem solid #ffffff;
  background-color: #ffffff;
}
.listed-item-list-view .listed-item-box {
  padding: 1.125rem;
}
.listed-item-list-view .img-box-wrap {
  width: 100px;
  height: 100px;
}
.listed-item-list-view .img-box-wrap img {
  border-radius: 100%;
}
@media (max-width: 768.81px) {
  .listed-item-list-view .icons-wrap {
    margin: 0.625rem 0rem;
  }
}
.listed-item-list-view .tag-item {
  font-size: 0.6875rem;
  font-weight: 700;
  margin: 0;
}
@media (max-width: 768.81px) {
  .listed-item-list-view .tag-item {
    font-size: 0.625rem;
  }
}
.listed-item-list-view .module-info {
  padding: 0rem 0rem 0rem 1.25rem;
  width: calc(100% - 100px);
}
@media (max-width: 480.81px) {
  .listed-item-list-view .module-info {
    width: 100%;
  }
}
.listed-item-list-view .module-info p {
  font-size: 0.8125rem;
}
.listed-item-list-view .module-info .info {
  margin-top: 0.3125rem;
}
.listed-item-list-view .module-info .info p {
  font-size: 0.9375rem;
}
.listed-item-list-view .spacer-line {
  border-top: 1px solid rgba(210, 207, 207, 0.1);
  padding: 0rem;
  margin: 0rem 1.875rem 0rem 1.875rem;
}
.listed-item-list-view .img-box-wrap .play-ic {
  width: 28px;
  height: 28px;
  right: 0;
  bottom: 0;
}
.listed-item-list-view .img-box-wrap .play-ic span {
  font-size: 0.5625rem;
}

.without-grid-icons .module-search-wrap .module-search input {
  padding: 0.75rem 11.25rem 0.75rem 3.75rem;
}
@media (max-width: 991.9px) {
  .without-grid-icons .module-search-wrap .module-search input {
    padding: 0.75rem 7.5rem 0.75rem 1.875rem;
  }
}
@media (max-width: 375.81px) {
  .without-grid-icons .module-search-wrap .module-search input {
    padding: 0.75rem 2.5rem 0.75rem 2.1875rem;
  }
}
@media (max-width: 375.81px) {
  .without-grid-icons .filter-btn-wrap {
    border: 0;
  }
}

.next-prev-indicators [class^=icon-] {
  position: absolute;
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  background-color: #E3E3E3;
  color: #222222;
  font-size: 0.75rem;
  top: -1px;
  left: -11px;
}
.next-prev-indicators .icon-icon-right-angle-arrow {
  right: -12px;
  left: auto;
}
@media (max-width: 480.81px) {
  .next-prev-indicators .icon-icon-right-angle-arrow {
    right: -11px;
  }
}

.left-tabs {
  flex-direction: column;
}
@media (max-width: 991.9px) {
  .left-tabs {
    flex-direction: initial;
    padding: 0rem 1.875rem;
  }
}
.left-tabs .nav-link {
  border-radius: 0;
  border-left: 2px solid #ffffff;
  color: #222222;
  display: flex;
  align-items: center;
  padding: 0.875rem 1.25rem 0.875rem 2.1875rem;
  font-weight: 600;
  font-size: 0.9375rem;
  position: relative;
}
@media (max-width: 991.9px) {
  .left-tabs .nav-link {
    padding: 0.5rem 0.625rem;
    background-color: #EFEFEF;
    border-left: none;
    color: #222222;
    border-radius: 0.25rem;
    margin: 0rem 0.625rem 0.625rem 0rem;
  }
}
@media (max-width: 768.81px) {
  .left-tabs .nav-link {
    font-size: 0.875rem;
    padding: 0.1875rem 0.5rem;
  }
}
.left-tabs .nav-link.active, .left-tabs .nav-link.show > .nav-link {
  background-color: transparent;
  border-left: 2px solid #0E378C;
  color: #0E378C;
}
@media (max-width: 991.9px) {
  .left-tabs .nav-link.active, .left-tabs .nav-link.show > .nav-link {
    background-color: #0E378C;
    border-left: none;
    color: #ffffff;
  }
}
.left-tabs span {
  padding: 0rem 0rem 0rem 0.875rem;
  position: absolute;
  right: 1.25rem;
  font-size: 0.75rem;
  color: #D6D6D6;
}
@media (max-width: 991.9px) {
  .left-tabs span {
    display: none;
  }
}
.left-tabs span em {
  font-style: inherit;
  margin-left: 0.3125rem;
}

.module-detail {
  margin: 1.875rem 0rem 0rem 0rem;
}
.module-detail .bg-default {
  background-color: #EFEFEF;
  color: #222222;
}
.module-detail .detal-view h6 {
  font-weight: 600;
  font-size: 0.9375rem;
  letter-spacing: -0.12px;
  line-height: 1.467;
  color: #0E378C;
  margin-bottom: 0.3125rem;
}
.module-detail .detal-view p {
  font-size: 0.9375rem;
  font-weight: 400;
  letter-spacing: -0.12px;
  line-height: 1.467;
  color: #222222;
  margin-bottom: 1.5625rem;
}
.module-detail .list-bg ul {
  margin-bottom: 0.9375rem;
}
.module-detail .list-bg ul li {
  display: inline-block;
  vertical-align: top;
  background-color: #EFEFEF;
  border-radius: 0.25rem;
  color: #222222;
  font-size: 0.8125rem;
  line-height: 1.692;
  letter-spacing: -0.03px;
  padding: 0.3125rem 0.625rem;
  margin-right: 0.625rem;
  margin-bottom: 0.625rem;
  font-weight: 600;
}
.module-detail .list-bg-none p {
  margin-bottom: 0;
}
.module-detail .profile-info {
  text-align: center;
  position: relative;
}
.module-detail .profile-info span {
  color: #9D9D9D;
  text-align: center;
  font-size: 0.9375rem;
}
.module-detail .profile-info h4 {
  font-size: 1.125rem;
}
.module-detail .profile-info h4 span {
  margin-top: 0;
}
.module-detail .profile-info p {
  margin-bottom: 0.3125rem;
}
.module-detail .profile-info h6 {
  margin-bottom: 0.9375rem;
}
.module-detail .profile-info .btnbox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.module-detail .profile-info .btnbox .btn {
  font-size: 0.875rem;
  margin: 0.3125rem 0.5rem;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40px;
  line-height: normal;
  max-width: 200px;
}
.module-detail .profile-info .btnbox .btn span {
  margin: 0rem 0.625rem 0rem 0.3125rem;
}
.module-detail .profile-info .btnbox .btn span:before {
  color: #ffffff;
}
.module-detail .profile-info .btnbox .btn span.icon-Group {
  font-size: 1.1875rem;
}
.module-detail .profile-info .btnbox .btn:hover span:before {
  color: #0E378C;
}
.module-detail .profile-info .like-and-action-wrap {
  position: absolute;
  top: -10px;
  right: -5px;
  display: flex;
  align-items: center;
}
.module-detail .profile-info .like-and-action-wrap a {
  padding: 0 5px span;
  padding-font-size: 1rem;
  padding-margin-top: 0;
}
.module-detail .profile-info .like-and-action-wrap a:hover span:before {
  color: #0E378C;
}
.module-detail .profile-info .like-and-action-wrap a:last-child {
  margin-left: 1.25rem;
}
.module-detail .profile-info .imgbox {
  width: 100px;
  height: 100px;
  margin: 0rem auto 1.25rem;
  padding: 0.625rem;
  border-radius: 100%;
  position: relative;
  border: 2px solid #EFEFEF;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.module-detail .profile-info .imgbox .play-btn {
  position: absolute;
  right: -5px;
  bottom: 0px;
  background-color: #0E378C;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  font-size: 0.9375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.module-detail .profile-info .imgbox .play-btn span {
  margin-left: 0px;
  margin-top: 0px;
}
.module-detail .profile-info .imgbox img {
  border-radius: 100%;
  width: 100%;
  object-fit: cover;
  height: 100%;
  object-position: center;
}
.module-detail .page-title {
  margin: 0;
  padding: 0rem 0rem 1.875rem 0rem;
  font-size: 1.25rem;
}
@media (max-width: 768.81px) {
  .module-detail .page-title {
    padding: 0rem 0rem 0.9375rem 0rem;
  }
}
.module-detail .video-box {
  margin: 0rem -1.875rem;
}
@media (max-width: 768.81px) {
  .module-detail .video-box {
    margin: 0rem -0.9375rem;
  }
}
.module-detail .video-box .video-feed {
  border-radius: 0;
}
.module-detail .profile-details .btnbox {
  margin: 0.9375rem 0rem 0.9375rem 0rem;
}
.module-detail .profile-details .btnbox .btn {
  width: 114px;
  border-radius: 0.25rem;
}
.module-detail .profile-details .rate-count {
  display: inline-block;
  font-size: 0.6875rem;
  font-weight: 700;
  color: #0E378C;
  text-transform: uppercase;
  margin-left: 0.625rem;
  vertical-align: top;
  line-height: 24px;
}
.module-detail .profile-details .profile-desc .addr {
  font-weight: 400;
  font-size: 0.8125rem;
  letter-spacing: -0.03px;
  line-height: 1.692;
  color: #222222;
}
.module-detail .profile-details .profile-desc .work-rate {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.625;
  color: #0E378C;
}
.module-detail .dropdown-menu {
  box-shadow: 0 2px 4px 5px #EFEFEF;
}
.module-detail #dropdown-msg span {
  font-size: 1.125rem;
}
.module-detail #dropdown-msg a {
  margin-bottom: 0.3125rem;
}
.module-detail #dropdown-msg a:hover {
  color: #0E378C;
}
.module-detail #dropdown-msg a:hover span:before {
  color: #0E378C;
}
.module-detail .profile-info .like span {
  font-size: 1.0625rem;
  font-weight: 400;
  margin-left: 0;
}
.module-detail .profile-info .like span:before {
  color: #0E378C;
}
.module-detail .profile-info .like .dropdown-box a {
  margin-left: 0.75rem;
}
.module-detail .profile-info .like .dropdown-box .dropdown-menu-right {
  right: -27px !important;
  left: auto;
}
@media (max-width: 576.81px) {
  .module-detail .profile-info .like .dropdown-box .dropdown-menu-right {
    right: -22px !important;
  }
}
.module-detail .profile-info .like .dropdown-box .dropdown-item span::before {
  color: #222222;
}
.module-detail .social-media li {
  margin: 0.9375rem 0.625rem 0rem 1.25rem;
}
.module-detail .social-media li span:before {
  color: #0E378C;
}
.module-detail .spacer-line {
  border-top: 1px solid rgba(101, 101, 101, 0.1);
  padding: 0rem;
  margin: 1.25rem 0rem 1.25rem 0rem;
}
.module-detail .primary-text {
  font-size: 0.9375rem;
  font-weight: 600;
}
.module-detail .about-card .info {
  padding: 0;
}
@media (max-width: 768.81px) {
  .module-detail .about-card .info p {
    padding: 0rem 0rem 0.625rem 0rem;
  }
}
.module-detail .investments .text-blue {
  color: #0E378C;
  font-weight: 600;
}
.module-detail .investments .primary-text {
  color: #222222 !important;
  font-weight: 400;
  padding: 0.3125rem 0rem;
}
.module-detail .badges .badge {
  background-color: #EFEFEF;
  font-weight: 600;
  padding: 0.5625rem 0.5rem;
  font-size: 0.8125rem;
  letter-spacing: 0;
  margin: 0rem 0.9375rem 0.625rem 0rem;
}
.module-detail .investment-list li {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  list-style-type: disc;
  list-style-position: inside;
}
.module-detail .previous-investment [class*=col-] {
  display: flex;
}
@media (max-width: 991.9px) {
  .module-detail .previous-investment [class*=col-]:nth-child(n+4) {
    margin-top: 1.875rem;
  }
}
@media (max-width: 768.81px) {
  .module-detail .previous-investment [class*=col-]:nth-child(n+3) {
    margin-top: 1.875rem;
  }
}
@media (max-width: 480.81px) {
  .module-detail .previous-investment [class*=col-]:nth-child(n+2) {
    margin-top: 1.875rem;
  }
}
.module-detail .previous-investment .imgbox {
  width: 100%;
  height: 50px;
  margin: 0rem auto 0.625rem;
}
.module-detail .previous-investment .profile-info span {
  display: inline-block;
  padding-left: 0.3125rem;
  margin: 0rem;
}
.module-detail .previous-investment .box-wrap {
  border: 1px solid #EFEFEF;
  padding: 1.25rem;
  border-radius: 0.25rem;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
@media (max-width: 480.81px) {
  .module-detail .previous-investment .box-wrap {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
.module-detail .previous-investment .btnbox {
  margin: 0.9375rem 0rem 0rem 0rem;
}
.module-detail .previous-investment [class*=col-]:nth-child(n+4) {
  margin-top: 1.875rem;
}
.module-detail .previous-investment .profile-contact h4 {
  font-size: 1rem;
  line-height: 1.375rem;
}
.module-detail .profile-info .btn {
  justify-content: flex-start;
}
.module-detail .profile-info .btn span {
  margin: 0rem 0.9375rem 0rem 0.3125rem;
}
.module-detail .other-pages {
  background-color: transparent;
  box-shadow: none;
}
.module-detail .other-pages p {
  font-size: 0.9375rem;
  color: #9D9D9D;
}
.module-detail .btn-follow {
  justify-content: flex-start;
  color: #0E378C;
  letter-spacing: 0;
  font-size: 0.9375rem;
}
.module-detail .item-listed-wrap + .item-listed-wrap {
  margin-top: 5rem;
}
@media (max-width: 768.81px) {
  .module-detail .item-listed-wrap + .item-listed-wrap {
    margin-top: 1.875rem;
  }
}
.module-detail .bg-transparant {
  background-color: transparent;
  box-shadow: none;
}
@media (max-width: 768.81px) {
  .module-detail .card .info {
    padding: 0rem;
  }
}
.module-detail .portfolio-wrap h2 {
  font-size: 1.375rem;
  font-weight: 700;
  letter-spacing: -0.4px;
  line-height: 1.364;
  color: #0E378C;
  margin-bottom: 0.75rem;
}
@media (max-width: 767.81px) {
  .module-detail .portfolio-wrap h2 {
    font-size: 1.125rem;
  }
}
.module-detail .portfolio-wrap .folio-sublist {
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 1.5;
  color: #222222;
  margin-bottom: 0.75rem;
  display: block;
}
@media (max-width: 767.81px) {
  .module-detail .portfolio-wrap .folio-sublist {
    font-size: 1rem;
  }
}
.module-detail .portfolio-wrap p {
  margin-bottom: 0.75rem;
  font-size: 0.9375rem;
  letter-spacing: -0.12px;
  line-height: 1.467;
  color: #222222;
}
.module-detail .portfolio-wrap .folio-dt {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.5;
  color: #9D9D9D;
  text-transform: uppercase;
}
.module-detail .package-wrap h2 {
  font-size: 1.375rem;
  font-weight: 700;
  letter-spacing: -0.4px;
  line-height: 1.364;
  color: #222222;
}
.module-detail .package-wrap .folio-sublist {
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 1.667;
  color: #222222;
  margin-bottom: 0.625rem;
  display: block;
}
.module-detail .package-wrap .duration li {
  display: inline-block;
  vertical-align: top;
  margin-right: 1.875rem;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
  letter-spacing: -0.12px;
  line-height: 1.467;
  color: #222222;
}
.module-detail .package-wrap .duration li img {
  margin-right: 0.625rem;
}
.module-detail .package-wrap .histroy-rating {
  margin-bottom: 0.625rem;
}
.module-detail .package-wrap .histroy-rating .rate-average {
  background-color: #0E378C;
  border-radius: 0.25rem;
  padding: 0.0625rem 0.3125rem;
  font-size: 0.6875rem;
  letter-spacing: 0.1px;
  line-height: 1.455;
  color: #ffffff;
  margin-right: 0.3125rem;
  margin-top: 0.125rem;
  vertical-align: top;
  display: inline-block;
}
.module-detail .package-wrap .btn-primary {
  padding: 10px 20px;
  font-size: 0.9375rem;
  letter-spacing: -0.12px;
  line-height: 1.467;
  color: #ffffff;
  min-height: auto;
  border-radius: 0.25rem;
  margin-bottom: 1.25rem;
}
.module-detail .package-wrap .btn-primary:hover {
  color: #222222;
}
.module-detail .package-wrap p {
  margin-bottom: 0.3125rem;
  font-size: 0.9375rem;
  letter-spacing: -0.12px;
  line-height: 1.467;
  color: #222222;
}
.module-detail .package-wrap .folio-dt {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.5;
  color: #9D9D9D;
  text-transform: uppercase;
}
.module-detail .package-wrap .folio-media {
  margin-top: 0.625rem;
}
.module-detail .package-wrap .folio-media li {
  display: inline-block;
  vertical-align: top;
  margin-right: 0.625rem;
  border-radius: 0.75rem;
  overflow: hidden;
}
.module-detail .package-wrap .folio-media li img {
  border: 1px solid #EFEFEF;
}

.members-list .member-img {
  position: relative;
  display: inline-block;
  min-width: 50px;
  max-height: 50px;
}
.members-list .member-img img {
  border-radius: 100%;
}
.members-list .member-img .play-ic {
  right: 0;
  width: 20px;
  height: 20px;
  border: 0;
  bottom: 0;
}
.members-list .member-img .play-ic span {
  font-size: 0.5rem;
}
.members-list .member-req .btn {
  min-width: auto;
}
.members-list .member-req span {
  width: 32px;
  height: 32px;
  background: #0E378C;
  display: inline-block;
  border-radius: 100%;
  line-height: 32px;
  text-align: center;
  font-size: 0.8125rem;
  cursor: pointer;
}
.members-list .member-req span:before {
  color: #ffffff;
}
.members-list .member-req span.yellow-bg {
  font-size: 0.9375rem;
}
.members-list .designation {
  margin: 0.3125rem 0rem 0rem 0.9375rem;
}
.members-list .designation h6 {
  font-weight: 600;
  margin: 0rem 0.625rem 0.3125rem 0rem;
  cursor: pointer;
}
.members-list .designation h6:hover {
  color: #0E378C;
}
.members-list .designation span {
  color: #0E378C;
  font-size: 0.9375rem;
}
.members-list .designation p {
  padding-top: 0.9375rem;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 600px;
  white-space: nowrap;
  margin-bottom: 0rem;
}
@media (max-width: 1200.81px) {
  .members-list .designation p {
    max-width: 410px;
  }
}
@media (max-width: 991.9px) {
  .members-list .designation p {
    max-width: 490px;
  }
}
@media (max-width: 767.81px) {
  .members-list .designation p {
    max-width: 320px;
  }
}
@media (max-width: 480.81px) {
  .members-list .designation p {
    max-width: 220px;
  }
}
@media (max-width: 375.81px) {
  .members-list .designation p {
    max-width: 190px;
  }
}
@media (max-width: 320.11px) {
  .members-list .designation p {
    max-width: 150px;
  }
}
.members-list .short-info {
  padding: 0.625rem 0rem 0rem 4.0625rem;
}
.members-list .spacer-line {
  margin: 0.625rem 0rem 1.25rem 0rem;
}

.req-remove-btn {
  margin-left: auto;
  min-width: auto;
}
@media (max-width: 767.81px) {
  .req-remove-btn {
    margin-left: 0;
    margin-top: 0.625rem;
  }
}
.req-remove-btn span {
  width: 32px;
  height: 32px;
  background: #0E378C;
  display: inline-block;
  border-radius: 100%;
  line-height: 32px;
  text-align: center;
  font-size: 0.8125rem;
  cursor: pointer;
}
.req-remove-btn .icon-red {
  background-color: #f3193e !important;
}
.req-remove-btn .icon-red::before {
  color: #ffffff;
}

.dropdown-menu#dropdown-feed {
  min-width: 130px;
}
.dropdown-menu#dropdown-feed li {
  display: inline-block;
}
.dropdown-menu#dropdown-feed li .dropdown-item {
  width: auto;
}
.dropdown-menu#dropdown-feed li .dropdown-item [class*=icon-] {
  margin-right: 0.625rem;
  margin-left: 0px;
}

.invester-listing .module-search-wrap .filter-btn-wrap .dropdown-menu .action-wrapper {
  margin: 0rem;
}

.listed-item-box {
  background-color: #ffffff;
  border-radius: 0.75rem;
  overflow: hidden;
}
.listed-item-box .img-box-wrap {
  width: 100%;
}
@media (max-width: 480.81px) {
  .listed-item-box .img-box-wrap {
    margin: 0rem auto 0.9375rem;
  }
}
.listed-item-box .img-box-wrap img {
  width: 100%;
}
@media (max-width: 480.81px) {
  .listed-item-box .img-box-wrap img {
    max-height: 150px;
  }
}
@media (max-width: 375.81px) {
  .listed-item-box .img-box-wrap img {
    max-height: 140px;
  }
}

.invester-info {
  padding: 1.25rem;
  width: 100%;
}
@media (max-width: 768.81px) {
  .invester-info {
    padding: 0.9375rem;
  }
}
@media (max-width: 767.81px) {
  .invester-info .d-flex .icon-Icon-green-tick {
    line-height: 22px;
  }
}
.invester-info h4 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: #222222;
  letter-spacing: -0.015625rem;
  margin-bottom: 0.1875rem;
  cursor: pointer;
  word-break: break-word;
  display: table;
}
.invester-info h4:hover {
  color: #0E378C;
}
@media (max-width: 768.81px) {
  .invester-info h4 {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.invester-info h4 span {
  margin-left: 0.625rem;
  font-size: 0.875rem;
}
.invester-info h4 .icon-blue::before {
  color: #0E378C;
}
.invester-info h4 a {
  font-size: 1.125rem;
}
@media (max-width: 768.81px) {
  .invester-info h4 a {
    font-size: 0.875rem;
  }
}
.invester-info .location {
  font-size: 0.8125rem;
  margin-top: 0.3125rem;
  margin-bottom: 0.1875rem;
  display: inline-block;
}
.invester-info h5 {
  font-size: 0.8125rem;
  font-weight: 600;
  color: #0E378C;
}
.invester-info p {
  font-size: 0.8125rem;
  margin-bottom: 0.1875rem;
}
.invester-info p.text-ellipsis-2line {
  height: 42px;
}
@media (max-width: 768.81px) {
  .invester-info p {
    font-size: 0.75rem;
  }
}
@media (min-width: 768.91px) {
  .invester-info .info {
    min-height: 42px;
    max-height: 42px;
    overflow: hidden;
  }
}
.invester-info .info p {
  color: #9D9D9D;
}

.listed-items-wrap [class*=col-] {
  margin-bottom: 1.875rem;
}

.img-box-wrap {
  position: relative;
}
.img-box-wrap .icons-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.img-box-wrap .tag-item {
  display: inline-block;
  font-size: 0.6875rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0rem 0.25rem 0rem 0rem;
  margin: 0rem;
}
@media (max-width: 768.81px) {
  .img-box-wrap .tag-item {
    font-size: 0.625rem;
    padding: 0.25rem 0.3125rem;
  }
}
.img-box-wrap .play-ic {
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  border: 2px solid #ffffff;
  background: #0E378C;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  bottom: -25px;
}
@media (max-width: 768.81px) {
  .img-box-wrap .play-ic {
    width: 30px;
    height: 30px;
    font-size: 0.75rem;
    bottom: -15px;
  }
}

.switch-box .switch {
  width: 40px;
  height: 20px;
  min-width: auto;
  background-color: #c2c2c2;
}
.switch-box .switch small {
  width: 16px;
  height: 16px;
  top: 2px;
  right: calc(100% - 18px);
}
.switch-box .switch.checked {
  background-color: #0E378C;
}
.switch-box .switch.checked small {
  right: calc(100% - 38px);
}
.switch-box h6 {
  color: #222222;
  font-size: 0.9375rem;
  font-weight: 600;
  margin: 0rem;
}
.switch-box p {
  color: #9D9D9D;
  font-size: 0.8125rem;
  margin-bottom: 0;
}

.text-btn {
  color: #0E378C;
  font-weight: 600;
  font-size: 0.9375rem;
}

.blue-text {
  color: #0E378C;
  font-size: 0.8125rem;
  font-weight: 600;
  margin: 0rem 0.9375rem 0rem 0rem;
}

.listed-item-list-view {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  border-radius: 0.75rem;
  border: 0.75rem solid #ffffff;
  background-color: #ffffff;
}
.listed-item-list-view .listed-item-box {
  padding: 1.125rem;
}
.listed-item-list-view .img-box-wrap {
  width: 100px;
  height: 100px;
}
.listed-item-list-view .img-box-wrap img {
  border-radius: 100%;
}
@media (max-width: 768.81px) {
  .listed-item-list-view .icons-wrap {
    margin: 0.625rem 0rem;
  }
}
.listed-item-list-view .tag-item {
  font-size: 0.6875rem;
  font-weight: 700;
  margin: 0;
}
@media (max-width: 768.81px) {
  .listed-item-list-view .tag-item {
    font-size: 0.625rem;
  }
}
.listed-item-list-view .invester-info {
  padding: 0rem 0rem 0rem 1.25rem;
  width: calc(100% - 100px);
}
@media (max-width: 480.81px) {
  .listed-item-list-view .invester-info {
    width: 100%;
  }
}
.listed-item-list-view .invester-info p {
  font-size: 0.8125rem;
}
.listed-item-list-view .invester-info .info {
  margin: 0.3125rem 0rem 0rem;
}
.listed-item-list-view .invester-info .info p {
  font-size: 0.9375rem;
}
@media (max-width: 480.81px) {
  .listed-item-list-view .invester-info .left-info {
    text-align: center;
    width: 100%;
  }
}
.listed-item-list-view .invester-info .left-info .card-name-box {
  display: block;
}
.listed-item-list-view .invester-info .left-info .card-name-box h4 {
  display: inline;
  margin-right: 5px;
}
.listed-item-list-view .invester-info .left-info .card-name-box .icon-Icon-green-tick {
  display: inline;
}
.listed-item-list-view .spacer-line {
  border-top: 1px solid #EFEFEF;
  padding: 0rem;
  margin: 0rem 1.875rem 0rem 1.875rem;
}
.listed-item-list-view .img-box-wrap .play-ic {
  width: 28px;
  height: 28px;
  right: 0;
  bottom: 0;
}
.listed-item-list-view .img-box-wrap .play-ic span {
  font-size: 0.5625rem;
}

.left-tabs {
  flex-direction: column;
}
@media (max-width: 991.9px) {
  .left-tabs {
    flex-direction: initial;
    padding: 0rem 1.875rem;
  }
}
.left-tabs .nav-link {
  border-radius: 0;
  border-left: 2px solid #ffffff;
  color: #222222;
  display: flex;
  align-items: center;
  padding: 0.875rem 1.25rem 0.875rem 1.25rem;
  font-weight: 600;
  font-size: 0.9375rem;
  position: relative;
}
@media (max-width: 991.9px) {
  .left-tabs .nav-link {
    padding: 0.5rem 0.625rem;
    background-color: #EFEFEF;
    border-left: none;
    color: #222222;
    border-radius: 0.25rem;
    margin: 0rem 0.625rem 0.625rem 0rem;
  }
}
@media (max-width: 768.81px) {
  .left-tabs .nav-link {
    font-size: 0.875rem;
    padding: 0.1875rem 0.5rem;
  }
}
.left-tabs .nav-link.active, .left-tabs .nav-link.show > .nav-link {
  background-color: transparent;
  border-left: 2px solid #0E378C;
  color: #0E378C;
}
@media (max-width: 991.9px) {
  .left-tabs .nav-link.active, .left-tabs .nav-link.show > .nav-link {
    background-color: #0E378C;
    border-left: none;
    color: #ffffff;
  }
}
.left-tabs .nav-link em {
  font-style: normal;
  margin-left: 0.3125rem;
}
.left-tabs span {
  padding: 0rem 0rem 0rem 0.875rem;
  position: absolute;
  right: 1.25rem;
  font-size: 0.75rem;
  color: #D6D6D6;
}
@media (max-width: 991.9px) {
  .left-tabs span {
    display: none;
  }
}
.left-tabs span em {
  font-style: inherit;
  margin-left: 0.3125rem;
}

.investors-detail {
  margin: 1.875rem 0rem 0rem 0rem;
}
.investors-detail .profile-info {
  text-align: center;
  position: relative;
}
.investors-detail .profile-info .card-name-box span {
  display: inline;
}
.investors-detail .profile-info span {
  color: #9D9D9D;
  margin-top: 0.625rem;
  text-align: center;
  display: block;
  font-size: 0.8125rem;
  font-weight: 600;
}
.investors-detail .profile-info h4 {
  font-size: 1.125rem;
}
.investors-detail .profile-info h4 span {
  margin-top: 0;
}
.investors-detail .profile-info .d-flex span {
  margin: 0 0 0 5px;
}
.investors-detail .profile-info p {
  margin-bottom: 0.3125rem;
}
.investors-detail .profile-info h6 {
  margin-bottom: 0.9375rem;
}
.investors-detail .profile-info .btnbox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.investors-detail .profile-info .btnbox .btn {
  font-size: 0.875rem;
  margin: 0.3125rem 0.625rem;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40px;
  line-height: normal;
  max-width: 200px;
}
.investors-detail .profile-info .btnbox .btn span {
  margin: 0rem 0.625rem 0rem 0.3125rem;
}
.investors-detail .profile-info .btnbox .btn span:before {
  color: #ffffff;
}
.investors-detail .profile-info .btnbox .btn:hover span:before {
  color: #0E378C;
}
.investors-detail .profile-info .like-and-action-wrap {
  position: absolute;
  top: -10px;
  right: -5px;
  display: flex;
  align-items: center;
}
.investors-detail .profile-info .like-and-action-wrap a {
  padding: 0rem 0.3125rem;
}
.investors-detail .profile-info .like-and-action-wrap a span {
  font-size: 1rem;
  margin-top: 0;
}
.investors-detail .profile-info .like-and-action-wrap a:hover span:before {
  color: #0E378C;
}
.investors-detail .profile-info .like-and-action-wrap a:last-child {
  margin-left: 1.25rem;
}
.investors-detail .profile-info .imgbox {
  margin: 0rem auto 1.25rem;
  border-radius: 100%;
  position: relative;
}
.investors-detail .profile-info .imgbox .play-btn {
  position: absolute;
  right: -5px;
  bottom: 0px;
  background-color: #0E378C;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  font-size: 0.9375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.investors-detail .profile-info .imgbox .play-btn span {
  margin-left: 0.375rem;
  margin-top: 0px;
}
.investors-detail .profile-info .imgbox img {
  border-radius: 100%;
}
.investors-detail .page-title {
  margin: 0;
  padding: 0rem 0rem 1.875rem 0rem;
  font-size: 1.25rem;
}
@media (max-width: 768.81px) {
  .investors-detail .page-title {
    padding: 0rem 0rem 0.9375rem 0rem;
  }
}
.investors-detail .video-box {
  margin: 0rem -1.875rem;
}
@media (max-width: 768.81px) {
  .investors-detail .video-box {
    margin: 0rem -0.9375rem;
  }
}
.investors-detail .video-box .video-feed {
  border-radius: 0;
}
.investors-detail .profile-details .btnbox {
  margin: 0.9375rem 0rem 0.9375rem 0rem;
}
.investors-detail .profile-details .btnbox .btn {
  width: 114px;
  border-radius: 0.25rem;
}
.investors-detail .dropdown-menu {
  box-shadow: 0 2px 4px 5px #EFEFEF;
}
.investors-detail .profile-info .like span {
  font-size: 1.0625rem;
  font-weight: 400;
  margin-top: 0rem;
}
.investors-detail .profile-info .like span:before {
  color: #0E378C;
}
.investors-detail .profile-info .like a {
  display: flex;
  align-items: center;
  margin: 0 5px;
}
.investors-detail .profile-info .like .dropdown-box .dropdown-menu.dropdown-menu-right {
  right: -27px !important;
}
@media (max-width: 767.81px) {
  .investors-detail .profile-info .like .dropdown-box .dropdown-menu.dropdown-menu-right {
    right: -20px !important;
  }
}
.investors-detail .profile-info .play-ic {
  width: 32px;
  height: 32px;
  border-radius: 50rem;
  background: #0E378C;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -4px;
  bottom: 0;
}
.investors-detail .profile-info .play-ic span {
  font-size: 0.625rem;
  margin: 0;
}
.investors-detail .social-media li {
  margin: 0.9375rem 0.625rem 0rem 1.25rem;
}
.investors-detail .social-media li span:before {
  color: #0E378C;
}
.investors-detail .spacer-line {
  border-top: 1px solid rgba(101, 101, 101, 0.1);
  padding: 0rem;
  margin: 1.25rem 0rem 1.25rem 0rem;
}
.investors-detail .primary-text {
  font-size: 0.9375rem;
  font-weight: 600;
}
.investors-detail .about-card .info {
  padding: 0;
}
@media (max-width: 768.81px) {
  .investors-detail .about-card .info p {
    padding: 0rem 0rem 0.625rem 0rem;
  }
}
.investors-detail .investments .text-blue {
  color: #0E378C;
  font-weight: 600;
}
.investors-detail .investments .primary-text {
  color: #222222 !important;
  font-weight: 400;
  padding: 0.3125rem 0rem;
}
.investors-detail .badges .badge {
  background-color: #EFEFEF;
  font-weight: 600;
  padding: 0.5625rem 0.5rem;
  font-size: 0.8125rem;
  letter-spacing: 0;
  margin: 0rem 0.9375rem 0.625rem 0rem;
}
.investors-detail .investment-list li {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  list-style-type: disc;
  list-style-position: inside;
}
.investors-detail .previous-investment {
  background: #ffffff;
  box-shadow: none;
  margin-bottom: 1.875rem;
}
.investors-detail .previous-investment [class*=col-] {
  display: flex;
}
@media (max-width: 991.9px) {
  .investors-detail .previous-investment [class*=col-]:nth-child(n+4) {
    margin-top: 1.875rem;
  }
}
@media (max-width: 768.81px) {
  .investors-detail .previous-investment [class*=col-]:nth-child(n+3) {
    margin-top: 1.875rem;
  }
}
@media (max-width: 480.81px) {
  .investors-detail .previous-investment [class*=col-]:nth-child(n+2) {
    margin-top: 1.875rem;
  }
}
.investors-detail .previous-investment .imgbox {
  margin: 0rem auto 0.625rem;
  border: 0;
}
.investors-detail .previous-investment .imgbox img {
  width: 50px;
  height: 50px;
}
.investors-detail .previous-investment .profile-info span {
  display: inline-block;
  padding-left: 0.3125rem;
  margin: 0rem;
  color: #9D9D9D;
  font-weight: 400;
}
.investors-detail .previous-investment .box-wrap {
  border: 1px solid #e8e8e8;
  padding: 1.25rem;
  border-radius: 0.25rem;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
@media (max-width: 480.81px) {
  .investors-detail .previous-investment .box-wrap {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
.investors-detail .previous-investment .btnbox {
  margin: 0.9375rem 0rem 0rem 0rem;
}
.investors-detail .previous-investment [class*=col-]:nth-child(n+4) {
  margin-top: 1.875rem;
}
.investors-detail .previous-investment .profile-contact h4 {
  font-size: 1rem;
  line-height: 1.375rem;
}
.investors-detail .previous-investment .profile-contact h4:hover {
  color: #0E378C;
}
.investors-detail .exit-investment .profile-info span {
  color: #222222;
}
.investors-detail .profile-info .btn {
  justify-content: flex-start;
}
.investors-detail .profile-info .btn span {
  margin: 0rem 0.9375rem 0rem 0.3125rem;
}
.investors-detail .other-pages {
  background-color: transparent;
  box-shadow: none;
}
.investors-detail .other-pages p {
  font-size: 0.9375rem;
  color: #9D9D9D;
}
.investors-detail .btn-follow {
  justify-content: flex-start;
  color: #0E378C;
  letter-spacing: 0;
  font-size: 0.9375rem;
}
.investors-detail .item-listed-wrap + .item-listed-wrap {
  margin-top: 5rem;
}
@media (max-width: 768.81px) {
  .investors-detail .item-listed-wrap + .item-listed-wrap {
    margin-top: 1.875rem;
  }
}
.investors-detail .bg-transparant {
  background-color: transparent;
  box-shadow: none;
}
@media (max-width: 768.81px) {
  .investors-detail .card .info {
    padding: 0rem;
  }
}

.network-main-pro-card {
  padding: 0;
}
.network-main-pro-card .network-profile-card-info {
  padding: 1.875rem 1.875rem 0.9375rem 1.875rem;
}
.network-main-pro-card .network-profile-card-info .btnbox {
  margin: 0.9375rem 0 0;
}
.network-main-pro-card .profile-inner-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.network-main-pro-card .profile-inner-info a {
  padding: 0.9375rem 0.3125rem;
  background-color: rgba(14, 55, 140, 0.05);
  font-size: 0.8125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #222222;
  width: 33.33%;
  font-weight: 600;
  text-align: center;
}
.network-main-pro-card .profile-inner-info a.added-video {
  background-color: rgba(14, 55, 140, 0.1);
}
.network-main-pro-card .profile-inner-info a:hover {
  background-color: #0e378c;
  color: #ffffff;
}
.network-main-pro-card .profile-inner-info a:first-child {
  border-radius: 0 0 0 0.75rem;
}
.network-main-pro-card .profile-inner-info a:last-child {
  border-radius: 0 0 0.75rem 0;
}
@media (max-width: 1280.81px) {
  .network-main-pro-card .profile-inner-info a {
    width: 50%;
  }
  .network-main-pro-card .profile-inner-info a:last-child {
    width: 100%;
    border-top: 1px solid rgba(14, 55, 140, 0.1);
    border-radius: 0 0 0.75rem 0.75rem;
  }
  .network-main-pro-card .profile-inner-info a:first-child {
    border-radius: 0;
  }
}

.media-view {
  display: block;
  position: relative;
}
.media-view .title-box {
  display: flex;
  align-items: flex-start;
}
.media-view .title-box .imgbox {
  margin-right: 0.625rem;
  width: 3.125rem;
  border-radius: 0.75rem;
  height: 3.125rem;
  overflow: hidden;
}
.media-view .title-box .title {
  width: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}
.media-view .title-box .title h6 {
  font-size: 0.9375rem;
  margin-bottom: 0;
}
.media-view .title-box .title span {
  margin-top: 0.25rem;
  font-size: 0.875rem;
}
.media-view .title-box .title p {
  margin-top: 0.25rem;
  font-size: 0.8125rem;
}

.info-licenses-media .title-box {
  padding-right: 1.875rem;
}
.info-licenses-media .title-box .imgbox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EFEFEF;
}
.info-licenses-media a.edit-icon {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.info-licenses-media a.edit-icon span::before {
  color: #9D9D9D;
}
.info-licenses-media a:hover span::before {
  color: #0E378C;
}

.info-interests-media .title-box {
  padding-right: 7.5rem;
}
@media (max-width: 480.81px) {
  .info-interests-media .title-box {
    padding-right: 0;
  }
}
.info-interests-media .btn {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  min-height: inherit;
  padding: 0.3125rem 0.9375rem;
  border-radius: 0.25rem;
}
.info-interests-media .btn span {
  margin-right: 0.5rem;
  font-size: 0.8125rem;
}
@media (max-width: 480.81px) {
  .info-interests-media .btn {
    position: inherit;
    float: right;
    margin-top: 0.625rem;
  }
}

.card-msg-nav-dropdown {
  margin-top: 0;
  padding: 0.625rem;
  min-width: 265px;
}
@media (max-width: 480.81px) {
  .card-msg-nav-dropdown {
    min-width: 240px;
  }
}
.card-msg-nav-dropdown a {
  display: flex;
  align-items: center;
  color: #222222;
  padding: 0.625rem;
  font-weight: 600;
}
.card-msg-nav-dropdown a span {
  margin-right: 0.625rem;
}
.card-msg-nav-dropdown a span::before {
  color: #222222;
}

.connection-list .module-search-wrap .module-search {
  position: relative;
}
.connection-list .module-search-wrap .module-search input {
  background-color: #EFEFEF;
  padding: 0.75rem 3.4375rem 0.75rem 3.75rem;
}
.connection-list .module-search-wrap .module-search span {
  left: 30px;
}
.connection-list .module-search-wrap .module-search .search-close {
  position: absolute;
  right: 25px;
  top: 25px;
}
@media (max-width: 991.9px) {
  .connection-list .module-search-wrap .module-search .search-close {
    top: 17px;
  }
}

.people-listing .filter-btn-wrap {
  margin-left: 0.625rem;
}

.network-experience-wrap .info-experience-media p {
  margin: 0rem;
}

.meeting-listing {
  margin-top: 2.1875rem;
}
.meeting-listing .cal-time-events {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media (max-width: 1400.81px) {
  .meeting-listing .cal-time-events {
    max-height: 420px;
  }
}
.meeting-listing .picker-wrap {
  padding-bottom: 0.3125rem;
}
@media (max-width: 767.81px) {
  .meeting-listing .filter-btn-wrap {
    height: 40px;
  }
}
.meeting-listing .filter-btn-wrap .dropdown-menu {
  max-width: 295px;
  top: 53px;
}
@media (max-width: 767.81px) {
  .meeting-listing .filter-btn-wrap .dropdown-menu {
    top: 48px;
  }
}
@media (max-width: 991.9px) {
  .meeting-listing .search-filter-wrap {
    right: 15px;
  }
}
@media (max-width: 767.81px) {
  .meeting-listing .search-filter-wrap {
    top: 55px;
  }
}
.meeting-listing .module-search-wrap {
  position: relative;
}
.meeting-listing .module-search-wrap .module-search input {
  padding: 0.75rem 21.875rem 0.75rem 4.6875rem;
}
@media (max-width: 991.9px) {
  .meeting-listing .module-search-wrap .module-search input {
    padding: 0.75rem 18.4375rem 0.75rem 3.75rem;
  }
}
@media (max-width: 767.81px) {
  .meeting-listing .module-search-wrap .module-search input {
    padding: 0.75rem 2.8125rem 0.75rem 3.125rem;
  }
}
@media (max-width: 414.81px) {
  .meeting-listing .module-search-wrap .module-search input {
    padding: 0.75rem 2.8125rem 0.75rem 2.5rem;
  }
}
@media (max-width: 767.81px) {
  .meeting-listing .module-search-wrap .module-search span.search-close {
    display: block !important;
    right: 20px;
    left: auto;
  }
}
.meeting-listing .module-search-wrap .btn {
  min-height: 40px;
  padding: 0.4375rem 0.625rem;
  min-width: 150px;
  font-size: 0.9375rem;
  border-radius: 0.25rem;
}
.meeting-listing .module-search-wrap .btn.btn-reset {
  min-width: 135px;
}
.meeting-listing .module-search-wrap .btn:hover .icon-icon-plush::before {
  color: #0E378C;
}
.meeting-listing .module-search-wrap .grid-list-icon span {
  font-size: 0.875rem;
  margin-right: 0.5625rem;
}
.meeting-listing .module-search-wrap .grid-list-icon span:before {
  color: #ffffff;
}
.meeting-listing .days-list {
  color: #0E378C;
  font-size: 0.75rem;
  font-weight: 700;
  margin: 0rem 0rem 0.75rem 1.6875rem;
  display: inline-block;
}
@media (max-width: 375.81px) {
  .meeting-listing .days-list {
    margin: 0rem 0.4375rem 0.75rem 1.125rem;
  }
}
@media (max-width: 320.11px) {
  .meeting-listing .days-list {
    margin: 0rem 0rem 0.625rem 1.125rem;
  }
}
.meeting-listing .cal-month-view {
  padding-top: 0.9375rem;
  padding-bottom: 1.25rem;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.meeting-listing .cal-month-view .cal-day-badge {
  display: none;
}
.meeting-listing .cal-month-view .cal-day-cell, .meeting-listing .cal-month-view .cal-cell-top {
  min-height: 40px;
  max-width: 40px;
  position: relative;
}
@media (max-width: 320.11px) {
  .meeting-listing .cal-month-view .cal-day-cell, .meeting-listing .cal-month-view .cal-cell-top {
    min-height: 30px;
    max-width: 30px;
  }
}
.meeting-listing .cal-month-view .cal-day-cell.cal-today {
  background-color: #0E378C !important;
  border-radius: 100%;
}
.meeting-listing .cal-month-view .cal-day-cell.cal-today .cal-day-number {
  color: #ffffff;
  background-color: #0E378C !important;
  border-radius: 100%;
}
.meeting-listing .cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
  opacity: 1;
  color: #9D9D9D;
}
.meeting-listing .cal-month-view .cal-day-cell .cal-day-number {
  color: #222222;
  opacity: 1;
}
.meeting-listing .cal-month-view .cal-day-number {
  font-size: 0.8125rem !important;
  font-weight: 600 !important;
  margin: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
@media (max-width: 320.11px) {
  .meeting-listing .cal-month-view .cal-day-number {
    min-height: 30px;
    max-width: 30px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}
.meeting-listing .cal-month-view .cal-cell, .meeting-listing .cal-month-view .cal-cell-row {
  border: none !important;
}
.meeting-listing .cal-month-view .cal-cell {
  margin: 0rem 0.2625rem 0.3125rem 0.2625rem;
}
.meeting-listing .cal-month-view .cal-cell.date-active {
  background-color: #E3E3E3;
  border-radius: 100%;
}
.meeting-listing .cal-month-view .cal-cell:hover {
  border-radius: 100%;
}
.meeting-listing .cal-month-view .cal-cell.cal-has-events.cal-open:hover {
  border-radius: 100%;
  background-color: #E3E3E3 !important;
}
.meeting-listing .cal-month-view .cal-cell.cal-has-events.cal-event-highlight {
  background-color: transparent !important;
}
.meeting-listing .cal-month-view .cal-cell-row {
  margin: 0rem 1.875rem 0rem 0.9375rem;
}
@media (max-width: 375.81px) {
  .meeting-listing .cal-month-view .cal-cell-row {
    margin: 0;
  }
}
@media (max-width: 320.11px) {
  .meeting-listing .cal-month-view .cal-cell-row {
    margin: 0rem 1.875rem 0rem 1rem;
  }
}
.meeting-listing .cal-month-view .cal-event {
  position: absolute;
  right: 15px;
  bottom: 2px;
  text-align: center;
  width: 5px;
  height: 5px;
  background-color: #E73945 !important;
}
@media (max-width: 320.11px) {
  .meeting-listing .cal-month-view .cal-event {
    right: 10px;
  }
}
.meeting-listing .cal-month-view .cal-events {
  margin: 0;
  line-height: 0;
}
.meeting-listing .cal-month-view .cal-days {
  border: 0;
}
.meeting-listing .profile-media .imgbox {
  margin-right: 0.625rem;
}
.meeting-listing .profile-media .media-body h5 {
  font-size: 0.9375rem;
  color: #222222;
  font-weight: 600;
  max-width: 194px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.meeting-listing .profile-media .media-body p {
  font-size: 0.8125rem;
  max-width: 194px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 2;
  white-space: nowrap;
}
.meeting-listing .profile-media .icon-icon-tick {
  color: #0E378C;
  font-size: 0.9375rem;
  padding-top: 0.625rem;
}
.meeting-listing .filter-btn-wrap .dropdown-menu .identiy-group {
  border-bottom: 0;
}
.meeting-listing .select-options {
  margin-bottom: 1.25rem;
  border-bottom: 1px solid #222222;
}
.meeting-listing .select-options .title-span {
  padding-bottom: 1.25rem;
  display: inline-block;
}
.meeting-listing .no-meeting-view {
  align-items: center;
}

.calender-wrap {
  border-radius: 0.75rem;
}

.dropdown-calender {
  background-color: #ffffff;
  padding: 1.25rem 1.875rem;
  border-radius: 0.75rem 0.75rem 0rem 0rem;
  border-bottom: 1px solid #EFEFEF;
}
@media (max-width: 480.81px) {
  .dropdown-calender {
    padding: 0.9375rem;
  }
}
.dropdown-calender .form-control {
  background-color: #ffffff;
  border: none;
  background-image: url("../../images/dropdown-arrow.svg");
  background-repeat: no-repeat;
  background-position: 100% center;
  padding: 0 0.9375rem 0 0;
  height: auto;
  font-weight: 600;
  font-size: 1.125rem;
  cursor: pointer;
  width: 140px;
}
@media (max-width: 480.81px) {
  .dropdown-calender .form-control {
    font-size: 1rem;
  }
}
.dropdown-calender .form-control::placeholder {
  color: #222222;
}

.date-day-pick-wrap {
  position: relative;
}
@media (max-width: 767.81px) {
  .date-day-pick-wrap .btn {
    min-width: auto;
  }
}
.date-day-pick-wrap .today {
  position: absolute;
  right: 30px;
  top: 15px;
}
@media (max-width: 767.81px) {
  .date-day-pick-wrap .today {
    top: 7px;
  }
}
@media (max-width: 480.81px) {
  .date-day-pick-wrap .today {
    position: static;
    margin-bottom: 0.9375rem;
  }
}
.date-day-pick-wrap .today span {
  background-color: #EFEFEF;
  font-size: 0.8125rem;
  width: 104px;
  display: inline-block;
  padding: 0.5rem 0.3125rem;
  border-radius: 1.25rem;
  font-weight: 600;
  cursor: pointer;
}
.date-day-pick-wrap .picker {
  font-size: 1.375rem;
  font-weight: 700;
}
@media (max-width: 1200.81px) {
  .date-day-pick-wrap .picker {
    font-size: 1.125rem;
  }
}
@media (max-width: 767.81px) {
  .date-day-pick-wrap .picker {
    font-size: 1rem;
  }
}
.date-day-pick-wrap .navigator {
  font-size: 0.5rem;
  padding: 0rem 0.625rem;
}

.schduled-calender .tooltip.show {
  opacity: 1;
}
.schduled-calender .tooltip .tooltip-inner {
  background-color: #ffffff;
}
@media (max-width: 1200.81px) {
  .schduled-calender {
    margin-top: 1.875rem;
  }
}
.schduled-calender .card {
  padding: 0.5rem 0rem 0rem;
  overflow: hidden;
}
@media (max-width: 767.81px) {
  .schduled-calender .card {
    padding: 0.5rem 0.9375rem 1.875rem 0.9375rem;
  }
}
.schduled-calender .cal-week-view .cal-hour-segment {
  overflow: hidden;
  border-bottom: 1px solid #EFEFEF !important;
}
.schduled-calender .cal-week-view .cal-hour-segment:hover {
  background-color: transparent !important;
}
.schduled-calender .cal-week-view .cal-event-container.my-active {
  height: 141px !important;
}
.schduled-calender .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-bottom: 1px solid;
  border-bottom-color: #EFEFEF;
}
.schduled-calender .cal-week-view .cal-time {
  font-size: 0.8125rem;
  font-weight: 600;
  color: #9D9D9D;
  position: relative;
  border-right: 1px solid #EFEFEF;
  border-bottom: 1px solid #EFEFEF;
  height: 141px;
}
.schduled-calender .cal-week-view .cal-time::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 116px;
  background-color: #EFEFEF;
  height: 0.0625rem;
  width: 1200px;
}
@media (max-width: 767.81px) {
  .schduled-calender .cal-week-view .cal-time::after {
    left: 56px;
  }
}
.schduled-calender .cal-week-view .cal-time.active::before {
  background-color: transparent;
}
.schduled-calender .cal-week-view .cal-time.active::after {
  display: none;
}
.schduled-calender .schduled-info {
  padding: 1.25rem;
  background-color: #ffffff;
  border-left: 5px solid #0E378C;
  max-height: 141px;
  min-height: 141px;
  overflow: hidden;
  box-shadow: 0.25rem 0.25rem 0.625rem -0.125rem #dedede;
  margin-right: 1.875rem;
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
@media (max-width: 1200.81px) {
  .schduled-calender .schduled-info {
    padding: 0.75rem;
  }
}
@media (max-width: 767.81px) {
  .schduled-calender .schduled-info {
    padding: 0.3125rem 0.3125rem 0.3125rem 0.75rem;
  }
}
.schduled-calender .schduled-info .schduled-img-wrap {
  min-width: 110px;
}
.schduled-calender .schduled-info .s-info {
  padding-right: 1.5625rem;
}
@media (max-width: 1200.81px) {
  .schduled-calender .schduled-info .s-info {
    padding-right: 0.75rem;
  }
}
@media (max-width: 767.81px) {
  .schduled-calender .schduled-info .s-info {
    padding: 0rem;
  }
}
.schduled-calender .schduled-info .s-info h5 {
  font-weight: 600;
  max-width: 505px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 26px;
  white-space: nowrap;
}
@media (max-width: 1200.81px) {
  .schduled-calender .schduled-info .s-info h5 {
    font-size: 1rem;
    margin-bottom: 0.3125rem;
    max-height: 20px;
    max-width: 665px;
  }
}
@media (max-width: 991.9px) {
  .schduled-calender .schduled-info .s-info h5 {
    max-width: 440px;
  }
}
@media (max-width: 767.81px) {
  .schduled-calender .schduled-info .s-info h5 {
    max-width: 350px;
  }
}
@media (max-width: 480.81px) {
  .schduled-calender .schduled-info .s-info h5 {
    max-width: 240px;
    margin-bottom: 0rem;
  }
}
@media (max-width: 375.81px) {
  .schduled-calender .schduled-info .s-info h5 {
    max-width: 180px;
  }
}
@media (max-width: 320.11px) {
  .schduled-calender .schduled-info .s-info h5 {
    max-width: 150px;
  }
}
.schduled-calender .schduled-info .s-info .event-desc {
  max-height: 68px;
  overflow: hidden;
  word-break: break-all;
}
@media (max-width: 767.81px) {
  .schduled-calender .schduled-info .s-info .event-desc {
    max-height: 55px;
  }
}
.schduled-calender .schduled-info .s-info .event-desc p {
  margin: 0;
  font-size: 0.9375rem;
  line-height: 1.46;
}
@media (max-width: 767.81px) {
  .schduled-calender .schduled-info .s-info .event-desc p {
    font-size: 0.875rem;
  }
}
.schduled-calender .schduled-info .schduled-img img {
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  position: relative;
  margin-left: -0.375rem;
}
.schduled-calender .schduled-info .counter {
  background-color: #0E378C;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 0.8125rem;
  font-weight: 600;
}
.schduled-calender .schduled-info .counter span {
  font-size: 0.4375rem;
  font-weight: 700;
}
.schduled-calender .schduled-info .ac-status {
  font-size: 0.75rem;
  font-weight: 700;
  color: #9D9D9D;
  padding-bottom: 0.625rem;
  display: inline-block;
}
@media (max-width: 480.81px) {
  .schduled-calender .schduled-info .ac-status {
    padding-bottom: 0.3125rem;
  }
}
.schduled-calender .cal-day-view .cal-current-time-marker {
  display: none;
}

.my-meetings-detail {
  margin-top: 2.1875rem;
}
@media (max-width: 480.81px) {
  .my-meetings-detail .breadcrumb .breadcrumb-item {
    padding-bottom: 0.625rem;
  }
}
.my-meetings-detail .card .info p {
  font-size: 1rem;
  line-height: 1.625;
}
.my-meetings-detail h2 {
  font-size: 2rem;
}
@media (max-width: 480.81px) {
  .my-meetings-detail .content-wrap {
    flex-wrap: wrap;
  }
}
.my-meetings-detail .content-wrap h2 {
  font-size: 1.375rem;
  width: calc(100% - 70px);
}
@media (max-width: 767.81px) {
  .my-meetings-detail .content-wrap h2 {
    font-size: 1.25rem;
  }
}
@media (max-width: 480.81px) {
  .my-meetings-detail .content-wrap h2 {
    width: 100%;
  }
}
@media (max-width: 767.81px) {
  .my-meetings-detail .content-wrap .icon-wrap {
    padding-top: 0.625rem;
  }
}
.my-meetings-detail .content-wrap .icon-wrap span {
  margin-left: 1.5625rem;
  font-size: 1.25rem;
  cursor: pointer;
}
@media (max-width: 767.81px) {
  .my-meetings-detail .content-wrap .icon-wrap span {
    font-size: 1rem;
    margin-left: 0.9375rem;
  }
}
@media (max-width: 480.81px) {
  .my-meetings-detail .content-wrap .icon-wrap span {
    margin: 0rem 0.9375rem 0.9375rem 0rem;
    display: inline-block;
  }
}
.my-meetings-detail .content-wrap .icon-wrap span:hover {
  color: #0E378C;
}
.my-meetings-detail .date-time {
  margin-bottom: 0.625rem;
  font-size: 0.75rem;
  color: #9D9D9D;
  text-transform: uppercase;
  font-weight: 700;
}
.my-meetings-detail .spacer-line {
  border-bottom: 1px solid #EFEFEF;
  margin-top: 0.625rem;
  margin-bottom: 1.875rem;
}
.my-meetings-detail .location-link span {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  background-color: #EFEFEF;
  display: inline-block;
  text-align: center;
  color: #0E378C;
  margin-right: 0.625rem;
}
.my-meetings-detail .location-link a {
  color: #0E378C;
}
.my-meetings-detail .location-link a:hover {
  color: #222222;
}
.my-meetings-detail .contnet-wrap {
  margin-bottom: 1.5625rem;
}
.my-meetings-detail .participants h5 {
  margin: 0;
  font-weight: 600;
  max-width: 135px;
}
.my-meetings-detail .participants span {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #47B872;
  margin: 0.4375rem 0.5rem 0rem 0rem;
}
.my-meetings-detail .participants span.acc-await {
  color: #9D9D9D;
}
.my-meetings-detail .participants span .gray-dot {
  width: 3px;
  height: 3px;
  margin: 0rem 0.25rem;
  vertical-align: 2px;
}
.my-meetings-detail .requsted-status .spacer-line {
  margin-top: 1.5625rem;
  margin-bottom: 1.25rem;
}
.my-meetings-detail .requsted-status .req-info {
  margin-left: 0.8125rem;
}
.my-meetings-detail .requsted-status .req-info h5 {
  font-size: 0.9375rem;
  margin: 0;
  font-weight: 600;
}
.my-meetings-detail .requsted-status .req-info span {
  font-size: 0.8125rem;
  color: #0E378C;
}
.my-meetings-detail .requsted-status .req-info span a {
  font-size: 0.8125rem;
  color: #9D9D9D;
}
.my-meetings-detail .requsted-status .org {
  font-size: 0.75rem;
  color: #0E378C;
  text-transform: uppercase;
}
.my-meetings-detail .requsted-status .req-status {
  color: #47B872;
  margin-left: 0.8125rem;
}
.my-meetings-detail .requsted-status .req-status.icon-icon-close-black {
  color: #E73945;
}
.my-meetings-detail .requsted-status .user-info {
  max-width: 200px;
  cursor: pointer;
}
@media (max-width: 991.9px) {
  .my-meetings-detail .requsted-status .user-info {
    max-width: 340px;
  }
}
@media (max-width: 480.81px) {
  .my-meetings-detail .requsted-status .user-info {
    max-width: 200px;
  }
}
@media (max-width: 320.11px) {
  .my-meetings-detail .requsted-status .user-info {
    max-width: 100%;
  }
}
.my-meetings-detail .requsted-status .user-info .img-wrap img {
  min-width: 40px;
  border-radius: 100%;
}
.my-meetings-detail .requsted-status .right-wrap {
  max-width: 100px;
  word-break: break-all;
}
@media (max-width: 320.11px) {
  .my-meetings-detail .requsted-status .right-wrap {
    width: 100%;
    margin-top: 0.625rem;
  }
}
.my-meetings-detail .requsted-status.rsvp .req-info span a {
  color: #0E378C;
}
.my-meetings-detail .card .info {
  padding-right: 0;
}

.set-reminder-popup.modal-dialog {
  max-width: 510px !important;
}

.co-info .card {
  padding: 4.375rem;
  border-radius: 1.5rem;
}
@media (max-width: 480.81px) {
  .co-info .card {
    padding: 1.875rem;
  }
}
.co-info .card h2 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2.1875rem;
}
@media (max-width: 480.81px) {
  .co-info .card h2 {
    font-size: 1.5rem;
    padding-right: 1.5625rem;
  }
}
.co-info .card .btn {
  margin-top: 1.25rem;
}
.co-info .close-ic {
  position: absolute;
  right: 30px;
  top: 35px;
}
.co-info .close-ic span {
  font-size: 1.3125rem;
}
@media (max-width: 480.81px) {
  .co-info .close-ic span {
    font-size: 1.125rem;
  }
}
.co-info ul {
  padding-left: 1.25rem;
}
@media (max-width: 480.81px) {
  .co-info ul {
    padding-left: 0rem;
  }
}
.co-info ul li {
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
  cursor: pointer;
}
.co-info ul li span {
  color: #9D9D9D;
  font-size: 1.25rem;
  margin-right: 1.125rem;
}
.co-info ul li .icon-icon-tick {
  float: right;
  font-size: 0.875rem;
  color: #0E378C;
}

.identity .img-wrap {
  border-radius: 100%;
  overflow: hidden;
  min-width: 40px;
  margin-right: 0.9375rem;
}
.identity .info-bar h6 {
  margin: 0rem;
  color: #0E378C;
  font-weight: 600;
}
.identity .info-bar p {
  margin: 0rem;
  font-size: 0.875rem;
}

.default-pointer {
  cursor: default !important;
}

.create-meeting-wrap {
  margin-top: 2.1875rem;
}
.create-meeting-wrap .select-box .ng-arrow-wrapper {
  cursor: inherit;
}
.create-meeting-wrap ng-select.ng-select-single .ng-select-container.ng-has-value {
  border-radius: 0.75rem;
}
.create-meeting-wrap .ng-select .ng-select-container .ng-value-container .ng-input {
  top: auto;
  padding-right: 2.5rem;
  padding-left: 1.5rem;
}
.create-meeting-wrap .ng-select .ng-select-container .ng-value-container .ng-input input {
  color: #474747 !important;
}
.create-meeting-wrap .ng-select .ng-select-container .ng-value-container .ng-input > input {
  margin: 0;
}
.create-meeting-wrap .imgbox img {
  border-radius: 100%;
  min-width: 40px;
}
.create-meeting-wrap .user-info {
  margin-left: 0.625rem;
}
.create-meeting-wrap .user-info h6 {
  color: #0E378C;
  margin: 0;
}
@media (max-width: 768.81px) {
  .create-meeting-wrap .user-info h6 {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 160px;
  }
}
@media (max-width: 576.81px) {
  .create-meeting-wrap .user-info span {
    max-width: 180px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.create-meeting-wrap .timepciker-create .btn {
  min-height: auto;
  padding: 0.3125rem 1rem;
}
.create-meeting-wrap .timepciker-create .btn span {
  right: 44px;
  color: #9D9D9D;
}
.create-meeting-wrap .timepciker-create .btn-default {
  background-color: #0E378C;
  color: #ffffff;
  min-width: auto;
}
.create-meeting-wrap .bs-timepicker-field {
  padding: 0rem;
  margin: 0 auto;
  background-image: none;
}

.create-meeting .section-title {
  margin-bottom: 1.875rem;
}
.create-meeting .section-title h2 {
  font-size: 2rem;
  margin-bottom: 0.9375rem;
}
@media (max-width: 767.81px) {
  .create-meeting .section-title h2 {
    font-size: 1.5rem;
  }
}
.create-meeting .section-title h5 {
  font-weight: 400;
}
@media (max-width: 767.81px) {
  .create-meeting .section-title h5 {
    font-size: 1rem;
  }
}
.create-meeting .content-wrap {
  padding: 4.375rem 0rem;
  text-align: center;
  margin: 0 auto;
}
@media (min-width: 768.91px) {
  .create-meeting .content-wrap {
    width: 570px;
  }
}
@media (max-width: 767.81px) {
  .create-meeting .content-wrap {
    padding: 1.875rem 0rem;
    margin: 0;
  }
}
@media (max-width: 480.81px) {
  .create-meeting .content-wrap {
    padding: 0.9375rem 0rem;
  }
}
.create-meeting .spacer-line {
  border-top: 1px solid #EFEFEF;
  margin: 1.25rem 0rem;
}
.create-meeting.edit-metting .form-control[readonly] {
  background-color: transparent;
  border: 1px solid #9D9D9D;
  color: #495057;
}

.reminder-listing .reminder-wrap h5 {
  margin: 0rem;
  font-weight: 600;
}
.reminder-listing .reminder-wrap .icon-info {
  color: #9D9D9D;
  font-size: 1.625rem;
  padding-right: 0.75rem;
}
@media (max-width: 767.81px) {
  .reminder-listing .reminder-wrap .icon-info {
    font-size: 1.25rem;
  }
}
.reminder-listing .reminder-wrap .info span {
  color: #9D9D9D;
  padding-top: 0.5rem;
  display: inline-block;
}
.reminder-listing .reminder-wrap .icon-icon-plush {
  color: #9D9D9D;
  font-size: 1.125rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}
@media (max-width: 767.81px) {
  .reminder-listing .reminder-wrap .icon-icon-plush {
    font-size: 0.9375rem;
  }
}
.reminder-listing .reminder-wrap .icon-icon-right-angle-arrow {
  color: #9D9D9D;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.reminder-listing .left-wrap {
  width: calc(100% - 25px);
}
.reminder-listing .btn-wrap {
  margin: 2.5rem 6.25rem 0rem;
}
@media (max-width: 480.81px) {
  .reminder-listing .btn-wrap {
    margin: 2.5rem 0rem 0rem;
  }
}

.meeting-form .form-group label {
  font-size: 0.8125rem;
}
.meeting-form textarea {
  line-height: 30px;
}
.meeting-form .icon-icon-info {
  font-size: 1rem;
  margin-left: 0.375rem !important;
}
.meeting-form .input-with-icon span {
  font-size: 1.375rem;
}
@media (max-width: 767.81px) {
  .meeting-form .input-with-icon span {
    font-size: 1.125rem;
  }
}
.meeting-form .input-with-icon span:before {
  color: #9D9D9D;
}

.select-participants.modal-dialog {
  max-width: 710px !important;
}
.select-participants .participants-search {
  position: relative;
}
.select-participants .participants-search input {
  width: 100%;
  background-color: #EFEFEF;
  color: #9D9D9D;
  font-size: 1rem;
  line-height: 1.625rem;
  letter-spacing: -0.015625rem;
  border-radius: 0.75rem;
  border: none;
  padding: 0.75rem 1.875rem 0.75rem 3.9375rem;
}
@media (max-width: 767.81px) {
  .select-participants .participants-search input {
    padding: 0.75rem 1.875rem 0.75rem 2.5rem;
  }
}
.select-participants .participants-search span {
  position: absolute;
  left: 33px;
  top: 16px;
  font-size: 1rem;
}
@media (max-width: 767.81px) {
  .select-participants .participants-search span {
    left: 14px;
  }
}
.select-participants .participants-list .s-title {
  margin: 1.25rem 0rem;
  font-weight: 600;
}
.select-participants .participants-list .reminder-wrap {
  border-bottom: 1px solid #EFEFEF;
  padding: 1.25rem 0rem;
}
.select-participants .participants-list .reminder-wrap .img-wrap {
  min-width: 56px;
}
.select-participants .participants-list .reminder-wrap .img-wrap img {
  border-radius: 100%;
}
.select-participants .participants-list .reminder-wrap h5 {
  margin: 0rem;
  font-weight: 600;
}
@media (max-width: 767.81px) {
  .select-participants .participants-list .reminder-wrap h5 {
    font-size: 1rem;
  }
}
.select-participants .participants-list .reminder-wrap .icon-icon-close {
  font-size: 1.25rem;
  padding-top: 0.75rem;
  cursor: pointer;
}
@media (max-width: 767.81px) {
  .select-participants .participants-list .reminder-wrap .icon-icon-close {
    font-size: 0.875rem;
  }
}
.select-participants .participants-list .reminder-wrap .icon-info {
  color: #9D9D9D;
  font-size: 1.625rem;
}
.select-participants .participants-list .reminder-wrap .info {
  padding: 0rem 0rem 0rem 1.25rem;
}
.select-participants .participants-list .reminder-wrap .info span {
  color: #222222;
  padding-top: 0.5rem;
  display: inline-block;
}
@media (max-width: 767.81px) {
  .select-participants .participants-list .reminder-wrap .info span {
    font-size: 0.8125rem;
  }
}
.select-participants .participants-list .reminder-wrap .icon-icon-plush {
  color: #9D9D9D;
  font-size: 1.125rem;
}
.select-participants .participants-list .left-wrap {
  width: calc(100% - 25px);
}
.select-participants .participants-list .btn-wrap {
  margin: 0rem 6.25rem 0rem;
}
@media (max-width: 480.81px) {
  .select-participants .participants-list .btn-wrap {
    margin: 0rem;
  }
}
.select-participants .card .btn {
  margin-top: 1.875rem;
}

.create.ng-select-single .ng-select-container.ng-has-value {
  cursor: pointer;
}
.create .ng-select-container .ng-value-container .ng-input > input {
  cursor: pointer !important;
}

.default-pointer {
  cursor: default !important;
}

.notifications-layout .title-wrap .btn {
  min-height: inherit;
  min-width: inherit;
  font-size: 0.9375rem;
}
.notifications-layout .info {
  padding-right: 0;
}
.notifications-layout .info:last-child .notification-list {
  margin-bottom: 0rem;
}
.notifications-layout .info .notification-list {
  margin-bottom: 2.5rem;
}
.notifications-layout .info .notification-list li {
  background-color: rgba(14, 55, 140, 0.03);
  border-radius: 0px;
}
.notifications-layout .info .notification-list li.active {
  background-color: rgba(14, 55, 140, 0.1);
}
.notifications-layout .info .notification-list li:first-child {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.notifications-layout .info .notification-list li:last-child {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.notifications-layout .info .notification-list.earlier-notification-list li {
  background-color: rgba(239, 239, 239, 0.3);
}
.notifications-layout .info .notification-list.earlier-notification-list li.active {
  background-color: rgba(239, 239, 239, 0.7);
}
.notifications-layout .info .notification-list li {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.9375rem 1.875rem;
  border-bottom: 1px solid rgba(157, 157, 157, 0.2);
}
.notifications-layout .info .notification-list li:last-child {
  border-bottom: none;
}
.notifications-layout .info .notification-list li p {
  margin-bottom: 0;
  font-size: 0.9375rem;
  padding-right: 4.0625rem;
}
.notifications-layout .info .notification-list li p .primary-text {
  font-size: 0.9375rem;
}
.notifications-layout .info .notification-list li span {
  position: absolute;
  right: 30px;
  top: 18px;
  font-size: 0.8125rem;
  color: #9D9D9D;
}

.message-layout .dropdown-menu {
  padding: 0.9375rem;
  left: 0;
  right: 0;
}
.message-layout .module-search-wrap {
  padding-bottom: 0rem;
}
.message-layout .module-search-wrap .title-wrap {
  padding: 1.25rem;
}
.message-layout .module-search-wrap .search-custom-checkbox {
  padding: 0.9375rem;
  border-top: 1px solid #EFEFEF;
}
.message-layout .module-search-wrap .identiy-group {
  padding: 0.9375rem 0rem;
  border-top: 1px solid #EFEFEF;
  margin: 0rem 0.9375rem;
  max-height: 250px;
  border: none;
}
.message-layout .module-search-wrap .identiy-group .media {
  align-items: center;
}
.message-layout .module-search-wrap .identiy-group .profile-media .imgbox img {
  width: 50px;
  height: 50px;
  border: none;
}
.message-layout .module-search-wrap .identiy-group .profile-media .media-body h5 {
  font-size: 0.9375rem;
  color: #0E378C;
  font-weight: 600;
}
.message-layout .module-search-wrap .identiy-group .profile-media .media-body p {
  font-size: 0.8125rem;
}
.message-layout .module-search-wrap .identiy-group .profile-media .icon-icon-tick {
  color: #0E378C;
}
.message-layout .module-search-wrap .identiy-group .profile-media:last-child {
  margin-bottom: 0rem;
}
.message-layout .filter-btn-wrap {
  position: relative;
  display: block;
  padding: 0rem;
}
.message-layout .icon-icon-plush {
  font-size: 0.9375rem;
}
.message-layout .msg-list-wrap {
  overflow: hidden;
  height: calc(100vh - 125px);
}
.message-layout .msg-list-wrap .title-wrap {
  padding: 1.25rem 1.875rem;
  margin-bottom: 0;
}
@media (max-width: 414.81px) {
  .message-layout .msg-list-wrap .title-wrap {
    padding: 0.625rem 0.9375rem;
  }
}
.message-layout .msg-list-wrap .title-wrap h5 {
  margin-bottom: 0;
}
.message-layout .msg-list-wrap .title-wrap .btn {
  min-width: inherit;
  min-height: inherit;
  line-height: normal;
  overflow: visible;
}
.message-layout .msg-list-wrap .title-wrap .btn span {
  font-size: 16px;
}
.message-layout .msg-list-wrap .title-wrap .btn span.filtered::after {
  content: "";
  background: #E73945;
  width: 8px;
  height: 8px;
  border-radius: 50rem;
  position: absolute;
  right: -2px;
  top: -2px;
}
.message-layout .msg-list-wrap .title-wrap .add-btn {
  margin-right: 0.9375rem;
}
.message-layout .msg-list-wrap .searchbox {
  border-bottom: 1px solid rgba(239, 239, 239, 0.4);
  border-top: 1px solid rgba(239, 239, 239, 0.4);
}
.message-layout .msg-list-wrap .searchbox .header-search {
  width: 100%;
  position: relative;
  border-top: 1px solid #EFEFEF;
  border-bottom: 1px solid #EFEFEF;
}
.message-layout .msg-list-wrap .searchbox .header-search input {
  width: 100%;
  color: #222222;
  font-size: 1rem;
  line-height: 1.625rem;
  letter-spacing: -0.015625rem;
  border-radius: 0;
  border: none;
  padding: 0.75rem 1.875rem 0.75rem 3.75rem;
  background-color: #f9f9f9;
}
.message-layout .msg-list-wrap .searchbox .header-search span {
  position: absolute;
  left: 33px;
  top: 16px;
  font-size: 1rem;
}
.message-layout .msg-list-wrap .searchbox .header-search span.close-search {
  right: 33px;
  left: auto;
}
.message-layout .msg-list-wrap .all-msg-box {
  max-height: calc(100% - 117px);
  overflow: auto;
}
.message-layout .msg-list-wrap .all-msg-box li {
  display: flex;
  align-items: flex-start;
  padding: 1.25rem;
  position: relative;
  border-bottom: 1px solid #EFEFEF;
  cursor: pointer;
}
@media (max-width: 414.81px) {
  .message-layout .msg-list-wrap .all-msg-box li {
    padding: 0.9375rem;
  }
}
.message-layout .msg-list-wrap .all-msg-box li .imgbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  position: relative;
  margin-right: 10px;
}
.message-layout .msg-list-wrap .all-msg-box li .imgbox img {
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.message-layout .msg-list-wrap .all-msg-box li .imgbox .status {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 3.125rem;
  background-color: #47B872;
}
.message-layout .msg-list-wrap .all-msg-box li .msg-info {
  width: calc(100% - 60px);
  padding-right: 3.75rem;
}
.message-layout .msg-list-wrap .all-msg-box li .msg-info h6 {
  margin-bottom: 0;
  font-size: 1rem;
  margin-right: 10px;
}
.message-layout .msg-list-wrap .all-msg-box li .msg-info h6.text-ellipsis-2line {
  height: auto;
  line-height: 1.5;
  font-weight: 600;
}
.message-layout .msg-list-wrap .all-msg-box li .msg-info p {
  font-size: 0.9375rem;
}
.message-layout .msg-list-wrap .all-msg-box li .msg-info p.text-ellipsis-1line {
  height: 22px;
}
.message-layout .msg-list-wrap .all-msg-box li .msg-time {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 0.75rem;
  text-transform: uppercase;
}
.message-layout .msg-list-wrap .all-msg-box li .new-msg-count {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background-color: #E73945;
  border-radius: 1.5rem;
  padding: 0.125rem 0.5rem;
  color: #ffffff;
  width: auto;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6875rem;
  font-weight: 600;
}

.msg-filter-by {
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  border-radius: 0.75rem;
}
.msg-filter-by .title-wrap .close-btn {
  min-width: inherit;
  min-height: inherit;
  line-height: normal;
}
.msg-filter-by .title-wrap .close-btn span {
  font-size: 0.8125rem;
}
.msg-filter-by .title-wrap .close-btn span::before {
  color: #222222;
}
.msg-filter-by .title-wrap .close-btn:hover span::before {
  color: #0E378C;
}
.msg-filter-by.show {
  left: 0;
}

.received-msg-card {
  height: calc(100vh - 125px);
}
@media (max-width: 991.9px) {
  .received-msg-card {
    height: auto;
  }
}
.received-msg-card .contact-name {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.25rem 3.125rem 1.25rem 1.875rem;
  border-bottom: 1px solid rgba(239, 239, 239, 0.4);
}
@media (max-width: 414.81px) {
  .received-msg-card .contact-name {
    padding: 0.625rem 1.875rem 0.625rem 0.9375rem;
  }
}
.received-msg-card .contact-name .back-btn {
  min-height: inherit;
  min-width: inherit;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  padding: 0;
  line-height: normal;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin-right: 0.5rem;
  display: none;
}
@media (max-width: 991.9px) {
  .received-msg-card .contact-name .back-btn {
    display: flex;
  }
}
.received-msg-card .contact-name .back-btn span {
  line-height: 11px;
}
.received-msg-card .contact-name .back-btn span::before {
  color: #0E378C;
  font-size: 0.75rem;
}
.received-msg-card .contact-name .imgbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: relative;
  margin-right: 0.625rem;
}
.received-msg-card .contact-name .imgbox img {
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.received-msg-card .contact-name .imgbox .status {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 3.125rem;
  background-color: #47B872;
}
.received-msg-card .contact-name .namebox {
  width: calc(100% - 50px);
}
.received-msg-card .contact-name .namebox h6 {
  margin-bottom: 0;
  font-weight: 600;
}
.received-msg-card .contact-name .action-dropdown-wrap {
  position: absolute;
  right: 30px;
}
@media (max-width: 414.81px) {
  .received-msg-card .contact-name .action-dropdown-wrap {
    right: 15px;
  }
}
.received-msg-card .contact-name .action-dropdown-wrap .action-btn {
  padding: 0.3125rem;
  min-width: inherit;
  min-height: inherit;
  line-height: normal;
}
.received-msg-card .contact-name .action-dropdown-wrap .action-btn span {
  font-size: 1rem;
}
.received-msg-card .contact-name .action-dropdown-wrap .action-btn:hover span::before {
  color: #0E378C;
}
.received-msg-card .contact-name .action-dropdown-wrap .dropdown-nav {
  min-width: 13.4375rem;
  right: -21px !important;
}
.received-msg-card .contact-name .action-dropdown-wrap .dropdown-nav a {
  display: flex;
  align-items: center;
  padding: 0.9375rem;
  color: #222222;
}
.received-msg-card .contact-name .action-dropdown-wrap .dropdown-nav a span {
  margin-right: 0.75rem;
  font-size: 1.125rem;
}
.received-msg-card .contact-name .action-dropdown-wrap .dropdown-nav a span::before {
  color: #222222;
}
.received-msg-card .contact-name .action-dropdown-wrap .dropdown-nav a:hover {
  color: #0E378C;
}
.received-msg-card .contact-name .action-dropdown-wrap .dropdown-nav a:hover span::before {
  color: #0E378C;
}
.received-msg-card .new-contact-notification {
  border-bottom: 1px solid rgba(101, 101, 101, 0.1);
  padding: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
  min-height: calc(100% - 210px);
}
.received-msg-card .new-contact-notification .imgbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  position: relative;
  margin-bottom: 1.25rem;
}
.received-msg-card .new-contact-notification .imgbox img {
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.received-msg-card .new-contact-notification h5 {
  font-size: 1.25rem;
}
.received-msg-card .new-contact-notification h6 {
  font-size: 0.9375rem;
}
.received-msg-card .new-contact-notification p {
  font-size: 0.8125rem;
}
.received-msg-card .new-contact-notification .spacer-line {
  display: block;
  width: 80%;
}
.received-msg-card .new-contact-notification .media {
  display: flex;
  align-items: center;
  margin-bottom: 1.875rem;
}
.received-msg-card .new-contact-notification .media .imgbox {
  width: 26px;
  height: 26px;
  border-radius: 100%;
  margin-right: 0.625rem;
  margin-bottom: 0;
}
.received-msg-card .new-contact-notification .media .imgbox img {
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.received-msg-card .new-contact-notification .media h6 {
  margin-bottom: 0;
  font-size: 0.9375rem;
}
.received-msg-card .new-contact-notification .btnbox {
  display: flex;
  align-items: center;
}
.received-msg-card .new-contact-notification .btnbox .btn {
  min-width: 136px;
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3125rem 0.9375rem;
  font-size: 1.0625rem;
}
@media (max-width: 1200.81px) {
  .received-msg-card .new-contact-notification .btnbox .btn {
    font-size: 1rem;
  }
}
@media (max-width: 767.81px) {
  .received-msg-card .new-contact-notification .btnbox .btn {
    font-size: 0.875rem;
  }
}
.received-msg-card .new-contact-notification .btnbox .btn span {
  margin-right: 0.75rem;
  font-size: 0.8125rem;
}
.received-msg-card .new-contact-notification .btnbox .btn:last-child {
  margin-left: 1.25rem;
}
.received-msg-card .new-contact-notification .block-btn {
  min-height: inherit;
  min-width: inherit;
  line-height: normal;
  margin-top: 1.875rem;
  font-size: 0.9375rem;
}
@media (max-width: 1200.81px) {
  .received-msg-card .new-contact-notification ~ .msg-chatbox {
    height: auto;
    padding: 0;
  }
}
.received-msg-card .msg-chatbox {
  padding: 1.875rem 1.875rem 0.625rem;
  max-height: calc(100% - 161px);
  overflow: auto;
  height: 100%;
}
@media (max-width: 414.81px) {
  .received-msg-card .msg-chatbox {
    padding: 1.25rem 0.9375rem 0.625rem;
  }
}
@media (max-width: 991.9px) {
  .received-msg-card .msg-chatbox {
    height: calc(100vh - 290px);
  }
}
@media (max-width: 576.81px) {
  .received-msg-card .msg-chatbox {
    height: calc(100vh - 260px);
  }
}
.received-msg-card .msg-chatbox .msg-receive-time-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto 1.875rem;
}
.received-msg-card .msg-chatbox .msg-receive-time-outer .msg-receive-status {
  text-transform: uppercase;
  background-color: rgba(239, 239, 239, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #222222;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  width: auto;
}
.received-msg-card .msg-outer {
  width: 100%;
  display: flex;
}
.received-msg-card .msg-outer.right-msg {
  justify-content: flex-end;
}
.received-msg-card .msg-outer.left-msg {
  justify-content: flex-start;
}
.received-msg-card .msg-outer .msg-text-box {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.75rem;
  max-width: 21.1875rem;
  position: relative;
  padding-left: 2.875rem;
}
@media (max-width: 414.81px) {
  .received-msg-card .msg-outer .msg-text-box {
    padding-left: 2.25rem;
  }
}
.received-msg-card .msg-outer .msg-text-box .profile-img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  margin-right: 0.625rem;
  margin-bottom: 0;
  border: 1px solid rgba(239, 239, 239, 0.4);
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 414.81px) {
  .received-msg-card .msg-outer .msg-text-box .profile-img {
    width: 25px;
    height: 25px;
  }
}
.received-msg-card .msg-outer .msg-text-box .profile-img img {
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.received-msg-card .msg-outer .msg-text-box .msg {
  padding: 0.9375rem;
  background-color: #EFEFEF;
}
@media (max-width: 414.81px) {
  .received-msg-card .msg-outer .msg-text-box .msg {
    padding: 0.625rem;
  }
}
.received-msg-card .msg-outer .msg-text-box .msg p {
  color: #222222;
  margin-bottom: 0.3125rem;
  text-align: left;
  font-size: 0.8125rem;
  max-width: 21.1875rem;
  word-wrap: break-word;
  white-space: pre-line;
}
.received-msg-card .msg-outer .msg-text-box .msg .time {
  color: #9D9D9D;
  font-size: 0.6875rem;
  text-align: left;
  display: block;
}
.received-msg-card .msg-outer .msg-text-box .msg .uploaded-imgbox {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
  max-width: 240px;
  flex-wrap: wrap;
}
.received-msg-card .msg-outer .msg-text-box .msg .uploaded-imgbox.multipost .imgbox {
  width: 100px;
  height: 100px;
}
.received-msg-card .msg-outer .msg-text-box .msg .uploaded-imgbox.multipost .imgbox:nth-child(2n+1) {
  margin-left: 0px;
}
@media (max-width: 414.81px) {
  .received-msg-card .msg-outer .msg-text-box .msg .uploaded-imgbox {
    flex-wrap: wrap;
  }
}
.received-msg-card .msg-outer .msg-text-box .msg .uploaded-imgbox .imgbox {
  width: 100px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}
.received-msg-card .msg-outer .msg-text-box .msg .uploaded-imgbox .imgbox:first-child {
  margin-left: 0px;
}
@media (max-width: 414.81px) {
  .received-msg-card .msg-outer .msg-text-box .msg .uploaded-imgbox .imgbox:first-child {
    margin: 0.625rem 0rem;
  }
}
.received-msg-card .msg-outer .msg-text-box .msg .uploaded-imgbox .imgbox img {
  border-radius: 0.5rem;
  object-fit: cover;
  object-position: center;
  width: auto;
  height: 100%;
}
.received-msg-card .msg-outer .msg-text-box .msg .uploaded-imgbox .imgbox-group {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.received-msg-card .msg-outer .msg-text-box .msg .uploaded-imgbox .imgbox-group .img-count {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 34, 34, 0.55);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 1rem;
}
.received-msg-card .msg-outer .msg-text-box .msg .uploaded-imgbox .imgbox-group .img-count span {
  margin-left: 0.1875rem;
  font-size: 0.6875rem;
}
.received-msg-card .msg-outer .msg-text-box .msg .uploaded-imgbox .imgbox-group .img-count span::before {
  color: #ffffff;
}
.received-msg-card .msg-outer .msg-text-box .msg .uploaded-file-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0.625rem;
  padding-right: 2.1875rem;
  position: relative;
}
@media (max-width: 414.81px) {
  .received-msg-card .msg-outer .msg-text-box .msg .uploaded-file-wrap {
    padding-right: 0;
  }
}
.received-msg-card .msg-outer .msg-text-box .msg .uploaded-file-wrap .file {
  display: flex;
  align-items: center;
  min-width: 200px;
}
@media (max-width: 414.81px) {
  .received-msg-card .msg-outer .msg-text-box .msg .uploaded-file-wrap .file {
    min-width: inherit;
    align-items: flex-start;
    flex-direction: column;
  }
}
.received-msg-card .msg-outer .msg-text-box .msg .uploaded-file-wrap .file .iconbox {
  margin-right: 0.625rem;
  margin-bottom: 0.3125rem;
  width: 40px;
  height: 40px;
  border-radius: 0.25rem;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 414.81px) {
  .received-msg-card .msg-outer .msg-text-box .msg .uploaded-file-wrap .file .iconbox {
    width: 25px;
    height: 25px;
  }
}
.received-msg-card .msg-outer .msg-text-box .msg .uploaded-file-wrap .file .iconbox span::before {
  color: #222222;
}
@media (max-width: 414.81px) {
  .received-msg-card .msg-outer .msg-text-box .msg .uploaded-file-wrap .file .iconbox span {
    font-size: 0.9375rem;
  }
}
.received-msg-card .msg-outer .msg-text-box .msg .uploaded-file-wrap .file p {
  margin-bottom: 0;
}
.received-msg-card .msg-outer .msg-text-box .msg .uploaded-file-wrap .download-btn {
  position: absolute;
  right: 0;
  top: 0px;
  min-height: inherit;
  min-width: inherit;
  width: 2rem;
  height: 2rem;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3125rem;
  margin-left: 0.625rem;
  border-radius: 100%;
}
@media (max-width: 414.81px) {
  .received-msg-card .msg-outer .msg-text-box .msg .uploaded-file-wrap .download-btn {
    width: 28px;
    height: 28px;
    top: 0px;
    position: inherit;
    display: none;
  }
}
.received-msg-card .msg-outer .msg-text-box .msg .uploaded-file-wrap .download-btn span::before {
  color: #222222;
}
.received-msg-card .msg-outer .msg-text-box .msg .videobox-imgbox {
  width: 170px;
  height: 100px;
  border-radius: 0.75rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
.received-msg-card .msg-outer .msg-text-box .msg .videobox-imgbox .video-overlay {
  background-color: rgba(34, 34, 34, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.received-msg-card .msg-outer .msg-text-box .msg .videobox-imgbox .video-overlay .btn {
  min-width: inherit;
  min-height: inherit;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  padding: 0.3125rem;
}
.received-msg-card .msg-outer .msg-text-box.receive-msg .profile-img {
  margin-right: 0.625rem;
}
.received-msg-card .msg-outer .msg-text-box.receive-msg .msg {
  border-radius: 0rem 0.75rem 0.75rem 0.75rem;
}
.received-msg-card .msg-outer .msg-text-box.sent-msg {
  float: right;
  padding-left: 0;
  padding-right: 2.875rem;
  justify-content: flex-end;
}
.received-msg-card .msg-outer .msg-text-box.sent-msg .profile-img {
  margin-left: 0.625rem;
  position: absolute;
  right: 0;
  top: 0;
  left: auto;
  margin-right: 0;
}
.received-msg-card .msg-outer .msg-text-box.sent-msg .msg {
  border-radius: 0.75rem 0rem 0.75rem 0.75rem;
  background-color: #0E378C;
}
.received-msg-card .msg-outer .msg-text-box.sent-msg .msg p {
  color: #ffffff;
  white-space: pre-line;
}
.received-msg-card .msg-outer .msg-text-box.sent-msg .msg .uploaded-file-wrap .file .iconbox {
  background-color: rgba(255, 255, 255, 0.2);
}
.received-msg-card .msg-outer .msg-text-box.sent-msg .msg .uploaded-file-wrap .file .iconbox span::before {
  color: #ffffff;
}
.received-msg-card .msg-outer .msg-text-box.sent-msg .msg .uploaded-file-wrap .download-btn {
  background-color: rgba(255, 255, 255, 0.2);
}
.received-msg-card .msg-outer .msg-text-box.sent-msg .msg .uploaded-file-wrap .download-btn span::before {
  color: #ffffff;
}
.received-msg-card .msg-type-input-outer {
  padding: 0.625rem 1.875rem;
}
@media (max-width: 480.81px) {
  .received-msg-card .msg-type-input-outer {
    padding: 0.625rem 0.9375rem;
  }
}
.received-msg-card .msg-type-input-outer .msg-type-input-wrap {
  position: relative;
  display: flex;
  align-items: center;
}
.received-msg-card .msg-type-input-outer .msg-type-input-wrap textarea {
  width: 100%;
  background-color: #EFEFEF;
  color: #222222;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: -0.015625rem;
  border-radius: 3.125rem;
  border: none;
  padding: 0.75rem 3.75rem;
  min-height: 3.75rem;
  resize: none;
}
@media (max-width: 767.81px) {
  .received-msg-card .msg-type-input-outer .msg-type-input-wrap textarea {
    min-height: inherit;
    padding: 0.75rem 3.125rem;
  }
}
.received-msg-card .msg-type-input-outer .msg-type-input-wrap .add-btn {
  position: absolute;
  left: 20px;
  font-size: 1.25rem;
  padding: 0;
  min-width: inherit;
  min-height: inherit;
}
.received-msg-card .msg-type-input-outer .msg-type-input-wrap .add-btn span::before {
  color: #0E378C;
}
.received-msg-card .msg-type-input-outer .msg-type-input-wrap .send-msg-btn {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #0E378C;
  border-radius: 100%;
  min-width: inherit;
  min-height: inherit;
}
.received-msg-card .msg-type-input-outer .msg-type-input-wrap .send-msg-btn span {
  font-size: 1.0625rem;
}
.received-msg-card .msg-type-input-outer .msg-type-input-wrap .send-msg-btn span::before {
  color: #ffffff;
}

.spacer-line {
  border-top: 1px solid rgba(101, 101, 101, 0.1);
  margin: 1.25rem 0rem;
}

.text-ellipsis-2line {
  white-space: normal;
  flex-wrap: wrap;
  max-width: 100%;
  height: 50px;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-ellipsis-1line {
  white-space: normal;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  height: 22px;
  margin: 0 auto;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-data-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.no-data-card p {
  margin-top: 1.875rem;
}

.msg-upload-icons {
  padding: 1.875rem 1.875rem 0.625rem;
}
.msg-upload-icons li {
  display: flex;
  align-items: center;
}
.msg-upload-icons li div {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  background: #f1f1f1;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 24px;
}
.msg-upload-icons li img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.msg-upload-icons div.play-icon-btn {
  position: relative;
}
.msg-upload-icons div.play-icon-btn .btn {
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  min-height: auto;
  min-width: auto;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.msg-upload-icons div.play-icon-btn .btn .icon-icon-play:before {
  color: #797979;
}

.documents-list {
  margin-top: 3.125rem;
}
.documents-list .module-search-wrap {
  position: sticky;
  top: 70px;
  z-index: 9;
}
.documents-list .module-search-wrap .module-search {
  position: relative;
}
.documents-list .module-search-wrap .module-search span {
  left: 24px;
}
.documents-list .left-tabs .nav-link {
  padding: 0.875rem 1.25rem;
}
.documents-list .card h6 {
  font-weight: 600;
}
.documents-list .card .usage {
  font-size: 0.8125rem;
  font-weight: 600;
}
.documents-list .storage .btn {
  width: 114px;
  min-height: 40px;
  font-size: 0.9375rem;
  padding: 0.4375rem 1rem;
  border-radius: 0.25rem;
  margin-top: 1.25rem;
  font-weight: 400;
}
@media (max-width: 991.9px) {
  .documents-list .module-search-wrap {
    padding-bottom: 1.875rem;
  }
}
.documents-list .module-search-wrap .module-search input {
  padding: 0.75rem 0.9375rem 0.75rem 3.75rem;
}
@media (max-width: 991.9px) {
  .documents-list .module-search-wrap .module-search span {
    left: 42px;
  }
}

.storage-bar {
  padding: 1.125rem 0rem;
}
.storage-bar span {
  width: 20px;
  display: inline-block;
  padding-right: 2.5rem;
}
.storage-bar .progress {
  height: 8px;
  border-radius: 0.75rem;
}

.documents-types h5 {
  margin-bottom: 1.875rem;
  font-size: 1.25rem;
}
.documents-types .icon-folder-icon {
  font-size: 6.5625rem;
  position: relative;
}
.documents-types .icon-folder-icon.icon-white .path1:before {
  color: #ffffff;
  position: absolute;
  left: -115px;
}
@media (max-width: 320.11px) {
  .documents-types .icon-folder-icon.icon-white .path1:before {
    left: -57px;
    bottom: 0;
  }
}
.documents-types .icons-wrap {
  border: 1px solid #EFEFEF;
  padding: 2.1875rem 0.3125rem 1.5625rem 0.3125rem;
}
.documents-types .icons-wrap .icon-info {
  width: 34px;
  height: 34px;
  line-height: 34px;
  background-color: #EFEFEF;
  border-radius: 100%;
  display: inline-block;
  font-size: 0.9375rem;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 55%;
}
.documents-types .icons-wrap .icon-info:before {
  color: #0E378C;
}
.documents-types .icons-wrap h6 {
  margin: 2rem 0rem 0rem;
}
@media (max-width: 768.81px) {
  .documents-types .icons-wrap h6 {
    font-size: 0.9375rem;
    margin: 0.9375rem 0rem 0rem;
  }
}
@media (max-width: 320.11px) {
  .documents-types .icons-wrap h6 {
    font-size: 0.875rem;
  }
}
.documents-types .icons-wrap .img-wrap {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 43%;
  max-width: 34px;
}
.documents-types .icons-wrap .img-wrap img {
  border-radius: 100%;
}

@media (max-width: 1200.81px) {
  .share-with-me .documents-types.rect-wrap [class*=col-]:nth-child(n+3) {
    margin-top: 0 !important;
  }
}
@media (max-width: 1200.81px) {
  .share-with-me .documents-types.rect-wrap [class*=col-]:nth-child(n+4) {
    margin-top: 1.25rem !important;
  }
}
@media (max-width: 767.81px) {
  .share-with-me .documents-types.rect-wrap [class*=col-]:nth-child(n+3) {
    margin-top: 1.25rem !important;
  }
}
@media (max-width: 767.81px) {
  .share-with-me .documents-types.rect-wrap [class*=col-]:nth-child(n+2) {
    margin-top: 0rem !important;
  }
}
@media (max-width: 767.81px) {
  .share-with-me .documents-types.rect-wrap [class*=col-]:nth-child(n+3) {
    margin-top: 1.25rem !important;
  }
}
.share-with-me .documents-types .rect-box {
  padding: 0rem;
  border: 0;
}

.recent-documents h5 {
  margin-bottom: 1.25rem;
}
.recent-documents.rect-wrap [class*=col-]:nth-child(n+3) {
  margin-top: 1.875rem;
}
@media (max-width: 1200.81px) {
  .recent-documents.rect-wrap [class*=col-]:nth-child(n+2) {
    margin-top: 0.9375rem;
  }
}
.recent-documents .rect-box .info-bar .content-wrap {
  max-width: 220px;
}
@media (max-width: 320.11px) {
  .recent-documents .rect-box .info-bar .content-wrap {
    max-width: 160px;
  }
}
.recent-documents .gray-dot {
  width: 3px;
  height: 3px;
  margin: 0rem 0.25rem;
}
.recent-documents .s-info .name {
  color: #222222;
  padding-left: 0.1875rem;
}

@media (max-width: 576.81px) {
  .video-block vg-controls {
    height: 40px;
  }
}
.video-block vg-controls vg-scrub-bar-current-time {
  background-color: #888183;
}
.video-block vg-controls vg-scrub-bar-current-time .background {
  background-color: red;
  border: 1px solid red;
}
.video-block vg-controls vg-scrub-bar-current-time .slider {
  display: none;
}
.video-block vg-time-display {
  width: 63px;
  font-size: 0.9375rem;
}
@media (max-width: 480.81px) {
  .video-block vg-time-display {
    width: 55px;
    font-size: 0.875rem;
  }
}
@media (max-width: 576.81px) {
  .video-block vg-time-display {
    height: 40px;
    line-height: 40px;
  }
}
.video-block vg-fullscreen .icon {
  font-size: 1.75rem;
}
@media (max-width: 480.81px) {
  .video-block vg-fullscreen .icon {
    font-size: 1.5rem;
  }
}
@media (max-width: 480.81px) {
  .video-block [class*=" vg-icon-"], .video-block [class^=vg-icon-] {
    font-size: 1.375rem;
  }
}
@media (max-width: 480.81px) {
  .video-block vg-play-pause {
    width: 34px;
  }
}
@media (max-width: 576.81px) {
  .video-block vg-play-pause {
    height: 40px;
  }
}
.video-block .custom-controls-icons span {
  cursor: pointer;
}
@media (max-width: 767.81px) {
  .video-block .custom-controls-icons span {
    display: none;
  }
}
.video-block vg-overlay-play .vg-overlay-play {
  cursor: inherit;
  opacity: 1;
}
.video-block vg-overlay-play .vg-overlay-play .overlay-play-container.vg-icon-play_arrow {
  font-size: 2.375rem;
}
@media (max-width: 576.81px) {
  .video-block vg-overlay-play .vg-overlay-play .overlay-play-container.vg-icon-play_arrow {
    font-size: 1.5rem;
  }
}
.video-block vg-overlay-play .vg-overlay-play .overlay-play-container.vg-icon-play_arrow:before {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  width: 72px;
  height: 72px;
  line-height: 72px;
  text-align: center;
}
@media (max-width: 576.81px) {
  .video-block vg-overlay-play .vg-overlay-play .overlay-play-container.vg-icon-play_arrow:before {
    width: 42px;
    height: 42px;
    line-height: 42px;
  }
}
.video-block vg-player video {
  height: 435px;
}
@media (max-width: 767.81px) {
  .video-block vg-player video {
    height: 264px;
  }
}
@media (max-width: 576.81px) {
  .video-block vg-player video {
    height: 215px;
  }
}
@media (max-width: 375.81px) {
  .video-block vg-player video {
    height: 190px;
  }
}
@media (max-width: 576.81px) {
  .video-block vg-scrub-bar {
    height: 40px;
  }
}
@media (max-width: 375.81px) {
  .video-block vg-scrub-bar {
    margin: 0rem 0.1875rem;
  }
}
.video-block .volume-control {
  position: relative;
}
.video-block .volume-control vg-volume {
  position: absolute;
  right: -20px;
  bottom: 80px;
  transform: rotate(-90deg);
}
@media (max-width: 576.81px) {
  .video-block vg-mute {
    height: 40px;
  }
}
@media (max-width: 375.81px) {
  .video-block vg-mute {
    width: 40px;
  }
}
.video-block vg-volume {
  margin-right: 0.9375rem;
}
@media (max-width: 576.81px) {
  .video-block vg-volume {
    height: 40px;
    width: 40px;
  }
}
@media (max-width: 375.81px) {
  .video-block vg-volume {
    margin: 0rem 0.1875rem;
  }
}
@media (max-width: 576.81px) {
  .video-block vg-fullscreen {
    height: 40px;
  }
}
@media (max-width: 375.81px) {
  .video-block vg-fullscreen {
    width: 40px;
  }
}
.video-block.fullscreen video {
  height: 100%;
  object-fit: contain !important;
}
.video-block .white-line {
  width: 1px;
  background-color: #ffffff;
  height: 13px;
  margin-top: 1.125rem;
  -webkit-transform: rotate(10deg);
  -moz-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  -o-transform: rotate(10deg);
  transform: rotate(10deg);
}
@media (max-width: 480.81px) {
  .video-block .white-line {
    margin-top: 0.875rem;
  }
}

.home-feed-modal .video-block {
  position: relative;
}
.home-feed-modal .video-block .custom-controls-icons {
  margin-right: 0.9375rem;
}
.home-feed-modal .video-block .dropdown-box {
  position: absolute;
  right: 13px;
  bottom: 13px;
  z-index: 999;
}
@media (max-width: 480.81px) {
  .home-feed-modal .video-block .dropdown-box {
    bottom: 9px;
  }
}
.home-feed-modal .video-block .controls-hidden ~ .dropdown-box .feed-dropdown .icon-dots {
  -webkit-transition: bottom 1s;
  -khtml-transition: bottom 1s;
  -moz-transition: bottom 1s;
  -ms-transition: bottom 1s;
  transition: bottom 1s;
  display: none;
}

/*comman search bar icon*/
@media (max-width: 375.81px) {
  .job-search.module-search-wrap .module-search span {
    left: 22px;
  }
}
.job-search.module-search-wrap .module-search input {
  padding: 0.75rem 0.75rem 0.75rem 4.375rem;
  color: #222222;
}
@media (max-width: 767.81px) {
  .job-search.module-search-wrap .module-search input {
    padding: 0.75rem 0.75rem 0.75rem 3.125rem;
  }
}

/*End of comman search bar icon*/
/*comman use job-nav-outer*/
.job-nav-outer {
  position: relative;
  margin-bottom: 1.875rem;
}
.job-nav-outer::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  display: block;
  height: 64px;
  z-index: -1;
}

/*End of comman use job-nav-outer*/
/*comman use header tabbing*/
.jobs-header-nav {
  position: relative;
  padding-right: 8.125rem;
  display: flex;
  align-items: flex-start;
}
.jobs-header-nav .jobs-nav {
  display: flex;
  align-items: center;
}
.jobs-header-nav .jobs-nav.left-tabs {
  flex-direction: row;
}
.jobs-header-nav .jobs-nav .nav-link {
  border-left: none;
  border-bottom: 2px solid transparent;
  padding: 1.25rem 0.9375rem;
  line-height: 1.375rem;
  font-weight: 600;
}
.jobs-header-nav .jobs-nav .nav-link.active {
  border-left: none;
  border-bottom: 2px solid #0E378C;
  color: #0E378C;
}
.jobs-header-nav .create-job-btn {
  min-height: 40px;
  font-size: 0.9375rem;
  position: absolute;
  right: 0;
  top: 13px;
  padding: 0.4375rem 0.625rem;
  border-radius: 0.25rem;
}
.jobs-header-nav .create-job-btn:hover .icon-icon-create-job:before {
  color: #0E378C;
}

/*End of comman use header tabbing*/
.job-card .name-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
}
.job-card .name-wrap .imgbox {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.625rem;
}
.job-card .name-wrap .imgbox img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}
.job-card .name-wrap h5 {
  font-size: 1.125rem;
  margin-bottom: 0;
}
@media (min-width: 1200.91px) {
  .job-card h4 {
    font-size: 1.375rem;
  }
}
.job-card .folio-dt {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-transform: uppercase;
  font-size: 0.75rem;
  color: #9D9D9D;
  font-weight: 700;
}
.job-card .folio-dt span.cerified-text {
  margin-left: 5px;
  color: #0E378C;
}
.job-card .folio-dt span.cerified-text span {
  font-size: 1rem;
  vertical-align: -2px;
}
.job-card.job-detail-card .title {
  position: relative;
  padding-right: 5rem;
}
.job-card.job-detail-card .title .shar-like-btns {
  margin-left: 15px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
}
.job-card.job-detail-card .title .shar-like-btns .btn {
  min-height: inherit;
  min-width: inherit;
}
.job-card.job-detail-card .title .shar-like-btns .btn:last-child {
  margin-left: 1.25rem;
}
.job-card.job-detail-card .title .shar-like-btns .btn span {
  font-size: 1.25rem;
}
.job-card.job-detail-card .title .shar-like-btns .btn span:before {
  color: #222222;
}

/*comman img with short info*/
.posted-by-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 576.81px) {
  .posted-by-wrap .media {
    margin-bottom: 1.25rem;
    width: 100%;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
.posted-by-wrap .media .imgbox {
  margin-right: 0.625rem;
  border-radius: 100%;
  overflow: hidden;
  flex-shrink: 0;
}
@media (max-width: 576.81px) {
  .posted-by-wrap .media .imgbox {
    margin-bottom: 0.9375rem;
    margin-right: 0rem;
  }
}
@media (max-width: 576.81px) {
  .posted-by-wrap .media .media-info .rating-wrap {
    justify-content: center !important;
  }
}
.posted-by-wrap .media h6 {
  margin-bottom: 0;
}
@media (max-width: 576.81px) {
  .posted-by-wrap .media h6 {
    margin-bottom: 0.3125rem;
  }
}
.posted-by-wrap .media .rate-count {
  font-size: 0.6875rem;
  line-height: 0.6875rem;
  font-weight: 600;
  text-transform: uppercase;
}
@media (max-width: 576.81px) {
  .posted-by-wrap .btnbox {
    justify-content: center;
    width: 100%;
  }
}
.posted-by-wrap .add-chat-btns {
  display: flex;
  align-items: center;
}
@media (max-width: 576.81px) {
  .posted-by-wrap .add-chat-btns {
    justify-content: center;
    width: 100%;
  }
}
.posted-by-wrap .add-chat-btns .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  min-height: inherit;
  min-width: inherit;
}
.posted-by-wrap .add-chat-btns .btn span {
  font-size: 0.875rem;
}
.posted-by-wrap .add-chat-btns .btn.add-btn {
  background-color: #0E378C;
  color: #fff;
  margin-right: 1.25rem;
}
.posted-by-wrap .add-chat-btns .btn.chat-btn {
  background-color: #EFEFEF;
  color: #222222;
}
.posted-by-wrap .add-chat-btns .btn.yellow-btn {
  background-color: #F3C32F;
  margin-right: 1.25rem;
}
.posted-by-wrap .add-chat-btns .btn.yellow-btn span:before {
  color: #ffffff;
  font-size: 1rem;
}
.posted-by-wrap .add-chat-btns .btn.remove-btn {
  background-color: #E73945;
  margin-right: 1.25rem;
}
.posted-by-wrap .add-chat-btns .btn.remove-btn span::before {
  color: #ffffff;
}

/*End of comman img with short info*/
/*comman Attachments images and icon*/
.folio-media {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.folio-media ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.folio-media ul li .thumb-wrap {
  width: 60px;
  height: 60px;
  border-radius: 0.75rem;
  border: 1px solid #EFEFEF;
  position: relative;
  background-color: #EFEFEF;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-right: 0.625rem;
  overflow: hidden;
  margin-bottom: 0.625rem;
}
.folio-media ul li .thumb-wrap.pdf-thumb-img span::before {
  color: #0E378C;
}
.folio-media ul li .thumb-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.folio-media ul li .thumb-wrap.video-thumb {
  position: relative;
}
.folio-media ul li .thumb-wrap.video-thumb:after {
  content: "";
  position: absolute;
  background-color: rgba(34, 34, 34, 0.3);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0.75rem;
}
.folio-media ul li .thumb-wrap.video-thumb .video-btn-play {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  border-radius: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 99;
  cursor: pointer;
  border: 0;
  background-color: rgba(0, 0, 0, 0.1);
  min-width: inherit;
  min-height: inherit;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.folio-media ul li .thumb-wrap.video-thumb .video-btn-play span {
  font-size: 0.6875rem;
}

/*End comman Attachments images and icon*/
.apply-job-card {
  text-align: center;
}
.apply-job-card h5 {
  margin-bottom: 0.625rem;
}
.apply-job-card p {
  font-size: 0.9375rem;
}
.apply-job-card .btn {
  max-width: 310px;
  margin: 0.625rem auto 0;
}

.create-icon {
  font-size: 1.125rem;
  padding-right: 0.625rem;
  vertical-align: middle;
}

/*comman Search bar reduce padding from Ipad*/
@media (max-width: 991.9px) {
  .module-search-wrap-sm-pd {
    padding-bottom: 1.875rem;
  }
}

/*End of comman search bar reduce padding from Ipad*/
.usd-label {
  right: 19px;
  top: 18px;
}

/*comman list countary name, dollar, months etc*/
.duration li {
  font-weight: 600;
  font-size: 0.9375rem;
  margin-right: 1.25rem;
}
@media (max-width: 1200.81px) {
  .duration li {
    margin-bottom: 0.3125rem;
  }
}
@media (max-width: 576.81px) {
  .duration li {
    margin-bottom: 0.9375rem;
  }
}
.duration li [class*=icon-] {
  margin-right: 0.5rem;
  font-size: 1.375rem;
  vertical-align: middle;
}
.duration li [class*=icon-]::before {
  color: #0E378C;
}

/*End of comman list countary name, dollar, months etc*/
.proposal-card .duration li {
  margin-right: 2.5rem;
}
.proposal-card .btn + .btn {
  margin-top: 1.875rem;
}

.req-status span {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 0.8125rem;
  margin: 0rem 0.625rem;
  cursor: pointer;
}
@media (max-width: 480.81px) {
  .req-status span {
    width: 35px;
    height: 35px;
    font-size: 0.75rem;
    margin: 0rem 0.3125rem;
  }
}
.req-status span::before {
  color: #ffffff;
}

.offers-wrap .duration + .duration {
  margin-top: 1.25rem;
}

.setup-milestones .bottom-wrap {
  padding-top: 6.25rem;
}

.doc-list-wrap .duration {
  margin-bottom: 1.25rem;
}
.doc-list-wrap .duration li {
  margin-right: 3.4375rem;
}
@media (max-width: 767.81px) {
  .doc-list-wrap .duration li {
    margin-right: 1.875rem;
    margin-bottom: 1.875rem;
  }
}
@media (max-width: 767.81px) {
  .doc-list-wrap .duration li:last-child {
    margin-bottom: 0.3125rem;
  }
}

@media (max-width: 991.9px) {
  .setup-milestones-list .btn-wrap .btn + .btn {
    margin-top: 0.9375rem;
  }
}

/*comman remove padding on class when use accordion*/
.card-panel .card {
  padding: 0rem;
}
.card-panel .card .panel-heading {
  padding: 0rem;
}

/*End of comman remove padding on class when use accordion*/
/*comman milestones section*/
.milestones.card {
  padding: 1.875rem 1.875rem 0rem 1.875rem;
}
@media (max-width: 480.81px) {
  .milestones.card {
    padding: 1.875rem 0.9375rem 0.625rem 0.9375rem;
  }
}
.milestones.card .card-header {
  border-bottom: 0;
}
.milestones.card .panel-open .icon-icon-arrow-down {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.milestones .milestone-wrap {
  border-left: 1px solid #EFEFEF;
  margin-top: 1.875rem;
}
.milestones .milestones-info-box {
  margin-bottom: 1.875rem;
  margin-left: 2.1875rem;
  position: relative;
  border-radius: 0.8125rem;
}
.milestones .milestones-info-box .triangle {
  position: absolute;
  top: 18px;
  left: -9px;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-right: 9px solid rgba(243, 195, 47, 0.2);
}
.milestones .milestones-info-box.warning-box {
  background-color: rgba(243, 195, 47, 0.2);
}
.milestones .milestones-info-box.warning-box:before {
  background-color: #F3C32F;
}
.milestones .milestones-info-box.warning-box .triangle {
  border-right: 9px solid rgba(243, 195, 47, 0.2);
}
.milestones .milestones-info-box.warning-box hr {
  border-top: 1px solid rgba(157, 157, 157, 0.2);
}
@media (max-width: 480.81px) {
  .milestones .milestones-info-box.warning-box .info-bar {
    border: none;
  }
}
@media (max-width: 480.81px) {
  .milestones .milestones-info-box.warning-box .date {
    border: none;
  }
}
.milestones .milestones-info-box.awaiting-box {
  background-color: rgba(194, 194, 194, 0.2);
}
.milestones .milestones-info-box.awaiting-box:before {
  background-color: #c2c2c2;
}
.milestones .milestones-info-box.awaiting-box .triangle {
  border-right: 9px solid rgba(194, 194, 194, 0.2);
}
.milestones .milestones-info-box.awaiting-box hr {
  border-top: 1px solid rgba(157, 157, 157, 0.2);
}
@media (max-width: 480.81px) {
  .milestones .milestones-info-box.awaiting-box .info-bar {
    border: none;
  }
}
@media (max-width: 480.81px) {
  .milestones .milestones-info-box.awaiting-box .date {
    border: none;
  }
}
.milestones .milestones-info-box.success-box {
  background-color: rgba(71, 184, 114, 0.2);
}
.milestones .milestones-info-box.success-box:before {
  background-color: #47B872;
}
.milestones .milestones-info-box.success-box .triangle {
  border-right: 9px solid rgba(71, 184, 114, 0.2);
}
.milestones .milestones-info-box.success-box hr {
  border-top: 1px solid rgba(157, 157, 157, 0.2);
}
.milestones .milestones-info-box.success-box .info-bar {
  border: none;
}
@media (max-width: 480.81px) {
  .milestones .milestones-info-box.success-box .date {
    border: none;
  }
}
.milestones .milestones-info-box.danger-box {
  background-color: rgba(231, 57, 69, 0.2);
}
.milestones .milestones-info-box.danger-box:before {
  background-color: #E73945;
}
.milestones .milestones-info-box.danger-box .triangle {
  border-right: 9px solid rgba(231, 57, 69, 0.2);
}
.milestones .milestones-info-box.danger-box hr {
  border-top: 1px solid rgba(157, 157, 157, 0.2);
}
.milestones .milestones-info-box.danger-box .info-bar {
  border: none;
}
@media (max-width: 480.81px) {
  .milestones .milestones-info-box.danger-box .date {
    border: none;
  }
}
.milestones .milestones-info-box::before {
  content: "";
  position: absolute;
  background-color: #EFEFEF;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  left: -42px;
  top: 21px;
}
.milestones .milestones-info-box .date {
  min-width: 87px;
  text-align: center;
  padding: 1.875rem 0rem;
}
@media (max-width: 480.81px) {
  .milestones .milestones-info-box .date {
    border-left: 1px solid rgba(157, 157, 157, 0.2);
    padding: 1.25rem 0rem 0rem;
  }
}
.milestones .milestones-info-box .date span {
  display: block;
  font-size: 0.8125rem;
  font-weight: 600;
}
.milestones .milestones-info-box .date strong {
  font-size: 1.375rem;
}
.milestones .milestones-info-box .info-bar {
  border-left: 1px solid rgba(157, 157, 157, 0.2);
  padding: 1.875rem 1.875rem 1.875rem 2.1875rem;
  word-break: break-word;
}
@media (max-width: 480.81px) {
  .milestones .milestones-info-box .info-bar {
    padding: 1.25rem;
  }
}
.milestones .milestones-info-box .info-bar .due {
  font-size: 0.75rem;
  color: #9D9D9D;
  font-weight: 700;
  text-transform: uppercase;
  min-width: 75px;
}
@media (max-width: 767.81px) {
  .milestones .milestones-info-box .info-bar .due {
    margin: 0.1875rem 0rem 0rem;
  }
}
@media (max-width: 480.81px) {
  .milestones .milestones-info-box .info-bar .due {
    margin: 0rem 0rem 0.3125rem;
  }
}
.milestones .milestones-info-box .info-bar h5 {
  margin-bottom: 0.75rem;
}
@media (max-width: 767.81px) {
  .milestones .milestones-info-box .info-bar h5 {
    font-size: 1rem;
    padding-right: 0.625rem;
  }
}
@media (max-width: 480.81px) {
  .milestones .milestones-info-box .info-bar h5 {
    margin: 0rem 0rem 0.3125rem;
  }
}
@media (max-width: 480.81px) {
  .milestones .milestones-info-box .info-bar h5 span {
    display: block;
    padding-left: 0rem !important;
  }
}
.milestones .milestones-info-box .info-bar .folio-media .icon-icon-photo {
  font-size: 1.3125rem;
}
.milestones .milestones-info-box .info-bar .folio-media .icon-icon-pdf {
  font-size: 1.375rem;
}
.milestones .milestones-info-box .info-bar .folio-media .thumb-img {
  background-color: #ffffff;
}
.milestones .milestones-info-box .info-bar .folio-media .thumb-img [class^=icon-]::before {
  color: #0E378C;
}
.milestones .milestones-info-box .info-bar .folio-media .thumb-img.more-items {
  background-color: rgba(34, 34, 34, 0.7);
}
.milestones .milestones-info-box .info-bar .folio-media .thumb-img.more-items .digit {
  color: #ffffff;
  position: absolute;
}
.milestones .milestones-info-box .text-warning {
  color: #F3C32F !important;
}
.milestones .panel-collapse .card-body {
  padding: 0rem;
}

/*End comman of milestones section*/
.proposals-received .posted-by-wrap {
  align-items: flex-start;
  flex-wrap: nowrap;
}
@media (max-width: 767.81px) {
  .proposals-received .posted-by-wrap {
    flex-wrap: wrap;
  }
}
.proposals-received .posted-by-wrap .media .media-info {
  padding-top: 0.625rem;
  padding-right: 0.625rem;
  word-break: break-word;
}
.proposals-received .posted-by-wrap .media .imgbox {
  margin-right: 1.25rem;
  min-width: 100px;
}
@media (max-width: 767.81px) {
  .proposals-received .posted-by-wrap .add-chat-btns {
    margin-top: 0.9375rem;
  }
}
.proposals-received .posted-by-wrap .add-chat-btns .btn {
  width: auto;
  height: auto;
  border-radius: 0.25rem;
}
.proposals-received .posted-by-wrap-list {
  padding: 1.875rem;
  border: 1px solid #EFEFEF;
  border-radius: 0.75rem;
}
@media (max-width: 767.81px) {
  .proposals-received .posted-by-wrap-list {
    padding: 0.9375rem;
  }
}
.proposals-received .posted-by-wrap-list hr {
  margin-left: 7.8125rem;
}
.proposals-received .posted-by-wrap-list .offer-list-wrap {
  margin-left: 7.8125rem;
}
@media (max-width: 767.81px) {
  .proposals-received .posted-by-wrap-list .offer-list-wrap {
    margin-left: 0rem;
  }
}
@media (max-width: 375.81px) {
  .proposals-received .posted-by-wrap-list .offer-list-wrap .btn {
    margin-bottom: 0.9375rem;
  }
}
.proposals-received .posted-by-wrap-list .offer-list-wrap .btn-group .dropdown .card-msg-nav-dropdown::before {
  left: 18px;
  right: auto;
}

.jobs-post-details .milestones .milestones-info-box .info-bar .folio-media .thumb-img [class^=icon-]:before {
  color: #ffffff;
}

.ongoing-jobs .btn.btn-accept, .ongoing-jobs .btn.btn-reject {
  min-height: 30px;
  min-width: max-content;
  padding: 0.1875rem 0.5625rem;
}

.my-proposals .duration-wrap .duration + .duration {
  padding-top: 0.9375rem;
}
.my-proposals .duration-wrap span {
  min-width: 135px;
}

@media (max-width: 767.81px) {
  .disputes .time-status {
    margin-bottom: 0.625rem;
  }
}

.disputes-detail .job-card .name-wrap .imgbox {
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
}
.disputes-detail .milestones .milestones-info-box .info-bar {
  padding: 1.25rem 1.25rem 0.25rem 1.25rem;
}
.disputes-detail .milestones .milestones-info-box.success-box {
  margin: 0rem;
}
.disputes-detail .milestones .milestones-info-box.success-box::before {
  display: none;
}
.disputes-detail .milestones .milestone-wrap {
  border-left: 0;
  margin-top: 0.25rem;
}
.disputes-detail .card .content-wrap {
  padding-left: 3.4375rem;
}
.disputes-detail .comment-form textarea {
  min-height: 7.5rem;
}

.modal .add-milestone-modal .modal-content .modal-card {
  padding: 4.375rem;
}

.upload-documents {
  background-color: #EFEFEF;
  height: 11.25rem;
  border-radius: 0.75rem;
  position: relative;
}
.upload-documents input {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  cursor: pointer;
}
.upload-documents .upload-wrap span {
  font-size: 2.75rem;
}

.upload-deliverable-modal .uploaded-doc-wrap {
  border: 1px solid rgba(157, 157, 157, 0.5);
  border-radius: 0.25rem;
}
.upload-deliverable-modal .folio-media {
  align-items: center;
  flex-wrap: nowrap;
  padding: 1.25rem 1.25rem 0.625rem 1.25rem;
}
.upload-deliverable-modal .folio-media h6 {
  word-break: break-all;
}
.upload-deliverable-modal .progressbar-wrap .progress-bar {
  border-radius: 0.25rem;
}
.upload-deliverable-modal .progressbar-wrap .progress {
  height: 0.5rem;
  background-color: #CCCCCC;
}

.send-counter-offer-modal .top-bar {
  padding: 1.875rem;
}
@media (max-width: 480.81px) {
  .send-counter-offer-modal .top-bar {
    padding: 0.9375rem;
  }
}
.send-counter-offer-modal .modal-content .modal-card.modal-md {
  padding: 4.375rem;
}
.send-counter-offer-modal .posted-by-wrap .media .imgbox {
  min-width: 48px;
}
.send-counter-offer-modal textarea {
  min-height: 8.4375rem;
}

.select-sevrice-provider .media-info .imgbox {
  border-radius: 100%;
  overflow: hidden;
  display: inline-block;
  margin: 1.375rem 0rem 1.25rem;
}
.select-sevrice-provider .t-title {
  line-height: 1.6;
}
.select-sevrice-provider .offers-wrap .duration hr {
  margin: 1.875rem 0rem;
}
@media (max-width: 480.81px) {
  .select-sevrice-provider .offers-wrap .text-gray {
    margin: 0rem !important;
  }
}
.select-sevrice-provider .add-chat-btns {
  margin-top: 1.875rem;
}
@media (max-width: 480.81px) {
  .select-sevrice-provider .add-chat-btns .btn {
    margin: 0rem !important;
  }
}
.select-sevrice-provider .add-chat-btns .btn [class*=icon-] {
  position: absolute;
  left: 20px;
  top: 20px;
}
@media (max-width: 480.81px) {
  .select-sevrice-provider .add-chat-btns .btn + .btn {
    margin-top: 0.625rem !important;
  }
}

.edit-milestone-modal hr {
  margin: 1.875rem 0rem;
}

.modal .need-help-modal .modal-content .modal-card {
  padding: 4.375rem;
}
.modal .need-help-modal .checkmark {
  border: 1px solid #9D9D9D;
  top: -2px;
}
.modal .need-help-modal textarea {
  height: 150px;
}

/* right sidebar filter responsive */
.responsive-filter-btn-wrap {
  display: none;
}
@media (max-width: 991.9px) {
  .responsive-filter-btn-wrap {
    display: flex;
    justify-content: flex-end;
  }
}

.icon-icon-filter {
  margin-right: 0.4375rem;
  position: relative;
}
.icon-icon-filter.filtered::after {
  content: "";
  background: #e73945;
  width: 8px;
  height: 8px;
  border-radius: 50rem;
  position: absolute;
  left: 11px;
  top: -1px;
}

.job-sidebar-card .close-filter-btn-wrap {
  display: none;
  margin-bottom: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.job-sidebar-card .close-filter-btn-wrap .btn {
  min-height: inherit;
  min-width: inherit;
  padding: 4px 10px;
  line-height: normal;
  font-size: 0.75rem;
}
@media (max-width: 991.9px) {
  .job-sidebar-card .close-filter-btn-wrap {
    display: block;
  }
}
.job-sidebar-card .filter-heading-wrap {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 991.9px) {
  .job-sidebar-card {
    right: -100%;
    position: fixed;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    top: 70px;
    height: calc(100vh - 70px);
    overflow: auto;
    z-index: 5;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) !important;
    max-width: 320px;
    width: 100%;
    background-color: #ffffff;
  }
  .job-sidebar-card.show {
    right: 0;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
  }
}

.documents-details .module-search-wrap {
  position: sticky;
  top: 70px;
  z-index: 9;
  align-items: center;
}
@media (max-width: 1200.81px) {
  .documents-details .module-search-wrap {
    padding-bottom: 1.5625rem;
  }
}
.documents-details .module-search-wrap .module-search input {
  padding: 0.75rem 19.375rem 0.75rem 3.75rem;
}
@media (max-width: 991.9px) {
  .documents-details .module-search-wrap .module-search input {
    padding: 0.75rem 17.5rem 0.75rem 3.75rem;
  }
}
@media (max-width: 767.81px) {
  .documents-details .module-search-wrap .module-search input {
    padding: 0.75rem 7.1875rem 0.75rem 2.5rem;
  }
}
.documents-details .module-search-wrap .module-search span {
  left: 32px;
}
@media (max-width: 768.81px) {
  .documents-details .module-search-wrap .module-search span {
    left: 15px;
  }
}
.documents-details .text-blue {
  color: #0E378C !important;
}

.upload-option {
  position: absolute;
  right: 25px;
  top: auto;
}
.upload-option a.create-btn {
  background-color: rgba(34, 34, 34, 0.1);
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
}
.upload-option a.upload-file {
  background-color: rgba(14, 55, 140, 0.1);
  border-radius: 0.25rem;
  color: #0E378C;
}
.upload-option a.upload-file .icon-upload-ic:before {
  color: #0E378C;
}
@media (max-width: 768.81px) {
  .upload-option {
    right: 5px;
  }
}
@media (max-width: 767.81px) {
  .upload-option .search-close {
    margin-right: 0.625rem;
  }
}
.upload-option .ic-wrap {
  margin-left: 0.625rem;
  font-size: 0.9375rem;
  color: #222222;
  font-weight: 600;
  padding: 0.4375rem 0.625rem;
}
@media (max-width: 767.81px) {
  .upload-option .ic-wrap {
    margin: 0rem 0.3125rem;
    padding: 0.3125rem 0.625rem;
  }
}
.upload-option .ic-wrap span {
  padding-right: 0.625rem;
  font-size: 0.8125rem;
}
@media (max-width: 767.81px) {
  .upload-option .ic-wrap span {
    padding-right: 0;
  }
}
.upload-option .ic-wrap span::before {
  color: #222222;
}

.icon-folder {
  position: relative;
}
.icon-folder:before {
  font-weight: 600;
}
.icon-folder .icon-icon-plush {
  position: absolute;
  font-size: 0.3125rem !important;
  left: 0.25rem;
  top: 0.25rem;
}

.rect-wrap [class*=col-] {
  margin-bottom: 20px;
}

.rect-box {
  border: 1px solid #EFEFEF;
  padding: 1.25rem;
}
@media (max-width: 480.81px) {
  .rect-box {
    padding: 0.625rem;
  }
}
.rect-box .flex-wrap {
  flex-wrap: inherit !important;
}
.rect-box .info-bar .icon-folder-icon {
  font-size: 3.875rem;
}
@media (max-width: 1200.81px) {
  .rect-box .info-bar .icon-folder-icon {
    font-size: 2.625rem;
  }
}
.rect-box .info-bar .content-wrap {
  padding-left: 0.625rem;
  max-width: 196px;
}
@media (max-width: 991.9px) {
  .rect-box .info-bar .content-wrap {
    max-width: 145px;
  }
}
@media (max-width: 767.81px) {
  .rect-box .info-bar .content-wrap {
    max-width: 196px;
  }
}
@media (max-width: 414.81px) {
  .rect-box .info-bar .content-wrap {
    max-width: 146px;
  }
}
.rect-box .info-bar .content-wrap h6 {
  font-size: 0.9375rem;
  font-weight: 600;
  margin: 0rem 0rem 0.3125rem;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 176px;
  white-space: nowrap;
}
.rect-box .info-bar .s-info {
  font-weight: 600;
  color: #9D9D9D;
  font-size: 0.8125rem;
}
.rect-box .icon-dots:before {
  color: #9D9D9D;
}
.rect-box .right-bar span {
  font-size: 1.375rem;
}
@media (max-width: 767.81px) {
  .rect-box .right-bar span {
    font-size: 1.25rem;
  }
}
.rect-box .right-bar .icon-icon-connections {
  padding-right: 1.25rem;
}
@media (max-width: 767.81px) {
  .rect-box .right-bar .icon-icon-connections {
    padding-right: 0.9375rem;
  }
}
.rect-box .right-bar .icon-dots {
  font-size: 1.1875rem;
}
.rect-box .right-bar .info-bar .s-info {
  padding-right: 1.25rem;
}
.rect-box .wrap-bg {
  width: 60px;
  height: 60px;
  border-radius: 0.75rem;
  background-color: #EFEFEF;
}
@media (max-width: 1200.81px) {
  .rect-box .wrap-bg {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 480.81px) {
  .rect-box .wrap-bg {
    width: 40px;
    height: 40px;
  }
}
.rect-box .wrap-bg span {
  color: #0E378C;
  font-size: 1.375rem;
  text-align: center;
  line-height: 60px;
  display: block;
}
@media (max-width: 1200.81px) {
  .rect-box .wrap-bg span {
    line-height: 48px;
  }
}
@media (max-width: 480.81px) {
  .rect-box .wrap-bg span {
    line-height: 40px;
  }
}
.rect-box .dropdown-menu .dropdown-item {
  align-items: center;
  display: flex;
  width: 100%;
}

.public-documents .module-search-wrap .module-search input {
  padding: 0.75rem 0.75rem 0.75rem 3.75rem;
}
@media (max-width: 991.9px) {
  .public-documents .module-search-wrap .module-search input {
    padding: 0.75rem 0.75rem 0.75rem 2.8125rem;
  }
}

.filter-by {
  margin-top: 0.125rem;
  margin-bottom: 1.25rem;
}
.filter-by .search-custom-checkbox .checkbox-list label {
  font-size: 0.9375rem;
}

.shared-with-pdf .upload-img iframe {
  width: 100%;
  height: 70vh;
  border: 0;
}

.team-member-req-list .request-request-item .imgbox img {
  border-radius: 50rem;
  object-fit: cover;
}
.team-member-req-list .request-request-item h6 {
  color: #0E378C;
}
.team-member-req-list .request-request-item .primary-text {
  color: #222222 !important;
  font-size: 0.8125rem;
}
.team-member-req-list .btnbox {
  margin-top: 1.25rem;
}
.team-member-req-list .btn {
  min-height: 40px;
  font-size: 0.9375rem;
  padding: 0.25rem 1rem;
  border-radius: 0.25rem;
}
.team-member-req-list .btn span {
  font-size: 0.6875rem;
  padding-right: 0.3125rem;
}
.team-member-req-list .btn + .btn {
  margin-left: 0.625rem;
}

.members-list-wrap .req-info-wrap {
  margin-bottom: 1.5625rem;
}
.members-list-wrap .page-title {
  font-weight: 700;
  font-size: 1.25rem;
  padding: 0rem;
}
.members-list-wrap .digit-wrap .digit {
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  display: inline-block;
  background-color: #0E378C;
  border-radius: 100%;
  font-size: 0.875rem;
  font-weight: 600;
  margin-right: 0.9375rem;
}
.members-list-wrap .digit-wrap .icon-icon-right-angle-arrow {
  font-size: 0.875rem;
  color: #9D9D9D;
}

.mt-35 {
  margin-top: 2.1875rem;
}

.settings-subscription .btn.btn-small {
  min-height: 32px;
  padding: 0.125rem 1.25rem;
}

.c-title {
  font-size: 1.375rem;
  margin: 0;
}
@media (max-width: 1200.81px) {
  .c-title {
    font-size: 1.125rem;
  }
}
.c-title span {
  width: 63px;
  height: 63px;
  line-height: 63px;
  background-color: #ffffff;
  border-radius: 100%;
  color: #0E378C;
  display: inline-block;
  text-align: center;
  box-shadow: 2px 3px 17px rgba(194, 194, 194, 0.5);
  margin: 0rem 1.25rem 1.25rem 0rem;
  font-size: 1.75rem;
}
.c-title .icon-icon-plan-essential, .c-title .icon-icon-plan-premium {
  font-size: 1.4375rem;
}

.card-inactive {
  background-color: transparent;
  box-shadow: none;
}

.table-card {
  padding: 1.875rem 1.875rem 0.625rem 1.875rem;
  overflow: auto;
  min-width: 100%;
}
.table-card .four-grid-table {
  overflow-x: hidden;
  min-width: 800px;
}

@media (min-width: 992px) {
  .overflow-unset {
    overflow: unset;
  }
  .overflow-unset .table-responsive {
    overflow: unset;
  }
}

.plans-table th {
  padding-bottom: 1.25rem;
  font-weight: 600;
}
.plans-table th.sticky-th {
  background: #ffffff;
  position: sticky;
  position: -webkit-sticky;
  top: 70px;
  z-index: 11;
}
@media (max-width: 991.9px) {
  .plans-table th.sticky-th {
    top: -20px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
}
.plans-table tr td {
  width: 100%;
  padding-bottom: 1.25rem;
  word-break: break-all;
}
.plans-table tr td:nth-child(n+2) {
  width: 150px;
  text-align: right !important;
}
.plans-table tr td span {
  font-size: 0.875rem;
  display: inline-block;
}

.four-grid-table .c-title {
  display: block;
  text-align: center;
  word-break: break-word;
}
@media (max-width: 767.81px) {
  .four-grid-table .c-title {
    min-height: 129px;
  }
}
@media (max-width: 480.81px) {
  .four-grid-table .c-title {
    font-size: 1rem;
  }
}
.four-grid-table .c-title span {
  margin: 0 auto 1.25rem;
  display: block;
}
.four-grid-table .plan-icon {
  font-size: 1.4375rem;
}
.four-grid-table .plan-icon.icon-icon-plan-core {
  font-size: 1.5625rem;
}
.four-grid-table tr td, .four-grid-table tr th {
  width: 30%;
  padding-bottom: 1.875rem;
  word-break: break-word;
}
@media (max-width: 991.9px) {
  .four-grid-table tr td, .four-grid-table tr th {
    width: 18%;
  }
}
.four-grid-table tr td:nth-child(n+2), .four-grid-table tr th:nth-child(n+2) {
  width: 10%;
  text-align: center !important;
  display: table-cell;
}
.four-grid-table hr {
  border-color: #EFEFEF;
  margin: 0rem -1.875rem 1.875rem -1.875rem;
}

.compare-plans .table-card {
  padding: 1.875rem 0rem 0.625rem 1.875rem;
}
@media (max-width: 480.81px) {
  .compare-plans .table-card {
    padding: 1.25rem;
  }
}
@media (max-width: 991.9px) {
  .compare-plans .table-card {
    max-height: calc(100vh - 200px);
  }
}

.verify-otp .text-danger {
  text-align: center !important;
}
.verify-otp .otp-input {
  font-size: 2rem !important;
  color: #222222 !important;
}
@media (min-width: 1200.91px) {
  .verify-otp .otp-input {
    width: 44px !important;
  }
}

.form-otp {
  margin-top: 2.1875rem;
}
.form-otp .btnbox {
  margin: 1.875rem 0rem;
}

.resend-req {
  color: #9D9D9D;
}

.change-password-form .form-control:placeholder-shown {
  background-color: transparent;
  border: 1px solid #9D9D9D;
}

.instruction-wrap {
  margin-top: 1.875rem;
}

.instruction-list {
  padding-left: 1.25rem;
}
.instruction-list li {
  list-style-type: disc;
  font-size: 0.8125rem;
  color: #9D9D9D;
  font-weight: 600;
  line-height: 1.4;
}

@media (max-width: 576.81px) {
  .change-password .btnbox {
    margin-top: 0.9375rem;
  }
}

.refer-and-earn .card {
  padding: 3.125rem;
}
@media (max-width: 767.81px) {
  .refer-and-earn .card {
    padding: 1.875rem;
  }
}
@media (min-width: 1200.91px) {
  .refer-and-earn .img-wrap {
    padding-left: 2.5rem;
  }
}
@media (max-width: 991.9px) {
  .refer-and-earn .img-wrap img {
    object-fit: contain;
  }
}
@media (max-width: 767.81px) {
  .refer-and-earn .img-wrap img {
    height: auto;
  }
}
.refer-and-earn .info-wrap {
  margin: 3.125rem 0rem 0rem 6.25rem;
  max-width: 355px;
}
@media (max-width: 991.9px) {
  .refer-and-earn .info-wrap {
    margin: 3.125rem 0rem 0rem 0.9375rem;
  }
}
@media (max-width: 767.81px) {
  .refer-and-earn .info-wrap {
    margin: 2.1875rem 0rem 0rem;
  }
}
.refer-and-earn .info-wrap .btnbox {
  margin-top: 1.875rem;
}
.refer-and-earn .ref-code {
  margin-top: 1.5625rem;
}
.refer-and-earn .ref-code .code {
  background-color: #EFEFEF;
  font-size: 1.5rem;
  padding: 0.9375rem 0.3125rem;
  text-align: center;
  border-radius: 0.75rem;
  max-width: 218px;
  margin: 0.3125rem 0rem;
  letter-spacing: 0.25rem;
}
@media (max-width: 767.81px) {
  .refer-and-earn .ref-code .code {
    font-size: 1.125rem;
  }
}
.refer-and-earn .ref-code span {
  font-size: 0.8125rem;
  color: #9D9D9D;
  font-weight: 600;
}
.refer-and-earn .ref-code .copy {
  color: #222222;
  font-size: 0.6875rem;
  text-transform: uppercase;
}

.verify-notes-wrap {
  margin-top: 1.75rem;
}
.verify-notes-wrap .custom-radio-square input[type=radio] + span {
  top: 2px;
}
.verify-notes-wrap .list-wrap {
  margin-bottom: 0.625rem;
}
.verify-notes-wrap ul {
  max-width: 329px;
}
.verify-notes-wrap ul li {
  list-style-type: disc;
  font-size: 0.8125rem;
  list-style-position: inside;
}
.verify-notes-wrap ul li::marker {
  font-size: 0.5rem;
}

.verify-page .project-files-box .icon-icon-plush {
  color: #9D9D9D;
}
@media (min-width: 768.91px) {
  .verify-page .btnbox {
    margin-right: -4.375rem;
  }
}

.verify-your-accrediation .project-files-box .icon-icon-plush {
  color: #9D9D9D;
}
.verify-your-accrediation .or {
  font-size: 0.8125rem;
  color: #9D9D9D;
  font-weight: 600;
  margin: 0.3125rem 0rem;
  display: inline-block;
}
.verify-your-accrediation .custom-checkbox label {
  padding-left: 2.5rem;
  margin: 0rem;
}
@media (max-width: 767.81px) {
  .verify-your-accrediation .custom-checkbox label {
    padding-left: 2.1875rem;
  }
}

.accordion-wrap {
  margin-top: 2.1875rem;
  padding-left: 1.875rem;
}
@media (max-width: 480.81px) {
  .accordion-wrap {
    padding-left: 0rem;
  }
}
.accordion-wrap .card {
  padding: 0rem;
}
.accordion-wrap .card .accordion-toggle {
  padding: 1.25rem;
  border: 1px solid #EFEFEF;
  border-radius: 0.75rem;
}
.accordion-wrap .card-header {
  border: 0;
  padding: 0rem;
  background-color: transparent;
}
.accordion-wrap .card-body {
  padding: 0rem 1.25rem 1.25rem 0rem;
}
.accordion-wrap .panel-heading p {
  padding-left: 2.6875rem;
  margin-bottom: 0;
}
.accordion-wrap .custom-radio-square label {
  margin: 0rem 0.625rem 0.625rem 0rem;
}
.accordion-wrap .panel-open .card {
  background-color: rgba(239, 239, 239, 0.4);
  border-radius: 0.75rem;
  padding: 1.25rem;
}
.accordion-wrap .panel-open .accordion-toggle {
  border: 0;
}

.checked-services input[type=checkbox] {
  display: none;
}
.checked-services input[type=checkbox] + span {
  background: transparent;
  border: 1px solid #E3E3E3;
  width: 20px;
  height: 20px;
}

.settings-privacy .custom-checkbox label {
  display: table;
  margin: 0rem 0.625rem 1.25rem 0rem;
  font-weight: 400 !important;
}
.settings-privacy .custom-checkbox label:last-child {
  margin: 0rem 0.625rem 0rem 0rem;
}
.settings-privacy .card + .card {
  margin: 1.875rem 0rem 0rem;
}
@media (max-width: 767.81px) {
  .settings-privacy .card + .card {
    margin: 0.9375rem 0rem 0rem;
  }
}
@media (max-width: 767.81px) {
  .settings-privacy .card .mb-3 {
    margin: 0rem 0rem 0.625rem !important;
  }
}

@media (max-width: 480.81px) {
  .credits-tabs .tab-info {
    margin-top: 5rem;
  }
}
@media (max-width: 991.9px) {
  .credits-tabs .common-tabs .search-filter-wrap {
    top: 0;
  }
}
@media (max-width: 480.81px) {
  .credits-tabs .common-tabs .search-filter-wrap {
    top: 80px;
  }
}
@media (max-width: 991.9px) {
  .credits-tabs .common-tabs .tab-nav-header .tab-container .nav-tabs {
    margin-bottom: 1.875rem;
  }
}
.credits-tabs .common-tabs .tab-nav-header .tab-container .nav-tabs .nav-link {
  padding: 1.125rem 0.9375rem;
}
.credits-tabs .filter-btn-wrap {
  padding: 0.375rem 1.125rem 0rem;
  border-left: 0;
}
.credits-tabs .filter-btn-wrap .filter-dropdown {
  background-color: #EFEFEF;
  width: 163px;
  padding: 0.5rem 0.625rem;
  margin-left: 0.9375rem;
  border-radius: 0.25rem;
}
.credits-tabs .filter-btn-wrap .filter-dropdown span {
  width: 100%;
  margin: 0;
  text-align: right;
  justify-content: flex-end;
  color: #9D9D9D;
}
.credits-tabs .filter-btn-wrap .ng-select {
  margin-left: 0.375rem;
}
.credits-tabs .filter-btn-wrap .ng-select .ng-arrow-wrapper {
  margin-left: 0.25rem;
}
.credits-tabs .filter-btn-wrap .ng-select .ng-option {
  padding: 0.75rem 0.625rem;
}

.credits-available {
  background-color: #0E378C;
  padding: 1.5625rem 1.5625rem 1.75rem 1.5625rem;
  border-radius: 0.75rem;
  background-image: url(../../images/card-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.credits-available .cr-info h6 {
  color: #ffffff;
  font-weight: 400;
  margin: 0rem 0rem 0.1875rem;
}
.credits-available .cr-info .digit {
  color: #ffffff;
  font-size: 2rem;
  font-weight: 700;
}
.credits-available .btn {
  font-size: 0.8125rem;
  background-color: #ffffff;
  color: #0E378C;
  padding: 0.3125rem 0.625rem;
  font-weight: 600;
  min-height: 32px;
  line-height: 1.5;
  border-radius: 0.375rem;
  min-width: 70px;
}

.status-list .status-info .status-icon {
  font-size: 0.875rem;
  padding-top: 0.625rem;
}
.status-list .status-info .text-wrap {
  padding: 0rem 1.5625rem;
  word-break: break-all;
}
.status-list .status-info h5 {
  color: #0E378C;
  font-weight: 600;
  font-size: 0.9375rem;
}
.status-list .status-info h5 span {
  margin-bottom: 0.1875rem;
}
.status-list .status-info p {
  font-size: 0.8125rem;
  color: #9D9D9D;
  font-weight: 600;
  margin: 0rem;
  text-transform: capitalize;
}
.status-list .status-info .number {
  font-size: 1.125rem;
}
.status-list .status-info .number span {
  font-weight: 600;
}
.status-list hr {
  border-top: 1px solid #EFEFEF;
}
@media (max-width: 767.81px) {
  .status-list.card {
    margin-top: 1.875rem;
  }
}
@media (min-width: 1200.91px) {
  .status-list.card {
    padding: 1.875rem 1.875rem 1.875rem 2.5rem;
  }
}

.credit-cards .card [class*=col-]:nth-child(n+4) {
  margin-top: 1.875rem;
}
@media (max-width: 1200.81px) {
  .credit-cards .card [class*=col-]:nth-child(n+3) {
    margin-top: 1.875rem;
  }
}
@media (max-width: 991.9px) {
  .credit-cards .card [class*=col-]:nth-child(n+2) {
    margin-top: 1.875rem;
  }
}
.credit-cards .card-wrap {
  background-color: #0E378C;
  border-radius: 0.75rem;
  padding: 1.875rem 1.875rem 1.5625rem 1.875rem;
  background-image: url(../../images/credit-card-bg.png);
  position: relative;
}
@media (max-width: 767.81px) {
  .credit-cards .card-wrap {
    padding: 1.5625rem;
  }
}
@media (max-width: 991.9px) {
  .credit-cards .card-wrap {
    max-width: 380px;
  }
}
.credit-cards .card-wrap.text-danger {
  background-color: #E73945;
}
.credit-cards .card-wrap.text-success {
  background-color: #47B872;
}
.credit-cards .card-wrap.text-yellow {
  background-color: #F3C32F;
}
.credit-cards .card-wrap.text-light-gray {
  background-color: #9D9D9D;
}
.credit-cards .card-wrap .card-type img {
  height: 100%;
  object-fit: scale-down;
}
.credit-cards .card-wrap .card-number {
  padding: 1.25rem 0rem;
}
.credit-cards .card-wrap .card-number span {
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.0625rem;
}
@media (max-width: 767.81px) {
  .credit-cards .card-wrap .card-number span {
    font-size: 1.125rem;
  }
}
.credit-cards .card-wrap .card-number span + span {
  padding-left: 2rem;
}
@media (max-width: 375.81px) {
  .credit-cards .card-wrap .card-number span + span {
    padding-left: 0.875rem;
  }
}
.credit-cards .card-owner-detail span {
  color: #ffffff;
  display: block;
  font-weight: 600;
}
.credit-cards .card-owner-detail .c-text {
  font-size: 0.75rem;
  padding-bottom: 0.3125rem;
}
.credit-cards .card-owner-detail .c-name {
  font-size: 0.9375rem;
}
.credit-cards .card-ex-date {
  padding-left: 3.4375rem;
}
.credit-cards .edit-delet-option {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #ffffff;
  cursor: pointer;
}
.credit-cards .edit-delet-option span {
  font-size: 1rem;
  font-weight: 400;
}

.add-new-card-wrap {
  height: 200px;
  background-color: rgba(239, 239, 239, 0.4);
  border-radius: 0.75rem;
  cursor: pointer;
  text-align: center;
}
.add-new-card-wrap .add-new-card .add-option {
  width: 39px;
  height: 39px;
  line-height: 39px;
  text-align: center;
  background-color: #EFEFEF;
  border-radius: 0.75rem;
  margin: 0rem auto 1.25rem;
}
.add-new-card-wrap .add-new-card .add-option span {
  color: #0E378C;
}
.add-new-card-wrap .add-new-card .add-option span::before {
  font-weight: 600;
}

.color-selection input[type=radio] + span {
  background-color: #0E378C;
  width: 24px !important;
  height: 24px !important;
}
.color-selection input[type=radio] ~ span:after {
  background-color: transparent !important;
  content: "";
  font-family: "icomoon" !important;
  color: #ffffff;
  font-size: 0.5rem;
  top: 64% !important;
  left: 65% !important;
}
@media (max-width: 767.81px) {
  .color-selection input[type=radio] ~ span:after {
    margin-left: -0.5rem !important;
    margin-top: -0.4375rem !important;
  }
}
.color-selection input.text-primary[type=radio] + span {
  background-color: #0E378C !important;
  border-color: #0E378C !important;
}
.color-selection input.text-danger[type=radio] + span {
  background-color: #E73945;
  border-color: #E73945;
}
.color-selection input.text-danger[type=radio] + span {
  background-color: #E73945;
  border-color: #E73945;
}
.color-selection input.text-success[type=radio] + span {
  background-color: #47B872;
  border-color: #47B872;
}
.color-selection input.text-yellow[type=radio] + span {
  background-color: #F3C32F;
  border-color: #F3C32F;
}
.color-selection input.text-light-gray[type=radio] + span {
  background-color: #9D9D9D;
  border-color: #9D9D9D;
}
.color-selection .custom-radio .form-group {
  position: relative;
  padding: 0.9375rem 2.5rem 0rem 0rem;
  display: inline-block;
}
.color-selection .custom-radio .form-group label {
  position: absolute;
  left: 0;
  opacity: 0;
  padding: 0rem;
}

.add-card-modal .btnbox {
  margin-top: 1.875rem;
}

.bank-account .card-holder-name {
  max-width: 60%;
}
.bank-account .card-ex-date .c-name {
  padding-left: 0.4375rem;
  margin-top: -0.125rem;
}

.block-listing .member-info-wrap + .member-info-wrap {
  margin-top: 1.875rem;
  padding-top: 1.5625rem;
  border-top: 1px solid #EFEFEF;
}
.block-listing .designation h6 {
  color: #0E378C;
}
@media (min-width: 768.91px) {
  .block-listing .designation h6 {
    font-size: 1.125rem;
  }
}
.block-listing .designation span {
  color: #222222;
  font-size: 0.875rem;
}
.block-listing .member-req {
  text-transform: uppercase;
  font-weight: 600;
}
.block-listing .member-req .package {
  width: 150px;
}

.close-account .select-options {
  margin-top: 1.875rem;
}
.close-account .subreasons-list {
  padding-left: 2.5rem;
}
@media (max-width: 767.81px) {
  .close-account .subreasons-list {
    padding-left: 1.875rem;
  }
}
.close-account .subreasons-list li {
  line-height: 1.7;
}

.video-upload-wrap .visible {
  z-index: 1000 !important;
}
@media (max-width: 1200.81px) {
  .video-upload-wrap .k-window {
    left: 50% !important;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
  }
}
@media (max-width: 767.81px) {
  .video-upload-wrap .k-window {
    max-width: 400px;
    width: 92% !important;
  }
}
.video-upload-wrap .k-window-titlebar {
  background-color: #0E378C;
}
.video-upload-wrap .k-window-titlebar .k-window-title {
  cursor: pointer !important;
}
.video-upload-wrap .k-window-titlebar button.k-button-icon.k-button.k-bare[aria-label=Maximize] {
  display: none !important;
}
.video-upload-wrap .k-window-titlebar button.k-button-icon.k-button.k-bare[aria-label=Close] {
  display: none !important;
}
.video-upload-wrap ul li {
  padding: 15px 0;
  margin-bottom: 15px;
  border-bottom: 1px solid #efefef;
}
.video-upload-wrap ul li .info-btn-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}
.video-upload-wrap ul li .info-btn-wrap span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 50%;
  margin-bottom: 10px;
  margin: 0;
  height: 23px;
}

.active-dot {
  width: 9.33px;
  height: 9.33px;
  background: #47B872;
  border: 1px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  bottom: 0px;
  right: 0;
}

.popuptitlebar .popup-btn {
  padding: 0;
  width: 24px !important;
  height: 24px !important;
  min-width: 0;
  min-height: 0;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  background: #EFEFEF !important;
  justify-content: center;
  font-size: 0.875rem;
}
.popuptitlebar .popup-btn:hover .k-button:hover::before,
.popuptitlebar .popup-btn:hover .k-button.k-state-hover::before {
  opacity: 0;
}
.popuptitlebar .popup-btn:hover {
  background: #EFEFEF !important;
}
.popuptitlebar .popup-btn .k-icon {
  font-size: 9px;
}
.popuptitlebar .popup-btn span::before {
  content: "";
  font-family: "icomoon", sans-serif !important;
}
.popuptitlebar .popup-btn img {
  mix-blend-mode: multiply;
  max-width: 15px;
}
.popuptitlebar .icon-Icon-down-arrow-block {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left: 1.875rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  font-size: 0.625rem;
  transform: rotate(0);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}

.msgblock-box {
  flex-wrap: wrap;
}
.msgblock-box .dropdown-menu {
  position: static;
  box-shadow: none;
  border-radius: 0;
  min-width: 100%;
  border-top: 1px solid #EFEFEF;
  padding: 10px 10px 10px 39px;
  margin-top: 1.25rem;
  margin-bottom: -1.25rem;
  background: transparent;
  animation: none;
}
@media (max-width: 414.81px) {
  .msgblock-box .dropdown-menu {
    margin-top: 10px;
    margin-bottom: -10px;
    padding: 5px 10px 0px 39px;
  }
}
.msgblock-box .dropdown-menu::before {
  display: none;
}

.popuptitlebar .imgbox {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin-right: 10px;
}
.popuptitlebar .imgbox img {
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.popuptitlebar .usermsg-titlename {
  width: 100%;
  flex-shrink: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  font-size: 1rem;
  letter-spacing: -0.25px;
}

.k-window {
  overflow: hidden;
  border-radius: 12px 12px 0 0 !important;
  min-height: auto !important;
}
.userlist-popup.video-dialoguewrap .k-window {
  position: fixed !important;
  top: auto !important;
  bottom: 0 !important;
  right: 26px !important;
  left: auto !important;
  box-shadow: 2px 2px 18px 8px rgba(31, 14, 14, 0.03) !important;
  transform: none !important;
  max-width: 320px;
  width: 100% !important;
}
@media (max-width: 991.9px) {
  .userlist-popup.video-dialoguewrap .k-window {
    right: 15px !important;
  }
}
@media (max-width: 768.81px) {
  .userlist-popup.video-dialoguewrap .k-window {
    max-width: 300px;
  }
}
@media (max-width: 480.81px) {
  .userlist-popup.video-dialoguewrap .k-window {
    right: 10px !important;
  }
}
.singlechat-popup .k-window {
  position: fixed !important;
  top: auto !important;
  bottom: 0 !important;
  right: 374px !important;
  left: auto !important;
  box-shadow: 2px 2px 18px 8px rgba(0, 0, 0, 0.03) !important;
  transform: none !important;
  max-width: 315px;
  width: 100% !important;
  z-index: 1255 !important;
}
@media (max-width: 991.9px) {
  .singlechat-popup .k-window {
    right: 350px !important;
  }
}
@media (max-width: 768.81px) {
  .singlechat-popup .k-window {
    max-width: 300px;
    right: 15px !important;
  }
}
@media (max-width: 480.81px) {
  .singlechat-popup .k-window {
    right: 10px !important;
  }
}
.k-window .k-content:empty {
  padding-bottom: 0 !important;
}

.msg-chatbox {
  min-height: 175px;
  max-height: 320px;
  overflow: auto;
}

.video-dialoguewrap.userlist-popup .k-window-content {
  padding-top: 0;
}
.video-dialoguewrap .uploaded-imgbox.multipost .imgbox {
  width: calc(50% - 10px) !important;
}
.video-dialoguewrap .uploaded-imgbox.multipost .imgbox:nth-child(even) {
  margin-right: 0 !important;
}
.video-dialoguewrap .msg-receive-time-outer {
  margin-bottom: 10px;
  text-align: center;
}
.video-dialoguewrap .uploaded-file-wrap .file p {
  flex-shrink: 1;
  word-break: break-all;
  max-width: calc(100% - 90px) !important;
}
@media (max-width: 414.81px) {
  .video-dialoguewrap .uploaded-file-wrap .file p {
    max-width: 100% !important;
  }
}
.video-dialoguewrap .btnbox .btn {
  font-size: 0.875rem;
  min-height: auto;
}
.video-dialoguewrap .message-layout .msg-list-wrap {
  height: auto;
  margin-left: -15px;
  margin-right: -15px;
}
.video-dialoguewrap .message-layout .msg-list-wrap .all-msg-box {
  max-height: calc(100vh - 200px);
  padding-left: 15px;
  padding-right: 15px;
}
.video-dialoguewrap .message-layout .msg-list-wrap li {
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}
.video-dialoguewrap .message-layout .msg-list-wrap li:last-child {
  padding-bottom: 4px;
  border-bottom: 0;
}
.video-dialoguewrap .message-layout .msg-list-wrap li:last-child .msgblock-box .dropdown-menu {
  margin-bottom: 0;
  padding-bottom: 0;
}
.video-dialoguewrap .message-layout .msg-list-wrap li .imgbox {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}
.video-dialoguewrap .message-layout .msg-list-wrap li .msg-info {
  align-self: center;
  width: 100%;
  padding-right: 0;
}
.video-dialoguewrap .message-layout .msg-list-wrap li .msg-info h6 {
  font-size: 0.9375rem;
}
.video-dialoguewrap .video-upload-wrap ul li {
  margin-bottom: 0;
}

.video-upload-wrap .k-window-titlebar {
  background: rgba(34, 34, 34, 0.05);
  border: 0;
  color: #222222;
  border-radius: 12px 12px 0 0;
  margin: 1px;
  padding: 9px 16px;
}
.video-upload-wrap .k-window-titlebar .k-window-title {
  font-size: 15px;
}

.video-dialoguewrap .received-msg-card {
  height: auto;
}
.video-dialoguewrap .received-msg-card .msg-outer .msg-text-box {
  margin-bottom: 1rem;
  width: 100%;
}
.video-dialoguewrap .received-msg-card.sentmsg .msg-outer .msg-text-box .msg {
  max-width: 100%;
  background-color: rgba(14, 55, 140, 0.05);
}
.video-dialoguewrap .cardpopup-footer .add-btn {
  width: 63px !important;
  font-size: 14px !important;
  text-align: left !important;
  display: flex;
  align-items: center;
}
.video-dialoguewrap .cardpopup-footer .send-msg-btn {
  padding: 0;
  flex-shrink: 0;
  background-color: #EFEFEF !important;
  border-color: #EFEFEF !important;
  width: 32px !important;
  height: 32px !important;
}
.video-dialoguewrap .cardpopup-footer .send-msg-btn span {
  font-size: 13px !important;
}
.video-dialoguewrap .cardpopup-footer .send-msg-btn span::before {
  color: #0E378C !important;
}
.video-dialoguewrap .cardpopup-footer .add-btn,
.video-dialoguewrap .cardpopup-footer .send-msg-btn {
  position: static !important;
}
.video-dialoguewrap .cardpopup-footer .msg-type-input-outer {
  padding: 20px 16px 8px !important;
  border-top: 1px solid #EFEFEF;
  margin: 0 -16px;
}
.video-dialoguewrap .cardpopup-footer .msg-type-input-wrap textarea {
  padding: 0 !important;
  min-height: 22px !important;
  height: 22px !important;
  background: #ffffff;
  border-radius: 0;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container .ngx-toastr {
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 0.75rem 1.25rem 0.75rem 50px;
  width: 300px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
  pointer-events: auto;
}
.toast-container .ngx-toastr .toast-title {
  font-weight: bold;
}
.toast-container .ngx-toastr .toast-message {
  word-wrap: break-word;
}
.toast-container .ngx-toastr .toast-message a:hover {
  text-decoration: underline;
}
.toast-container .ngx-toastr .toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: inherit;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  background: transparent;
  border: 0;
  padding: 0;
}
.toast-container .ngx-toastr .toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.3;
}
.toast-container .ngx-toastr .toast-close-button:hover,
.toast-container .ngx-toastr .toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.75;
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  opacity: 1;
  cursor: pointer;
}

.toast-success {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/check.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgba(53, 112, 76, 0.999999)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
  color: #35704c;
  background-color: #daf1e3;
  border: 1px solid #cbebd8;
}

.toast-error {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/times-circle.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgba(136, 46, 52, 0.999999)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
  color: #882e34;
  background-color: #fad7da;
  border: 1px solid #f8c8cb;
}

.toast-info {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/info-circle.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgba(28, 101, 112, 0.999999)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
  color: #1c6570;
  background-color: #d1ecf1;
  border: 1px solid #bee5eb;
}

.toast-warning {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/exclamation-triangle.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgba(148, 82, 27, 0.999999)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
  color: #94521b;
  background-color: #ffe5d0;
  border: 1px solid #fedbbd;
}

@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
/** Colors variables */
/** share button variables */
/** share buttons colors */
/** Colors variables */
/** share button variables */
/** share buttons colors */
.sb-group,
.sb-button {
  display: inline-flex;
  align-items: flex-start;
}

.sb-group {
  flex-wrap: wrap;
}

.sb-button {
  margin: var(--sb-margin);
}

/** Default style */
.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent;
  -webkit-print-color-adjust: exact;
}
.sb-wrapper .sb-icon,
.sb-wrapper .sb-text {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.sb-wrapper .sb-text {
  flex: 1;
  height: 100%;
  white-space: nowrap;
  padding: 0 0.7em;
}
.sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  min-width: 2em;
}
.sb-wrapper .sb-text {
  font-weight: bold;
}

.sb-material-dark .sb-wrapper {
  background-color: #2E4057;
  background-color: var(--button-color) !important;
  font-family: "Roboto", sans-serif;
  color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.sb-material-dark .sb-wrapper.sb-facebook {
  background-color: #4267B2;
}
.sb-material-dark .sb-wrapper.sb-twitter {
  background-color: #00acee;
}
.sb-material-dark .sb-wrapper.sb-google {
  background-color: #db4437;
}
.sb-material-dark .sb-wrapper.sb-mix {
  background-color: #ff8226;
}
.sb-material-dark .sb-wrapper.sb-line {
  background-color: #00b900;
}
.sb-material-dark .sb-wrapper.sb-linkedin {
  background-color: #006fa6;
}
.sb-material-dark .sb-wrapper.sb-pinterest {
  background-color: #bd081c;
}
.sb-material-dark .sb-wrapper.sb-reddit {
  background-color: #ff4006;
}
.sb-material-dark .sb-wrapper.sb-tumblr {
  background-color: #36465d;
}
.sb-material-dark .sb-wrapper.sb-whatsapp {
  background-color: #25d366;
}
.sb-material-dark .sb-wrapper.sb-messenger {
  background-color: #0080FF;
}
.sb-material-dark .sb-wrapper.sb-telegram {
  background-color: #0088cc;
}
.sb-material-dark .sb-wrapper.sb-xing {
  background-color: #006567;
}
.sb-material-dark .sb-wrapper.sb-sms {
  background-color: #20c16c;
}
.sb-material-dark .sb-wrapper.sb-email {
  background-color: #FF961C;
}
.sb-material-dark .sb-wrapper.sb-viber {
  background-color: #665ca7;
}
.sb-material-dark .sb-wrapper.sb-vk {
  background-color: #4C75A3;
}
.sb-material-dark .sb-wrapper.sb-copy {
  background-color: #607D8B;
}
.sb-material-dark .sb-wrapper.sb-print {
  background-color: #765AA2;
}
.sb-material-dark .sb-wrapper.sb-expand {
  background-color: #FF6651;
}
.sb-material-dark .sb-wrapper:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.sb-material-dark .sb-wrapper.sb-show-icon.sb-show-text {
  padding-left: 0.3em;
}
.sb-material-dark .sb-wrapper.sb-show-icon.sb-show-text .sb-text {
  padding-right: 1em;
  padding-left: 0;
}

/** Colors variables */
/** share button variables */
/** share buttons colors */
/** Colors variables */
/** share button variables */
/** share buttons colors */
.sb-group,
.sb-button {
  display: inline-flex;
  align-items: flex-start;
}

.sb-group {
  flex-wrap: wrap;
}

.sb-button {
  margin: var(--sb-margin);
}

/** Default style */
.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent;
  -webkit-print-color-adjust: exact;
}
.sb-wrapper .sb-icon,
.sb-wrapper .sb-text {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.sb-wrapper .sb-text {
  flex: 1;
  height: 100%;
  white-space: nowrap;
  padding: 0 0.7em;
}
.sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  min-width: 2em;
}
.sb-wrapper .sb-text {
  font-weight: bold;
}

.sb-modern-light .sb-wrapper {
  color: var(--button-color) !important;
  background-color: #fff;
  transition: box-shadow ease-in 150ms, transform ease-in 150ms;
  border-radius: 3px;
  box-shadow: 0 0.1em 0.5em rgba(165, 165, 165, 0.6);
}
.sb-modern-light .sb-wrapper.sb-facebook {
  color: #4267B2;
}
.sb-modern-light .sb-wrapper.sb-twitter {
  color: #00acee;
}
.sb-modern-light .sb-wrapper.sb-google {
  color: #db4437;
}
.sb-modern-light .sb-wrapper.sb-mix {
  color: #ff8226;
}
.sb-modern-light .sb-wrapper.sb-line {
  color: #00b900;
}
.sb-modern-light .sb-wrapper.sb-linkedin {
  color: #006fa6;
}
.sb-modern-light .sb-wrapper.sb-pinterest {
  color: #bd081c;
}
.sb-modern-light .sb-wrapper.sb-reddit {
  color: #ff4006;
}
.sb-modern-light .sb-wrapper.sb-tumblr {
  color: #36465d;
}
.sb-modern-light .sb-wrapper.sb-whatsapp {
  color: #25d366;
}
.sb-modern-light .sb-wrapper.sb-messenger {
  color: #0080FF;
}
.sb-modern-light .sb-wrapper.sb-telegram {
  color: #0088cc;
}
.sb-modern-light .sb-wrapper.sb-xing {
  color: #006567;
}
.sb-modern-light .sb-wrapper.sb-sms {
  color: #20c16c;
}
.sb-modern-light .sb-wrapper.sb-email {
  color: #FF961C;
}
.sb-modern-light .sb-wrapper.sb-viber {
  color: #665ca7;
}
.sb-modern-light .sb-wrapper.sb-vk {
  color: #4C75A3;
}
.sb-modern-light .sb-wrapper.sb-copy {
  color: #607D8B;
}
.sb-modern-light .sb-wrapper.sb-print {
  color: #765AA2;
}
.sb-modern-light .sb-wrapper.sb-expand {
  color: #FF6651;
}
.sb-modern-light .sb-wrapper:active {
  box-shadow: 0 0.4em 1em rgba(165, 165, 165, 0.9);
}
.sb-modern-light .sb-wrapper .sb-icon {
  min-width: 2em;
}
.sb-modern-light .sb-wrapper.sb-show-text {
  padding: 0;
}
.sb-modern-light .sb-wrapper.sb-show-text .sb-icon {
  box-shadow: 1px 0 1px -1px rgba(0, 0, 0, 0.3);
}

/* icomoon fonts */
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?t7b44q");
  src: url("../fonts/icomoon.eot?t7b44q#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?t7b44q") format("truetype"), url("../fonts/icomoon.woff?t7b44q") format("woff"), url("../fonts/icomoon.svg?t7b44q#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-promote-remove:before {
  content: "";
}

.icon-bank-account:before {
  content: "";
}

.icon-arrow_upward1:before {
  content: "";
  color: #47b872;
}

.icon-arrow_upward:before {
  content: "";
  color: #e73945;
}

.icon-Group:before {
  content: "";
  color: #fff;
}

.icon-request-pending-icon .path1:before {
  content: "";
  color: #f3c32f;
}

.icon-request-pending-icon .path2:before {
  content: "";
  margin-left: -1em;
  color: white;
}

.icon-request-pending-icon .path3:before {
  content: "";
  margin-left: -1em;
  color: white;
}

.icon-icon-icon-left-angle-arrow:before {
  content: "";
}

.icon-icon-create-job:before {
  content: "";
  color: #fff;
}

.icon-icon-photo:before {
  content: "";
  color: #0e378c;
}

.icon-download-icon:before {
  content: "";
}

.icon-duble-tick:before {
  content: "";
}

.icon-duble-tick-read:before {
  content: "";
  color: #0046d8;
}

.icon-icon-mute:before {
  content: "";
}

.icon-icon-send-msg:before {
  content: "";
  color: #fff;
}

.icon-cloud:before {
  content: "";
  color: #9d9d9d;
}

.icon-folder:before {
  content: "";
}

.icon-folder-icon .path1:before {
  content: "";
  color: #dedede;
}

.icon-folder-icon .path2:before {
  content: "";
  margin-left: -1.048828125em;
  color: #efefef;
}

.icon-alarm-clock:before {
  content: "";
}

.icon-location:before {
  content: "";
}

.icon-flags:before {
  content: "";
}

.icon-fullscreen:before {
  content: "";
  color: #fff;
}

.icon-maximize:before {
  content: "";
  color: #fff;
}

.icon-dots:before {
  content: "";
  color: #fff;
}

.icon-grid:before {
  content: "";
  color: #0e378c;
}

.icon-list:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-clock:before {
  content: "";
  color: #0e378c;
}

.icon-icon-doller:before {
  content: "";
  color: #0e378c;
}

.icon-upload-ic:before {
  content: "";
  color: #0e378c;
}

.icon-icon-pdf:before {
  content: "";
}

.icon-icon-tick:before {
  content: "";
}

.icon-blogger:before {
  content: "";
}

.icon-reddit:before {
  content: "";
}

.icon-website:before {
  content: "";
}

.icon-icon-add-network:before {
  content: "";
}

.icon-icon-remove-network:before {
  content: "";
}

.icon-icon-angel:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-apital:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-banker:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-equity:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-family:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-one-user:before {
  content: "";
  color: #9d9d9d;
}

.icon-iconpublic:before {
  content: "";
}

.icon-icon-certi:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-building:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-green-border-tick:before {
  content: "";
}

.icon-icon-plush:before {
  content: "";
}

.icon-icon-plan-core:before {
  content: "";
}

.icon-icon-plan-essential:before {
  content: "";
}

.icon-icon-plan-premium:before {
  content: "";
}

.icon-icon-block:before {
  content: "";
}

.icon-icon-credit-card:before {
  content: "";
}

.icon-icon-credits:before {
  content: "";
}

.icon-icon-email:before {
  content: "";
}

.icon-icon-lock:before {
  content: "";
}

.icon-icon-mobile:before {
  content: "";
}

.icon-icon-refer:before {
  content: "";
}

.icon-icon-right-angle-arrow:before {
  content: "";
}

.icon-icon-subscription:before {
  content: "";
}

.icon-icon-verify-thik:before {
  content: "";
}

.icon-icon-verify:before {
  content: "";
}

.icon-icon-info:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-setting:before {
  content: "";
}

.icon-icon-caleder:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-delete:before {
  content: "";
}

.icon-icon-edit:before {
  content: "";
}

.icon-icon-promote:before {
  content: "";
}

.icon-Ver-action-btn:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-linkedin:before {
  content: "";
  color: #fff;
}

.icon-icon-dribble:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-facebook:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-twitter:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-action:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-add-video:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-arrow-down:before {
  content: "";
  color: #9d9d9d;
}

.icon-Icon-arrow-left:before {
  content: "";
  color: #9d9d9d;
}

.icon-Icon-arrow-right:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-camera:before {
  content: "";
  color: #fff;
}

.icon-icon-close-black:before {
  content: "";
}

.icon-icon-close:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-comment:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-connections:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-document:before {
  content: "";
  color: #9d9d9d;
}

.icon-Icon-down-arrow-block:before {
  content: "";
}

.icon-icon-eye:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-favorites-selected:before {
  content: "";
  color: #0e378c;
}

.icon-icon-favorites:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-filter:before {
  content: "";
  color: #9d9d9d;
}

.icon-Icon-green-tick:before {
  content: "";
  color: #47b872;
}

.icon-icon-home:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-investor:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-like-selected:before {
  content: "";
  color: #0e378c;
}

.icon-icon-like:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-meeting:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-msg:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-my-video:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-nav:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-notification:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-opportunities:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-play:before {
  content: "";
  color: #fff;
}

.icon-icon-search:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-service-provider:before {
  content: "";
  color: #9d9d9d;
}

.icon-icon-share:before {
  content: "";
  color: #9d9d9d;
}

.icon-Icon-suitcase:before {
  content: "";
  color: #0e378c;
}

.icon-icon-unlike-selected:before {
  content: "";
  color: #0e378c;
}

.icon-icon-unlike:before {
  content: "";
  color: #9d9d9d;
}

body {
  background: #EFEFEF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  line-height: 1.25;
  color: #222222;
  font-weight: 400;
  letter-spacing: -0.03px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
}
@media (max-width: 991.9px) {
  body {
    font-size: 0.875rem;
  }
}

a {
  text-decoration: none;
  color: #222222;
  font-size: 1rem;
  cursor: pointer;
  outline: 0;
}
a:hover, a:focus {
  text-decoration: none;
  outline: 0;
  color: black;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.62;
}
@media (max-width: 1200.81px) {
  p {
    font-size: 0.9375rem;
    line-height: normal;
  }
}

img {
  max-width: 100%;
}

.font-weight-semibold {
  font-weight: 600;
}

* {
  scrollbar-color: rgba(34, 34, 34, 0.3) transparent;
  scrollbar-width: thin;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(34, 34, 34, 0.3);
  border-radius: 0.75rem;
}

:focus {
  outline: none;
  box-shadow: none;
}

button:focus,
.btn:focus {
  outline: none;
  box-shadow: none;
}

/* ============================================================================= Commen style Start */
@media (min-width: 1200px) {
  .container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
    max-width: 1200px;
  }
}
section {
  padding: 9.375rem 0rem;
}
@media (max-width: 1440.81px) {
  section {
    padding: 7.8125rem 0rem;
  }
}
@media (max-width: 1200.81px) {
  section {
    padding: 6.25rem 0rem;
  }
}
@media (max-width: 991.9px) {
  section {
    padding: 4.375rem 0rem;
  }
}
@media (max-width: 576.81px) {
  section {
    padding: 3.125rem 0rem;
  }
}

.xs-title {
  margin-bottom: 0.9375rem;
}

.layout-wrap {
  padding: 4.375rem 0rem 6.25rem;
}
@media (max-width: 767.81px) {
  .layout-wrap {
    padding: 3.625rem 0rem 4.375rem;
  }
}

.inner-layout {
  padding: 1.875rem 0rem;
}

.card-padding-0 {
  padding: 0 !important;
}

em {
  font-style: normal;
}

/* ==================================== vert-move */
.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
/* ========================================  Slider */
@media (max-width: 768.81px) {
  .slick-slider .slick-list {
    max-width: 80%;
    margin: 0 auto;
  }
}
.slick-slider .slick-list .slick-track {
  display: flex;
  align-items: center;
}
.slick-slider .slick-list .slick-track .slick-slide img {
  margin: auto;
  padding: 0rem 0.625rem;
}
@media (max-width: 1280.81px) {
  .slick-slider .slick-list .slick-track .slick-slide img {
    width: 100%;
    max-width: 150px;
  }
}
@media (max-width: 991.9px) {
  .slick-slider .slick-list .slick-track .slick-slide img {
    width: 100%;
    max-width: 125px;
  }
}
@media (max-width: 767.81px) {
  .slick-slider .slick-list .slick-track .slick-slide img {
    width: 100%;
    max-width: 100px;
  }
}
.slick-slider .slick-arrow {
  position: absolute;
  width: 48px;
  height: 48px;
  border: 1px solid #9D9D9D;
  border-radius: 3.125rem;
}
@media (max-width: 991.9px) {
  .slick-slider .slick-arrow {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 576.81px) {
  .slick-slider .slick-arrow {
    width: 30px;
    height: 30px;
  }
}
.slick-slider .slick-arrow.slick-prev {
  background: url("../images/Icon-arrow-left.svg");
  background-position: center center;
  background-size: 1rem;
  background-repeat: no-repeat;
  left: -60px;
}
.slick-slider .slick-arrow.slick-prev:before {
  content: "";
}
.slick-slider .slick-arrow.slick-prev:hover, .slick-slider .slick-arrow.slick-prev:focus {
  background: #0E378C url("../images/Icon-arrow-left.svg");
  background-position: center center;
  background-size: 1rem;
  background-repeat: no-repeat;
  border: 1px solid #0E378C;
}
@media (max-width: 1280.81px) {
  .slick-slider .slick-arrow.slick-prev {
    left: -60;
  }
}
@media (max-width: 768.81px) {
  .slick-slider .slick-arrow.slick-prev {
    left: 0;
  }
}
@media (max-width: 576.81px) {
  .slick-slider .slick-arrow.slick-prev {
    left: auto;
    right: 50px;
    top: -30px;
    background-size: 10px;
  }
}
.slick-slider .slick-arrow.slick-next {
  background: url("../images/Icon-arrow-right.svg");
  background-position: center center;
  background-size: 1rem;
  background-repeat: no-repeat;
  right: -60px;
}
.slick-slider .slick-arrow.slick-next:before {
  content: "";
}
.slick-slider .slick-arrow.slick-next:hover, .slick-slider .slick-arrow.slick-next:focus {
  background: #0E378C url("../images/Icon-arrow-right.svg");
  background-position: center center;
  background-size: 1rem;
  background-repeat: no-repeat;
  border: 1px solid #0E378C;
}
@media (max-width: 1280.81px) {
  .slick-slider .slick-arrow.slick-next {
    right: -60;
  }
}
@media (max-width: 768.81px) {
  .slick-slider .slick-arrow.slick-next {
    right: 0;
  }
}
@media (max-width: 576.81px) {
  .slick-slider .slick-arrow.slick-next {
    right: 15px;
    top: -30px;
    background-size: 10px;
  }
}

/* Primary Link */
.primary-link {
  color: #0E378C;
  cursor: pointer;
}
.primary-link:hover {
  text-decoration: underline;
}

.delete-link {
  color: #E73945;
  cursor: pointer;
}
.delete-link:hover {
  text-decoration: underline;
}

.primary-text {
  color: #0E378C !important;
}

.text-danger {
  color: #E73945 !important;
  display: block;
  text-align: left !important;
}

.text-gray {
  color: #9D9D9D !important;
}

hr {
  border-top: 1px solid #EFEFEF;
  margin: 1.25rem 0rem;
}

.text-line-through {
  text-decoration: line-through;
}

.blue-tick:before {
  color: #0E378C !important;
}

/* Background */
.bg-black {
  background-color: #000000;
}

.success-light-bg {
  background-color: rgba(40, 167, 69, 0.2) !important;
}

.warning-lgiht-bg {
  background-color: rgba(243, 195, 47, 0.2) !important;
}

.grey-light-bg {
  background-color: rgba(194, 194, 194, 0.2) !important;
}

/* Play Button */
.play-btn {
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ====================================== Modals */
.modal-backdrop.show {
  opacity: 0.15;
}

.modal {
  padding: 0.9375rem;
}
.modal .modal-dialog {
  margin: 0 auto;
  max-width: inherit;
}
.modal .icon-icon-close:hover:before {
  color: #0E378C;
}
.modal .common-modal {
  padding: 0.9375rem;
}
@media (max-width: 576.81px) {
  .modal .common-modal {
    padding: 0;
  }
}
.modal .modal-content {
  border: none;
  border-radius: 0;
  background-color: transparent;
}
.modal .modal-content .modal-card {
  padding: 4.375rem 3.125rem;
  border-radius: 1.5rem;
  background-color: #ffffff;
  position: relative;
  max-width: 470px;
  margin: 0 auto;
}
@media (max-width: 1200.81px) {
  .modal .modal-content .modal-card {
    padding: 2.8125rem 1.875rem;
    min-height: initial;
  }
}
@media (max-width: 414.81px) {
  .modal .modal-content .modal-card {
    padding: 1.875rem 1.25rem;
  }
}
@media (max-width: 767.81px) {
  .modal .modal-content .modal-card {
    max-width: 375px;
    padding: 2.5rem 0.9375rem !important;
  }
}
.modal .modal-content .modal-card.modal-md {
  max-width: 710px;
}
.modal .modal-content .modal-card.modal-md .btnbox .btn {
  max-width: 370px;
}
.modal .modal-content .modal-card h3 {
  text-align: center;
  margin-bottom: 1.875rem;
}
.modal .modal-content .modal-card p {
  text-align: center;
  margin-bottom: 1.875rem;
}
@media (max-width: 991.9px) {
  .modal .modal-content .modal-card p {
    margin-bottom: 1.25rem;
  }
}
.modal .modal-content .modal-card .btnbox {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
}
@media (max-width: 767.81px) {
  .modal .modal-content .modal-card .btnbox {
    flex-wrap: wrap;
  }
  .modal .modal-content .modal-card .btnbox .btn {
    margin-bottom: 0.625rem !important;
  }
}
.modal .modal-content .modal-card .btnbox .btn {
  width: 100%;
}
.modal .modal-content .sm-modal-card .imgbox {
  max-width: 280px;
  position: relative;
  margin: 0rem auto 1.875rem;
}
@media (max-width: 767.81px) {
  .modal .modal-content .sm-modal-card .imgbox {
    max-width: 220px;
  }
}
.modal .popup-close-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 0;
  min-height: auto;
  min-width: inherit;
}
@media (max-width: 1200.81px) {
  .modal .popup-close-btn {
    top: 15px;
    right: 20px;
  }
}
.modal .popup-close-btn:hover span:before {
  color: #0E378C;
}

.search-result {
  max-height: 240px;
  overflow: scroll;
  box-shadow: 0 4px 6px rgba(32, 33, 36, 0.28);
  border-radius: 0.75rem;
}
.search-result ul {
  padding: 0rem;
}
.search-result li {
  display: flex;
  align-items: center;
  margin: 0 !important;
  padding: 0.9375rem 0.75rem;
}
.search-result li:hover {
  background-color: #EFEFEF;
}
.search-result li p {
  margin: 0 !important;
  padding-left: 0.9375rem;
  font-weight: 600;
  text-align: left !important;
}
.search-result .img-box {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
}
.search-result .img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 0.9375rem;
}

/* =========================================== Breadcrumb */
.breadcrumb {
  padding: 0;
  margin-bottom: 1.875rem;
  background-color: transparent;
  border-radius: 0;
}
.breadcrumb .breadcrumb-item {
  font-size: 0.8125rem;
}
@media (max-width: 767.81px) {
  .breadcrumb .breadcrumb-item {
    padding-bottom: 0.3125rem;
  }
}
.breadcrumb .breadcrumb-item::before {
  display: inline-block;
  margin-right: 10px;
  color: #6c757d;
  background-image: url("../images/right-dubble-arrow.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 8.2px;
  height: 7px;
  content: "";
}
.breadcrumb .breadcrumb-item:first-child::before {
  display: none;
}
.breadcrumb .breadcrumb-item.active {
  color: #9D9D9D;
}
.breadcrumb .breadcrumb-item a {
  color: #222222;
  font-size: 0.8125rem;
}
.breadcrumb .breadcrumb-item a:hover {
  color: #0E378C;
}

/* =========================================== Animation img vert move */
.animation-img {
  position: relative;
  margin: 0rem auto 1.875rem;
}
.animation-img .inner-img {
  position: absolute;
  bottom: 15px;
  left: 20px;
}

/* =============================================== cards*/
.lg-card {
  border-radius: 0.75rem;
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(34, 34, 34, 0.15);
  padding: 6.25rem 3.125rem;
}
@media (max-width: 414.81px) {
  .lg-card {
    padding: 3.125rem 1.25rem;
  }
}

/* =============================================== Black Dot */
.black-dot {
  background: #222222;
  width: 4px;
  height: 4px;
  border-radius: 50rem;
  display: inline-block;
  vertical-align: middle;
  margin: 0rem 0.375rem;
}

.gray-dot {
  background: #9D9D9D;
  width: 4px;
  height: 4px;
  border-radius: 50rem;
  display: inline-block;
  vertical-align: middle;
  margin: 0rem 0.375rem;
}

.primary-dot {
  background: #0E378C;
  width: 4px;
  height: 4px;
  border-radius: 50rem;
  display: inline-block;
  vertical-align: middle;
  margin: 0rem 0.375rem;
}

/* =============================================== Video block */
.video-block {
  width: 100%;
}
.video-block .video-introduction {
  border-radius: 1.5rem;
}
.video-block .video-introduction-sm {
  border-radius: 0.75rem;
}
@media (max-width: 576.81px) {
  .video-block .custom-controls {
    bottom: 18px;
    right: 5px;
  }
}
.video-block .custom-controls span {
  margin: 0rem 0.875rem !important;
  font-size: 1.125rem;
  cursor: pointer;
}
@media (max-width: 576.81px) {
  .video-block .custom-controls span {
    font-size: 0.875rem;
    margin: 0rem 0.4375rem;
  }
}
.video-block:not(.hide-control) .video-btn-play {
  background: url("../images/play-ic.svg") no-repeat;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  z-index: 99;
  cursor: pointer;
  border: 0px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 767.81px) {
  .video-block:not(.hide-control) .video-btn-play {
    width: 48px;
    height: 48px;
    background-size: 100%;
  }
}
.video-block:not(.hide-control) .video-btn-play.md-btn {
  width: 56px;
  height: 56px;
  background-size: 100%;
}
@media (max-width: 767.81px) {
  .video-block:not(.hide-control) .video-btn-play.md-btn {
    width: 48px;
    height: 48px;
  }
}
.video-block:not(.hide-control) .video-btn-pause {
  background: url("../images/pause-ic.svg") no-repeat;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  z-index: 99;
  cursor: pointer;
  border: 0px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: sm-sm-0.1) {
  .video-block:not(.hide-control) .video-btn-pause {
    width: 48px;
    height: 48px;
    background-size: 100%;
  }
}
.video-block:not(.hide-control) .video-btn-pause.md-btn {
  width: 56px;
  height: 56px;
  background-size: 100%;
}
@media (max-width: 767.81px) {
  .video-block:not(.hide-control) .video-btn-pause.md-btn {
    width: 48px;
    height: 48px;
  }
}
.video-block .video-btn-play.video-btn-pause {
  opacity: 0;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.video-block:hover .video-btn-play.video-btn-pause {
  opacity: 1;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.video-block.hide-control .video-btn-play {
  display: none;
}

/* =========================================== Tooltip Popover */
.icon-Icon-green-tick {
  cursor: pointer;
}

.tooltip-icon {
  display: inline-block;
}

.tooltip-wrap {
  display: inline-block;
}

.tooltip {
  pointer-events: all !important;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .tooltip-inner {
  background-color: #ffffff;
  padding: 0.9375rem;
  border-radius: 0.75rem;
  color: #222222;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
}
.tooltip .arrow::before,
.tooltip .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0rem;
  border-top-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
}

/* profile media */
.profile-media {
  margin-bottom: 1.25rem;
}
.profile-media.selected .media-body h5, .profile-media:hover .media-body h5 {
  color: #0E378C;
}
.profile-media .imgbox {
  margin-right: 0.9375rem;
  flex: 0 0 auto;
}
.profile-media .imgbox img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 1px solid #EFEFEF;
  border-radius: 50rem;
}
.profile-media .media-body {
  width: calc(100% - 55px);
  text-align: left;
}
.profile-media .media-body h5 {
  margin-bottom: 0;
}
.profile-media .media-body p {
  margin-bottom: 0 !important;
  text-align: left !important;
}

/* Wizard Steps */
ng-wizard .ng-wizard-theme-circles ul.step-anchor {
  position: relative;
  background: transparent !important;
  border: none;
  list-style: none;
  margin-bottom: 2.5rem;
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
}
ng-wizard .ng-wizard-theme-circles ul.step-anchor::before {
  display: none;
}
ng-wizard .ng-wizard-theme-circles ul.step-anchor li {
  margin-left: 0 !important;
  margin-bottom: 0px !important;
  position: relative;
  width: 100%;
  flex-shrink: 1;
}
ng-wizard .ng-wizard-theme-circles ul.step-anchor li::before {
  content: "";
  width: 2rem !important;
  height: 2rem !important;
  padding: 0.3125rem !important;
  background-color: #D6D6D6;
  border: 2px solid #D6D6D6;
  display: block;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 7px;
  transform: translateX(-50%);
  z-index: 0;
}
@media (max-width: 991.9px) {
  ng-wizard .ng-wizard-theme-circles ul.step-anchor li::before {
    top: -7px;
  }
}
ng-wizard .ng-wizard-theme-circles ul.step-anchor li::after {
  content: "";
  display: block;
  height: 2px;
  width: calc(100% - 9px);
  background: #D6D6D6;
  position: absolute;
  left: calc(-50% + 16px);
  top: 22px;
  border-radius: 0.75rem 0rem 0rem 0.75rem;
  z-index: -1;
}
@media (max-width: 991.9px) {
  ng-wizard .ng-wizard-theme-circles ul.step-anchor li::after {
    top: 8px;
  }
}
ng-wizard .ng-wizard-theme-circles ul.step-anchor li:last-child::after {
  width: 140%;
  border-radius: 0rem 0.75rem 0.75rem 0rem;
}
@media (max-width: 767.81px) {
  ng-wizard .ng-wizard-theme-circles ul.step-anchor li:last-child::after {
    width: 135%;
  }
}
ng-wizard .ng-wizard-theme-circles ul.step-anchor li:first-child::after {
  width: 50%;
  left: 0;
  border-radius: 0.75rem 0rem 0rem 0.75rem;
}
ng-wizard .ng-wizard-theme-circles ul.step-anchor li.active:after, ng-wizard .ng-wizard-theme-circles ul.step-anchor li.done:after {
  background: #0E378C;
  height: 4px;
  top: 20px;
}
@media (max-width: 991.9px) {
  ng-wizard .ng-wizard-theme-circles ul.step-anchor li.active:after, ng-wizard .ng-wizard-theme-circles ul.step-anchor li.done:after {
    top: 7px;
  }
}
ng-wizard .ng-wizard-theme-circles ul.step-anchor li.active::before {
  content: "";
  width: 2rem !important;
  height: 2rem !important;
  padding: 0.3125rem !important;
  background-color: #EFEFEF;
  border-color: #0E378C;
}
ng-wizard .ng-wizard-theme-circles ul.step-anchor li.active a {
  color: #0E378C;
}
ng-wizard .ng-wizard-theme-circles ul.step-anchor li.active a small {
  color: #222222;
}
ng-wizard .ng-wizard-theme-circles ul.step-anchor li.done::before {
  border-color: #0E378C !important;
  color: #ffffff !important;
  background: #0E378C !important;
}
ng-wizard .ng-wizard-theme-circles ul.step-anchor li.done small {
  color: #222222;
}
ng-wizard .ng-wizard-theme-circles ul.step-anchor li a {
  box-shadow: none !important;
  position: relative;
  z-index: 1;
  border: none !important;
  background: transparent !important;
  width: auto !important;
  height: auto !important;
  padding: 0.875rem 0rem 0rem;
  color: #222222;
}
@media (max-width: 991.9px) {
  ng-wizard .ng-wizard-theme-circles ul.step-anchor li a {
    padding: 0;
  }
}
ng-wizard .ng-wizard-theme-circles ul.step-anchor li small {
  bottom: -15px !important;
  font-size: 1rem;
  color: #222222;
}
@media (max-width: 991.9px) {
  ng-wizard .ng-wizard-theme-circles ul.step-anchor li small {
    display: none;
  }
}
ng-wizard .ng-wizard-theme-circles ng-wizard-step {
  margin-top: 2.8125rem;
  display: block;
}
ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content {
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
  padding: 6.25rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  border-radius: 1.5rem;
}
@media (max-width: 991.9px) {
  ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content {
    padding: 3.75rem;
  }
}
@media (max-width: 767.81px) {
  ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content {
    padding: 1.875rem;
  }
}
@media (max-width: 414.81px) {
  ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content {
    padding: 0.9375rem;
  }
}
ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content h2 {
  margin-bottom: 1.875rem;
}
ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content p {
  margin-bottom: 1.875rem;
  word-break: break-word;
}
ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content p.info-text {
  color: #9D9D9D;
  margin: 3.125rem 0rem;
  font-size: 1rem;
}
@media (max-width: 767.81px) {
  ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content p.info-text {
    margin: 1.875rem 0rem;
  }
}
ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content p.link-text {
  margin-bottom: 0;
  color: #9D9D9D;
}
@media (max-width: 767.81px) {
  ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content p.link-text {
    margin-bottom: 1.875rem;
    margin-top: 0 !important;
  }
}
ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content .btnbox {
  display: flex;
  align-items: center;
  margin-top: 1.25rem;
}
@media (max-width: 576.81px) {
  ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content .btnbox {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content .btnbox .btn {
  margin: 0rem 0.9375rem;
}
@media (max-width: 576.81px) {
  ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content .btnbox .btn {
    margin: 0rem auto 1.875rem;
  }
}
ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content .btnbox .btn-lg, ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content .btnbox .btn-group-lg > .btn {
  max-width: 370px;
  width: 100%;
}
ng-wizard .ng-wizard-theme-circles ng-wizard-step .step-content .btnbox .btn-md {
  max-width: 185px;
  width: 100%;
}

/* Connection Request */
.connection-request-item {
  border: 1px solid #EFEFEF;
  border-radius: 0.75rem;
  padding: 1.875rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 216px;
  margin: 0rem auto 1.875rem;
  min-height: 100%;
}
.connection-request-item .imgbox {
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1.5625rem;
  overflow: hidden;
}
.connection-request-item .imgbox img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.connection-request-item .btnbox {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: auto;
}
.connection-request-item .btnbox .btn {
  min-width: 4.375rem;
  min-height: 2.25rem;
  padding: 0.4375rem 0.5rem;
  font-size: 0.8125rem;
  border-radius: 0.25rem;
}
.connection-request-item h6 .icon-Icon-green-tick {
  position: relative;
  top: 2px;
}
.connection-request-item .d-flex .icon-Icon-green-tick {
  line-height: 22px;
}

/* Connection */
.coneection-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 1.875rem;
}
@media (max-width: 767.81px) {
  .coneection-wrap {
    flex-direction: column;
    text-align: center;
  }
}
.coneection-wrap .imgbox {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 100%;
  margin-right: 0.625rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767.81px) {
  .coneection-wrap .imgbox {
    margin-right: 0;
    margin-bottom: 0.9375rem;
  }
}
.coneection-wrap .name-wrap .d-flex .icon-Icon-green-tick {
  line-height: 22px;
}
.coneection-wrap .name-wrap h6 {
  font-size: 0.9375rem;
  margin-bottom: 0;
  cursor: pointer;
}
@media (max-width: 375.81px) {
  .coneection-wrap .name-wrap h6 {
    font-size: 0.8125rem;
  }
}
.coneection-wrap .name-wrap span {
  color: #0E378C;
  font-size: 0.8125rem;
}
@media (max-width: 375.81px) {
  .coneection-wrap .name-wrap span {
    font-size: 0.75rem;
  }
}

/* Folder style */
.folder-wrap {
  position: relative;
  padding: 2.5rem 1.875rem 1.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border: 1px solid #EFEFEF;
  border-radius: 0.75rem;
  margin-bottom: 1.875rem;
}
.folder-wrap .imgbox {
  margin-bottom: 1.25rem;
  background-color: transparent !important;
}
.folder-wrap h6 {
  font-size: 0.875rem;
  margin-bottom: 0.3125rem;
}
.folder-wrap span {
  color: #0E378C;
}
.folder-wrap .dropdown-box {
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
}
.folder-wrap .dropdown-box .feed-dropdown {
  padding: 0rem 0.3125rem;
}
.folder-wrap .dropdown-box .dropdown-menu-right {
  right: -25px !important;
  left: auto !important;
}

.member-info {
  text-align: left;
}
.member-info .left-img-avtar {
  margin-bottom: 1.875rem;
}

.left-img-avtar {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-right: 2.1875rem;
}
.left-img-avtar .imgbox {
  width: 50px;
  height: 50px;
  overflow: hidden;
  margin-right: 0.9375rem;
  flex: 0 0 auto;
  border-radius: 100%;
}
.left-img-avtar .imgbox img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.left-img-avtar .avtar-title {
  text-align: left;
}
.left-img-avtar .avtar-title h5 {
  margin-bottom: 0;
}
@media (max-width: 414.81px) {
  .left-img-avtar .avtar-title h5 {
    font-size: 0.8125rem;
  }
}
.left-img-avtar .avtar-title span {
  font-size: 0.9375rem;
  display: block;
}
.left-img-avtar .avtar-title p {
  margin-bottom: 0 !important;
  font-size: 0.9375rem;
}
@media (max-width: 414.81px) {
  .left-img-avtar .avtar-title p {
    font-size: 0.8125rem;
  }
}
.left-img-avtar .edit-btn {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  background: transparent;
  min-width: auto;
  min-height: 0;
}
@media (max-width: 767.81px) {
  .left-img-avtar .edit-btn {
    min-width: auto;
  }
}
.left-img-avtar .edit-btn span::before {
  color: #9D9D9D;
}
.left-img-avtar .edit-btn:hover span::before {
  color: #0E378C;
}

/* Error Popup */
.swal2-container.swal2-shown {
  z-index: 10000;
}

/* My pages common css */
.border-light {
  border: 1px solid #EFEFEF;
  border-color: #EFEFEF !important;
}

.shadow-none {
  box-shadow: none !important;
}

/* range slider css */
mv-slider .slider.slider-horizontal {
  width: 99%;
}
mv-slider .slider.slider-horizontal .slider-track {
  height: 4px;
  background-image: linear-gradient(to bottom, #CCCCCC 0, #CCCCCC 100%);
  top: 0.9375rem;
  border-radius: 1.5rem;
}
mv-slider .slider-selection {
  background-image: linear-gradient(to bottom, #0E378C 0, #0E378C 100%);
  border-radius: 1.5rem;
}
mv-slider .slider-handle {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #ffffff;
  background-image: linear-gradient(to bottom, #ffffff 0, #ffffff 100%);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

/* Toaster Style */
.toast-container .ngx-toastr {
  background-image: none;
}
.toast-container .ngx-toastr.toast-success {
  background-color: #0E378C;
  border: 1px solid #0E378C;
  padding: 0.9375rem;
  text-align: center;
}
.toast-container .ngx-toastr.toast-success .toast-message {
  color: #ffffff;
}
.toast-container .ngx-toastr.toast-success .toast-success {
  display: none;
}
.toast-container .ngx-toastr.toast-error {
  background-image: none;
  color: #ffffff;
  background-color: #E73945;
  border: 1px solid #E73945;
  padding: 0.9375rem;
  text-align: center;
}

.save-changes-notification .btnbox .btn {
  min-width: 185px;
  margin: 0rem 0.9375rem !important;
}
.save-changes-notification .btnbox .btn.btn-primary {
  border-width: 0.125rem;
}
.save-changes-notification .modal-card {
  padding: 4.375rem 3.125rem 3.125rem 3.125rem !important;
  max-width: 555px !important;
}

video::-webkit-media-controls-volume-control-container {
  display: none !important;
}

video::-webkit-media-controls-volume-control-container.closed {
  display: none !important;
}

video::-webkit-media-controls-fullscreen-button,
video::-webkit-media-controls-mute-button {
  display: none !important;
}

video::-internal-media-controls-overflow-button {
  display: none !important;
}

video::-webkit-media-controls.sizing-small input[pseudo=-internal-media-controls-overflow-button i] {
  display: none !important;
}

video::-webkit-media-controls-play-button {
  margin-left: 0px !important;
}
@media (max-width: 576.81px) {
  video::-webkit-media-controls-play-button {
    margin-left: -20px !important;
    position: relative;
    bottom: -39px;
    height: 90px;
  }
}

video::-webkit-media-controls.sizing-small div[pseudo=-internal-media-controls-button-panel i] {
  display: none !important;
}

video::-internal-media-controls-download-button {
  display: none;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px);
  /* Adjust as needed */
}

video::-webkit-media-controls-timeline {
  max-width: 56%;
  margin-left: 40px;
  position: relative;
  bottom: 26px;
  left: 8px;
}
@media (max-width: 768.81px) {
  video::-webkit-media-controls-timeline {
    width: 36%;
  }
}
@media (max-width: 576.81px) {
  video::-webkit-media-controls-timeline {
    bottom: 0;
    width: 55%;
  }
}
@media (max-width: 480.81px) {
  video::-webkit-media-controls-timeline {
    width: 55%;
  }
}
@media (max-width: 375.81px) {
  video::-webkit-media-controls-timeline {
    width: 48%;
  }
}
@media (max-width: 320.11px) {
  video::-webkit-media-controls-timeline {
    width: 42%;
  }
}

video::-webkit-media-controls-current-time-display {
  width: 63.5%;
}
@media (max-width: 768.81px) {
  video::-webkit-media-controls-current-time-display {
    width: 47.5%;
  }
}
@media (max-width: 576.81px) {
  video::-webkit-media-controls-current-time-display {
    width: auto;
    text-align: center;
    margin-left: 1.4375rem;
  }
}

video::-internal-media-controls-download-button {
  display: none;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px);
  /* Adjust as needed */
}

.home-feed-modal .dropdown-menu {
  margin-right: -0.6875rem;
}
.home-feed-modal .dropdown-menu:before {
  right: 3px;
  top: 1px;
}
@media (max-width: 576.81px) {
  .home-feed-modal .dropdown-menu:before {
    right: 0;
  }
}

.default-date {
  background-color: rgba(14, 55, 140, 0.15);
}

.otp-input {
  color: #0E378C;
}

.text-red {
  color: #E73945 !important;
}

.d-btn-center {
  text-align: center !important;
}

.popover {
  z-index: 1049 !important;
}

.icon-Group.yellow-bg {
  background-color: #F3C32F !important;
}

.filter-text-btn-wrap {
  padding-bottom: 0.9375rem;
}
.filter-text-btn-wrap .btn {
  margin-left: 0.9375rem;
}

.icon-icon-close-black.search-close {
  font-size: 0.875rem;
  cursor: pointer;
}
.icon-icon-close-black.search-close::before {
  color: #9D9D9D !important;
}

.single-search-icon .module-search-wrap .module-search {
  position: relative;
}
.single-search-icon .module-search-wrap .module-search input {
  padding: 0.75rem 3.4375rem 0.75rem 5rem;
}
@media (max-width: 991.9px) {
  .single-search-icon .module-search-wrap .module-search input {
    padding: 0.75rem 3.4375rem 0.75rem 3.4375rem;
  }
}
.single-search-icon .module-search-wrap .module-search .search-close {
  position: absolute;
  right: 30px;
  top: 23px;
}
@media (max-width: 991.9px) {
  .single-search-icon .module-search-wrap .module-search .search-close {
    top: 18px;
  }
}

.send-invitation {
  box-shadow: 2px 2px 18px 8px rgba(0, 0, 0, 0.15);
}

.popup-box-shadow {
  box-shadow: 2px 2px 18px 8px rgba(0, 0, 0, 0.15);
}

.modal-block-btn {
  display: block !important;
  text-align: center;
}
.modal-block-btn .btn + .btn {
  margin: 1.875rem auto 0;
}

.max-height-four-line {
  max-height: 80px;
  overflow: hidden;
}
.max-height-four-line p {
  font-size: 1rem !important;
}
@media (max-width: 767.81px) {
  .max-height-four-line {
    max-height: 78px;
  }
}

.member-info p {
  font-size: 1rem !important;
}

.white-bg {
  background-color: #ffffff;
}

/*Custom classes*/
.height-56 .ng-select-container {
  padding: 0.3125rem 1.5rem 0.375rem 1.5rem !important;
  min-height: 56px;
}
@media (max-width: 576.81px) {
  .height-56 .ng-select-container {
    min-height: 46px;
    padding: 0rem 1.5rem !important;
  }
}
@media (max-width: 576.81px) {
  .height-56 .ng-select-container .ng-placeholder {
    position: static !important;
  }
}

.w-100 .step-content {
  max-width: 100% !important;
}

.c-black {
  color: #222222 !important;
}

.fw-sb {
  font-weight: 600 !important;
}

.fw-b {
  font-weight: 700 !important;
}

.fw-r {
  font-weight: 400 !important;
}

.fs-12 {
  font-size: 0.75rem !important;
}

.fs-13 {
  font-size: 0.8125rem !important;
}

.fs-14 {
  font-size: 0.875rem !important;
}

.fs-15 {
  font-size: 0.9375rem !important;
}

.fs-16 {
  font-size: 1rem !important;
}

.fs-18 {
  font-size: 1.125rem !important;
}

.fs-21 {
  font-size: 1.3125rem !important;
}
@media (max-width: 767.81px) {
  .fs-21 {
    font-size: 1.125rem !important;
  }
}

.fs-22 {
  font-size: 1.375rem !important;
}
@media (max-width: 767.81px) {
  .fs-22 {
    font-size: 1.125rem !important;
  }
}

.pointer {
  cursor: pointer;
}

.mw-max-content {
  min-width: max-content;
}

.mw-card {
  max-width: 810px;
}

.mw-570 {
  max-width: 570px !important;
  margin: 0 auto;
}

.mw-790 {
  max-width: 790px;
  margin: 0 auto;
}

.p-100 {
  padding: 6.25rem;
}
@media (max-width: 767.81px) {
  .p-100 {
    padding: 1.875rem;
  }
}

.br-12 .ng-select.ng-select-single .ng-select-container {
  border-radius: 0.75rem;
}

/*End of Custom classes*/
/*select user av box with the img and text*/
.img-with-text ng-select.ng-select-single .ng-select-container.ng-has-value {
  border-radius: 0.75rem;
}
.img-with-text .ng-value-label {
  display: flex;
  align-items: center;
}
.img-with-text .ng-value-label img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50rem;
  flex: 0 0 auto;
}
.img-with-text .ng-value-label .user-info {
  margin-left: 0.75rem;
  text-align: left;
}
.img-with-text .ng-value-label .user-info h6 {
  font-size: 1.0625rem;
  color: #0E378C;
  line-height: 1.375rem;
  font-weight: 600;
  margin-bottom: 0;
  text-align: left;
}
.img-with-text .ng-value-label .user-info span {
  font-size: 0.8125rem;
  color: #222222;
  line-height: 1.375rem;
  font-weight: 400;
}

/*End select user av box with the img and text*/
/* tooltip icon css */
.d-flex .icon-Icon-green-tick {
  line-height: 26px;
  margin-left: 5px;
}

.card-name-box {
  display: block;
}
.card-name-box h3 {
  display: inline;
  margin-right: 5px;
}
.card-name-box h4 {
  display: inline;
  margin-right: 5px;
}
.card-name-box h6 {
  display: inline;
  margin-right: 5px !important;
}
.card-name-box .icon-Icon-green-tick {
  display: inline;
  padding-left: 0 !important;
  margin-left: 0;
  position: relative;
  top: 1px;
}

@media (max-width: 576.81px) {
  .custom-controls-icons {
    width: 40%;
  }

  .video-block vg-volume {
    height: 40px;
    width: 100%;
    margin-right: 10px;
  }
}
@media (max-width: 423px) {
  .custom-controls-icons {
    width: 37%;
  }
}
@media (max-width: 375px) {
  .custom-controls-icons {
    width: 33%;
  }
}
.job-sidebar-card {
  box-shadow: 2px 2px 18px 8px rgba(0, 0, 0, 0.03);
}
.job-sidebar-card .ng-select .ng-select-container {
  padding: 5px 10px !important;
  min-height: 50px;
}
.job-sidebar-card ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 15px !important;
}
.job-sidebar-card > .card {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: none;
  margin-bottom: 0px;
}
@media (min-width: 992px) {
  .job-sidebar-card > .card {
    max-height: 500px;
    overflow-y: auto;
  }
}
.job-sidebar-card .btn-box {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.job-sidebar-card .btn-box .btn-reset {
  color: #0e378c;
}

.squer-radio-select {
  display: block;
  margin-top: 15px;
}
.squer-radio-select .radio-button-box {
  width: auto;
  padding: 0px;
  max-width: unset;
  display: inline-block;
}
.squer-radio-select .radio-button-box .box {
  position: relative;
  padding: 0.4375rem 0.4375rem 0.4375rem 0.4375rem;
  background-color: #fff;
  border: 1px solid rgba(101, 101, 101, 0.1);
  border-radius: 0.25rem;
  text-align: left;
  height: auto;
  width: auto;
  transform: none !important;
  text-transform: capitalize;
  font-size: 0.9375rem;
  margin-right: 5px;
}
.squer-radio-select input[type=radio]:checked + .box {
  background-color: #0e378c;
  border-color: #0e378c;
  color: #fff;
}
.squer-radio-select.md-squer-radio-select .box {
  padding: 10px 17px;
  font-weight: 600;
}

.sort-by-select {
  display: flex;
  align-items: center;
}
.sort-by-select .sortby-heading {
  font-weight: 600;
  margin-right: 15px;
}
.sort-by-select .ng-select {
  min-width: 185px;
}
.sort-by-select .ng-select.ng-select-opened .ng-select-container {
  background-color: #efefef !important;
}
.sort-by-select .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #efefef;
}
.sort-by-select .ng-select .ng-dropdown-panel-items {
  box-shadow: none;
}
.sort-by-select .ng-select .ng-select-container.ng-has-value {
  background-color: #efefef !important;
  border-color: #efefef !important;
}

.proposals-received-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 600px) {
  .proposals-received-wrap {
    flex-flow: column;
    align-items: flex-start;
  }
}
.proposals-received-wrap .posted-by-wrap-list .offer-list-wrap .btn-group .card-msg-nav-dropdown {
  left: 0 !important;
}

.profile-id-wrap {
  max-height: 260px;
  overflow: auto;
}
.profile-id-wrap .identity-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.progress-status .progress-heading {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0px;
  color: #47b872;
}
.progress-status p {
  margin-bottom: 0px;
}

.progress-status-wrap svg text {
  display: none;
}

.job-search-wrap {
  right: 15px;
  left: auto !important;
}

.job-posting-actions {
  position: relative;
}
.job-posting-actions .icon-Ver-action-btn {
  transform: rotate(90deg);
  display: block;
}
.job-posting-actions .dropdown-menu {
  right: -28px;
  top: 10px;
}
.job-posting-actions.open .dropdown-menu, .job-posting-actions.show .dropdown-menu {
  display: block;
}

.card-right {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-right .dropdown-box {
  margin-left: 1rem;
}
@media (max-width: 767.81px) {
  .card-right .dropdown-box {
    margin-left: 0.875rem;
  }
}

.tag-comman {
  background-color: #c3cfe5;
  color: #0E378C;
  border-radius: 4px;
  padding: 0.375rem 1rem;
  display: inline-block;
  line-height: 1;
  font-size: 0.75rem;
  text-align: center;
  text-transform: uppercase;
}
@media (max-width: 767.81px) {
  .tag-comman {
    font-size: 0.75rem;
    padding: 0.375rem 0.75rem;
  }
}
.tag-comman.tag-right-fix {
  border-radius: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.listed-item-box {
  position: relative;
}

.need-help {
  color: #e73945;
  font-weight: 600;
}

@media (max-width: 767px) {
  .ongoing-heading-wrap {
    flex-flow: column;
    align-items: flex-start !important;
  }
}

.heading-with-dropdown {
  justify-content: space-between;
}

.rating-pop-up-wrap {
  padding: 30px !important;
}
.rating-pop-up-wrap .rating-pop-up {
  position: relative;
}
.rating-pop-up-wrap .rating-pop-up .popup-close-btn {
  top: 50%;
  transform: translateY(-50%);
}
.rating-pop-up-wrap .icon-icon-close:before {
  color: #000;
}
.rating-pop-up-wrap .rating-subheading {
  max-width: 300px;
  margin: auto;
}
.rating-pop-up-wrap rating > span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rating-pop-up-wrap .rating-words {
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
}
.rating-pop-up-wrap span.bs-rating-star {
  font-size: 30px;
  color: #d5d5d5;
  margin: 0px 5px;
}
.rating-pop-up-wrap span.bs-rating-star.active {
  color: #0e378c;
}
.rating-pop-up-wrap textarea {
  font-size: 18px;
  height: 53px;
}
.rating-pop-up-wrap textarea::-webkit-input-placeholder {
  color: #9f9f9f;
}
.rating-pop-up-wrap textarea::-moz-placeholder {
  color: #9f9f9f;
}
.rating-pop-up-wrap textarea:-ms-input-placeholder {
  color: #9f9f9f;
}
.rating-pop-up-wrap textarea:-moz-placeholder {
  color: #9f9f9f;
}
.rating-pop-up-wrap .count {
  text-align: right;
  color: #b2b2b2;
  font-size: 13px;
  margin-top: 5px;
  font-weight: 600;
}

.video-package.members-list .designation {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  padding-right: 20px;
}
@media (max-width: 767.81px) {
  .video-package.members-list .designation {
    margin-bottom: 20px;
    padding-right: 0;
  }
  .video-package.members-list .designation h5 {
    font-size: 1rem;
  }
}
@media (max-width: 767.81px) {
  .video-package.members-list .member-info-wrap {
    flex-wrap: wrap;
  }
}

.chat-btn-right {
  margin-left: auto;
  min-width: auto;
}
@media (max-width: 767.81px) {
  .chat-btn-right {
    margin-top: 0.5rem;
    margin-left: 0;
  }
}
@media (max-width: 576.81px) {
  .chat-btn-right .card-msg-nav-dropdown {
    min-width: 210px;
    margin-right: -46px;
  }
  .chat-btn-right .card-msg-nav-dropdown a {
    font-size: 0.875rem;
  }
}
.chat-btn-right .pointer {
  cursor: pointer;
}