.invester-listing{
    .module-search-wrap{
        .filter-btn-wrap{
            .dropdown-menu{
                .action-wrapper{
                    margin: size(0);
                }
            }
        }
    }   
}
.listed-item-box{
    background-color: $white;
    border-radius: $border-radius-12;
    overflow: hidden;
    .img-box-wrap{
           width: 100%;
        @include media(down, $breakpoint-xs-xs){
            margin: size(0) auto size(15);
        }
        img{
            width: 100%; 
            // object-fit: cover;
            // object-position: top;
            @include media(down, $breakpoint-xs-xs){
                max-height: 150px;
            }
            @include media(down, $breakpoint-xsw){
                max-height: 140px;
            }
        }
    }
}
.invester-info{
    padding: size(20);
    width: 100%;
    @include media(down, $breakpoint-md){
        padding: size(15);
    }
    .d-flex{
        .icon-Icon-green-tick{
            @include media(down, $breakpoint-sm-sm){
                line-height: 22px;
            }
        }
    }
    h4 {
        font-size: size(18);
        line-height: size(24);
        font-weight: $font-semibold;
        color: $black;
        letter-spacing: size(-0.25);
        margin-bottom: size(3);
        cursor: pointer;
        word-break: break-word;
        display: table;
        &:hover{
            color: $primary;
        }
        @include media(down, $breakpoint-md){
            font-size: size(14);
            line-height: size(20);
        }
        span {
            margin-left: size(10);
            font-size: size(14);
        }
        .icon-blue{
            &::before{
                color: $primary;
            }
        }
        a{
            font-size: size(18);
            @include media(down, $breakpoint-md){
                font-size: size(14);
            }
        }
    }
    .location{
        font-size: size(13);
        margin-top: $margin-top-5;
        margin-bottom: size(3);
        display: inline-block;
    }
    h5 {
        font-size: size(13);
        font-weight: $font-semibold;
        color: $primary;
    }
    p{ 
        font-size: size(13);
        margin-bottom: size(3);
        
        &.text-ellipsis-2line{
            height: 42px;
        }
        @include media(down, $breakpoint-md){
            font-size: size(12);
        }
    }
    .info{
        @include media(up, $breakpoint-md){
            min-height: 42px;
            max-height: 42px;
            overflow: hidden;
        }
        p{
            color: $light-gray;
        }
    }
}
.listed-items-wrap{
    [class*=col-]{
        margin-bottom: $margin-bottom-30;
    }
}
.img-box-wrap{
    position: relative;
    .icons-wrap{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .tag-item{
        display: inline-block;
        font-size: size(11);
        padding: size(4) size(12);
        border-radius: size(0) size(4) size(0) size(0);
        margin: size(0);
        @include media(down, $breakpoint-md){
            font-size: size(10);
            padding: size(4) size(5)
        }
    }
    .play-ic {
        width: 50px;
        height: 50px;
        border-radius: $rounded-pill;
        border: 2px solid $white;
        background: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 20px;
        bottom: -25px;
        @include media(down, $breakpoint-md){
            width: 30px;
            height: 30px;
            font-size: size(12);
            bottom: -15px;
        }
    }
}
.switch-box{
    .switch{
        width: 40px;
        height: 20px;
        min-width: auto;
        background-color: $switch-gray;
        small{
            width: 16px;
            height: 16px;
            top: 2px;
            right: calc(100% - 18px);
        }
        &.checked{
            background-color: $primary;
            small{
                right: calc(100% - 38px);
            }
        }
    }
    h6{
        color: $black;
        font-size: size(15);
        font-weight: $font-semibold;
        margin: size(0);
    }
    p{
        color: $light-gray;
        font-size: size(13);
        margin-bottom: 0;
    }
}
.text-btn{
    color: $primary;
    font-weight: $font-semibold;
    font-size: size(15);
   
}
.blue-text{
    color: $primary;
    font-size: size(13);
    font-weight: $font-semibold;
    margin: size(0) size(15) size(0) size(0);
}
.listed-item-list-view{
    box-shadow: 0 0 5px rgb(0 0 0 / 5%);
    border-radius: $border-radius-12; 
    border: size(12) solid $white;
    background-color: $white;
    .listed-item-box{
        padding: size(18);

    }
    .img-box-wrap{
        width: 100px;
        height: 100px;
        img{
            border-radius: 100%;
        }
    }
    .icons-wrap{
        @include media(down, $breakpoint-md){
            margin: size(10) size(0);
        }
    }
    .tag-item{
        font-size: size(11);
        font-weight: $font-bold;
        margin: 0;
        @include media(down, $breakpoint-md){
            font-size: size(10);
        }
    }
    .invester-info{
        padding: size(0) size(0) size(0) size(20);
        width: calc(100% - 100px);
        @include media(down, $breakpoint-xs-xs){
            width: 100%;
        }

        p{
            font-size: size(13);
        }
        .info{
            margin: size(5) size(0) size(0);
            p{
                font-size: size(15);
            }
        }
        .left-info{
            @include media(down, $breakpoint-xs-xs){
                text-align: center;
                width: 100%;
            }
            .card-name-box{
                display: block;
                h4{
                    display: inline;
                    margin-right: 5px;
                }
                .icon-Icon-green-tick{
                    display: inline;
                }
            }
        }
    }
    .spacer-line {
        border-top: 1px solid $extra-light-gray;
        padding: size(0);
        margin: size(0) size(30) size(0) size(30);
    }
    .img-box-wrap{
        .play-ic{
            width: 28px;
            height: 28px;
            right: 0;
            bottom: 0;
            span{
                font-size: size(9);
            }
        }
    } 
}  