.my-profile-layout{
  padding-top: $padding-top-30;
  .coneection-wrap{
    .imgbox, .name-wrap{
      cursor: pointer;
    }
  }
  .interests-box{
    .interests-details{
      .img-box, .content-info{
          cursor: pointer;
      }
      .content-info{
        .d-flex{
          .icon-Icon-green-tick{
            line-height: 22px;
          }
        }
      }
    }
  }  
  .common-tabs{
    margin-top: $margin-top-30;
    .tab-nav-header {
      .tab-container {
        .nav-tabs{
          margin-bottom: $margin-bottom-10; 
        }
      } 
    }
  }
}
.card{
  &.card-padding-0{
    padding: 0;
  }
  .main-profile-info{
    position: relative;
    .profile-details{
      display: flex;
      align-items: center;
      padding: size(30);
      position: relative;
      @include media(down, $breakpoint-xs-xs){
        flex-direction: column;
      }
      .profile-progress{
        width: size(140);
        height: size(140);
        margin-right: size(30);
        @include media(down, $breakpoint-xs-xs){
          margin: 0 auto size(25);
        }
      }
      .profile-contact{
        position: relative;
        width: calc(100% - 170px);
        text-align: left;
        @include media(down, $breakpoint-xs-xs){
          margin: 0 auto;
          width: 100%;
          text-align: center;
        }
        h4{
          font-size: size(22);
        }
      }
      .edit-btn{
        position: absolute;
        right: size(30);
        top: size(20);
        font-size: size(13);
        color: $primary;
        @include media(down, $breakpoint-xs-xs){
          right: size(30);
          top: size(12);
        }
        span{
          margin-right: size(5);
          &::before{
            color: $primary;
          }
        }
      }
    }
    .profile-complete{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      background-color: rgba($primary,0.05);
      padding: size(30);
      @include media(down, $breakpoint-lg) {
        text-align: center;
        justify-content: center;
      }
      p{
        margin-bottom: 0;
        font-size: size(13);
        @include media(down, $breakpoint-lg) {
          margin-bottom: $margin-bottom-20;
        }
      }
      .btn{
        margin-left: size(15);
        @include media(down, $breakpoint-lg) {
          margin: 0 auto;
        }
      }
    }
  }
  .info{
    position: relative;
    padding-right: size(30);
    h5{
      margin-bottom: $margin-bottom-20;
    }
    p{
      font-size: size(15);
      &:last-child{
        margin-bottom: 0 !important;
      }
    }
    .edit-btn{
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
  .edit-btn{
    padding: 0;
    min-height: inherit;
    min-width: inherit;
    .icon-icon-edit{
      &::before{
        color: $light-gray;
      }
      &:hover{
        &::before{
          color: $primary;
        }
      }
    }
  }
  .inner-info{
    padding: size(30);
    .experience-info{
      padding: size(20) 0;
      border-top: 1px solid rgba($color: $black, $alpha: 0.2);
      &:last-child{
        padding-bottom: 0;
      }
      &:first-child{
        border-top: none !important;
        padding-top: 0;
      }
    }
    .title-wrap{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $margin-bottom-20;
      h5{
        margin-bottom: 0;
      }
      a{
        font-weight: $font-semibold;
      }
    }
    .imgbox {
      width: size(50);
      height: size(50);
      border-radius: $border-radius-12;
      overflow: hidden;
      position: relative;
      background-color: $extra-light-gray;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position:center;
      }
      span{
        &::before{
          color: $light-gray;
        }
      }
    } 
    .item{
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      border-top: 1px solid rgba($color: $black, $alpha: 0.2);
      padding: size(20) 0;
      &:last-child{
        padding-bottom: 0;
      }
      &:first-child{
        border-top: none !important;
        padding-top: 0;
      }
      .imgbox{
        margin-right: size(10);
      }
      .info-wrap{
        width: calc(100% - 60px);     
        position: relative;
        padding-right: size(30);
        p{
          text-align: left;
        }
        .edit-btn{
          position: absolute;
          right: 0;
          top: 0;
        }
        .item-info{
          margin-bottom: $margin-bottom-15;
          position: relative;
          .tooltip-icon{
            &::before{
              color: $success;
            }
          }
          .edit-btn{
            position: absolute;
            right: -30px;
            top: 0;
          }
          &::before{
            content: '';
            width: size(6);
            height: size(6);
            position: absolute;
            left: size(-38);
            top: size(8);
            background-color: $extra-light-gray;
            border-radius: 100%;
          }
          &::after{
            content: '';
            width: size(2);
            height: 100%;
            position: absolute;
            left: size(-36);
            top: size(18);
            background-color: $extra-light-gray;
            border-radius: $border-radius-4;
          }
          &:first-child{
            &::before{
              display: none;
            }
            &::after{
              display: none;
            }
          }
          &:last-child{
            &::after{
              display: none;
            }
          }
        }   
        h6{
          margin-bottom: 0;
          span{
            margin-left: size(2);
            position: relative;
            top: 2px;
            line-height: normal;
          }
        }
        .tag-span{
          line-height: size(18);
        }
        p{
          margin-bottom: 0;
          font-size: size(13);
        }
      }
      .extra-info{
        font-size: size(12);
        margin-top: $margin-top-10;
        margin-bottom: 0;
        word-break: break-all;
      }
    }
  }
  .add-indo-btn-wrap{
    padding: size(12) size(30);
    border-top: 1px solid rgba($color: $black, $alpha: 0.2);
    .btn{
      padding: 0;
      min-height: inherit;
      min-width: inherit;
      display: flex;
      align-items: center;
      color: $primary;
      font-size: size(13);
      &:hover{
        color: $black;
        span{
          &::before{
            color: $black;
          }
        }
      }
      span{
        margin-right: size(10);
        font-size: size(13);
        &::before{
          color: $primary;
        }
        
      }
    }
  }
}
.exp-item-wrap{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: size(20) size(0);
  position: relative;
  text-align: left;
  h6{
    margin-bottom: 0;
    .tooltip-icon{
      &::before{
        color: $success;
      }
    }
  }
  .tag-span {
    line-height: 1.125rem;
  }
  .imgbox {
    width: size(50);
    height: size(50);
    border-radius: $border-radius-12;
    overflow: hidden;
    position: relative;
    background-color: $extra-light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: size(10);
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position:center;
    }
    span{
      &::before{
        color: $light-gray;
      }
    }
  } 
  .info-wrap{
    width: calc(100% - 60px);
    position: relative;
    padding-right: size(30);
  }
  .edit-btn{
    position: absolute;
    right: 0;
    top: 0;
    font-size: size(13);
    color: $primary;
    padding: 0;
    min-height: inherit;
    min-width: inherit;
    @include media(down, $breakpoint-xs-xs){
      right: size(30);
      top: size(12);
    }
    &:hover{
      span{
        margin-right: size(5);
        &::before{
          color: $primary;
        }
      }
    }
    span{
      margin-right: size(5);
      &::before{
        color: $light-gray;
      }
    }
  }
}

/* Modal */
.my-favorite-modal{
  max-width: 710px !important;
  .btnbox{
    .btn-lg{
      max-width: 370px;
    }
  }
}
.services-search-box{
  .coneection-wrap{
    margin-bottom: size(0);
  }
}