.my-video-layout {
  .upload-video-card {
    padding: size(140) size(20);
    @include media(down, $breakpoint-md) {
      padding: size(100) size(60);
    }
    @include media(down, $breakpoint-md) {
      padding: size(90) size(60);
    }
    @include media(down, $breakpoint-md) {
      padding: size(50) size(30);
    }
    .upload-video-form {
      .dropzone {
        transition: border-color 200ms ease-in;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dashed lightgrey;
        min-height: 492px;
        max-width: 770px;
        position: relative;
        flex-direction: column;
        padding: size(50);
        width: 100%;
        border-radius: $border-radius-12;
        background-color: $white;
        text-align: center;
        margin: 0 auto;
        @include media(down, $breakpoint-lg) {
          min-height: 400px;
        }
        @include media(down, $breakpoint-md) {
          padding: size(30);
          min-height: 360px;
        }
        @include media(down, $breakpoint-sm) {
          min-height: 280px;
        }
        &:hover {
          cursor: pointer;
          border-color: $primary;
          span {
            &::before {
              color: $primary;
            }
          }
        }
        &.active {
          border-color: grey;
        }
        .upload-input {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
        span {
          font-size: size(72);
        }
        p {
          margin: size(30) size(0);
        }
        .btn {
          width: 100%;
          max-width: 370px;
        }
      }
    }
  }
}

/* ================================ My video list */

.all-video-layout {
  .video-filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    border-radius: $border-radius-12;
    padding: size(0) size(20);
    margin-bottom: $margin-bottom-30;
    .video-list-nav {
      display: flex;
      align-items: center;
      li {
        &.active,
        &:hover {
          a {
            color: $primary;
            border-color: $primary;
          }
        }
        a {
          padding: size(20) size(15);
          color: $black;
          border-bottom: 2px solid transparent;
          display: flex;
        }
      }
    }
  }
}

.video-card-item {
  background-color: $white;
  border-radius: $border-radius-12;
  min-height: 430px;
  margin-bottom: $margin-bottom-30;
  box-shadow: 0 0 5px rgba($color: $dark-black, $alpha: 0.2);
  .video-block {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: $border-radius-12;
    height: 250px;
    video {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
    .tag-comman {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }
  }
  .info {
    padding: size(30) size(50) size(30) size(30);
    position: relative;
    .dropdown-btn-wrap {
      position: absolute;
      right: 20px;
      top: 35px;
      .action-btn {
        padding: size(0) size(3);
        line-height: normal;
        display: block;
        span {
          margin-bottom: 0;
          display: block;
        }
        &:hover {
          span {
            &::before {
              color: $primary;
            }
          }
        }
      }
      .dropdown-menu {
        margin-top: $margin-top-10;
        right: -10px !important;
        &::before {
          right: 0.25rem;
        }
        .dropdown-item {
          display: flex;
          align-items: center;
          &:hover {
            span {
              &::before {
                color: $primary;
              }
            }
          }
          span {
            margin-right: size(12);
            &::before {
              color: $black;
            }
          }
        }
      }
    }
    h6 {
      line-height: 26px;
      margin-bottom: $margin-bottom-10;
      padding-right: size(40);
    }
    span {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      &.tag-name {
        font-weight: $font-semibold;
        font-size: size(15);
        margin-bottom: $margin-bottom-10;
        .d-flex {
          .icon-Icon-green-tick {
            line-height: 20px;
            margin-left: 5px;
          }
        }
      }
      &.date-view {
        font-size: size(13);
      }
    }
  }
}

.upload-video-box {
  display: flex;
  align-items: center;
  background-color: $white;
  border-radius: $border-radius-12;
  padding: size(30);
  min-height: 430px;
  margin-bottom: $margin-bottom-30;
  .info {
    border-radius: $border-radius-12;
    border: 2px dashed $extra-light-gray;
    padding: 10px;
    text-align: center;
    min-height: 368px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: 350px;
    width: 100%;
    &:hover {
      border-color: $primary;
      span {
        &:before {
          color: $primary;
        }
      }
    }
    span {
      font-size: size(75);
      margin-bottom: $margin-bottom-20;
      &:before {
        color: $black;
      }
    }
    p {
      color: $black;
    }
  }
}

/* Filter Button */

.filter-btn-wrap {
  display: flex;
  align-items: center;
  position: relative;
  padding: size(18);
  border-left: 1px solid $extra-light-gray;
  @include media(down, $breakpoint-lg) {
    padding: size(6) size(20);
  }
  &.show,
  &.open {
    .icon-Icon-down-arrow-block {
      @include css3-prefix(transform, rotate(180deg));
      @include transition-0-3s;
    }
  }
  .filter-dropdown {
    color: $black;
    display: flex;
    align-items: center;
    .icon-icon-filter {
      margin-right: size(7);
      position: relative;
      &.filtered {
        &::after {
          content: "";
          background: #e73945;
          width: 8px;
          height: 8px;
          border-radius: 50rem;
          position: absolute;
          right: -2px;
          top: -2px;
        }
      }
    }
  }
  .form-control {
    height: 41px;
    padding: size(13) size(70) size(13) size(15);
  }
  .icon-Icon-down-arrow-block {
    width: 24px;
    height: 24px;
    margin-left: size(30);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $extra-light-gray;
    font-size: size(10);
    @include css3-prefix(transform, rotate(0));
    @include transition-0-3s;
  }
  .dropdown-menu {
    padding: size(25) size(0) size(70) size(0);
    min-width: 295px;
    top: 50px;
    @include media(down, $breakpoint-sm-sm) {
      top: 32px;
    }
    &::before {
      display: none;
    }
    &.dropdown-menu-right {
      right: 0px;
    }
    .dropdown-menu-wrapper {
      padding-left: size(20);
      padding-right: size(20);
      max-height: 600px;
      overflow-y: scroll;
      overflow-x: hidden;
      @include media(down, $breakpoint-xxxxl) {
        max-height: 470px;
      }
      @include media(down, $breakpoint-xxl-b) {
        max-height: 360px;
      }
      @include media(down, $breakpoint-x-xl) {
        max-height: 310px;
      }
      @include media(down, $breakpoint-sm-sm) {
        max-height: 280px;
      }
      // @include media(down, $breakpoint-xs-xs){
      //     max-height: 400px;
      // }
      &.investor-dropdown-menu-wrapper {
        margin-top: -25px;
      }
      .switch-group-dropdown-wrap {
        position: relative;
        z-index: 0;
        &::after {
          content: "";
          width: calc(100% + 40px);
          left: -20px;
          background-color: #f2f5f9;
          z-index: -1;
          top: 0;
          position: absolute;
          height: 100%;
          border-radius: size(12) size(12) size(0) size(0);
        }
        .switch-box {
          padding: 10px 0;
          border-bottom: 1px solid rgba(34, 34, 34, 0.1);
          &:last-child {
            border-bottom: none;
            margin-bottom: 15px;
          }
        }
      }
    }

    label {
      text-transform: uppercase;
      font-size: size(11);
      letter-spacing: -0.1px;
      line-height: 16px;
      margin-bottom: $margin-bottom-5;
    }
    .select-box {
      ng-select {
        .ng-select-container {
          padding: size(3) size(12) !important;
          min-height: 41px;
          border-radius: $border-radius-4;
          .ng-placeholder {
            top: 25% !important;
          }
          .ng-value-container {
            .ng-input {
              input {
                height: 25px !important;
              }
            }
          }
        }
      }
    }
    .identiy-group {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: $margin-bottom-20;
      max-height: 220px;
      overflow: scroll;
      @include media(down, $breakpoint-xxl-b) {
        max-height: 140px;
      }
      .title-span {
        font-size: size(15);
        display: block;
        margin-bottom: $margin-bottom-20;
        line-height: 22px;
      }
      .media {
        cursor: pointer;
        span {
          color: $primary;
        }
      }
    }
    .action-wrapper {
      padding: size(15) size(0);
      background-color: $extra-light-gray;
      border-bottom-left-radius: size(12);
      border-bottom-right-radius: size(12);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      .btn-box {
        padding: size(0) size(20);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: none;
        margin-top: 0;
        .btn {
          padding: size(5) size(15) size(5) size(0);
          text-align: left;
          min-height: 40px;
          font-size: size(15);
          line-height: 22px;
          color: $primary;
          border-radius: $border-radius-4;
          &.btn-primary {
            min-width: 100px;
            padding: size(5) size(15);
            text-align: center;
            color: $white;
            &.btn-primary:hover {
              color: $primary;
            }
          }
        }
      }
    }
  }
  .spacer-line {
    border-top: 1px solid rgba($black, 0.1);
    // padding-top: $padding-top-20;
    margin-top: $margin-top-20;
  }
}

/* ==================================================== Video Details page */

.video-detial-card {
  display: flex;
  align-items: center;
  justify-content: center;
  .card-title-p {
    padding: size(0) size(120) size(10);
    @include media(down, $breakpoint-sm-sm) {
      padding: size(0) size(50) size(10);
    }
    @include media(down, $breakpoint-xs) {
      padding: size(0) size(5) size(5);
    }
  }
  form {
    max-width: 570px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    textarea {
      resize: vertical;
      min-height: 58px;
    }
    .btnbox {
      display: flex;
      justify-content: flex-end;
      .btn {
        min-width: 180px;
      }
    }
  }
  .img-with-text {
    .ng-value-label {
      display: flex;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: $rounded-pill;
        flex: 0 0 auto;
      }
      .user-info {
        margin-left: size(12);
        text-align: left;
        h6 {
          font-size: size(17);
          color: $primary;
          line-height: size(22);
          font-weight: $font-semibold;
          margin-bottom: 0;
          text-align: left;
        }
        span {
          font-size: size(13);
          color: $black;
          line-height: size(22);
          font-weight: $font-regular;
        }
      }
    }
  }
}

/* Profile select Modal */

.identity-modal-card {
  .profile-group-wrap {
    margin-top: $margin-top-20;
    max-height: 300px;
    overflow: scroll;
    .profile-media {
      padding: size(15);
      margin-bottom: 0;
      word-break: break-all;
      &.selected {
        background-color: $extra-light-gray;
        .media-body {
          .selected-icon {
            display: block;
          }
        }
      }
      .media-body {
        padding-right: size(50);
        position: relative;
        .selected-icon {
          display: none;
          position: absolute;
          right: 0;
          top: 12px;
        }
      }
    }
  }
  .btnbox {
    margin-top: $margin-top-20;
    margin-bottom: size(0) !important;
  }
}

/* Common tabs */

.common-tabs {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  .search-filter-wrap {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 999;
    background-color: $white;
    border-radius: $border-radius-12;
    @include media(down, $breakpoint-lg) {
      top: 15px;
      border-radius: $border-radius-12;
    }
    @include media(down, $breakpoint-sm-sm) {
      top: 79px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
      padding: 3px 10px;
    }
    .search-wrap {
      margin-right: size(25);
      cursor: pointer;
      @include media(down, $breakpoint-lg) {
        padding: size(5) size(15);
        margin-right: 0;
      }
    }
    .video-search-inputbox {
      width: 100%;
      position: absolute;
      min-width: 300px;
      right: 5px;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 100ms ease;
      z-index: 1;
      opacity: 0;
      &.show {
        transform: scaleX(1);
        opacity: 1;
        margin-top: size(4);
      }
      @include media(down, $breakpoint-lg) {
        min-width: 100%;
        right: 0;
        .icon-icon-search {
          top: 18px;
          font-size: size(13);
        }
        .search-close {
          top: 18px;
          font-size: size(13);
        }
      }
    }
  }
  .tab-nav-header {
    width: 100%;
    .tab-content {
      width: 100%;
    }
    .tab-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      .nav-tabs {
        border-bottom: none;
        display: flex;
        align-items: center;
        background-color: $white;
        border-radius: $border-radius-12;
        padding: size(0) size(20);
        margin-bottom: $margin-bottom-30;
        width: 100%;
        flex-wrap: nowrap;
        overflow-y: auto;
        @include media(down, $breakpoint-lg) {
          margin-bottom: 65px;
          justify-content: flex-start;
        }
        .nav-link {
          border: none;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom: 2px solid transparent;
          padding: size(20) size(15);
          font-size: size(15);
          line-height: 22px;
          color: $black;
          font-weight: $font-semibold;
          &.active {
            color: $primary;
            background-color: transparent;
            border-color: $primary;
          }
        }
        .nav-item {
          width: auto;
          flex-shrink: 0;
          margin-bottom: 0;
          &.show {
            .nav-link {
              color: $primary;
              background-color: transparent;
              border-color: $primary;
            }
          }
        }
      }
    }
    &.search-filter-sticky {
      .tab-container {
        .nav-tabs {
          position: sticky;
          top: 70px;
          z-index: 999;
        }
      }
    }
  }
}

/* Uploading Video style */
.uploading-video-card {
  .uploading-video-wrap {
    max-width: 770px;
    margin: 0 auto;
    width: 100%;
    @include media(down, $breakpoint-lg) {
    }
    .uploading-wrap {
      margin-bottom: $margin-bottom-30;
      position: relative;
      width: 100%;
      border-radius: $border-radius-24;
      overflow: hidden;
      img {
        height: auto;
      }
      .overlay {
        background-color: rgba($primary, 0.7);
        height: 100%;
        right: 0;
        top: 0;
        position: absolute;
      }
    }
    .status-wrap {
      margin-bottom: $margin-bottom-30;
    }
    .btnbox {
      .btn {
        min-width: 180px;
      }
    }
  }
}
/* Video croping */
.video-croping-card {
  .video-block {
    margin-bottom: $margin-bottom-30;
    border-radius: 12px;
    overflow: hidden;
  }
  .video-crop-slider {
    width: 100%;
    height: 68px;
    position: relative;

    .video-box {
      height: 56px;
      overflow: hidden;
      position: relative;
      top: -5px;
      border-radius: 0;
      video {
        border-radius: 0;
      }
    }

    mv-slider {
      position: absolute;
      left: 0;
      width: 100%;
      top: 0;
      .slider-handle {
        width: 10px;
        height: 68px;
        border-radius: 0;
        background-color: transparent;
        box-shadow: none;
        margin-left: 0;
        margin-top: size(-5);
        &.min-slider-handle {
          background-image: url("../../../assets/images/left-thumb.svg");
          background-repeat: no-repeat;
          margin-left: size(-6);
          width: 14px;
        }
        &.max-slider-handle {
          background-image: url("../../../assets/images/right-thumb.svg");
          background-repeat: no-repeat;
        }
      }
      .slider {
        &.slider-horizontal {
          .slider-track {
            border-radius: 0;
            height: 56px;
            top: 0;
            width: 100%;
            background-color: transparent;
            background-image: none;
          }
        }
      }
      .slider-selection {
        background-image: linear-gradient(
          to bottom,
          rgba($primary, 0.4) 0,
          rgba($primary, 0.4) 100%
        );
        border-radius: 0;
      }
    }
  }
}
.video-details-layout {
  ng-select.ng-select-single {
    .ng-select-container {
      .ng-has-value {
        height: 56px;
        border-radius: $border-radius-12;
      }
    }
  }
  .select-box {
    .ng-select {
      .ng-select-container {
        border-radius: $border-radius-12;
      }
    }
  }
}
