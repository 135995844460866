.video-block{
  vg-controls {
    @include media(down, $breakpoint-sm){
      height: 40px;
    } 
    vg-scrub-bar-current-time{
      background-color: #888183; 
      .background{
        background-color: red;
        border: 1px solid red;
      }
      .slider{
        display: none;
      }
    }
  }
  vg-time-display{
    width: 63px;
    font-size: size(15);  
    @include media(down, $breakpoint-xs-xs){
      width: 55px;
      font-size: size(14);
    }
    @include media(down, $breakpoint-sm){
      height: 40px;
      line-height: 40px;
    }
  }
  vg-fullscreen{
    .icon{
      font-size: size(28);
      @include media(down, $breakpoint-xs-xs){
        font-size: size(24);
      }
    }
  }
  [class*=" vg-icon-"], [class^=vg-icon-]{
    @include media(down, $breakpoint-xs-xs){
      font-size: size(22);
    }
  }
  vg-play-pause{
    @include media(down, $breakpoint-xs-xs){
      width: 34px;
    }
    @include media(down, $breakpoint-sm){
      height: 40px;
    } 
  }
  .custom-controls-icons{
    span{
      cursor: pointer;
      @include media(down, $breakpoint-sm-sm){
        display: none;
      }
    }
    
  }
  vg-overlay-play{
    .vg-overlay-play{
     cursor: inherit;
      opacity: 1;
      .overlay-play-container{
        &.vg-icon-play_arrow{
          font-size: size(38);
          @include media(down, $breakpoint-sm) {
            font-size: size(24);
          }
          &:before{
            background-color:rgba(0,0,0,0.5);
            border-radius: 100%;
            width: 72px;
            height: 72px;
            line-height: 72px;
            text-align: center;
            @include media(down, $breakpoint-sm) {
              width: 42px;
              height: 42px;
              line-height: 42px;
            }
          }
        }
      }
    }
  }  
  vg-player{
    video{
      height: 435px;
      @include media(down, $breakpoint-sm-sm) {
        height: 264px;
      }
      @include media(down, $breakpoint-sm) {
          height: 215px;
      }
      @include media(down, $breakpoint-xsw) {
        height: 190px;
    }
    }
  }
  vg-scrub-bar{
    @include media(down, $breakpoint-sm) {
      height: 40px;
    }
    @include media(down, $breakpoint-xsw) {
      margin:size(0) size(3);
    }
  }
  .volume-control {
    position:relative;
    vg-volume {
      position: absolute;
      right: -20px;
      bottom: 80px;
      transform: rotate(-90deg);
    }
  }
  vg-mute{
    @include media(down, $breakpoint-sm) {
      height: 40px;
    }
    @include media(down, $breakpoint-xsw) {
      width: 40px;
    }
  }
  vg-volume{
    margin-right: size(15);
    @include media(down, $breakpoint-sm) {
      height: 40px;
      width:40px;
    }
    @include media(down, $breakpoint-xsw) {
      margin:size(0) size(3);
    }
  }
  vg-fullscreen{
    @include media(down, $breakpoint-sm) {
      height: 40px;
  }
  @include media(down, $breakpoint-xsw) {
    width: 40px;
  }
  }
  &.fullscreen{
    video{
      height: 100%;
      object-fit: contain !important;
    }
  }
  .white-line{
    width: 1px;
    background-color: $white;
    height: 13px;
    margin-top: size(18);
    @include css3-prefix(transform, rotate(10deg));
    @include media(down, $breakpoint-xs-xs) {
      margin-top: size(14);
    }
  }
}
.home-feed-modal{
  .video-block{
    position: relative;
  
  .custom-controls-icons{ 
    margin-right: size(15);
  }
  .dropdown-box{
    position: absolute;
    right: 13px;
    bottom: 13px;
    z-index: 999;
    @include media(down, $breakpoint-xs-xs){
      bottom: 9px;
    }
  }
  .controls-hidden{
    ~ .dropdown-box{
      .feed-dropdown{
        .icon-dots{
            -webkit-transition: bottom 1s;
            -khtml-transition: bottom 1s;
            -moz-transition: bottom 1s;
            -ms-transition: bottom 1s;
            transition: bottom 1s;
            display: none;
        }
      }
    }
  }
}
}
