.notifications-layout{
  
  .title-wrap {
    .btn{
      min-height: inherit;
      min-width: inherit;
      font-size: size(15);
    }
  }
  .info{
    padding-right: 0;
    &:last-child{      
      .notification-list{
        margin-bottom: size(0);
      }
    }
    .notification-list{
      margin-bottom: $margin-bottom-40;
      li{
        background-color: rgba($primary, 0.03);
        &.active{
          background-color: rgba($primary, 0.1);
        } 
        border-radius: 0px;
        &:first-child{ 
       border-top-left-radius: $border-radius-12;
       border-top-right-radius: $border-radius-12;
        }
        &:last-child{ 
          border-bottom-left-radius: $border-radius-12;
          border-bottom-right-radius: $border-radius-12;
        }
      }
      &.earlier-notification-list{
        li{
          background-color: rgba($extra-light-gray,0.3); 
          &.active{
            background-color: rgba($extra-light-gray,0.7); 
          } 
        }
      }
      li{
        display: flex;
        align-items: center;
        position: relative; 
        padding: size(15) size(30);
        border-bottom: 1px solid rgba($light-gray,0.2);      
        &:last-child{ 
          border-bottom: none;
        }
        p{
          margin-bottom: 0;
          font-size: size(15);
          padding-right: size(65);
          .primary-text{
            font-size: size(15);
          }
        }
        span{
          position: absolute;
          right: 30px;
          top: 18px;
          font-size: size(13);
          color: $light-gray;
        }
      }
    }
  }
}
.message-layout{
  .dropdown-menu{
    padding: size(15);
    left: 0;
    right: 0;
  }
  .module-search-wrap{
    padding-bottom: size(0);
    .title-wrap{
      padding: size(20);
    }
    .search-custom-checkbox{
      padding: size(15);
      border-top: 1px solid $extra-light-gray;
    }
    .identiy-group{
      padding: size(15) size(0);
      border-top: 1px solid $extra-light-gray;
      margin: size(0) size(15); 
      max-height: 250px;
      border: none;
      .media{
        align-items: center;
      }
      .profile-media{
        .imgbox{
          img{
            width: 50px;
            height: 50px;
            border: none;
          }
        }
        .media-body{
          h5{
            font-size: size(15);
            color: $primary;
            font-weight: $font-semibold;
          }
          p{
            font-size: size(13);
          }
        }
        .icon-icon-tick{
          color: $primary;
        }
        &:last-child{
          margin-bottom: size(0);
        }
      } 
    }
  }
  .filter-btn-wrap{
    position: relative;
    display: block;
    padding: size(0);
  }
  .icon-icon-plush{
    font-size: size(15);
  }
  .msg-list-wrap{
    overflow: hidden;
    // height: 842px;
    height: calc(100vh - 125px);
    .title-wrap{
      padding: size(20) size(30);    
      margin-bottom: 0;
      @include media(down,$breakpoint-xs){
        padding: size(10) size(15);
      }
      h5{
        margin-bottom: 0;
      }
      .btn{
        min-width: inherit;
        min-height: inherit;
        line-height: normal;
        overflow: visible;
        span{
          font-size: 16px;
          &.filtered {
            &::after {
                content: '';
                background: #E73945;
                width: 8px;
                height: 8px;
                border-radius: 50rem;
                position: absolute;
                right: -2px;
                top: -2px;
            }
        }
        }
      }
      .add-btn {
        margin-right: size(15);
      }
    }
    .searchbox{
      border-bottom: 1px solid rgba($extra-light-gray,0.4);
      border-top: 1px solid rgba($extra-light-gray,0.4);
      .header-search{
        width: 100%;
        position: relative;
        border-top: 1px solid $extra-light-gray;
        border-bottom: 1px solid $extra-light-gray;
        
        input{
            width: 100%;
            color: $black;
            font-size: size(16);
            line-height: size(26);
            letter-spacing: size(-0.25);
            border-radius: 0;
            border: none;
            padding: size(12) size(30) size(12) size(60);
            background-color: #f9f9f9;
        }
        span{
            position: absolute;
            left: 33px;
            top:16px;
            font-size: size(16);
            &.close-search {
              right: 33px;
              left: auto;
            }
        }
      }
    }
    .all-msg-box{
      max-height: calc(100% - 117px);
      overflow: auto;
      li{
        display: flex;
        align-items: flex-start;
        padding: size(20);
        position: relative;
        border-bottom: 1px solid $extra-light-gray;
        cursor: pointer;
        @include media(down,$breakpoint-xs){
          padding:size(15);
        }
        .imgbox{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          border-radius: 100%;
          position: relative;
          margin-right: 10px;
          img{
            border-radius: 100%;
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
          }
          .status{
            width: 10px;
            height: 10px;
            position: absolute;
            right: 0;
            bottom: 0;
            border-radius: size(50);
            background-color: $success;
          }
        }
        .msg-info{
          width: calc(100% - 60px);
          padding-right: size(60);
          h6{
            margin-bottom: 0;
            font-size: size(16);
            margin-right: 10px;
            &.text-ellipsis-2line{
              height: auto;
              line-height: 1.5;
              font-weight: $font-semibold;
            }
          }
          p{
            font-size: size(15);
            &.text-ellipsis-1line{
              height: 22px;
            }
          }
        }
        .msg-time{
          position: absolute;
          right: 20px;
          top: 20px;
          font-size: size(12);
          text-transform: uppercase;
        }
        .new-msg-count{
          position: absolute;
          right: 20px;
          bottom: 20px;
          background-color: $danger;
          border-radius: $border-radius-24;
          padding: size(2) size(8);
          color: $white;
          width: auto;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: size(11);
          font-weight: $font-semibold;
        }
      }
    }
  }
}
.msg-filter-by{
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background-color: $white;
  border-radius: $border-radius-12;
  .title-wrap{
    .close-btn{
      min-width: inherit;
      min-height: inherit;
      line-height: normal;
      span{
        font-size: size(13);
        &::before{
          color: $black;
        }
      }
      &:hover{
        span{
          &::before{
            color: $primary;
          }
        }
      }
    }
  }
  &.show{
    left: 0;
  }
}

.received-msg-card{
  // height: 842px;
  height: calc(100vh - 125px);
  @include media(down, $breakpoint-lg){
    height: auto;
  }
  .contact-name{
    position: relative;
    display: flex;
    align-items: center;
    padding: size(20) size(50) size(20) size(30);
    border-bottom: 1px solid rgba($extra-light-gray, 0.4);
    @include media(down,$breakpoint-xs){
      padding: size(10) size(30) size(10) size(15);
    }
    .back-btn{
      min-height: inherit;
      min-width: inherit;
      width: 25px;
      height: 25px;
      border-radius: 100%;
      padding: 0;
      line-height: normal;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      margin-right: size(8);
      display: none;
      @include media(down,$breakpoint-lg){
        display: flex;
      }
      span{
        line-height: 11px;
        &::before{
          color: $primary;
          font-size: size(12);
        }
      }
    }
    .imgbox{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      position: relative;
      margin-right: size(10);
      img{
        border-radius: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
      .status{
        width: 10px;
        height: 10px;
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: size(50);
        background-color: $success;
      }
    }
    .namebox{
      width: calc(100% - 50px);
      h6{
        margin-bottom: 0;
        font-weight: $font-semibold;
      }
    }
    .action-dropdown-wrap{
      position: absolute;
      right: 30px;
      @include media(down,$breakpoint-xs){
        right: 15px;
      }
      .action-btn{        
        padding: size(5);
        min-width: inherit;
        min-height: inherit;
        line-height: normal;
        span{
          font-size: size(16);
        }
        &:hover{
          span{
            &::before{
              color: $primary;
            }
          }
        }
      }
      .dropdown-nav{
        min-width: size(215);
        right: -21px!important;
        a{
          display: flex;
          align-items: center;
          padding: size(15);
          color: $black;
          span{
            margin-right: size(12);
            font-size: size(18);
            &::before{
              color: $black;
            }
          }
          &:hover{
            color: $primary;
            span{
              &::before{
                color: $primary;
              }
            }
          }
        }
      }
    }
  }
  .new-contact-notification{
    border-bottom: 1px solid rgba(101, 101, 101, 0.1);
    padding: size(30);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow: auto;
    min-height: calc(100% - 210px);
    .imgbox{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 120px;
      border-radius: 100%;
      position: relative;
      margin-bottom: $margin-bottom-20;
      img{
        border-radius: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }
    h5{
      font-size: size(20);
    }
    h6{
      font-size: size(15);
    }
    p{
      font-size: size(13);
    }
    .spacer-line {
      display: block;
      width: 80%;
    }
    .media{
      display: flex;
      align-items: center;
      margin-bottom: $margin-bottom-30;
      .imgbox{
        width: 26px;
        height: 26px;
        border-radius: 100%;
        margin-right: size(10);
        margin-bottom: 0;
        img{
          border-radius: 100%;
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%;
        }        
      }
      h6{
        margin-bottom: 0;
        font-size: size(15);
      }
    }
    .btnbox{
      display: flex;
      align-items: center;
      .btn{
        min-width: 136px;
        min-height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: size(5) size(15);
        font-size: size(17);
        @include media(down, $breakpoint-xl){
          font-size: size(16);
        }
        @include media(down, $breakpoint-sm-sm){
          font-size: size(14);
        }
        span{
          margin-right: size(12);
          font-size: size(13);
        }
        &:last-child{
          margin-left: size(20);
        }
      }
    }
    .block-btn{
      min-height: inherit;
      min-width: inherit;
      line-height: normal;
      margin-top: $margin-top-30;
      font-size: size(15);
    }
    ~ .msg-chatbox {
      @include media(down, $breakpoint-xl){
        height: auto;
        padding: 0;
      } 
    }
  }
  .msg-chatbox{
    padding: size(30) size(30) size(10);
    max-height: calc(100% - 161px);
    overflow: auto;
    height: 100%;
    @include media(down,$breakpoint-xs){
      padding: size(20) size(15) size(10);
    }
    @include media(down,$breakpoint-lg){
      height: calc(100vh - 290px);
    }
    @include media(down,$breakpoint-sm){
      height: calc(100vh - 260px);
    }
    .msg-receive-time-outer{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0 auto size(30);
      .msg-receive-status{        
        text-transform: uppercase;
        background-color: rgba($extra-light-gray, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $black;
        padding: size(4) size(8);
        border-radius: $border-radius-4;
        font-size: size(12);
        width: auto;
      }
    }
  }
  .msg-outer{
    width: 100%;
    display: flex;
    &.right-msg{
      justify-content: flex-end;
    }
    &.left-msg{
      justify-content: flex-start;
    }
    .msg-text-box{
      display: flex;
      align-items: flex-start;
      margin-bottom: size(12);
      max-width: size(339);   
      position: relative;
      padding-left: size(46);
      @include media(down,$breakpoint-xs){
        padding-left: size(36);
      }
      .profile-img{ 
        width: 36px;
        height: 36px;
        border-radius: 100%;
        margin-right:size(10);
        margin-bottom: 0;
        border:1px solid rgba($extra-light-gray,0.4);
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        @include media(down,$breakpoint-xs){
          width: 25px;
          height: 25px;
        }
        img{
          border-radius: 100%;
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%;
        }
      }
      .msg{
        padding: size(15);
        background-color: $extra-light-gray;
        @include media(down,$breakpoint-xs){
          padding: size(10);
        }
        p{
          color: $black;
          margin-bottom: $margin-bottom-5;
          text-align: left;
          font-size: size(13);
          max-width: size(339);
          word-wrap: break-word;
          white-space: pre-line;
        }
        .time{
          color: $light-gray;
          font-size: size(11);
          text-align: left;
          display: block;
        }
        .uploaded-imgbox{
          &.multipost{
            .imgbox{
              width: 100px;
               height: 100px;
               &:nth-child(2n+1){
                margin-left: 0px;
              }
            }
          
          }
          display: flex;
          align-items: center;
          margin-bottom: $margin-bottom-10;
          max-width: 240px;
          flex-wrap: wrap;
          @include media(down,$breakpoint-xs){
            flex-wrap: wrap;
          }
          .imgbox{
            width: 100px;
            //height: 72px;
            border-radius: $border-radius-8;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px;
            &:first-child{
              margin-left: 0px;
              @include media(down,$breakpoint-xs){
                margin: size(10) size(0);
              }
            }
            img{
              border-radius: $border-radius-8;
              object-fit: cover;
              object-position: center;
              width: auto;
              height: 100%;
            }
          }
          .imgbox-group{
            position: relative;
            cursor: pointer;
            overflow: hidden;
            .img-count{
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: rgba($black,0.55);
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              color: $white;
              font-size: size(16);
              span{
                margin-left: size(3);
                font-size: size(11);
                &::before{
                  color: $white;
                }
              }
            }

          }
        }
        .uploaded-file-wrap{
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: $margin-bottom-10;
          padding-right: size(35);
          position: relative;
          @include media(down,$breakpoint-xs){
            padding-right: 0;
          }
          .file{
            display: flex;
            align-items: center;
            min-width: 200px;
            @include media(down,$breakpoint-xs){
              min-width: inherit;
              align-items: flex-start;
              flex-direction: column;
            }
            .iconbox{
              margin-right: size(10);
              margin-bottom: $margin-bottom-5;
              width: 40px;
              height: 40px;
              border-radius: $border-radius-4;
              background-color: $white;
              display: flex;
              align-items: center;
              justify-content: center;
              @include media(down,$breakpoint-xs){
                width: 25px;
                height: 25px;
              }
              span{
                &::before{
                  color: $black;
                }
                @include media(down,$breakpoint-xs){
                  font-size: size(15);
                }
              }
            }
            p{
              margin-bottom: 0;
            }
          }
          .download-btn{
            position: absolute;
            right: 0;
            top: 0px;
            min-height: inherit;
            min-width: inherit;
            width: size(32);
            height: size(32);
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: size(5);
            margin-left: size(10);
            border-radius: 100%;
            @include media(down,$breakpoint-xs){
              width: 28px;
              height: 28px;
              top: 0px;
              position: inherit;
              display: none;
            }
            span{
              &::before{
                color: $black;
              }
            }
          }
        }
        .videobox-imgbox{
          width: 170px;
          height: 100px;
          border-radius: $border-radius-12;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          margin-bottom: 10px;
          .video-overlay{
            background-color: rgba($black,0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 100%;
            height: 100%;
            .btn{
              min-width: inherit;
              min-height: inherit;
              width: size(32);
              height: size(32);
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgba($white,0.3);
              border-radius: 100%;
              padding: size(5);
            }
          }
        }
      }
      &.receive-msg{
        .profile-img{
          margin-right: size(10);
        }
        .msg{
          border-radius: size(0) size(12) size(12) size(12);
        }
      }
      &.sent-msg{
        float: right;
        padding-left: 0;
        padding-right: size(46);
        justify-content: flex-end;
        .profile-img{
          margin-left: size(10);
          position: absolute;
          right: 0;
          top: 0;
          left: auto;
          margin-right: 0;
        }
        .msg{
          border-radius:  size(12) size(0) size(12) size(12);
          background-color: $primary;
          p{
            color: $white;
            white-space: pre-line;
          }
          .uploaded-file-wrap{
            .file {
              .iconbox{
                background-color: rgba($white,0.2);
                span{
                  &::before{
                    color: $white;
                  }
                }
              }
            }
            .download-btn{
              background-color: rgba($white,0.2);
              span{
                &::before{
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
  .msg-type-input-outer{
    padding: size(10) size(30);
    @include media(down, $breakpoint-xs-xs){
      padding: size(10) size(15);
    }
    .msg-type-input-wrap{
      position: relative;
      display: flex;
      align-items: center;
      textarea{
        width: 100%;
        background-color: $extra-light-gray;
        color: $black;
        font-size: size(16);
        line-height: size(22);
        letter-spacing: size(-0.25);
        border-radius: size(50);
        border: none;
        padding: size(12) size(60);
        min-height: size(60);
        resize: none;
        @include media(down, $breakpoint-sm-sm){
          min-height: inherit;
          padding: size(12) size(50);
        }
      }
      .add-btn{
        position: absolute;
        left: 20px;
        font-size: size(20);
        padding: 0;
        min-width: inherit;
        min-height: inherit;
        span{
          &::before{
            color: $primary;
          }
        }
      }
      .send-msg-btn{
        position: absolute;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: $primary;
        border-radius: 100%;
        min-width: inherit;
        min-height: inherit;
        span{
          font-size: size(17);
          &::before{
            color: $white;
          }
        }
      }
    }
  }
}
.spacer-line {
  border-top: 1px solid rgba(101, 101, 101, 0.1);
  margin: size(20) size(0);
}
.text-ellipsis-2line{
  white-space: normal;
  // display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  height: 50px;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-ellipsis-1line{
  white-space: normal;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  height: 22px;
  margin: 0 auto;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.no-data-card{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  p{
    margin-top: $margin-top-30;
  }
}
.msg-upload-icons{
  padding: size(30) size(30) size(10);
  li{
    display: flex;
    align-items: center;
    div{
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      background: #f1f1f1;
      justify-content: center;
      padding: 10px;
      border-radius: 5px;
      margin-right: 10px;
      font-size: 24px;
      }
      img{
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }
    div.play-icon-btn{
      position: relative;
      .btn{
        position: absolute;
        left: 50%;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: auto;
        min-height: auto;
        min-width: auto;
        transform: translate(-50%, -50%);
        z-index: 2;
        .icon-icon-play:before{
          color: #797979;
        }
      }
    }
}
