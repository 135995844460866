.profile-edit-add-modal{
  max-width: 710px !important;
  .custom-checkbox{
    margin-bottom: $margin-bottom-20;
  }
  textarea{
    min-height: 140px;
    resize: vertical;
  }
  .add-info-btnbox{
    .btn{
      min-height: auto;
      &:hover{
        text-decoration: none;
      }
    }
  }
  .btnbox{
    flex-direction: column;
    margin-top: $margin-top-40;
    .btn-lg{
      max-width: 370px;
    }
  }
  .search-result{
    li{
      display: flex;
      align-items: center;
      margin-bottom: $margin-bottom-15; 
      p{
        margin: 0 !important;
        padding-left: size(15); 
        font-weight: $font-semibold;
        text-align: left !important;
      }
    }
    .img-box{
      width: 40px;
      height: 40px;
      border-radius: 100%;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-bottom: $margin-bottom-15;
      }
    }
  }
}
