.add-investors-list {
    ul {
        li {
            a {
                font-size: size(16);
                line-height: size(26);
                color: $black;
                letter-spacing: size(-0.25);
                font-weight: $font-regular;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                @include media(down, $breakpoint-sm-sm) {
                    flex-direction: column;
                    align-items: flex-start;
                    margin-top: $margin-top-10;
                }
                span {
                    color: $light-gray;
                    margin-left: size(10);
                    @include media(down, $breakpoint-sm-sm) {
                        margin-left: size(0);
                    }
                }
            }
        }
    }
}

.add-round-startup {
    margin-top: $margin-top-20;
}

.tabset-line-ui {
    .nav-tabs {
        border-bottom: none;
        margin: 0 size(-5);
        .nav-item {
            padding: 0 size(5);
        }
        .nav-link {
            border: none;
            color: $white;
            border-radius: $border-radius-24;
            background-color: $disabled;
            padding: 0;
            font-size: 0;
            line-height: 0;
            height: 5px;
            width: 100%;
            display: block;
            &.active {
                background-color: $primary;
            }
        }
    }
    .tab-content {
        margin-top: $margin-top-30;
    }
    .note-text {
        font-size: size(13);
        line-height: size(22);
        letter-spacing: size(-0.03);
        text-align: left;
        margin: 20px 0;
        p {
            margin-bottom: 0;
        }
    }
    .upload-img {
        display: flex;
        justify-content: center;
        img {
            border-radius: $border-radius-12;
        }
        iframe{
            width: 100%;
            max-width: 350px;
            min-height: 400px;
        }
    }
}

.confirm-details-section {
    text-align: left;
    h5 {
        font-size: size(16);
        line-height: size(26);
        letter-spacing: size(-0.25);
        color: $primary;
        margin-bottom: $margin-bottom-20;
        font-weight: $font-semibold;
    }
    h4 {
        font-size: size(15);
        line-height: size(22);
        letter-spacing: size(-0.12);
        color: $primary;
        margin-bottom: $margin-bottom-5;
        font-weight: $font-semibold;
    }
    p {
        font-size: size(15);
        line-height: size(22);
        letter-spacing: size(-0.12);
        color: $black;
        margin-bottom: size(0) !important;
        font-weight: $font-regular;
    }
    table {
        border: 1px solid $extra-light-gray;
        margin-bottom: 0;
        th {
            background: $extra-light-gray;
            font-size: size(15);
            line-height: size(22);
            letter-spacing: size(-0.12);
            color: $primary;
            font-weight: $font-semibold;
        }
        td {
            font-size: size(15);
            line-height: size(22);
            letter-spacing: size(-0.12);
            color: $black;
            font-weight: $font-regular;
        }
        &.table-md {
            th {
                background: transparent;
            }
            tr {
                &:nth-child(even) {
                    background: $extra-light-gray;
                }
            }
        }
    }
    ;
    .confirm-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba($primary, 0.05);
        padding: size(10) size(20);
        h3 {
            font-size: size(18);
            line-height: size(28);
            letter-spacing: size(-0.25);
            color: $black;
            margin-bottom: 0;
        }
        a {
            color: $light-gray;
            &:hover {
                color: $primary;
            }
        }
    }
    .detail-list {
        border-bottom: 1px solid $extra-light-gray;
        padding: size(20);
        p {
            text-align: left;
            margin-bottom: $margin-bottom-20 !important;
            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
    .founding-team {
        padding: size(20);
        .member-info {
            border: 1px solid $extra-light-gray;
            border-radius: $border-radius-12;
            padding: size(20);
            margin-bottom: $margin-bottom-30;
            height: calc(100% - 30px);
            p{
                text-align: left;
            }
            .left-img-avtar {
                margin-bottom: $margin-bottom-20;
                .imgbox {
                    position: relative;
                    .play-ic {
                        width: 20px;
                        height: 20px;
                        border-radius: $rounded-pill;
                        background: $primary;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        span {
                            font-size: size(9);
                            margin-left: size(3);
                        }
                    }
                }
            }
            .avtar-title {
                h5 {
                    font-size: size(18);
                    line-height: size(28);
                    letter-spacing: size(-0.25);
                    color: $black;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.teaser-preview-modal {
    text-align: center;
    .upload-img {
        img {
            border-radius: $border-radius-12;
        }
    }
    .file-name {
        margin-top: $margin-top-30;
        span {
            font-size: size(15);
            line-height: size(22);
            letter-spacing: size(-0.12);
            color: $black;
            font-weight: $font-regular;
            display: block;
        }
    }
    .btnbox {
        margin-top: $margin-top-50;
    }
}

.confirm-teaser-modal {
    .btnbox {
        flex-wrap: wrap;
        margin-bottom: 0;
        margin-top: $margin-top-30;
        .btn-primary {
            margin-bottom: $margin-bottom-30;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    textarea.form-control {
        resize: vertical;
    }
}

.add-investor-modal {
    .send-invite {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: $margin-bottom-20;
        span {
            font-size: size(18);
            line-height: size(28);
            letter-spacing: size(-0.25);
            color: $light-gray;
        }
        a {
            font-size: size(15);
            line-height: size(22);
            letter-spacing: size(-0.12);
            color: $primary;
            font-weight: $font-semibold;
        }
    }
    .btnbox {
        margin-top: $margin-top-30;
    }
} 