.documents-list{
  margin-top: $margin-top-50;
  .module-search-wrap{
    position: sticky;
    top: 70px;
    z-index: 9;
    .module-search{
      position: relative;
      span{
        left: 24px;
      }
    }
  }
  .left-tabs{
    .nav-link{
      padding: size(14) size(20);
    }
  } 
  .card{
    h6{
      font-weight: $font-semibold;
    }
    .usage{
      font-size: size(13);
      font-weight: $font-semibold;
    }
  }
  .storage{
    .btn{
      width: 114px;
      min-height: 40px;
      font-size: size(15);
      padding: size(7) size(16);
      border-radius: $border-radius-4;
      margin-top: $margin-top-20;
      font-weight: $font-regular;
    }
  }
  .module-search-wrap{
    @include media(down, $breakpoint-lg){
      padding-bottom: $padding-bottom-30;
    }
    .module-search{
      input{
        padding: size(12) size(15) size(12) size(60); 
      }
      span{
        @include media(down, $breakpoint-lg){ 
          left: 42px;
        }
      }
    }
  } 
} 
.storage-bar{
  padding: size(18) size(0);
  span{
    width: 20px;
    display: inline-block;
    padding-right: size(40);
  }
  .progress{
    height: 8px;
    border-radius: $border-radius-12;
  }
}
.documents-types{
  h5{
    margin-bottom: $margin-bottom-30;
    font-size: size(20);
  }
  .icon-folder-icon{
    font-size: size(105);
    position: relative;
    &.icon-white{
      .path1{
        &:before{
          color: $white;
          position: absolute;
          left: -115px;
          @include media(down, $breakpoint-xsww){
            left: -57px;
            bottom: 0;
          }
        }
      }
    }
  }
  .icons-wrap{
    border: 1px solid $extra-light-gray;
    padding: size(35) size(5) size(25) size(5);
    .icon-info{
      width: 34px;
      height: 34px;
      line-height: 34px;
      background-color: $extra-light-gray;
      border-radius: 100%;
      display: inline-block;
      font-size: size(15);
      position: absolute;
      left: 50%;
      @include css3-prefix (transform, translate(-50%, -50%));
      top: 55%;
      &:before{
        color: $primary;
      }
    }
    h6{
      margin: size(32) size(0) size(0);
      @include media(down, $breakpoint-md){
        font-size: size(15);
        margin: size(15) size(0) size(0);
      }
      @include media(down, $breakpoint-xsww){
        font-size: size(14);
      }
    }
    .img-wrap{
      position: absolute;
      left: 50%;
      @include css3-prefix (transform, translate(-50%, -50%));
      top: 43%;
      max-width: 34px;
      img{
        border-radius: 100%;
      }
    }
  }
}
.share-with-me{
  .documents-types{
    &.rect-wrap{
      [class*=col-]:nth-child(n+3){
        @include media(down, $breakpoint-xl){
          margin-top: 0 !important;
        }
      }
      [class*=col-]:nth-child(n+4){
        @include media(down, $breakpoint-xl){
          margin-top: $margin-top-20 !important;
        }
      }
      [class*=col-]:nth-child(n+3){
        @include media(down, $breakpoint-sm-sm){
          margin-top: $margin-top-20 !important;
        }
      }
      [class*=col-]:nth-child(n+2){
        @include media(down, $breakpoint-sm-sm){
          margin-top: size(0) !important;
        }
      }
      [class*=col-]:nth-child(n+3){
        @include media(down, $breakpoint-sm-sm){
          margin-top: $margin-top-20 !important;
        }
      }
    }
    .rect-box{
      padding: size(0);
      border: 0;
    }
  }
}
.recent-documents{
  h5{
    margin-bottom: $margin-bottom-20;
  }
  &.rect-wrap{
    [class*=col-]{
      &:nth-child(n + 3){
          margin-top: $margin-top-30;
      }
      &:nth-child(n + 2){
        @include media(down, $breakpoint-xl){
        margin-top: $margin-top-15;
        }
      } 
    }
  }
  .rect-box{
    .info-bar {
      .content-wrap{
        max-width: 220px;
        @include media(down, $breakpoint-xsww){
          max-width: 160px;
        }
      }
    }
  } 
  .gray-dot{
    width: 3px;
    height: 3px;
    margin: size(0) size(4);
  }
  .s-info{
    .name{
      color: $black;
      padding-left: size(3);
    }
  }

}