//!IMPORTANT NOTE:Please take a note that whenever have used comman word in comments there is common css for all pages, before any changes in  this css  please make sure any UI getting not disturb

/*comman search bar icon*/
.job-search {
  &.module-search-wrap {
    .module-search {
      span {
        @include media(down, $breakpoint-xsw) {
          left: 22px;
        }
      }
      input {
        padding: size(12) size(12) size(12) size(70);
        color: $black;
        @include media(down, $breakpoint-sm-sm) {
          padding: size(12) size(12) size(12) size(50);
        }
      }
    }
  }
}
/*End of comman search bar icon*/
/*comman use job-nav-outer*/
.job-nav-outer {
  position: relative;
  margin-bottom: $margin-bottom-30;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: $white;
    display: block;
    height: 64px;
    z-index: -1;
  }
}
/*End of comman use job-nav-outer*/
/*comman use header tabbing*/
.jobs-header-nav {
  position: relative;
  padding-right: size(130);
  display: flex;
  align-items: flex-start;
  .jobs-nav {
    display: flex;
    align-items: center;
    &.left-tabs {
      flex-direction: row;
    }
    .nav-link {
      border-left: none;
      border-bottom: 2px solid transparent;
      padding: size(20) size(15);
      line-height: size(22);
      font-weight: $font-semibold;
      &.active {
        border-left: none;
        border-bottom: 2px solid $primary;
        color: $primary;
      }
    }
  }
  .create-job-btn {
    min-height: 40px;
    font-size: size(15);
    position: absolute;
    right: 0;
    top: 13px;
    padding: size(7) size(10);
    border-radius: $border-radius-4;
    &:hover {
      .icon-icon-create-job {
        &:before {
          color: $primary;
        }
      }
    }
  }
}
/*End of comman use header tabbing*/
.job-card {
  .name-wrap {
    display: flex;
    align-items: center;
    margin-bottom: $margin-bottom-10;
    .imgbox {
      width: size(32);
      height: size(32);
      min-width: size(32);
      border-radius: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: size(10);
      img {
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }
    h5 {
      font-size: size(18);
      margin-bottom: 0;
    }
  }
  h4 {
    @include media(up, $breakpoint-xl) {
      font-size: size(22);
    }
  }
  .folio-dt {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-transform: uppercase;
    font-size: size(12);
    color: $light-gray;
    font-weight: $font-bold;
    span {
      &.cerified-text {
        margin-left: 5px;
        color: $primary;
        span {
          font-size: size(16);
          vertical-align: -2px;
        }
      }
    }
  }
  &.job-detail-card {
    .title {
      position: relative;
      padding-right: size(80);
      .shar-like-btns {
        margin-left: 15px;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        .btn {
          min-height: inherit;
          min-width: inherit;
          &:last-child {
            margin-left: size(20);
          }
          span {
            font-size: size(20);
            &:before {
              color: $black;
            }
          }
        }
      }
    }
  }
}
/*comman img with short info*/
.posted-by-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .media {
    @include media(down, $breakpoint-sm) {
      margin-bottom: $margin-bottom-20;
      width: 100%;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
    .imgbox {
      margin-right: size(10);
      border-radius: 100%;
      overflow: hidden;
      flex-shrink: 0;
      @include media(down, $breakpoint-sm) {
        margin-bottom: $margin-bottom-15;
        margin-right: size(0);
      }
    }
    .media-info {
      .rating-wrap {
        @include media(down, $breakpoint-sm) {
          justify-content: center !important;
        }
      }
    }
    h6 {
      margin-bottom: 0;
      @include media(down, $breakpoint-sm) {
        margin-bottom: $margin-bottom-5;
      }
    }
    .rate-count {
      font-size: size(11);
      line-height: size(11);
      font-weight: $font-semibold;
      text-transform: uppercase;
    }
  }
  .btnbox {
    @include media(down, $breakpoint-sm) {
      justify-content: center;
      width: 100%;
    }
  }
  .add-chat-btns {
    display: flex;
    align-items: center;
    @include media(down, $breakpoint-sm) {
      justify-content: center;
      width: 100%;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      min-height: inherit;
      min-width: inherit;
      span {
        font-size: size(14);
      }
      &.add-btn {
        background-color: $primary;
        color: #fff;
        margin-right: size(20);
      }
      &.chat-btn {
        background-color: $extra-light-gray;
        color: $black;
      }
      &.yellow-btn {
        background-color: $yellow;
        margin-right: size(20);
        span {
          &:before {
            color: $white;
            font-size: size(16);
          }
        }
      }
      &.remove-btn {
        background-color: $danger;
        margin-right: size(20);
        span {
          &::before {
            color: $white;
          }
        }
      }
    }
  }
}
/*End of comman img with short info*/
/*comman Attachments images and icon*/
.folio-media {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    li {
      .thumb-wrap {
        width: 60px;
        height: 60px;
        border-radius: $border-radius-12;
        border: 1px solid $extra-light-gray;
        position: relative;
        background-color: $extra-light-gray;
        display: flex;
        flex: none;
        align-items: center;
        justify-content: center;
        font-size: size(24);
        margin-right: size(10);
        overflow: hidden;
        margin-bottom: $margin-bottom-10;
        &.pdf-thumb-img {
          span {
            &::before {
              color: $primary;
            }
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
        &.video-thumb {
          position: relative;
          &:after {
            content: "";
            position: absolute;
            background-color: rgba($black, 0.3);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: $border-radius-12;
          }
          .video-btn-play {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            border-radius: 100%;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: 99;
            cursor: pointer;
            border: 0;
            background-color: rgba(0, 0, 0, 0.1);
            min-width: inherit;
            min-height: inherit;
            @include css3-prefix(transform, translateY(-50%));
            span {
              font-size: size(11);
            }
          }
        }
      }
    }
  }
}
/*End comman Attachments images and icon*/
.apply-job-card {
  text-align: center;
  h5 {
    margin-bottom: $margin-bottom-10;
  }
  p {
    font-size: size(15);
  }
  .btn {
    max-width: 310px;
    margin: size(10) auto 0;
  }
}
.create-icon {
  font-size: size(18);
  padding-right: size(10);
  vertical-align: middle;
}
/*comman Search bar reduce padding from Ipad*/
.module-search-wrap-sm-pd {
  @include media(down, $breakpoint-lg) {
    padding-bottom: $padding-bottom-30;
  }
}
/*End of comman search bar reduce padding from Ipad*/
.usd-label {
  right: 19px;
  top: 18px;
}
/*comman list countary name, dollar, months etc*/
.duration {
  li {
    font-weight: $font-semibold;
    font-size: size(15);
    margin-right: size(20);
    @include media(down, $breakpoint-xl) {
      margin-bottom: $margin-bottom-5;
    }
    @include media(down, $breakpoint-sm) {
      margin-bottom: $margin-bottom-15;
    }
    [class*="icon-"] {
      margin-right: size(8);
      font-size: size(22);
      vertical-align: middle;
      &::before {
        color: $primary;
      }
    }
  }
}
/*End of comman list countary name, dollar, months etc*/
.proposal-card {
  .duration {
    li {
      margin-right: size(40);
    }
  }

  .btn {
    + {
      .btn {
        margin-top: $margin-top-30;
      }
    }
  }
}
.req-status {
  span {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: size(13);
    margin: size(0) size(10);
    cursor: pointer;
    @include media(down, $breakpoint-xs-xs) {
      width: 35px;
      height: 35px;
      font-size: size(12);
      margin: size(0) size(5);
    }
    &::before {
      color: $white;
    }
  }
}
.offers-wrap {
  .duration {
    + {
      .duration {
        margin-top: $margin-top-20;
      }
    }
  }
}
.setup-milestones {
  .bottom-wrap {
    padding-top: size(100);
  }
}
.doc-list-wrap {
  .duration {
    margin-bottom: $margin-bottom-20;
    li {
      margin-right: size(55);
      @include media(down, $breakpoint-sm-sm) {
        margin-right: size(30);
        margin-bottom: $margin-bottom-30;
      }
      &:last-child {
        @include media(down, $breakpoint-sm-sm) {
          margin-bottom: $margin-bottom-5;
        }
      }
    }
  }
}
.setup-milestones-list {
  .btn-wrap {
    .btn + .btn {
      @include media(down, $breakpoint-lg) {
        margin-top: $margin-top-15;
      }
    }
  }
}
/*comman remove padding on class when use accordion*/
.card-panel {
  .card {
    padding: size(0);
    .panel-heading {
      padding: size(0);
    }
  }
}
/*End of comman remove padding on class when use accordion*/
/*comman milestones section*/
.milestones {
  &.card {
    padding: size(30) size(30) size(0) size(30);
    @include media(down, $breakpoint-xs-xs) {
      padding: size(30) size(15) size(10) size(15);
    }
    .card-header {
      border-bottom: 0;
    }
    .panel-open {
      .icon-icon-arrow-down {
        @include css3-prefix(transform, rotate(-180deg));
      }
    }
  }
  .milestone-wrap {
    border-left: 1px solid $extra-light-gray;
    margin-top: $margin-top-30;
  }
  .milestones-info-box {
    margin-bottom: $margin-bottom-30;
    margin-left: size(35);
    position: relative;
    border-radius: size(13);
    .triangle {
      position: absolute;
      top: 18px;
      left: -9px;
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
      border-right: 9px solid rgba($yellow, 0.2);
    }
    &.warning-box {
      background-color: rgba($yellow, 0.2);
      &:before {
        background-color: $yellow;
      }
      .triangle {
        border-right: 9px solid rgba($yellow, 0.2);
      }
      hr {
        border-top: 1px solid rgba($light-gray, 0.2);
      }
      .info-bar {
        @include media(down, $breakpoint-xs-xs) {
          border: none;
        }
      }
      .date {
        @include media(down, $breakpoint-xs-xs) {
          border: none;
        }
      }
    }
    &.awaiting-box {
      background-color: rgba($switch-gray, 0.2);
      &:before {
        background-color: $switch-gray;
      }
      .triangle {
        border-right: 9px solid rgba($switch-gray, 0.2);
      }
      hr {
        border-top: 1px solid rgba($light-gray, 0.2);
      }
      .info-bar {
        @include media(down, $breakpoint-xs-xs) {
          border: none;
        }
      }
      .date {
        @include media(down, $breakpoint-xs-xs) {
          border: none;
        }
      }
    }
    &.success-box {
      background-color: rgba($success, 0.2);
      &:before {
        background-color: $success;
      }
      .triangle {
        border-right: 9px solid rgba($success, 0.2);
      }
      hr {
        border-top: 1px solid rgba($light-gray, 0.2);
      }
      .info-bar {
        border: none;
      }
      .date {
        @include media(down, $breakpoint-xs-xs) {
          border: none;
        }
      }
    }
    &.danger-box {
      background-color: rgba($danger, 0.2);
      &:before {
        background-color: $danger;
      }
      .triangle {
        border-right: 9px solid rgba($danger, 0.2);
      }
      hr {
        border-top: 1px solid rgba($light-gray, 0.2);
      }
      .info-bar {
        border: none;
      }
      .date {
        @include media(down, $breakpoint-xs-xs) {
          border: none;
        }
      }
    }
    &::before {
      content: "";
      position: absolute;
      background-color: $extra-light-gray;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      left: -42px;
      top: 21px;
    }
    .date {
      min-width: 87px;
      text-align: center;
      padding: size(30) size(0);
      @include media(down, $breakpoint-xs-xs) {
        border-left: 1px solid rgba($light-gray, 0.2);
        padding: size(20) size(0) size(0);
      }
      span {
        display: block;
        font-size: size(13);
        font-weight: $font-semibold;
      }
      strong {
        font-size: size(22);
      }
    }
    .info-bar {
      border-left: 1px solid rgba($light-gray, 0.2);
      padding: size(30) size(30) size(30) size(35);
      word-break: break-word;
      @include media(down, $breakpoint-xs-xs) {
        padding: size(20);
      }
      .due {
        font-size: size(12);
        color: $light-gray;
        font-weight: $font-bold;
        text-transform: uppercase;
        min-width: 75px;
        @include media(down, $breakpoint-sm-sm) {
          margin: size(3) size(0) size(0);
        }
        @include media(down, $breakpoint-xs-xs) {
          margin: size(0) size(0) size(5);
        }
      }
      h5 {
        margin-bottom: size(12);
        @include media(down, $breakpoint-sm-sm) {
          font-size: size(16);
          padding-right: size(10);
        }
        @include media(down, $breakpoint-xs-xs) {
          margin: size(0) size(0) size(5);
        }
        span {
          @include media(down, $breakpoint-xs-xs) {
            display: block;
            padding-left: size(0) !important;
          }
        }
      }
      .folio-media {
        .icon-icon-photo {
          font-size: size(21);
        }
        .icon-icon-pdf {
          font-size: size(22);
        }
        .thumb-img {
          background-color: $white;
          [class^="icon-"] {
            &::before {
              color: $primary;
            }
          }
          &.more-items {
            background-color: rgba($black, 0.7);
            .digit {
              color: $white;
              position: absolute;
            }
          }
        }
      }
    }
    .text-warning {
      color: $yellow !important;
    }
  }
  .panel-collapse {
    .card-body {
      padding: size(0);
    }
  }
}
/*End comman of milestones section*/

// Proposals Received card
.proposals-received {
  .posted-by-wrap {
    align-items: flex-start;
    flex-wrap: nowrap;
    @include media(down, $breakpoint-sm-sm) {
      flex-wrap: wrap;
    }
    .media {
      .media-info {
        padding-top: $padding-top-10;
        padding-right: size(10);
        word-break: break-word;
      }
      .imgbox {
        margin-right: size(20);
        min-width: 100px;
      }
    }
    .add-chat-btns {
      @include media(down, $breakpoint-sm-sm) {
        margin-top: $margin-top-15;
      }
      .btn {
        width: auto;
        height: auto;
        border-radius: $border-radius-4;
      }
    }
  }
  .posted-by-wrap-list {
    padding: size(30);
    border: 1px solid $extra-light-gray;
    border-radius: $border-radius-12;
    @include media(down, $breakpoint-sm-sm) {
      padding: size(15);
    }
    hr {
      margin-left: size(125);
    }
    .offer-list-wrap {
      margin-left: size(125);
      @include media(down, $breakpoint-sm-sm) {
        margin-left: size(0);
      }
      .btn {
        @include media(down, $breakpoint-xsw) {
          margin-bottom: $margin-bottom-15;
        }
      }
      .btn-group {
        .dropdown {
          .card-msg-nav-dropdown {
            &::before {
              left: 18px;
              right: auto;
            }
          }
        }
      }
    }
  }
}
// Post jobs detail page
.jobs-post-details {
  .milestones {
    .milestones-info-box {
      .info-bar {
        .folio-media {
          .thumb-img {
            [class^="icon-"] {
              &:before {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}
// ongoing-jobs page
.ongoing-jobs {
  .btn {
    &.btn-accept,
    &.btn-reject {
      min-height: 30px;
      min-width: max-content;
      padding: size(3) size(9);
    }
  }
}
//My Proposals page
.my-proposals {
  .duration-wrap {
    .duration {
      + .duration {
        padding-top: $padding-top-15;
      }
    }
    span {
      min-width: 135px;
    }
  }
}
//My disputes page
.disputes {
  @include media(down, $breakpoint-sm-sm) {
    .time-status {
      margin-bottom: $margin-bottom-10;
    }
  }
}
//My disputes detail
.disputes-detail {
  .job-card {
    .name-wrap {
      .imgbox {
        width: size(48);
        height: size(48);
        min-width: size(48);
      }
    }
  }
  .milestones {
    .milestones-info-box {
      .info-bar {
        padding: size(20) size(20) size(4) size(20);
      }
      &.success-box {
        margin: size(0);
        &::before {
          display: none;
        }
      }
    }
    .milestone-wrap {
      border-left: 0;
      margin-top: size(4);
    }
  }
  .card {
    .content-wrap {
      padding-left: size(55);
    }
  }
  .comment-form {
    textarea {
      min-height: size(120);
    }
  }
}
// add-milestone-modal
.modal {
  .add-milestone-modal {
    .modal-content {
      .modal-card {
        padding: size(70);
      }
    }
  }
}
// Upload Deliverable
.upload-documents {
  background-color: $extra-light-gray;
  height: size(180);
  border-radius: $border-radius-12;
  position: relative;
  input {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    cursor: pointer;
  }
  .upload-wrap {
    span {
      font-size: size(44);
    }
  }
}
.upload-deliverable-modal {
  .uploaded-doc-wrap {
    border: 1px solid rgba($light-gray, 0.5);
    border-radius: $border-radius-4;
  }
  .folio-media {
    align-items: center;
    flex-wrap: nowrap;
    padding: size(20) size(20) size(10) size(20);
    h6 {
      word-break: break-all;
    }
  }
  .progressbar-wrap {
    .progress-bar {
      border-radius: $border-radius-4;
    }
    .progress {
      height: size(8);
      background-color: $disabled;
    }
  }
}
// Send counter
.send-counter-offer-modal {
  .top-bar {
    padding: size(30);
    @include media(down, $breakpoint-xs-xs) {
      padding: size(15);
    }
  }
  .modal-content {
    .modal-card {
      &.modal-md {
        padding: size(70);
      }
    }
  }
  .posted-by-wrap {
    .media {
      .imgbox {
        min-width: 48px;
      }
    }
  }
  textarea {
    min-height: size(135);
  }
}
// select-sevrice-provider
.select-sevrice-provider {
  .media-info {
    .imgbox {
      border-radius: 100%;
      overflow: hidden;
      display: inline-block;
      margin: size(22) size(0) size(20);
    }
  }
  .t-title {
    line-height: 1.6;
  }
  .offers-wrap {
    .duration {
      hr {
        margin: size(30) size(0);
      }
    }
    .text-gray {
      @include media(down, $breakpoint-xs-xs) {
        margin: size(0) !important;
      }
    }
  }
  .add-chat-btns {
    margin-top: $margin-top-30;
    .btn {
      @include media(down, $breakpoint-xs-xs) {
        margin: size(0) !important;
      }
      [class*="icon-"] {
        position: absolute;
        left: 20px;
        top: 20px;
      }
      + .btn {
        @include media(down, $breakpoint-xs-xs) {
          margin-top: $margin-top-10 !important;
        }
      }
    }
  }
}
// edit-milestone-modal
.edit-milestone-modal {
  hr {
    margin: size(30) size(0);
  }
}
// need-help-modal
.modal {
  .need-help-modal {
    .modal-content {
      .modal-card {
        padding: size(70);
      }
    }
    .checkmark {
      border: 1px solid $light-gray;
      top: -2px;
    }
    textarea {
      height: 150px;
    }
  }
}

/* right sidebar filter responsive */
.responsive-filter-btn-wrap {
  display: none;

  @include media(down, $breakpoint-lg) {
    display: flex;
    justify-content: flex-end;
  }
}
.icon-icon-filter {
  margin-right: size(7);
  position: relative;
  &.filtered {
    &::after {
      content: "";
      background: #e73945;
      width: 8px;
      height: 8px;
      border-radius: 50rem;
      position: absolute;
      left: 11px;
      top: -1px;
    }
  }
}
.job-sidebar-card {
  .close-filter-btn-wrap {
    display: none;
    margin-bottom: 10px;
    position: absolute;
    right: 10px;
    top: 10px;
    .btn {
      min-height: inherit;
      min-width: inherit;
      padding: 4px 10px;
      line-height: normal;
      font-size: size(12);
    }
    @include media(down, $breakpoint-lg) {
      display: block;
    }
  }
  .filter-heading-wrap {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  @include media(down, $breakpoint-lg) {
    right: -100%;
    position: fixed;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    top: 70px;
    height: calc(100vh - 70px);
    overflow: auto;
    z-index: 5;
    box-shadow: 0 0 5px rgb(0, 0, 0, 0.2) !important;
    max-width: 320px;
    width: 100%;
    background-color: $white;
    &.show {
      right: 0;
      transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
    }
  }
}
