/* ============================================================================= Header Start */  
header{  
    position: fixed;
    top: 0;
    width: 100%;
    background-color: $white;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 70px;
    z-index: 1050;
  .container-fluid{
    padding: size(0) size(100);
    @include media(down, $breakpoint-xxl){
      padding: size(20) size(50);
    }
    @include media(down, $breakpoint-x-xl){
      padding: size(20) size(15);
    }
  }
  .search-result{
    max-height: 240px;
    overflow: scroll;
    position: absolute;
    background: $white;
    left: 0;
    right: 0;
    padding: 0;
    box-shadow: 0 4px 6px rgb(32 33 36 / 28%);
    z-index: 99999999;
    border-bottom-left-radius: size(12); 
    border-bottom-right-radius: size(12);
    li{
        padding: size(15) size(20);
    }
    @include media(down, $breakpoint-lg){
        margin: size(0) size(15);
    }
  }
  .nav-wrap{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      .header-logo{
        max-width: 172px;
        img{
          width: 100%;
        }
        @include media(down, $breakpoint-sm-sm){
          max-width: 100px ;
        }
      }
      .menu{
          display: flex;
          align-items: center;
          .close-btnbox{
            display: none;
            @include media(down, $breakpoint-lg){
              display: block;
              position: fixed;
              right: 15px;
              top: 15px;
              cursor: pointer;
            }
          }
          @include media(down, $breakpoint-lg){
              background-color: $white;
              position: fixed;
              left: 0;
              top: 0;
              z-index: 100;
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: 0;
              -webkit-transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
              transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
              -webkit-transform: scale(0);
                      transform: scale(0);
              &.act {
                opacity: 1;
                -webkit-transform: scale(1);
                        transform: scale(1);
              }
              &.act{
                ul{
                  li{
                    opacity: 1;
                    -webkit-transform: translateX(0);
                    transform: translateX(0);
                  }
                }
                
              }
              ul {
                display: flex;
                flex-direction: column;
                li {
                  padding: size(8) size(0);
                  -webkit-transition: all 400ms 510ms;
                  transition: all 400ms 510ms;
                  opacity: 1;
                }
              }
            }
          ul{
            display: flex;
            align-items: center;
            li{
              padding: size(0) size(15);
              @include media(down, $breakpoint-xl){
                padding: size(0) size(8);
              }
              @include media(down, $breakpoint-lg){
                padding: size(15) size(8);
              }
              a{                
                color: $black;
                line-height: 22px;
                font-size: $font-15;
                text-transform: uppercase;
                &:hover{
                  text-decoration: none;
                  color: $primary;
                }
                @include media(down, $breakpoint-xl){
                  font-size: $font-14;
                }
                @include media(down, $breakpoint-lg){
                  font-size: $font-20;
                }
              }
            }
          }
      }
  }
  .hdr-login-wrap{
    display: flex;
    align-items: center;
    a{
      margin-left: size(30);
      font-size: $font-15;
      color: $black;
      line-height: 22px;
      font-weight: $font-semibold;
      @include media(down, $breakpoint-xl){
        font-size: $font-14;
        margin-left: size(12);
      }
      &:hover{
        color: $primary;
      }
      &.btn-primary{
        color: $white;
        border-radius: $border-radius-4;
        min-width: 90px;
        min-height: 40px;
        line-height: normal;
        padding: size(8) size(15);
        &:hover{
          background-color: $white;
          color: $primary;
          border: 1px solid $primary;
          box-shadow: none;
        }
        @include media(down, $breakpoint-xl){
          min-width: inherit;
          padding: size(6) size(10);
          min-height: inherit;
          font-size: $font-14;
        }
        @include media(down, $breakpoint-sm-sm){
          padding: size(4) size(8);
        }
      }
    }
  }
  .befor-login-menu-btn{
    display: none;
    @include media(down, $breakpoint-lg){
      display: block;
      margin-left: size(15);
      cursor: pointer;
      border: 1px solid $primary;
      border-radius: $border-radius-4;
      padding: size(5);
      min-height: 32px;
      line-height: 12px;
      min-width: 32px;
      span{
        &:before{
          color:$primary;
        }
      }
    }
    @include media(down, $breakpoint-sm-sm){
      padding: 4px 5px;
        min-height: 27px;
        line-height: 12px;
        min-width: 28px;
    }
  }
  .back-to-home-btn{
    margin-right: size(25);
    font-weight: $font-semibold;
    display: flex;
    align-items: center;
    font-size: size(13);
    @include media(down, $breakpoint-sm-sm){
        margin-right: size(10);
    }
    &:hover{
        text-decoration: none;
    }
    span{
        font-size: size(13);
        margin-right: size(20);
        @include media(down, $breakpoint-sm-sm){
            margin-right: size(10);
            font-size: size(12);
        }
        &::before{
            color: $primary;            
        }
    }
  }
}

.nav-header{
    background: $white;
    .container-fluid{
        padding: size(10) size(100);
        @include media(down, $breakpoint-xxl){
            padding: size(10) size(50);
        }
        @include media(down, $breakpoint-x-xl){
            padding: size(10) size(15);
        }
        @include media(down, $breakpoint-lg){
            padding: size(19) size(15);
        }
        @include media(down, $breakpoint-sm-sm){
            padding: size(15);
        }
    }
    .nav-login-wrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .header-logo{
        display: flex;
        align-items: center;
        .nav-icon{
            margin-right: size(20);
            @include media(down, $breakpoint-sm-sm){
                margin-right: size(10);
            }
            a:hover{
                span::before{
                    color: $primary;
                }
            }
        }
        .logo-nav{
            img{
                max-width: inherit;
                @include media(down, $breakpoint-sm-sm){
                    max-width: 110px;
                }
            }
        }
    }
    .header-search{
        max-width: 1170px;
        width: 100%;
        position: relative;
        margin: size(0) size(40);
        @include media(down, $breakpoint-lg){
            display: none;
        }
        input{
            width: 100%;
            background-color: $extra-light-gray;
            color: $light-gray;
            font-size: size(16);
            line-height: size(26);
            letter-spacing: size(-0.25);
            border-radius: $border-radius-12;
            border: none;
            padding: size(12) size(50) size(12) size(60);
        }
        .icon-icon-search{
            position: absolute;
            left: 16px;
            top:16px;
            font-size: size(16);
            z-index: 10;
        }
        .search-close{
            right: 25px;
            left: auto;
        }
        ng-select{
            height: 50px;
            .ng-arrow-wrapper{
                display: none;
            }
            .ng-option-disabled{
                display: none !important;
            }
            .ng-option{
                display: flex;
                align-items: center;
                .img-box{
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                    overflow: hidden;
                    margin-right: 10px;
                    border: 1px solid $extra-light-gray;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .ng-select-container{
                padding-left: 50px !important;
                height: 100% !important;
                .ng-input{
                    left: 30px !important;
                    position: absolute;
                    width: calc(100% - 30px) !important;
                    height: 100%;
                    top: 0 !important;
                    input{
                        margin-top: 0;
                        height: 100%;
                        color: $light-gray !important;
                    }
                }
                .ng-clear-wrapper{
                    position: absolute;
                    right: 0;
                    display: block !important;
                }
            }
        }
    }
    .header-right{
        display: flex;
        align-items: center;
        .box-icon{
            margin-right: size(25);
            @include media(down, $breakpoint-sm-sm){
                margin-right: size(12);
            }
            @include media(down, $breakpoint-xsww){
                margin-right: size(5);
            }
            a{
                position: relative; 
                @include transition-0-2s;
                span{
                    font-size: size(18);
                    @include media(down, $breakpoint-sm-sm){
                        font-size: size(16);
                    }
                }
                &:hover{
                    span::before{
                        color: $primary;
                    }
                }
                .red-dots{
                    background: $danger;
                    width: 8px;
                    height: 8px;
                    border-radius: $rounded-pill;
                    position: absolute;
                    right: -2px;
                    top: -2px;
                }
            }
        }
        .video-box {
            a{
                span{
                    font-size: size(22);
                    @include media(down, $breakpoint-sm-sm){
                        font-size: size(20);
                    }
                }
            }
        }
        .chat-box, .notification-box{
            @include media(down, $breakpoint-sm){
                .btn-group{
                    position: inherit;
                }
                .dropdown-menu::before{
                    display: none;
                }
            }
        }
        .user-profile{
            border-left: 1px solid $gary;
            padding-left: size(25);
            @include media(down, $breakpoint-sm-sm){
                padding-left: size(12);
            }
            @include media(down, $breakpoint-xsww){
                padding-left: size(5);
            }
            .profile-dropdown{
                display: flex;
                align-items: center;
                img{
                    width: 32px;
                    height: 32px;
                    object-fit: cover;
                    border-radius: $rounded-pill;
                    max-width: inherit;
                    @include media(down, $breakpoint-sm-sm){
                        width: 28px;
                        height: 28px;
                    }
                }
                span{
                    font-size: size(12);
                    padding-left: size(6);
                    @include media(down, $breakpoint-sm-sm){
                        font-size: size(10);
                        padding-left: size(4);
                    }
                }
            }
        }
        .header-mobile-search{
            display: none;
            @include media(down, $breakpoint-lg){
                display: block;
            }
            .search-box{
                position: absolute;
                left: 0px;
                right: 0px;
                top: 70px;
                background: $white;
                padding: size(10);
                border-top:1px solid $extra-light-gray ;
                display: none;
                @include media(down, $breakpoint-sm-sm){
                    top: 58px;
                }
                input{
                    width: 100%;
                    background-color: $extra-light-gray;
                    color: $light-gray;
                    font-size: size(16);
                    line-height: size(26);
                    letter-spacing: size(-0.25);
                    border-radius: $border-radius-12;
                    border: none;
                    padding: size(12) size(45) size(12) size(45);
                }
                .icon-icon-search{
                    position: absolute;
                    left: 25px;
                    top:26px;
                    font-size: size(16);
                }
                .icon-icon-close{
                    position: absolute;
                    right: 25px;
                    top:26px;
                    font-size: size(16);
                }
                ng-select{
                    .ng-arrow-wrapper{
                        display: none;
                    }
                    .ng-option-disabled{
                        display: none !important;
                    }
                    .ng-option{
                        display: flex;
                        align-items: center;
                        .img-box{
                            width: 50px;
                            height: 50px;
                            border-radius: 100%;
                            overflow: hidden;
                            margin-right: 10px;
                            border: 1px solid $extra-light-gray;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}
.siderbar-wrap{
    position: fixed;
    top: 0;
    left: -100%;
    bottom: 0;
    background: $white;
    max-width: 493px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    z-index: 99999999999;
    @include transition-0-5s;
    &.sidebar-in{
        left: 0;
    }
    .sidebar-menu-wrap{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin-left: -100%;
        max-width: 500px;
        width: 100%;
        @include transition-0-5s;
        &.slide-menu-open{
            margin-left: 0;
        }
    }
    .sidebar-inner{
        padding: size(70) size(44) size(100) size(43);
        @include media(down, $breakpoint-sm-sm){
            padding: size(30);
        }   
    }
    .logo-wrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        img{
            @include media(down, $breakpoint-sm-sm){
                max-width: 120px;
            }
        }
        .sidebar-close{
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f5f5f5;
            border-radius: $rounded-pill;
            @include transition-0-2s;
            &:hover{
                background-color: $primary;
                color: $white;
            }
            @include media(down, $breakpoint-sm-sm){
                width: 40px;
                height: 40px;
            }
            span{
                font-size: size(14);
                @include media(down, $breakpoint-sm-sm){
                    font-size: size(12);
                }
            }
        }
    }
    .sidebar-nav{
        margin-top: size(80);
        @include media(down, $breakpoint-sm-sm){
            margin-top: $margin-top-30;
        }   
        li{
            padding-bottom: size(45);
            @include media(down, $breakpoint-sm-sm){
                padding-bottom: $padding-bottom-20;
            }
            &:last-child{
                padding-bottom: size(0);
            }
            a{
                color: $black;
                font-size: size(20);
                line-height: size(28);
                font-weight: $font-regular;
                letter-spacing: size(-0.34);
                display: flex;
                align-items: center;
                @include transition-0-2s;
                @include media(down, $breakpoint-sm-sm){
                    font-size: size(16);
                }
                span{
                    font-size: size(28);
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 32px;
                    height: 32px;
                    margin-right: size(30);
                    @include transition-0-2s;
                    &::before{
                        @include transition-0-2s;
                        color: $light-gray;
                    }
                    @include media(down, $breakpoint-sm-sm){
                        font-size: size(20);
                        width: 20px;
                        height: 20px;
                        margin-right: size(15);
                    }
                    &.icon-Icon-suitcase {
                        font-size: size(27);
                        @include media(down, $breakpoint-sm-sm){
                            font-size: size(20);
                        }
                    }
                    &.icon-icon-meeting {
                        font-size: size(32);
                        @include media(down, $breakpoint-sm-sm){
                            font-size: size(22);
                        }
                    }
                }
                &:hover, &.active{
                    color: $primary;
                    span{
                        &::before{
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
    .user-profile-nav{
        background: $extra-light-gray;
        padding: size(50);
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media(down, $breakpoint-sm-sm){
            padding: size(30);
        }
        .profile-info{
            display: flex;
            align-items: center;
            .profile-img{
                flex: 0 0 auto;
                border-radius: 100%;
                overflow: hidden;
                img{
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                    @include media(down, $breakpoint-sm-sm){
                        width: 40px;
                        height: 40px;
                    }
                }
            }
            .user-info{
                margin-left: size(20);
                width: calc(100% - 80px);
                @include media(down, $breakpoint-sm-sm){
                    margin-left: size(15);
                    width: calc(100% - 40px);
                }
                h4{
                    color: $primary;
                    margin-bottom: size(4);
                    text-align: left;
                }
                p{
                    color: $black;
                    margin-bottom: size(0);
                    word-break: break-word;
                    text-align: left;
                }
            }
        }
        .profile-setting{
            a{
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $white;
                border-radius: $rounded-pill;
                @include media(down, $breakpoint-sm-sm){
                    width: 40px;
                    height: 40px;
                }
                span{
                    font-size: size(20);
                    &::before{
                        color: $black;
                    }
                    @include media(down, $breakpoint-sm-sm){
                        font-size: size(16);
                    }
                }
                &:hover{
                    background-color: $primary;
                    span{
                        &:before{
                            color: $white;
                        }
                    }
                }
            }
        }
    }
    .sidebar-settings-wrap{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin-left: -100%;
        max-width: 500px;
        width: 100%;
        @include transition-0-5s;
        &.slide-menu-open{
            margin-left: 0;
        }
        .setting-header{
            display: flex;
            align-items: center;
            a{
                font-size: size(22);
                line-height: size(30);
                color: $primary;
                font-weight: $font-bold;
                letter-spacing: size(-0.4);
                span{
                    font-size: 16px;
                    margin-right: size(20);
                    &::before{
                        color: $primary;
                    }
                }
            }
        }
        .setting-menu{
            margin-top: size(80);
            @include media(down, $breakpoint-sm-sm){
                margin-top: $margin-top-30;
            }
            .setting-item{
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid $extra-light-gray;
                margin-bottom: $margin-bottom-20;
                padding-bottom: $padding-bottom-20;
                .setting-info{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    .setting-icon{
                        width: 48px;
                        height: 48px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: $extra-light-gray;
                        border-radius: $rounded-pill;
                        flex: 0 0 auto;
                        @include media(down, $breakpoint-sm-sm){
                            width: 40px;
                            height: 40px;
                        }
                        span{
                            font-size: size(22);
                            color: $primary;
                            &::before{
                                color: $primary;
                            }
                            @include media(down, $breakpoint-sm-sm){
                                font-size: size(18);
                            }
                            &.icon-icon-credit-card, &.icon-icon-email{
                                font-size: size(18);
                            }
                        }
                    }
                    .setting-title{
                        margin-left: size(15);
                        width: 100%;
                        h4{
                            font-size: size(20);
                            line-height: size(28);
                            font-weight: $font-regular;
                            letter-spacing: size(-0.34);
                            color: $black;
                            margin-bottom: size(4);
                            @include media(down, $breakpoint-sm-sm){
                                font-size: size(16);
                                line-height: size(24);
                            }
                        }
                        p{
                            font-size: size(15);
                            line-height: size(22);
                            font-weight: $font-regular;
                            letter-spacing: size(-0.12);
                            color: $light-gray;
                            margin-bottom: size(0);
                            @include media(down, $breakpoint-sm-sm){
                                font-size: size(14);
                                line-height: size(20);
                            }
                        }
                        // .tooltip-box{
                        //     display: flex;
                        //     align-items: center;
                        //     justify-content: space-between;
                        //     flex-wrap: wrap;
                        //     @include media(down, $breakpoint-sm-sm){
                        //         align-items: inherit;
                        //         justify-content: inherit;
                        //         flex-direction: column;
                        //     }
                        //     h4{
                        //         align-items: center;
                        //         display: flex;
                        //     }
                        //     .tooltip-icon{
                        //         margin-left: size(5);
                        //     }
                        //     .tooltip{
                        //         &.show{
                        //             opacity: 1;
                        //         }
                        //         .tooltip-inner{
              
                        //         }
                        //     }
                        //     .primary-link{
                        //         text-decoration: none;
                        //         font-size: size(13);
                        //         line-height: size(18);
                        //         letter-spacing: size(-0.03);
                        //         font-weight: $font-semibold;
                        //     }
                        // }
                        .status{
                            font-size: size(11);
                            text-transform: uppercase;
                            font-weight: $font-semibold;
                            line-height: size(16);
                            letter-spacing: size(0.1);
                            &.verified{
                                color: $success;
                            }
                            &.request{
                                color: $yellow;
                            }
                        }
                    }
                }
                .setting-view{
                    margin-left: size(15);
                    span{
                        font-size: size(16);
                        color: $black;
                    }
                }
                &:hover{
                    .setting-title{
                        h4{
                            color: $primary;
                        }
                    }
                    .setting-view{
                        span{
                            color: $primary;
                            &::before{
                                color: $primary;
                            }
                        }
                    }
                }
            }
        }
    }
    .tool-trip-wrapper{
        position: relative;
        .tooltip {
            .tooltip-inner{
                background-color:$white;
                text-align: left;
                font-size: size(14);
                color: $black;
                line-height: size(24);
                letter-spacing: size(-0.22);
                max-height: 650px;
                overflow: scroll;
            }
            &.show{
                opacity: 1;
            }
        }
        .tooltip-icon{
            position: absolute;
            top: 4px; 
            right: 110px;
            font-size: size(21);
            cursor: pointer;
            @include media(down, $breakpoint-sm-sm){
                right: 195px;
            }
            @include media(down, $breakpoint-xs-xs){
                right: 165px;
            }
            @include media(down, $breakpoint-xs){
                right: 115px;
            }
            @include media(down, $breakpoint-xsw){
                right: 60px;
            }
            @include media(down, $breakpoint-xsww){
                right: 20px;
            }
        }
        &.verify-your-page{
            .tooltip-icon{
                right: 175px;
                @include media(down, $breakpoint-sm-sm){
                    right: 245px;
                }
                @include media(down, $breakpoint-xs-xs){
                    right: 115px;
                }
                @include media(down, $breakpoint-xs){
                    right: 165px;
                }
                @include media(down, $breakpoint-xsw){
                    right: 112px; 
                }
                @include media(down, $breakpoint-xsww){
                    right: 70px;
                }
            }
        }
    }
}
.body-sidebar-open{
    .overlay-full{ 
        background: rgba(34, 34, 34, 0.10);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}