.meeting-listing{
    margin-top: $margin-top-35;
    .cal-time-events{
        max-height: 500px;
        overflow-y: scroll;
        overflow-x: hidden;
        @include media(down, $breakpoint-xxl-b){
            max-height: 420px;
        } 
    }
    .picker-wrap{
        padding-bottom: $padding-bottom-5;
    }
    .filter-btn-wrap{
        @include media(down, $breakpoint-sm-sm){
            height: 40px;
        }
        .dropdown-menu{
            max-width: 295px;
            top: 53px;
            @include media(down, $breakpoint-sm-sm){
                top: 48px;
            }
        }
    } 
    .search-filter-wrap{
        @include media(down, $breakpoint-lg){
            right: 15px;
        }
        @include media(down, $breakpoint-sm-sm){
            top: 55px;
        }
    }
    .module-search-wrap{
        position: relative;
        .module-search{
            input{
                padding: size(12) size(350) size(12) size(75);
                @include media(down, $breakpoint-lg){
                    padding: size(12) size(295) size(12) size(60);
                }
                @include media(down, $breakpoint-sm-sm){
                    padding: size(12) size(45) size(12) size(50);
                }
                @include media(down, $breakpoint-xs){
                    padding: size(12) size(45) size(12) size(40);
                }
            }
            span{
                &.search-close{
                    @include media(down, $breakpoint-sm-sm){
                        display: block !important;
                        right: 20px;
                        left: auto;
                    }
                }
            }
        }
        .btn{
            min-height: 40px;
            padding: size(7) size(10);
            min-width: 150px;
            font-size: size(15);
            border-radius: $border-radius-4;
            &.btn-reset{
                min-width: 135px;
            }
            &:hover{
                .icon-icon-plush{
                        &::before{
                            color: $primary;
                        }
                }
            }
        }
        .grid-list-icon{
            span{
                font-size:size(14);
                margin-right: size(9);
                &:before{
                    color: $white;
                }
            }
            
        }
    }
    .days-list{
        color: $primary;
        font-size: size(12);
        font-weight: $font-bold;
        margin: size(0) size(0) size(12) size(27);
        display: inline-block;
        @include media(down, $breakpoint-xsw){
            margin: size(0) size(7) size(12) size(18); 
        }
        @include media(down, $breakpoint-xsww){
            margin: size(0) size(0) size(10) size(18); 
        }
    }
    .cal-month-view{
        padding-top: $padding-top-15;
        padding-bottom: $padding-bottom-20;
        border-bottom-left-radius: size(12);
        border-bottom-right-radius: size(12);
        .cal-day-badge{
            display: none;
        }
        .cal-day-cell, .cal-cell-top{
            min-height: 40px;
            max-width: 40px;
            position: relative;
            @include media(down, $breakpoint-xsww){
                min-height: 30px;
                max-width: 30px;
            }
        }
        .cal-day-cell{
            &.cal-today{
                background-color: $primary !important;
                border-radius: 100%;
                .cal-day-number{
                    color: $white;
                    background-color: $primary !important;
                    border-radius: 100%;
                }
            }
            &.cal-out-month{
                .cal-day-number{
                    opacity: 1;
                    color: $light-gray;
                }
            }
            .cal-day-number{
                color: $black;
                opacity: 1;
            }
        }
        .cal-day-number{
            font-size: size(13) !important;
            font-weight: $font-semibold !important;
            margin: 0;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            @include media(down, $breakpoint-xsww){
                min-height: 30px;
                max-width: 30px;
                width: 30px;
                height: 30px;
                line-height: 30px;
            }
        }
        .cal-cell, .cal-cell-row{
            border: none !important;
        }
        .cal-cell{
            margin: size(0) size(4.2) size(5) size(4.2);
            &.date-active{
                background-color:$gary;
                border-radius: 100%;
            }
            &:hover{
                border-radius: 100%;
            }
            &.cal-has-events{
                &.cal-open{
                    &:hover{
                        border-radius: 100%;
                        background-color:$gary !important;
                    }
                }
                &.cal-event-highlight{
                    background-color:transparent !important;
                }
            }
        }
        .cal-cell-row{
            margin: size(0) size(30) size(0) size(15);
            @include media(down, $breakpoint-xsw){
                margin: 0;
            }
            @include media(down, $breakpoint-xsww){ 
                margin: size(0) size(30) size(0) size(16);
            }
        }
        .cal-event{
            position: absolute;
            right: 15px;
            bottom: 2px;
            text-align: center;
            width: 5px;
            height: 5px;
            background-color: $danger !important;
            @include media(down, $breakpoint-xsww){ 
                right: 10px;
            }
        }
        .cal-events{
            margin: 0;
            line-height: 0;
        }
        .cal-days{
            border: 0;
        }
    } 
    .profile-media{
        .imgbox{
            margin-right: size(10);
        }
        .media-body{
            h5{
                font-size: size(15);
                color: $black;
                font-weight: $font-semibold;
                max-width: 194px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            p{
                font-size: size(13);
                max-width: 194px;
                text-overflow: ellipsis;
                overflow: hidden;
                line-height: 2;
                white-space: nowrap;
            }
        }
        .icon-icon-tick{
            color: $primary;
            font-size: size(15);
            padding-top: $padding-top-10;
        }
    }
    .filter-btn-wrap{
        .dropdown-menu{
            .identiy-group{
                border-bottom: 0;
            }
        } 
    }
    .select-options{
        margin-bottom: $margin-bottom-20;
        border-bottom: 1px solid $black;
        .title-span{
            padding-bottom: $padding-bottom-20;
            display: inline-block;
        }
    }
    .no-meeting-view {
        align-items: center;
    }
}
.calender-wrap{
    border-radius: $border-radius-12;
}
.dropdown-calender{
    background-color: $white;
    padding: size(20) size(30);
    border-radius: size(12) size(12) size(0) size(0);
    border-bottom: 1px solid $extra-light-gray;
    @include media(down, $breakpoint-xs-xs){
        padding: size(15);
    }
    .form-control{
        background-color: $white;
        border: none;
        background-image: url('../../images/dropdown-arrow.svg');
        background-repeat: no-repeat;
        background-position: 100% center;
        padding: 0 size(15) 0 0;
        height: auto;
        font-weight: $font-semibold;
        font-size: size(18);
        cursor: pointer;
        width: 140px;
        @include media(down, $breakpoint-xs-xs){
            font-size: size(16);
        }
        &::placeholder{
            color: $black;
        }
    }
}
.date-day-pick-wrap{
    position: relative;
    .btn{
        @include media(down, $breakpoint-sm-sm){
            min-width: auto;
        }
    }
    .today{
        position: absolute;
        right: 30px;
        top: 15px;
        @include media(down, $breakpoint-sm-sm){
            top: 7px;
        }
        @include media(down, $breakpoint-xs-xs){
            position: static;
            margin-bottom: $margin-bottom-15;
        }
        span{
            background-color: $extra-light-gray;
            font-size: size(13);
            width: 104px;
            display: inline-block;
            padding: size(8) size(5);
            border-radius: size(20);
            font-weight: $font-semibold;
            cursor: pointer;
        }
    }
    .picker{
        font-size: size(22);
        font-weight: $font-bold;
        @include media(down, $breakpoint-xl){
            font-size: size(18);
        }
        @include media(down, $breakpoint-sm-sm){
            font-size: size(16);
        }
    }
    .navigator{
        font-size: size(8);
        padding: size(0) size(10);
    }
}
.schduled-calender{
    .tooltip{
        &.show{
            opacity: 1;
        }
        .tooltip-inner{
            background-color:$white;
        }
    } 
    @include media(down, $breakpoint-xl){
        margin-top: $margin-top-30;
    }
    .card{
        padding: size(8) size(0) size(0);
        overflow: hidden;
        @include media(down, $breakpoint-sm-sm){
            padding: size(8) size(15) size(30) size(15);
        }
    }
    .cal-week-view{
        .cal-hour-segment{
            overflow: hidden;
            border-bottom: 1px solid $extra-light-gray !important;
        }
        .cal-hour-segment{
            &:hover{
                background-color: transparent !important;
            }
        }
        .cal-event-container{
            &.my-active{
                height: 141px !important;
            }
        }
    } 
    .cal-week-view{
        .cal-hour:last-child :not(:last-child){
            .cal-hour-segment{
                border-bottom: 1px solid;
                border-bottom-color:$extra-light-gray;
            }
        }
        .cal-time{
            font-size: size(13);
            font-weight: $font-semibold;
            color: $light-gray;
            position: relative;
            border-right: 1px solid $extra-light-gray;
            border-bottom: 1px solid $extra-light-gray;
            height: 141px;
            &::after{
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                left: 116px;
                background-color:$extra-light-gray; 
                height: size(1);
                width: 1200px;
                @include media(down, $breakpoint-sm-sm){
                    left: 56px;
                }
            }
            &.active{
                &::before{
                    background-color: transparent;
                }
                &::after{
                    display: none;
                }
            }
        }
    } 
    .schduled-info{
        padding: size(20);
        background-color: $white;
        border-left: 5px solid $primary;
        max-height: 141px;
        min-height: 141px;
        overflow: hidden;
        box-shadow: size(4) size(4) size(10) size(-2) #dedede;
        margin-right: size(30);
        border-top-right-radius: size(12);
        border-bottom-right-radius: size(12);
        @include media(down, $breakpoint-xl){
            padding: size(12);
        }
        @include media(down, $breakpoint-sm-sm){
            padding: size(5) size(5) size(5) size(12);
        }
        .schduled-img-wrap{
            min-width: 110px;
        }
        .s-info{
            padding-right: size(25);
            @include media(down, $breakpoint-xl){
                padding-right: size(12);
            }
            @include media(down, $breakpoint-sm-sm){
                padding: size(0);
            }
            h5{
                font-weight: $font-semibold;
                max-width: 505px;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: 26px;
                white-space: nowrap;
                @include media(down, $breakpoint-xl){
                    font-size: size(16);
                    margin-bottom: $margin-bottom-5;
                    max-height: 20px;
                    max-width: 665px;
                }
                @include media(down, $breakpoint-lg){
                    max-width: 440px;
                }
                @include media(down, $breakpoint-sm-sm){
                    max-width: 350px;
                }
                @include media(down, $breakpoint-xs-xs){
                    max-width: 240px;
                 margin-bottom: size(0);
                }
                @include media(down, $breakpoint-xsw){
                    max-width: 180px;
                }
                @include media(down, $breakpoint-xsww){
                    max-width: 150px;
                }
            }
            .event-desc{
                max-height: 68px;
                overflow: hidden;
                word-break: break-all;
                @include media(down, $breakpoint-sm-sm){
                    max-height: 55px;
                }
                p{
                    margin: 0;
                    font-size: size(15);
                    line-height: 1.46;
                    @include media(down, $breakpoint-sm-sm){
                        font-size: size(14);
                    }
                }
             }
        }
        .schduled-img{
            img{
                min-width: 24px;
                width: 24px;
                height: 24px;
                border-radius: 100%;
                position: relative;
                margin-left: size(-6);
            }
        }
        .counter{
            background-color: $primary;
            min-width: 24px;
            height: 24px;
            line-height: 24px;
            border-radius: 100%;
            color: $white;
            text-align: center;
            font-size: size(13);
            font-weight: $font-semibold;
            span{
                font-size: size(7);
                font-weight: $font-bold;
            }
        }
        .ac-status{
            font-size: size(12);
            font-weight: $font-bold;
            color: $light-gray;
            padding-bottom: $padding-bottom-10;
            display: inline-block;
            @include media(down, $breakpoint-xs-xs){
                padding-bottom: $padding-bottom-5; 
            }
        }
    }
    .cal-day-view{
        .cal-current-time-marker{
            display: none;
        }
    }
}
