.video-upload-wrap {
  .visible {
    z-index: 1000 !important;
  }
  .k-window {
    @include media(down, $breakpoint-xl) {
      left: 50% !important;
      transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0);
    }
    @include media(down, $breakpoint-sm-sm) {
      max-width: 400px;
      width: 92% !important;
    }
  }
  .k-window-titlebar {
    background-color: $primary;
    .k-window-title{
      cursor: pointer !important;
    }
    button {
      &.k-button-icon {
        &.k-button {
          &.k-bare[aria-label="Maximize"] {
            display: none !important;
          }
          &.k-bare[aria-label="Close"] {
            display: none !important;
          }
        }
      }
    }
  }
  ul {
    li {
      padding: 15px 0;
      margin-bottom: 15px;
      border-bottom: 1px solid #efefef;
      .info-btn-wrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        align-items: center;
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          width: 50%;
          margin-bottom: 10px;
          margin: 0;
          height: 23px;
        }
      }
    }
  }
}
