.hm-sec-welcome{
  position: relative;
  padding: size(70) size(0);
  background-color: $white;
  
  &::before{
    content: '';
    background-image: url("../../images/hm-top-shape-right.svg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 150px;
    height: 208px;
    position: absolute;
    right: 70px;
    top: 50px;
    z-index: 1;
    @include media(down, $breakpoint-xxl) {
      width: 110px;
      height: 145px;
      top: 22px;
    }
    @include media(down, $breakpoint-x-xl){
      width: 80px;
      height: 115px;
      top: 10px;
    }
    @include media(down, $breakpoint-sm-sm){
      width: 58px;
      height: 80px;
      right: 20px;
    }
  }
  &::after{
    content: '';
    background-image: url("../../images/hm-top-shape-left.svg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 233px;
    height: 322px;
    position: absolute;
    left: 36px;
    bottom: -120px;
    z-index: -1;
    @include media(down, $breakpoint-xxl){
      width: 195px;
      height: 285px;
    }
    @include media(down, $breakpoint-x-xl){
      width: 140px;
      height: 200px;
    }
    @include media(down, $breakpoint-sm){
      bottom: -40px;
    }
    @include media(down, $breakpoint-sm-sm){
      width: 100px;
      height: 140px;
    }
  }
  .welcome-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @include media(down, $breakpoint-md){
      flex-wrap: wrap;
    }
    .info{
      max-width: 472px;
      position: relative;
      @include media(down, $breakpoint-md){
        order: 2;
      }
      h1{
        margin-bottom: $margin-bottom-20;
      }
      h3{
        font-weight: $font-medium;
        margin-bottom: $margin-bottom-30;
      }
      .link-group{
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        a{
          color: $primary;
          font-weight: $font-semibold;
          margin-bottom: $margin-bottom-30;
          display: flex;
          align-items: center;
          @include transition-0-2s;
          &:last-child{
            margin-bottom: none;
          }
          &:hover{
            @include transition-0-2s;
            span{
              @include transition-0-2s;
              margin-left:15px;
              &:before{
                color: $primary;
              }
            }
          }
          span{
            margin-left: size(10);
            @include transition-0-2s;
          }
        }
      }
    }
    .imgbox{
      position: relative;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url("../../images/hm-top-img-bg.svg");
      max-width: 720px;
      min-height: 734px;
      width: 100%;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      @include media(down, $breakpoint-md){
        order: 1;
        margin: 0 auto 30px;
      }
      picture{
        position: absolute;
          top: 50%;
          left: 62%;
          transform: translate(-50% , -50%);
          width: 100%;
          max-width: 570px;
        img{
          width: 100%;
          height: auto;
        }
        @include media(down, $breakpoint-sm-sm){
          left: 50%;
        }
      }
      @include media(down, $breakpoint-xl){
        max-width: 458px;
        min-height: 530px;
      }
      @include media(down, $breakpoint-lg){
        max-width: 319px;
        min-height: 375px;
      }
      @include media(down, $breakpoint-sm-sm){
        max-width: 319px;
        min-height: 335px;
      }
    }
  }
}
.sec-title-wrap{
  margin-bottom: 70px;
  background-color: $white;
  h2{
    margin-bottom: $margin-bottom-10;
  }
  h3{
    font-weight: $font-medium;
    margin-bottom: $margin-bottom-30;
  }
}
.sec-hm-getstarted{
  padding: size(150) size(0);
  background-color: $white;
  @include media(down, $breakpoint-xl){
    padding: size(100) size(0);
  }
  @include media(down, $breakpoint-lg){
    padding: size(70) size(0);
  }
  .sec-title-wrap{
    text-align: center;
  }
  .journey-card{
    max-width: 360px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include media(down, $breakpoint-sm-sm){
      margin: size(15) auto size(30);
    }
    .videobox{
      width: 100%;
      height: auto;
      video{
        width: 100%;
        height: 100%;
      }
    }
    .imgbox{
      position: relative;
      .inner-img{
        position: absolute;
        bottom: 15px;
        left: 20px;
      }
    }
    .info{
      text-align: center;
      padding-top: $padding-top-30;
      h4{
        margin-bottom: $margin-bottom-10;
      }
      p{
        line-height: 1.62;
        margin-bottom: $margin-bottom-20;
        letter-spacing: -0.25px;
      }
      
      
    }
    .btn{
      min-width: 139px;
      @include media(down, $breakpoint-sm) {
        margin-top: $margin-top-10 !important;
      }
    }
  }
}

.sec-app-download{
  background-color: $white;
  border-bottom: 200px solid $primary;
  padding: size(200) size(0) size(214);
  position: relative;
  @include media(down, $breakpoint-xxl) {
    padding: size(100) size(0);
    border-bottom: 130px solid $primary;
  }
  @include media(down, $breakpoint-lg) {
    padding: size(70) size(0);
    border-bottom: 0 solid $primary;
  }
  @include media(down, $breakpoint-sm) {
    padding: size(50) size(0);
  }
  .info{
    max-width: 590px;
    @include media(down, $breakpoint-lg) {
      max-width: 100%;
      text-align: center;
    }
    h2{
      margin-bottom: $margin-bottom-30;
      @include media(down, $breakpoint-xxl) {
        margin-bottom: $margin-bottom-20;
      }
    }
    h3{
      font-weight: $font-medium;
      margin-bottom: $margin-bottom-30;
      @include media(down, $breakpoint-xxl) {
        margin-bottom: $margin-bottom-20;
      }
    }
    .btnbox{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      @include media(down, $breakpoint-lg) {
        max-width: 100%;
        justify-content: center;
      }
      
      a{
        &:first-child{
          margin-right: 15px;
        }
        @include media(down, $breakpoint-md) {
          img{
            width: 120px; 
          }
        }
      }
    }
  }
  .imgbox{
    position: absolute;
    right: 0;
    bottom: -100px;
    width: 48%;
    height: auto;
    
    img{
      height: auto;
    }
    .arrow-img{
      position: absolute;
      right: 0;
      top: 0;
      height: auto;
      width: 27%;
      img{
        height: auto;
      }
    }
    @include media(down, $breakpoint-xxl) {
      bottom: -70px;
      width: 42%;
    }
    @include media(down, $breakpoint-xl) {
      bottom: -70px;
      width: 42%;
    }
    @include media(down, $breakpoint-lg) {
      position:inherit;
      max-width: 600px;
      margin: 0 auto 80px;
      bottom: 0;
      width: 100%;
    }
    @include media(down, $breakpoint-md) {
      max-width: 500px;
    }
    @include media(down, $breakpoint-sm) {
      max-width: 380px;
      padding: 15px;
    }
  }
  
}
.achieve-exponential{
  background-color: $primary;
  overflow: hidden;
  @include media(down, $breakpoint-xl) {
    padding:size(100) size(0) size(150);
  }
  @include media(down, $breakpoint-lg) {
    padding:size(70) size(0) size(150);
  }
  .left-bar{
    margin-left: 16.6666666667%;
    @include media(down, $breakpoint-xxxxl){
      margin-left: 10.666667%;
    }
    @include media(down, $breakpoint-xxxl){
      margin-left: 7.666667%;
    }
    @include media(down, $breakpoint-xxl){
      margin-left: 5%;
    }
    @include media(down, $breakpoint-xl){
      margin-left: 20px;
    }
    @include media(down, $breakpoint-sm-sm){
      margin-left: 0;
      text-align: center;
    }
  }
  @include media(up, $breakpoint-xl){
    padding:size(150) size(0) size(120) size(0); 
  }
  .container-fluid{
    @include media(up, $breakpoint-xl){
      padding-right: size(0);
    }
  }
  .left-info-wrap{
    padding: size(30) size(0) size(0) size(21);
    @include media(down, $breakpoint-x-xl){
      padding: size(30) size(0) size(50) size(0);
    }
    @include media(down, $breakpoint-lg){
      padding: size(0) size(0) size(50) size(0);
    }
    h2, h3, .sign-in-btn{
      color: $white;
    }
    h2{
      @include media(down, $breakpoint-xxxxl){
        font-size: size(36);
      }
      @include media(down, $breakpoint-xxxl){
        font-size: size(34);
      }
      @include media(down, $breakpoint-sm-sm){
        font-size: size(28);
      }
      @include media(down, $breakpoint-xs-xs){
        font-size: size(19);
      }
    }
    h3{
      font-weight: $font-medium;
      padding:size(25) size(60) size(30) size(0);
      @include media(down, $breakpoint-xxxxl){
        font-size: size(26);
      }
      @include media(down, $breakpoint-xxxl){
        padding:size(25) size(0) size(30) size(0);
      }
      @include media(down, $breakpoint-sm-sm){
        font-size: size(18);
      }
      @include media(down, $breakpoint-xs-xs){
        font-size: size(16);
      }
    }
    .sign-in-btn{
      span{
        padding-left: size(10);
        vertical-align: middle;
      &::before{
          color: $white;
        }
      }
      &:hover{
        color: $gary;
        span{
          &:before{
            color: $gary;
          }
        }
      }
    }
  }
  .achieve-slider{
    margin-right: -70px;
    @include media(down, $breakpoint-xxxxl){
      margin-right: -250px;
    }
    @include media(down, $breakpoint-xxxl){
      margin-right: -350px;
    }
    @include media(down, $breakpoint-xxl){
      margin-right: -400px;
    }
    @include media(down, $breakpoint-xl){
      margin-right: 0;
    }
    .slick-track{
      padding-left: 70px;
      @include media(down, $breakpoint-xxxxl){
        padding-left: 250px;
      }
      @include media(down, $breakpoint-xxxl){
        padding-left: 350px;
      }
      @include media(down, $breakpoint-xxl){
        padding-left: 400px;
      }
      @include media(down, $breakpoint-xl){
        padding-left: 0;
      }
    }
    .slick-arrow{
      border: 1px solid $white;
      background-image: none !important;
      bottom: 0;
      left: -40%;
      line-height: size(48);
      text-align: center;
      cursor: pointer;
      margin-bottom: $margin-bottom-30;
      @include media(down, $breakpoint-xxxxl){
        left: -33%;
        margin-bottom: size(70);
      }
      @include media(down, $breakpoint-xxxl){
        left: -30%;
        margin-bottom: size(38);
      }
      @include media(down, $breakpoint-xxxl){
        margin-bottom: size(110);
      }
      @include media(down, $breakpoint-xxl){
        margin-bottom: $margin-bottom-50;
        left: -28%;
      }
      @include media(down, $breakpoint-xl){
        bottom: -120px !important;
        left: 45% !important;
      }
      @include media(down, $breakpoint-lg) {
        bottom: -140px !important;
        line-height: size(40);
        left: 41% !important;
      }
      @include media(down, $breakpoint-xs-xs) {
        line-height: size(30);
        left: 40% !important;
        font-size: size(11);
      }
      @include media(down, $breakpoint-xsw) {
        left: 39% !important;
      }
      &.icon-Icon-arrow-right{
        bottom: 0;
        margin-left: size(75);
        @include media(down, $breakpoint-xxxxl){
          margin-left:size(80);
        }
        @include media(down, $breakpoint-sm-sm){
          margin-left:size(55);
        }
        @include media(down, $breakpoint-xs-xs){
          margin-left:size(40);
        }
      }
      &::before{
        color: $white;
      }
      &:hover{
        border-color:$gary;
        &:before{
          color: $gary;
        }
      }
    }
    .slick-list{
      @include media(down, $breakpoint-xl){
        padding:0 !important;
      }
      @include media(down, $breakpoint-lg){
        max-width: 100%;
      }
      .slick-track{
        .slick-slide{
          img{
            border-top-right-radius: 15px;
            margin: 0;
            padding:0;
            @include media(down, $breakpoint-x-xl){
              max-width: 100%;
            } 
        }
      }
    }
  }
  .box-wrap{
    border-radius: 15px; 
    overflow: hidden;
    background-color: $light-blue;
    max-width: 348px;
    margin: size(0) size(25);
    @include media(down, $breakpoint-xl){
      margin: size(0) size(15);
    }
  }
  .contnet-box{
    padding: size(30);
    min-height: 190px;
    @include media(down, $breakpoint-xs-xs){
      min-height: 100%;
    }
    h4, p{
      color: $white;
    }
  }
  }
}
.no-padding{
  padding: 0 !important;
}
.started-slider{
  background-color: $white;
}
.investor-slider{
  background-color: $white;
}
.sec-hm-jobgallery{
  background-color: $white;
  background-image: url("../../images/job-gallery-bg.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  .info{
    padding-right: size(65);
    @include media(down, $breakpoint-xl){
      padding-right: 0px;
    }
    @include media(down, $breakpoint-sm-sm){
      text-align: center;
    }
    h2{
      margin-bottom: $margin-bottom-30;
    }
    h3{
      font-weight: $font-medium;
      margin-bottom: $margin-bottom-30;
    }
    a{
      color: $primary;
      font-weight: $font-semibold;
      margin-bottom: $margin-bottom-30;
      display: flex;
      align-items: center;
      @include transition-0-2s;

      @include media(down, $breakpoint-sm-sm){
        display: block;
      }

      &:last-child{
        margin-bottom: none;
      }
      &:hover{
        @include transition-0-2s;
        span{
          @include transition-0-2s;
          margin-left:size(15);
          &:before{
            color: $primary;
          }
        }
      }
      span{
        margin-left: size(10);
        @include transition-0-2s;
      }
    }
  }
  ul{
    li{
        display: flex;
        align-items: center;
        background-color:$white;
        position: relative;
        box-shadow: 0 0 5px rgba(0,0,0,0.12);
        margin-bottom: $margin-bottom-30;
        padding: size(38) size(30);        
        border-radius: $border-radius-12;
        @include media(down, $breakpoint-xl){
          padding: size(20) size(30);  
        }
        span{
          width: 56px;
          height: 56px;
          border-radius: 50px;
          background-color: $extra-light-gray;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: size(30);
          &:before{
            color: $primary;
        }
      } 
      .content{
        width: calc(100% - 90px);
        p{
          margin-bottom: 0;
        }     
      }
    }
  }
}